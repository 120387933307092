import Footer from '../../Footer';
import Header from '../../../frontendPages/Header';



function EditProviderManagementCenter() {
    return (

        <div className="main">

        <Header />
        <div className="parent-main">
            <div className="container">
                <h2>Provider MANAGEMENT  CENTER</h2>
                <div className="form-row dandt mt-0 ai-c">
                    <div className="one-third">
                        <label for="birthday">Provider Types:</label>
                        <select>
                            <option value="0">Select car:</option>
                            <option value="1">Audi</option>
                            <option value="2">BMW</option>
                            <option value="3">Citroen</option>
                        </select>
                    </div>
                    <div className="one-third">
                        <label for="birthday">Provider:</label>
                        <select>
                            <option value="0">Select car:</option>
                            <option value="1">Audi</option>
                            <option value="2">BMW</option>
                            <option value="3">Citroen</option>
                        </select>
                    </div>
                    <div className="one-third">
                    <a href="" className="normal">Select User</a>
                    </div>
                </div>
                <h3 className="blue-heading">MANAGE EXISTING PROVIDER ACCOUNT</h3>
                <div className="log-action">
                    <span>UNLOCK ACCOUNT</span>
                    <span>LOCK ACCOUNT</span>
                    <span>RESET PASSWORD</span>
                </div>
                <h4 className="blue-bg-heading"> Provider’s Online Account</h4>
                <div className="log-detail">
                    <span>
                    <label for="birthday">Provider Name:</label>
                    <label for="birthday">Stovall,Don and Brenda</label>
                    </span>
                    <span>
                    <label for="birthday">User Name:</label>
                    <label for="birthday">donstovall</label>
                    </span>
                    <span>
                    <label for="birthday">Email:</label>
                    <label for="birthday">dbstovall@newtonlbc.org</label>
                    </span>
                    <span>
                    <label for="birthday">Home Training Date:</label>
                    <label for="birthday">4/16/2010</label>
                    </span>
                    <span>
                    <label for="birthday">Account Locked:</label>
                    <label for="birthday">NO</label>
                    </span>
                    <span>
                    <label for="birthday">Account Status:</label>
                    <label for="birthday">Active</label>
                    </span>
                    <span>
                    <label for="birthday">Last Login Date:</label>
                    <label for="birthday">4/16/2010</label>
                    </span>
                </div>
            </div>
        </div>
        <Footer />
    </div>
    );
}




export default EditProviderManagementCenter;
