import '../../Forms/CriticalUnusualIncidentForm/CriticalUnusualIncidentForm.scss'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { post } from '../../../utils/common';
import { useHistory } from 'react-router';
import download from '../../../images/assets/myinfo/download.svg';

import mrinner from '../../../images/assets/formimages/mrinner.svg';

import React, { useRef } from "react";
import ReactToPrint from "react-to-print";

const ComponentToPrint = React.forwardRef((props, ref) => (

    <div className="main" ref={ref}>
        <div className="main-content" >
            <div className="pagewithsidebar">
                <div className="container">
                    <div className="pagewithsidebar-inner">
                        <div className="tab-detail Critical">
                            <div className="tab-detail-top">
                                <div className="Critical-unusual">
                                    <img src={mrinner} alt="" />
                                    Mileage Reimbursement
                                </div>
                            </div>
                            <div className="tab-detail-bottom">
                                <div className="form-with-sidebar">
                                    <form>
                                        <div className="form-row dandt">
                                            <div className="one-third">
                                                <label for="birthday">Person Completing Form:</label>
                                                <select>
                                                    <option value="0">Select:</option>
                                                    {/* <option value="1">Audi</option>
                                                        <option value="2">BMW</option>
                                                        <option value="3">Citroen</option> */}
                                                </select>
                                            </div>
                                            <div className="one-third">
                                                <label for="birthday">Date:</label>
                                                <input type="date" />
                                            </div>
                                            <div className="one-third">
                                                <label for="appt">Trip Begin Time:</label>
                                                <input type="time" />
                                            </div>
                                        </div>

                                        <div className="form-row dandt mt-0 ai-c">

                                            <div className="one-third">
                                                <p>CHILDREN</p>  <br />
                                                <label for="birthday">State:</label>
                                                <select>
                                                    <option value="0">From...</option>
                                                    {/* <option value="1">Audi</option>
                                                        <option value="2">BMW</option>
                                                        <option value="3">Citroen</option> */}
                                                </select>
                                            </div>
                                            <div className="one-third">
                                                <p>SFM AUTHORIZATION</p>  <br />
                                                <label for="birthday">City:</label>
                                                <select>
                                                    <option value="0">From...</option>
                                                    {/* <option value="1">Audi</option>
                                                        <option value="2">BMW</option>
                                                        <option value="3">Citroen</option> */}
                                                </select>
                                            </div>
                                            <div className="one-third">
                                                <span className="check-option">
                                                    <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                    <label for="vehicle2"> Round Trip</label>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-row dandt mt-0">
                                            <div className="one-third">
                                                <label for="birthday">State:</label>
                                                <select>
                                                    <option value="0">To....</option>
                                                    {/* <option value="1">Audi</option>
                                                        <option value="2">BMW</option>
                                                        <option value="3">Citroen</option> */}
                                                </select>
                                            </div>
                                            <div className="one-third">
                                                <label for="birthday">City:</label>
                                                <select>
                                                    <option value="0">To....</option>
                                                    {/* <option value="1">Audi</option>
                                                        <option value="2">BMW</option>
                                                        <option value="3">Citroen</option> */}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="req-update-textarea pt-0 b-0">
                                            <label><span><b>Description:</b></span></label>
                                            <textarea className="m-0"></textarea>
                                        </div>
                                        <div className="form-row dandt mt-0">
                                            <div className="one-third">
                                                <label for="birthday">Total Miles:</label>
                                                <input type="text" />
                                            </div>
                                            <div className="one-third">
                                                <label for="birthday">Rate/Miles:</label>
                                                <input type="text" />
                                            </div>
                                            <div className="one-third">
                                                <label for="birthday">Amount Requested:</label>
                                                <input type="text" />
                                            </div>
                                        </div>

                                        <div className="imp-note mt-25">
                                            <p className="note-p">Mileage should be submitted within 180 days from the date of service.  Mileage outsidepolicy will be denied unless an authorization number is noted.  Please provide a specific description of the purpose of the trip.  Missing information can cause delays in payment.  The reimbursement amount will be adjusted by staff as mileage is amended or denied.</p>
                                        </div>

                                        <div className="req-update-textarea pt-0 mt-25 b-0">
                                            <div className="req-update-action">
                                                <Link href="#" className="close">Close</Link>
                                                <Link href="#" className="normal">Save and Continue</Link>
                                                <Link href="#" className="normal">Submit</Link>
                                            </div>
                                        </div>

                                    </form>
                                    <div className="form-note">
                                        <div className="form-grey">
                                            <ul>
                                                <li>*Note:   If you do not know the SFM Authorization, then you may leave the default set to None.</li>
                                                <li>* Future dates will not be allowed.</li>
                                                <li>* You will only be allowed to select a child if we have them listed as being in your home on the selected date.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

));

const MilageRemburseMentPrintForm = () => {
    const componentRef = useRef();
    return (
        <div >
            <ReactToPrint
                trigger={() =>

                    <Link to="#">Print Form</Link>
                }
                content={() => componentRef.current}
            />
            <div className="hiddenPrintable">
                <ComponentToPrint ref={componentRef} />
            </div>
        </div>
    );
}

export default MilageRemburseMentPrintForm;
