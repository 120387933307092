import "../../Forms/CriticalUnusualIncidentForm/CriticalUnusualIncidentForm.scss";
import { Link } from "react-router-dom";
import { useState } from "react";
import { post } from "../../../utils/common";
import { useHistory } from "react-router";
import download from "../../../images/assets/myinfo/download.svg";

import mrinner from "../../../images/assets/formimages/mrinner.svg";

import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useEffect } from "react";
import DataTable from "react-data-table-component";

const MilageRemburseMentPrintFormView = () => {
  const [data, setData] = useState([]);
  const [HeaderData, setHeaderData] = useState([]);
  const clickedForDownload = async () => {
    const mileageFormData = JSON.parse(
      sessionStorage.getItem("mileageformdata")
    );
    const mileageFormHeaderData = JSON.parse(
      sessionStorage.getItem("mileageformdataHeaderData")
    );
    setHeaderData(mileageFormHeaderData[0]);

    // console.log(lodesformdata);
    setData(mileageFormData);
  };

  // console.log(data);

  const columns = [
    {
      name: "Date",
      selector: row => row.Date,
      sortable: true,
      wrap: true,
    },
    {
      name: "Children",
      selector: row => row.Children,
      sortable: true,
      wrap: true,
    },

    {
      name: "From",
      selector: row => row.From,
      sortable: true,
      wrap: true,
    },

    {
      name: "To",
      selector: row => row.To,
      sortable: true,
      wrap: true,
    },

    {
      name: "Round Trip",
      selector: row => row.Round_Trip,
      sortable: true,
      wrap: true,
    },

    {
      name: "Description",
      selector: row => row.Description,
      sortable: true,
      wrap: true,
    },
    {
      name: "Requested Miles",
      selector: row => row.Total_Miles,
      sortable: true,
      wrap: true,
    },
    {
      name: "Rate",
      selector: row => row.Rate,
      sortable: true,
      wrap: true,
    },
    {
      name: "Amount Requested",
      selector: row => row.Amount_Requested,
      sortable: true,
      wrap: true,
    },
  ];

  useEffect(() => {
    clickedForDownload();
  }, []);

  return (
    <div className="main">
      <div className="main-content">
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <div className="tab-detail Critical pagealignpad">
                <div className="tab-detail-top">
                  <div className="Critical-unusual">
                    <img src={mrinner} alt="" />
                    <span className="headerpad"> Foster Home - Mileage Reimbursement</span>
                   
                  </div>
                </div>
                <div className="mt-50" >
                  <div className="form-row">
                    <div className="label18 increasedFont">
                      {" "}
                      Reimburse to:
                    </div>
                    <div className="label30 tableborderheight increasedFont">
                     <b className="increasedFont">{HeaderData.ProviderName}</b>
                    </div>
                    <div className="label4"></div>
                    <div  className="label18 increasedFont">
                      Address: 
                    </div>
                    <div  className="label30 tableborderheight" >
                      <b className="increasedFont">{HeaderData.ProviderAddress}</b>
                    </div>{" "}
                  </div>

                  <div className="form-with-sidebar mt-25">
                    <div className="form-tab-1 m-bottom widthfull ">
                      <div className="tableborder">
                      <DataTable 
                        columns={columns}
                        data={data}
                        pagination={false}
                      />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="label18 increasedFont">
                    Form completed by:{" "}
                    </div>
                    <div className="label30 tableborderheight">
                    <b className="increasedFont">{HeaderData.ProviderMemberName}</b>
                    </div>
                    <div className="label4"></div>
                    <div  className="label18 increasedFont ">
                    Total Requested {"   "} $:
                    </div>
                    <div  className="label30" >
                    <b className="increasedFont">{HeaderData.Total}</b>
                    </div>
                  </div>
              
                  <div className="imp-note mt-25">
                    <p className="note-p">
                      {" "}
                      Field Staff Approval: Your Resource Worker will review and
                      update the status. A Pending status submits the request to
                      Contract Services. Denials or Revisions will be noted
                      below.
                    </p>
                  </div>
                  <div>
                  <div className="form-row mt-25">
                    <div className="label10 increasedFont">
                    Staff:
                    </div>
                    <div className="label17 tableborderheight">
                    <b className="increasedFont">{HeaderData.Field_Staff}</b>
                    </div>
                    <div  className="label3"></div>
                    <div  className="label10 increasedFont">
                    Status: {"   "} 
                    </div>
                    <div  className="label17 tableborderheight">
                    <b className="increasedFont">{HeaderData.Field_Status}</b>
                    </div>
                    <div  className="label3"></div>
                    <div  className="label10 increasedFont">
                    Notes: {"   "} 
                    </div>
                    <div  className="label30 tableborderheight">
                    <b className="increasedFont">{HeaderData.Field_Staff_Note}</b>
                    </div>{" "}
                  </div>
                  
                  </div>
                  <div className="imp-note mt-25">
                    <p className="note-p">
                      {" "}
                      Contract Services Approval: Contract Services will review
                      and revise, if necessary. Once approved, the request will
                      be paid according to your Foster Parent payment schedule.
                    </p>
                  </div>

                  <div className="form-row mt-25">
                    <div className="label10 increasedFont">
                    Staff:
                    </div>
                    <div className="label17 tableborderheight">
                    <b className="increasedFont">{HeaderData.Contract_Staff}</b>
                    </div>
                    <div  className="label3"></div>
                    <div  className="label10 increasedFont">
                    Status: {"   "} 
                    </div>
                    <div  className="label17 tableborderheight">
                    <b className="increasedFont">{HeaderData.Payment_Status}</b>
                    </div>
                    <div  className="label3"></div>
                    <div  className="label10 increasedFont">
                    Notes: {"   "} 
                    </div>
                    <div  className="label30 tableborderheight">
                    <b className="increasedFont">{HeaderData.Payment_Note}</b>
                    </div>{" "}
                  </div>
                  <div className="imp-note mt-25">
                    <p className="note-p">
                      {" "}
                      Mileage should be submitted within 180 days from the date
                      of service. Mileage outside SFM policy will be denied
                      unless an authorization number is noted. Please provide a
                      specific description of the purpose of the trip. Missing
                      information can cause delays in payment. The reimbursement
                      amount will be adjusted by staff as mileage is revised or
                      denied.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MilageRemburseMentPrintFormView;
