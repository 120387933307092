import { Link } from 'react-router-dom'
import Footer from "../HeaderFooter/Footer/Footer";
import Header from "../HeaderFooter/Header/Header";
import aboutimg from '../../../images/assets/landingpage/sait.png';
import whoweare from "../WhoWeAre/WhoWeAre.scss"
import headerscss from "../../LandingPages/HeaderFooter/Header/Header.scss"



function WhoWeAre() {


  return (
    <div className="flex h-screen overflow-hidden">
      <Header />
      <div className="hero-section">
        <div className="container">
          <div className="hero-text">
            <h2>SAINT FRANCIS MINISTRIES | SFMFAMILIES.ORG</h2>
            <p>SFM Families is an on-line community for our valued foster and kinship families. It's a place to find responsive support, up-to-date information, easily accessible tools and training, and professional resources. If you are not a foster parent and would like to explore this opportunity for service, please contact us. Your decision could bring the change a child needs to reach his or her full potential.</p>
            <div className="action-btn">

            <Link   to={{ pathname: "http://www.kfapa.org/" }} target="_blank" title="More Questions...?">To know more </Link>
              <Link to="/form/fosterparentreferalform" title="Ready to foster">Foster Parent Referral</Link>
              <Link to="/forms/public/powerofyou" title="Recommend/Refer A Family...">Foster Family Application</Link>
            
            </div>
          </div>
          <div className="login-form">
            <h3>My Account</h3>
            <div className="form-row fd-c">
              <div class="log-field">
                <label class="form-label">Username:</label>
                <input type="text" class="form-control" />
              </div>
              <div class="log-field">
                <label class="form-label">Password:</label>
                <input type="password" class="form-control" />
              </div>
              <div className="show-pass">
                <input type="checkbox" id="showpass" name="vehicle3" value="Boat" />
                <label for="showpass"> Show Password</label>
              </div>
              <div class="log-action">
                <a href="#" className="yellow-btn">Login</a>
                <a href="#">forgot Password?</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="whoweare-section">
        <div className="container">
          <div className="whoweare-section-left">
            <p>SFM Families is an on-line community for our valued foster and kinship families. It's a place to find responsive support, up-to-date information, easily accessible tools and training, and professional resources. If you are not a foster parent and would like to explore this opportunity for service, please contact us. Your decision could bring the change a child needs to reach his or her full potential.</p>
          </div>
          <div className="whoweare-section-right">
            <p>SFM Families is an on-line community for our valued foster and kinship families. It's a place to find responsive support, up-to-date information, easily accessible tools and training, and professional resources. If you are not a foster parent and would like to explore this opportunity for service, please contact us. Your decision could bring the change a child needs to reach his or her full potential.</p>
          </div>
        </div>
      </div>
      <Footer />

    </div>
  );
}

export default WhoWeAre;