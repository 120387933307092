import Footer from "../../Footer";
import Header from "../../../frontendPages/Header";
import pdf from "../../../images/assets/pdf.png";
import { useEffect, useState } from "react";

import { post } from "../../../utils/common";
import { Link } from "react-router-dom";
import LoadSpinner from "../../Loader/LoadSpinner";
import SelectSearch, { fuzzySearch } from "react-select-search";
import "../../select-search.css";
import axios from "axios";

function ProviderManagementCenter() {
  const [providerTypeData, setproviderType] = useState([]);
  const [allProviderData, setAllProvider] = useState([]);
  const [providerSession, setProviderSessionData] = useState([]);

  const [typeId, setTypeId] = useState({
    Id: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);

    let data = await fetch("/api/provider/getprovidertype");
    if (!data.ok) {
      return;
    }
    let providerType = await data.json();
    console.log(providerType);
    setproviderType(providerType.data);
    setIsLoading(false);
  };

  const fetchAllProviderData = async () => {
    setIsLoading(true);
    let data = await fetch("/api/provider/getallprovider");
    let allProviderData1 = await data.json();
    let allProviderData = allProviderData1.data;

    let res = allProviderData.map((item) => {
      item.name = item.PName;
      item.value = item.PNumber;
      delete item.PName;
      delete item.PNumber;
      return item;
    });
    setAllProvider(res);

    setIsLoading(false);
  };

  //        //TODO

  //get all provider by selected  provider tyoe
  const getAllProviderByTypeId = async (e) => {
    setIsLoading(true);

    const id = e.target.value;
    if (id == 0) {
      let data = await fetch("/api/provider/getallprovider");
      let allProviderData = await data.json();
      console.log("all provider", allProviderData);

      let allproviderData1 = allProviderData.data;
      let res = allproviderData1.map((item) => {
        item.name = item.PName;
        item.value = item.PNumber;
        delete item.PName;
        delete item.PNumber;
        return item;
      });
      setAllProvider(res);
    } else {
      console.log(id);
      let allProviderDataByTypeId = await post(
        "/api/provider/getallproviderbytypeid",
        { id }
      );
      console.log("all provider by type id", allProviderDataByTypeId);

      let allproviderData1 = allProviderDataByTypeId.data.data;
      let res = allproviderData1.map((item) => {
        item.name = item.ProviderName;
        item.value = item.ProviderID;
        delete item.ProviderName;
        delete item.ProviderID;
        return item;
      });
      setAllProvider(res);
    }
    setIsLoading(false);
  };

  const [creatingProviderSessionData, setcreatingProviderSessionData] =
    useState([]);

  const [showCreateprovider, setShowCreateprovider] = useState(false);

  //TODO
  const [existingAccountData, setExistingAccountData] = useState([]);
  const [showExistingAccount, setShowExistingAccount] = useState(false);

  const [onlineInfo,setOnlineInfo] = useState([])
  const checkProviderAccountExistence = async () => {
    setIsLoading(true);
    const selectedProvider = providerSession.Id;
    let data = await axios
      .post("/api/provider/check-existence", { selectedProvider })
      .then(async (response) => {
        if (response.data.data.length > 0) {
          // console.log(response.data.data.data.length)
          alert("User Account Exist");
          setExistingAccountData(response.data.data[0]);
          setShowExistingAccount(true);
          setShowCreateprovider(false)

          let dataOne = await post("/api/provider/get-online-info", {selectedProvider});
        
          setOnlineInfo(dataOne.data.data[0])
        } else {
          //   alert('nothing here');
          //fetch data from base table where provider id is selected

          let dataOne = await post("/api/provider/getproviderdetailbyid", {selectedProvider});
          if(!dataOne.data.data[0].Email || !dataOne.data.data[0].FedTaxNo){
            alert("Email/FedTax No Should be Present");
            window.location.reload(false);

          }
          setcreatingProviderSessionData(dataOne.data.data[0])
          setShowCreateprovider(true);
          setShowExistingAccount(false);
          setIsLoading(false);
        }
      });

    setIsLoading(false);
  };

  //TODO
  const CreateUser = async () => {
    setIsLoading(true);
    const ProviderId = providerSession.Id;
    let data = await axios
      .post("/api/provider/create-account", {
        creatingProviderSessionData,
        ProviderId,
      })
      .then((response) => {
        if (response) {
          alert("Created");
          setIsLoading(false);
        } else {
          alert("Please try Again");
        }
      });

    setIsLoading(false);
    window.location.reload(false);
  };

  useEffect(() => {
    fetchData();
    fetchAllProviderData();
  }, []);

  // console.log("a", creatingProviderSessionData);
  // console.log("b", providerSession);
  return (
    <div className="main">
      <Header />
      <div className="parent-main">
        <div className="container">
          <h2>PROVIDER MANAGEMENT CENTER</h2>
          <div className="form-row dandt mt-0 ai-c">
            <div className="one-third">
              <label htmlFor="birthday">Provider Types</label>
              <select
                onChange={getAllProviderByTypeId}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                {/* <option value="">All</option> */}

                {providerTypeData.map((providertype) => (
                  <option value={providertype.ProviderTypeID}>
                    {providertype.ProviderType}
                  </option>
                ))}
              </select>
            </div>
            <div className="ot-field">
              <label className="form-label"> Provider:</label>
              {/* <select onChange={e => setProviderSessionData({ ...providerSession, Id: e.target.value })} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">

                                {allProviderData.map(provider => (
                                    <option value={provider.PNumber}>{provider.PName}</option>
                                ))}
                            </select> */}

              <SelectSearch
                options={allProviderData}
                value={providerSession.Id}
                onChange={(e) =>
                  setProviderSessionData({ ...providerSession, Id: e })
                }
                search
                filterOptions={fuzzySearch}
                placeholder="Set Provider"
              />
            </div>

            <div className="one-third">
              <Link
                to="#"
                onClick={checkProviderAccountExistence}
                className="normal"
              >
                Select Provider
              </Link>
            </div>
          </div>

          {isLoading ? <LoadSpinner /> : ""}

          {showCreateprovider ? (
            <div>
              <h3 className="blue-heading">CREATE NEW PROVIDER ACCOUNT</h3>
              <h4 className="blue-bg-heading">
                Create Provider’s Secure Login
              </h4>
              <div className="create-login">
                <span>
                  <label htmlFor="birthday">User Name:</label>
                  <input
                    type="text"
                    onChange={(e) =>
                      setcreatingProviderSessionData({
                        ...creatingProviderSessionData,
                        UserName: e.target.value,
                      })
                    }
                  />
                </span>
                <span>
                  <label htmlFor="birthday">Email:</label>
                  <input
                    type="text"
                    onChange={(e) =>
                      setcreatingProviderSessionData({
                        ...creatingProviderSessionData,
                        Email: e.target.value,
                      })
                    }

                    value={creatingProviderSessionData.Email}
                  />
                </span>
                <span>
                  <label htmlFor="birthday">Home Training Date:</label>
                  <input
                    type="date"
                    onChange={(e) =>
                      setcreatingProviderSessionData({
                        ...creatingProviderSessionData,
                        HomeTrainingDate: e.target.value,
                      })
                    }
                  />
                </span>
                <div className="login-action">
                  <Link href="#" onClick={CreateUser} className="normal">
                    Create User
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          {showExistingAccount ? (
            <div>
              <h3 className="blue-heading">MANAGE EXISTING ACCOUNT</h3>
              <div class="row">
                {/* <div class="column" style={{"float":"left","width":"33.3%"}}><h5>Lock Account</h5></div>
                <div class="column" style={{"float":"left","width":"33.3%"}}><h5>UnLock Account</h5></div>
                <div class="column" style={{"float":"left","width":"33.3%"}}><h5>Reset Password</h5></div> */}
              </div>
              <h4 className="blue-bg-heading">Provider's Online Account</h4>
              <div className="create-login">
                <span>
                  <label htmlFor="birthday">Provider Name:</label>
                  {onlineInfo.providername}
                  {/* <b>{existingAccountData.UserName}</b> */}
                </span>
                <span>
                  <label htmlFor="birthday">User Name:</label>
                  <b>{onlineInfo.UserName}</b>
                  {/* <input
                    type="text"
                    onChange={(e) =>
                      setcreatingProviderSessionData({
                        ...creatingProviderSessionData,
                        UserName: e.target.value,
                      })
                    }
                  /> */}
                </span>
                <span>
                  <label htmlFor="birthday">Email:</label>
                  {onlineInfo.email}
                  {/* <input
                    type="text"
                    onChange={(e) =>
                      setcreatingProviderSessionData({
                        ...creatingProviderSessionData,
                        Email: e.target.value,
                      })
                    }
                  /> */}
                </span>
                <span>
                  <label htmlFor="birthday">Home Training Date:</label>
                  {/* {new DOMParser().parseFromString(onlineInfo.HomeTrainingDate,'text/xml').dateTime} */}
                  {onlineInfo.Home_Training_Date}
                  {/* <input
                    type="date"
                    onChange={(e) =>
                      setcreatingProviderSessionData({
                        ...creatingProviderSessionData,
                        HomeTrainingDate: e.target.value,
                      })
                    }
                  /> */}
                </span>
                <span>
                  <label htmlFor="birthday">Account Locked</label>
                  {onlineInfo.Account_Locked}
                  
                  {/* <b>{existingAccountData.UserName}</b> */}
                </span>
                <span>
                  <label htmlFor="birthday">Account Status</label>
                  {/* <b>{existingAccountData.UserName}</b> */}
                  {onlineInfo.AccountStatus}
                </span>
                {/* <div className="login-action">
                  <Link href="#" onClick={CreateUser} className="normal">
                    Create User
                  </Link>
                </div> */}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ProviderManagementCenter;
