import Footer from '../Footer';
import Header from '../Header';
import accountinformation from '../accountinformation/accountinformation.scss'
import acicon from '../../images/assets/images/Account_Info.svg';
import SideBar from '../sidebar/sidebar';
import HeaderTopDetail from '../CurrentChildren/HeaderTopDetail/HeaderTopDetail';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { post } from '../../utils/common';
import LoadSpinner from '../Loader/LoadSpinner';



function AccountInformation() {

    const [isLoading, setIsLoading] = useState(false);

    const [LocationData, setLocationData] = useState([]);
    const [ProviderData, setProviderData] = useState([])
    const [LicenseData, setLicensedata] = useState([])
    const [LicenseRenewalData, setLicenseRenewalData] = useState([])
    const [TrainingData, setProviderTrainingData] = useState([])

    const [AccountInfo,setAccountInfo] = useState([]);


    const fetchData = async () => {
        setIsLoading(true)
        let providerId = sessionStorage.getItem('providerSessionId');

        let allProviderDataByProviderId = await post('/api/provider/getallproviderdatabyproviderid', { providerId });
        console.log(allProviderDataByProviderId)
        if (allProviderDataByProviderId.data.ProviderLocationData.length !== 0) {
            setLocationData(allProviderDataByProviderId.data.ProviderLocationData[0]);
        }

        if (allProviderDataByProviderId.data.ProviderData.length !== 0) {
            setProviderData(allProviderDataByProviderId.data.ProviderData[0]);
        }

        if (allProviderDataByProviderId.data.ProviderLicenseData.length !== 0) {
            setLicensedata(allProviderDataByProviderId.data.ProviderLicenseData[0]);
        }
        if (allProviderDataByProviderId.data.ProviderLicenseRenewalData.length !== 0) {
            setLicenseRenewalData(allProviderDataByProviderId.data.ProviderLicenseRenewalData[0]);
        }
        if (allProviderDataByProviderId.data.ProviderTrainingData.length !== 0) {

            setProviderTrainingData(allProviderDataByProviderId.data.ProviderTrainingData[0])
        }

        if (allProviderDataByProviderId.data.AccountInfoData.length !== 0) {

            setAccountInfo(allProviderDataByProviderId.data.AccountInfoData[0])
        }
        setIsLoading(false)
    };

    



    useEffect(() => {
        fetchData()

    }, []);





    return (


        <div className="main">

            <Header />

            <div className="main-content" >
                <HeaderTopDetail />
                <div className="pagewithsidebar">
                    <div className="container">
                        <div className="pagewithsidebar-inner">


                            <SideBar />
                            <div className="tab-detail pl">
                                <div className="tab-detail-top">
                                    <div className="m-profile">
                                        <img src={acicon} alt="" />
                                        Account Information
                                    </div>
                                    {/* <Link to="#">Request update</Link> */}
                                </div>
                                <div className="tab-detail-bottom">
                                    <form className="myprofile-form">
                                        <div className="form-row">
                                            <div className="ot-field">
                                                <label className="form-label">Provider Name:</label>
                                                <input type="text" readOnly class="form-control" value={ProviderData.ProviderName ? ProviderData.ProviderName : ' '} className="form-control" id="exampleFormControlInput1" />
                                            </div>
                                            <div className="ot-field">
                                                <label className="form-label">Provider Type:</label>
                                                <input type="text" readOnly class="form-control" value={AccountInfo.ProviderType ? AccountInfo.ProviderType : ' '} className="form-control" id="exampleFormControlInput1" />
                                            </div>
                                            <div className="ot-field">
                                                <label className="form-label">Email Address:</label>
                                                <input type="email" readOnly class="form-control" value={ProviderData.Email ? ProviderData.Email : ' '} className="form-control" id="exampleFormControlInput1" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div class="ot-field">
                                                <label className="form-label">DT Date:</label>
                                                <input type="text" readOnly class="form-control" value={ProviderData.DtDate ? new Date(ProviderData.DtDate).toLocaleDateString() : ' '} className="form-control" id="exampleFormControlInput1"  />   



                                            </div>
                                            <div className="ot-field">
                                                <label className="form-label">Training Type:</label>
                                                <input type="text" readOnly class="form-control" value={AccountInfo.TrainingType ? AccountInfo.TrainingType : ' '} className="form-control" id="exampleFormControlInput1" />
                                            </div>
                                            <div className="ot-field">
                                                <label class="form-label">Training Date:</label>
                                                <input type="text" value={AccountInfo.Trainingdate ? AccountInfo.Trainingdate.slice(0,10) : ' '} id="exampleFormControlInput1" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="ot-field">
                                                <label className="form-label">MAPP Date:</label>
                                                <input type="text" value={ProviderData.MappDate ? ProviderData.MappDate.slice(0,10) : ' '} className="form-control" id="exampleFormControlInput1" />
                                            </div>
                                            <div className="ot-field">
                                                <label className="form-label">Weekly Daycare:</label>
                                                <input type="text" value={AccountInfo.Weekly_DayCare ? AccountInfo.Weekly_DayCare : ' '} className="form-control" id="exampleFormControlInput1" />
                                            </div>
                                        </div>
                                        <div className="form-row check-boxes">
                                            <label for="vehicle1"><input disabled type="checkbox" checked={AccountInfo.EmailConsentDate == true?"checked":''}/> Allow SFM Email</label>
                                            <label for="vehicle1"><input  disabled type="checkbox" checked={AccountInfo.PaySemiMonthly == true?"checked":''}/> Pay Semi-Monthly</label>
                                            <label for="vehicle1"><input  disabled type="checkbox" checked={AccountInfo.IsEmployee == true?"checked":''} /> IS SFM Employee</label>
                                        </div>
                                    </form>
                                </div>
                                {isLoading ? (<LoadSpinner />) : ''}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}




export default AccountInformation;
