function OnlineTrainingSideBar() {
    return (



            <div className="sidebar dnone">
                
            </div>




    );
}
export default OnlineTrainingSideBar;