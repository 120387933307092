import Footer from "../../Footer";
import Header from "../../Header";
import traininginner from "../../../images/assets/myinfo/traininginner.svg";
import download from "../../../images/assets/myinfo/download.svg";
import edittwo from "../../../images/assets/myinfo/edittwo.svg";
// import acmanage from '../../images/assets/images/ManageInfo.svg';
import SideBar from "../../sidebar/sidebar";
import "../Training/Training.scss";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { post } from "../../../utils/common";
import DataTable from "react-data-table-component";
import LoadSpinner from "../../Loader/LoadSpinner";
import mail from "../../../images/mail.png";
import plus from '../../../images/assets/cm/plus.svg';

function Training() {
  const [updateDiv, setShowUpdateDiv] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updateData, setUpdateData] = useState([]);
  const showUpdateDiv = () => {
    setShowUpdateDiv(true);
    setAddDiv(false);
  };

  const hideUpdateDiv = () => {
    setShowUpdateDiv(false);
    setUpdateData([]);
  };

  const [AddDiv, setAddDiv] = useState(false);
 

  // const showAddDiv = () => {
  //     setAddDiv(true)
  //     setShowUpdateDiv(false)
  // }

  const hideAddDiv = () => {
    setAddDiv(false);
    setUpdateData([]);
  };

  const [traingDataByProviderId, settraingDataByProviderId] = useState([]);

  const [selectedForEditRequest, setSelectedForRequestEdit] = useState([]);

  const getTrainingByProviderId = async () => {
    const id = sessionStorage.getItem("providerSessionId");
    let TrainingData = await post(
      "/api/training/getprovidertrainingbyproviderid/",
      { id }
    );
    console.log(TrainingData.data.data);
    settraingDataByProviderId(TrainingData.data.data);
    setUpdateData([TrainingData.data.data]);
    console.log(" Welcome ",sessionStorage.username);
  };

  const onupdateClick = async () => {
    setIsLoading(true);
    updateData.LicenseNo = updateData[0][0].LicenseNo;
    console.log(updateData)
    if (!updateData.LicenseNo) {
      alert("License Number is Mandatory !!");
      setIsLoading(false);
      return false;
     
    }
    let username = sessionStorage.getItem("username");
    let ProviderName = sessionStorage.getItem("ProviderName");
    await post("/api/training/update-training-email", { updateData, username, ProviderName, pdf: updateData.FileName});
    alert("Request Emailed Successfully !");
    console.log(" Welcome ",sessionStorage.username);

    setShowUpdateDiv(true);
   // setUpdateData([]);
    setIsLoading(false);
    window.location.reload(false);
  };

  const onSubmitByRow = async () => {
    setIsLoading(true);

    let username = sessionStorage.getItem("username");
    let ProviderName = sessionStorage.getItem("ProviderName");
    await post("/api/training/update-training-addition", {
      username,
      updateData,
      ProviderName,
    });
    alert("Request Addition  Emailed Successfully !");

    setShowUpdateDiv(false);
    setUpdateData([]);
    setIsLoading(false);
    window.location.reload(false);
  };

  const requestEdit = (j) => {
    setSelectedForRequestEdit(traingDataByProviderId[j]);
    setAddDiv(true);
    setShowUpdateDiv(false);
  };

  useEffect(() => {
    // fetchData()
    getTrainingByProviderId();
  }, []);

  const columns = [
    {
      name: "Name",
      selector: row => row.Name,
      sortable: true,
    },
    {
      name: "Type",
      selector: row => row.Type,
      sortable: true,
    },
    {
      name: "Sponsor",
      selector: row => row.Sponsor,
      sortable: true,
    },
    {
      name: "Training Date",
      selector: row => row.Training_Date,
      // cell:(row, j) =>  (new Date(row.TrainingDate).toLocaleDateString()),
      sortable: true,
    },
    {
      name: "Units",
      selector: row => row.Units,
      sortable: true,
    },
    {
      name: "Relicense Year",
      selector: row => row.Relicense_Year,
      // cell:(row, j) =>  (new Date(row.TrainingDate).toLocaleDateString()),
      sortable: true,
    },
    {
      name: "License No",
      selector: row => row.LicenseNo,
      // cell:(row, j) =>  (new Date(row.TrainingDate).toLocaleDateString()),
      sortable: true,
    },

    // {

    //     name: ' Training Certificate',
    //     selector: '',
    //     sortable: true,
    // },

    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div class="inline-flex">
          <Link
            onClick={() => requestEdit(j)}
            class="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            to="#"
          >
            <img src={mail}></img>
          </Link>
          &nbsp;
          
        </div>
      ),
    },
  ];


  // <Link
  //           className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
  //           to="#"
  //         >
  //           <img src={download}></img>
  //         </Link

  return (
    <div className="main">
      <Header />

      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <SideBar />
              <div className="tab-detail traning pl">
                <div className="tab-detail-top">
                  <div className="training-top">
                    <img src={traininginner} alt="" />
                    <div className="pagesubtitle">
                      Training
                      <p>
                        Below are the trainings you have taken. You may request
                        an addition and submit training documents applicable for
                        your foster home license.
                      </p>
                    </div>
                  </div>
                  <Link to="#" onClick={showUpdateDiv} className="request-btn"><img src={plus} style={{"height": "18px"}} alt="#"/>
                    Request Addition
                  </Link>
                </div>
                {isLoading ? <LoadSpinner /> : ""}
                <div className="tab-detail-bottom">
                  <div className="training-form">
                    {updateDiv ? (
                      <div className="req-update-textarea">
                        <label>
                          <span>
                            <b>Request Addition for</b> - Training <br></br>
                            <br></br>
                            <b> Description for Addition</b>
                          </span>
                        </label>
                        <textarea
                          value={updateData.description}
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                        <form className="child-case-form">
                          <div className="form-col">
                            <div class="ot-field">
                              <label class="form-label">
                                Title of Training:
                              </label>
                              <input
                                type="text"
                                value={updateData.TrainingTitle}
                                onChange={(e) =>
                                  setUpdateData({
                                    ...updateData,
                                    TrainingTitle: e.target.value,
                                  })
                                }
                              ></input>
                            </div>
                            <div class="ot-field">
                              <label class="form-label">
                                Provider taking the course:
                              </label>
                              <input
                                type="text"
                                value={updateData.ProviderTakingTheCourse}
                                onChange={(e) =>
                                  setUpdateData({
                                    ...updateData,
                                    ProviderTakingTheCourse: e.target.value,
                                  })
                                }
                              ></input>
                            </div>
                          </div>
                          <div className="form-col">
                            <div class="ot-field">
                              <label class="form-label">
                                Training Sponsor:
                              </label>
                              <input
                                type="text"
                                value={updateData.TrainingSponser}
                                onChange={(e) =>
                                  setUpdateData({
                                    ...updateData,
                                    TrainingSponser: e.target.value,
                                  })
                                }
                              ></input>
                            </div>
                            <div class="ot-field">
                              <label class="form-label">Training Date:</label>
                              <input
                                type="date"
                                value={updateData.TrainingData}
                                onChange={(e) =>
                                  setUpdateData({
                                    ...updateData,
                                    TrainingData: e.target.value,
                                  })
                                }
                              ></input>
                            </div>
                          </div>
                          <div className="form-col">
                            <div class="ot-field">
                              <label class="form-label">Units:</label>
                              <input
                                type="text"
                                value={updateData.Units}
                                onChange={(e) =>
                                  setUpdateData({
                                    ...updateData,
                                    Units: e.target.value,
                                  })
                                }
                              ></input>
                            </div>
                            <div class="ot-field">
                              <label class="form-label">
                                Training Certificate:
                              </label>
                              <input type="file"
                              onChange={(e) =>
                                setUpdateData({
                                  ...updateData,
                                  FileName: e.target.files[0],
                                })
                              }></input>
                            </div>
                          </div>

                          <div className="form-col">
                            <div class="ot-field">
                              <label class="form-label">License No:</label>
                              <input
                                type="text"
                                value={updateData[0][0].LicenseNo}
                                onChange={(e) =>
                                  setUpdateData({
                                    ...updateData,
                                    LicenseNo: e.target.value,
                                  })
                                }
                              ></input>
                            </div>
                          </div>
                        </form>
                        <div className="req-update-action">
                          <Link
                            to="#"
                            onClick={hideUpdateDiv}
                            className="close"
                          >
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={onupdateClick}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {AddDiv ? (
                      <div className="req-update-textarea">
                        <label>
                          <span>
                            <b>Request Update for</b> - Training | | Name:{" "}
                            {selectedForEditRequest.Name
                              ? selectedForEditRequest.Name
                              : "null"}
                            | Type:{" "}
                            {selectedForEditRequest.Type
                              ? selectedForEditRequest.Type
                              : "null"}
                            | Sponsor:{" "}
                            {selectedForEditRequest.Sponsor
                              ? selectedForEditRequest.Sponsor
                              : "null"}
                            <br></br>
                            <br></br>
                            <b> Description for Update:</b>
                          </span>
                        </label>
                        <textarea></textarea>
                        <div className="req-update-action">
                          <Link to="#" onClick={hideAddDiv} className="close">
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={onSubmitByRow}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <div className="req-update-textarea">
                                            <label><span><b>Request update for</b> -  Training (Record to update)  |   Name: Stovall, Brenda    |    Type: Class    |    Sponsor: Saint Francis Community Services</span></label>
                                            <textarea></textarea>
                                            <div className="req-update-action">
                                                <Link href="" className="close">close</Link>
                                                <Link href="" className="normal">Submit</Link>
                                            </div>
                                        </div> */}
                    {/* <table>
                                            <tr>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>Sponsor</th>
                                                <th>Training Date</th>
                                                <th>Units</th>
                                                <th>Relicense Year</th>
                                                <th>Training Certificate</th>
                                                <th></th>
                                            </tr>
                                            <tr>
                                                <td>Stovall, Brenda</td>
                                                <td>Class</td>
                                                <td>Saint Francis Community Services</td>
                                                <td>08/12/14</td>
                                                <td>4.00</td>
                                                <td>2015</td>
                                                <td></td>
                                                <td className="table-act"><Link href=""><img src={download} alt="" /></Link><Link href=""><img src={edittwo} alt="" /></Link></td>
                                            </tr>
                                            <tr>
                                                <td>Stovall, Brenda</td>
                                                <td>Class</td>
                                                <td>Saint Francis Community Services</td>
                                                <td>08/12/14</td>
                                                <td>4.00</td>
                                                <td>2015</td>
                                                <td></td>
                                                <td className="table-act"><Link href=""><img src={download} alt="" /></Link><Link href=""><img src={edittwo} alt="" /></Link></td>
                                            </tr>
                                            <tr>
                                                <td>Stovall, Brenda</td>
                                                <td>Class</td>
                                                <td>Saint Francis Community Services</td>
                                                <td>08/12/14</td>
                                                <td>4.00</td>
                                                <td>2015</td>
                                                <td></td>
                                                <td className="table-act"><Link href=""><img src={download} alt="" /></Link><Link href=""><img src={edittwo} alt="" /></Link></td>
                                            </tr>

                                        </table> */}
                    <DataTable
                      columns={columns}
                      data={traingDataByProviderId}
                      pagination={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Training;
