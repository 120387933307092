import Footer from "../../Footer";
import Header from "../../Header";
import attachdoc from "../../../images/assets/myinfo/attachdoc.svg";
import download from "../../../images/assets/myinfo/download.svg";
import search from "../../../images/assets/myinfo/search.svg";
import edit from "../../../images/assets/myinfo/edit.svg";
// import acmanage from '../../images/assets/images/ManageInfo.svg';
import SideBar from "../../sidebar/sidebar";
import "../AttachDocuments/AttachDocuments.scss";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import exportPDFByRow from "../../../documents/pdfFunctions/commonPdfFunction";
import plus from '../../../images/assets/cm/plus.svg';

import CriticalUnusualIncidentForm from "../../Forms/CriticalUnusualIncidentForm/CriticalUnusualIncidentForm";
import { post } from "../../../utils/common";
import DataTable from "react-data-table-component";
import axios from "axios";
import mail from "../../../images/mail.png";
import LoadSpinner from "../../Loader/LoadSpinner";
import { DownloadURL } from "../../common/commonURL";
import Modal from "react-modal";

Modal.setAppElement("#root");

function AttachDocuments() {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const [data, setData] = useState([]);

  const [selectedDataForEdit, setSelectedDataForEdit] = useState([]);
  const [updateDiv, setShowUpdateDiv] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imgName, setImgName] = useState("");
  const [dataForAdd, setDataForAdd] = useState([]);

  console.log(dataForAdd);

  const showUpdateDiv = () => {
    setShowUpdateDiv(true);
  };

  const hideUpdateDiv = () => {
    setShowUpdateDiv(false);
  };

  const [AddDiv, setShowAddDiv] = useState(false);
  const showAddDiv = () => {
    setShowAddDiv(true);
    setShowUpdateDiv(false);
  };

  const hideAddDiv = () => {
    setShowAddDiv(false);
  };
  const onclickStoreSubmit = async () => {
    // setIsLoading(true);

    let username = sessionStorage.getItem("username");
    setShowUpdateDiv(false);
    setShowAddDiv(false);
    setUpdateData([]);
    await post("/api/attachdocument/update-attachdocument-mail-addition", {
      username,
      updateData,
    });
    alert("Request Addition  Emailed Successfully !");
    //  window.location.reload(false)
    // alert("Submitted Successfully");
    setIsLoading(false);
    window.location.reload(false);
  };

  const requestEdit = (rowData) => {
    setShowUpdateDiv(true);
    setShowAddDiv(false);
    setSelectedDataForEdit(data[rowData]);
    console.log(selectedDataForEdit);
  };
  const downloadAttachment = async (fileName) => {
   let tokenData = await post("/api/authentication/generateToken", {
    userId: localStorage.getItem("userId"), userName: localStorage.getItem("username")
  });
    let data = await post("/api/attachdocument/downloadAttachment", {
      fileName,
    });
    setImgName(data.data.data+"?token="+tokenData.data.token);
    setTimeout(() => {
      toggleModal();
   //   window.open(data.data.data+"?token="+tokenData.data.token, "popup", "width=800,height=600");
     var downloadedPath=data.data.data;
     var downloadedFile=downloadedPath.replace(/^.*(\\|\/|\:)/, '');
     setTimeout(async function() { 
       let data = await post("/api/attachdocument/infoPage", {
        downloadedFile,
    });
   }, 5000);
      });
  };

  const [updateData, setUpdateData] = useState([]);

  const onUpdateClick = async () => {
    // setIsLoading(true);

    let username = sessionStorage.getItem("username");
    setShowUpdateDiv(false);
    setShowAddDiv(false);
    setUpdateData([]);
    await post("/api/attachdocument/update-attachdocument-mail", {
      username,
      updateData,
    });
    alert("Request Update  Emailed Successfully !");
    // window.location.reload(false)
    // alert("Submitted Successfully");
    // setIsLoading(false);
    window.location.reload(false);
  };

  const fetchData = async () => {
    const providerid = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/attachdocument/myinformationindex", {
      providerid,
    });
    console.log(data);
    setData(data.data.data);
  };

  console.log(data);

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      name: "Document Type",
      selector: row => row.Document_Type,
      // cell: (row, j) => (row.DocumentType[0]),
      sortable: true,
    },
    {
      name: "Date Of Upload",
      selector: row => row.Date_of_Upload,
      cell: (row, j) => new Date(row.Date_of_Upload).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Description",
      selector: row => row.Description,
      sortable: true,
    },

    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div class="inline-flex">
          <Link
            to="#"
            onClick={() => requestEdit(j)}
            class="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          >
            <img src={mail} alt=""></img>
            {/* <img src={edit} alt="edit"></img> */}
          </Link>{" "}
          &nbsp;
          <Link
            to="#"
            onClick={() => downloadAttachment(row.Download)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"  >
            <img src={search} alt=""></img>
            {/* <img src={edit} alt="edit"></img> */}
          </Link>{" "}
        </div>
      ),
    },
  ];

  return (
    <div className="main">
      <Header />

      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
         
            <div className="pagewithsidebar-inner">
              <SideBar />
              <div className="tab-detail">
                <div className="tab-detail-top">
                  <div className="m-profile">
                    <img src={attachdoc} alt="" />
                    <div className="pagesubtitle">
                      Attached Documents
                      <p>
                        You may retrieve documents required for your foster home
                        license. You may also submit current documents required
                        for the renewal. Click on Request Addition, select and
                        upload your document.
                      </p>
                    </div>
                  </div>
                  <Link to="#" onClick={showAddDiv} className="request-btn"><img src={plus} style={{"height": "18px"}} alt="#"/>
                    request Addition
                  </Link>
                  <div>
                  {/* <button onClick={toggleModal}>Open modal</button> */}

<Modal
  isOpen={isOpen}
  onRequestClose={toggleModal}
  contentLabel="My dialog"
  className="mymodal"
  overlayClassName="myoverlay"
  closeTimeoutMS={500}
> 
<div class="btn-bg">
<button onClick={toggleModal} class="close-button topright">&times;</button>
</div>

  <div> <iframe  src={imgName} class="iframeWidht"/>         
     </div>
 
</Modal>
                  </div>
                </div>

                {isLoading ? <LoadSpinner /> : ""}

                <div className="tab-detail-bottom">
                  <form className="attach-doc-form">
                    {AddDiv ? (
                      <div className="req-update-textarea">
                        <label>
                          <span>
                            <b>Request Addition for</b> - Attached documents
                            <br></br>
                            <br></br>
                            <b> Description for Addition:</b>
                          </span>
                        </label>
                        <textarea
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                        <div className="req-update-action">
                          <Link to="#" onClick={hideAddDiv} className="close">
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={onclickStoreSubmit}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {updateDiv ? (
                      <div className="req-update-textarea">
                        <label>
                          <span>
                            <b>Request Update for</b> - Attached documents |
                            Document Type:{" "}
                            {selectedDataForEdit.Document_Type
                              ? selectedDataForEdit.Document_Type
                              : ""}{" "}
                            | Description:{" "}
                            {selectedDataForEdit.Description
                              ? selectedDataForEdit.Description
                              : ""}
                            <br></br>
                            <br></br>
                            <b> Description for Update:</b>
                          </span>
                        </label>
                        <textarea
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                        <div className="req-update-action">
                          <Link
                            to="#"
                            onClick={hideUpdateDiv}
                            className="close"
                          >
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={onUpdateClick}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <DataTable
                      columns={columns}
                      data={data}
                      pagination={true}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AttachDocuments;
