import Footer from "../../Footer";
import Header from "../../../frontendPages/Header";
import flowchartinner from "../../../images/assets/currentChildren/flowchartinner.svg";
import print from "../../../images/assets/currentChildren/print.svg";
import download from "../../../images/assets/currentChildren/download.svg";
import edit from "../../../images/assets/myinfo/edit.svg";
import "../../CurrentChildren/FlowChart/FlowChart.scss";
import CurrentChildrenSideBar from "../CurrentChildrenSidebar/CurrentChildrenSidebar";
import HeaderTopDetail from "../HeaderTopDetail/HeaderTopDetail";
import { Link } from "react-router-dom";
import { post } from "../../../utils/common";
import { useState } from "react";
import LoadSpinner from "../../Loader/LoadSpinner";
import DataTable from "react-data-table-component";
import { useEffect } from "react";
import mail from "../../../images/mail.png";
import { DownloadURL } from "../../common/commonURL";
import plus from '../../../images/assets/cm/plus.svg';

function FlowChart() {
  const [isLoading, setIsLoading] = useState(false);
  const [addChildDiv, setAddChildDiv] = useState(false);
  const [updateDiv, setShowUpdateDiv] = useState(false);
  const [data, setData] = useState([]);
  const [selectedDataForEdit, setSelectedDataForEdit] = useState([]);

  const [updateData, setUpdateData] = useState([]);
  const [childrenData, setChildrenData] = useState([]);
  const fetchChildData = async () => {
    const id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/currentchildren/index", { id });
    setChildrenData(data.data.data);

    let children_id = data.data.data[0].ClientID;
    setActiveId(children_id);
    let provider_id = id;
    let detailData = await post("/api/currentchildren/flowchart", {
      provider_id,
      children_id,
    });
    console.log("detailData", detailData);

    setData(detailData.data.data);
  };
  const showUpdateDiv = () => {
    // setShowUpdateDiv(true)
    setAddChildDiv(true);
    setShowUpdateDiv(false);
  };
  const hideUpdateDiv = () => {
    setShowUpdateDiv(false);
  };
  const hideAddChildDiv = () => {
    setAddChildDiv(false);
  };

  const requestEditFlowChart = (rowData) => {
    setShowUpdateDiv(true);
    setAddChildDiv(false);

    setUpdateData(data[rowData]);
    setSelectedDataForEdit(data[rowData]);
    // console.log(LicenseDataForEdit)
  };

  const onChildAdditionSubmit = async () => {
    // setIsLoading(true)
    let username = sessionStorage.getItem("username");
    setShowUpdateDiv(false);
    setAddChildDiv(false);
    setUpdateData([]);
    await post("/api/currentchildren/update-flowChart-addition", {
      username,
      updateData,
    });
    alert("Request Addition  Emailed Successfully !");
    // window.location.reload(false)
    //  setAddChildDiv(true)
    setIsLoading(false);

    // alert("Submitted Successfully");
    //  window.location.reload(false)
  };

  const onupdateClick = async () => {
    // setIsLoading(true)

    let username = sessionStorage.getItem("username");
    setShowUpdateDiv(false);
    setAddChildDiv(false);
    setUpdateData([]);
    await post("/api/currentchildren/update-flowChart", {
      username,
      updateData,
    });
    alert("Request Updation Emailed Successfully !");
    // window.location.reload(false)

    // setShowUpdateDiv(true)
    setIsLoading(false);
    // alert("Submitted Successfully");
    // window.location.reload(false)
  };

  const [activeId, setActiveId] = useState();

  const handleCurrentChildGlobalFlowChart = async (children_id) => {
    setShowUpdateDiv(false);
    setAddChildDiv(false);
    setUpdateData([]);

    setActiveId(children_id);

    setIsLoading(true);
    sessionStorage.setItem("childrenId", children_id);
    children_id = sessionStorage.getItem("childrenId");
    const provider_id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/currentchildren/flowchart", {
      provider_id,
      children_id,
    });
    // setselectedChildrenData(data.data.data);
    let detaildata = await post("/api/currentchildren/children-detail", {
      provider_id,
      children_id,
    });
    sessionStorage.setItem("current_child", detaildata.data.data[0].ClientName);

    setData(data.data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchChildData();
  }, []);

  const columns = [
    {
      name: "Date Entered",
      selector: row => row.Date_Entered,
      cell: (row, j) => row.Date_Entered.slice(0, 10),

      // cell: (row, j) => (row.Strength[0]),
      sortable: true,
    },

    {
      name: "Description",
      selector: row => row.Description,
      // cell: (row, j) => (row.StrengthInformationSource[0]),
      sortable: true,
    },

    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div class="inline-flex">
          <Link onClick={()=>window.open(DownloadURL.url+row.Download+DownloadURL.tokenString)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={print} alt="Im"></img>
          </Link>
          &nbsp;
          <Link
           target="_blank" download
           to={{pathname:DownloadURL.url+row.Download+DownloadURL.tokenString}}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={download} alt="Im"></img>
          </Link>
          &nbsp;
          <Link
            to="#"
            onClick={() => requestEditFlowChart(j)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={mail} alt="tag"></img>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div className="main">
      <Header />
      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <CurrentChildrenSideBar />
              <div className="tab-detail">
                <div className="tab-detail-top childprofile-tabs">
                  <div className="children-section">
                    <img src={flowchartinner} alt="" />
                    <div className="pagesubtitle">Flowchart</div>
                  </div>
                  <Link to="#" onClick={showUpdateDiv}><img src={plus} style={{"height": "18px"}} alt="#"/>
                    Request Addition
                  </Link>
                </div>

                <div className="children-tab">
                  {childrenData.map((child) => {
                    return (
                      <span
                        className={activeId === child.ClientID ? "active" : ""}
                        onClick={() =>
                          handleCurrentChildGlobalFlowChart(child.ClientID)
                        }
                      >
                        {child.ClientName}
                      </span>
                    );
                  })}
                  {/* <span className="active">Easton Elliott</span> | <span>Easton Easton</span> | <span>Anna Litchfield</span> */}
                </div>

                {isLoading ? <LoadSpinner /> : ""}

                <div className="tab-detail-bottom children-data">
                  <form className="children-doc-form">
                    {addChildDiv ? (
                      <div className="req-update-textarea">
                        <label>
                          <span>
                            <b>Request addition for</b>- FlowChart |
                            <b>
                              {" "}
                              Child: {sessionStorage.getItem(
                                "current_child"
                              )} |{" "}
                            </b>
                            <br></br>
                            <br></br>
                            <b> Description for Additon:</b>{" "}
                          </span>
                        </label>
                        <textarea
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                        <div className="req-update-action">
                          <Link
                            to="#"
                            onClick={hideAddChildDiv}
                            className="close"
                          >
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={() => onChildAdditionSubmit()}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {updateDiv ? (
                      <div className="req-update-textarea">
                        <label>
                          <span>
                            <b>Request update for</b>- FlowChart |
                            <b>
                              {" "}
                              Child: {sessionStorage.getItem(
                                "current_child"
                              )} |{" "}
                            </b>
                            Date Entered:{" "}
                            {selectedDataForEdit.Date_Entered
                              ? selectedDataForEdit.Date_Entered.slice(0, 10)
                              : ""}{" "}
                            | Description:{" "}
                            {selectedDataForEdit.Description
                              ? selectedDataForEdit.Description
                              : ""}
                            <br></br>
                            <br></br>
                            <b>Description For Update:</b>
                          </span>
                        </label>
                        <textarea
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                        <div className="req-update-action">
                          <Link
                            to="#"
                            onClick={hideUpdateDiv}
                            className="close"
                          >
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={onupdateClick}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* <table>
                                            <tr>
                                                <th className="doc-type">Document Type</th>
                                                <th>Description</th>
                                                <th className="download-action"></th>
                                            </tr>
                                            <tr>
                                                <td>Child's Picture</td>
                                                <td>Child's Picture</td>
                                                <td className="download-icon"><Link to="#"><img src={download} alt="" /></Link>
                                                 <Link to="#"><img src={print} alt="" /></Link></td>
                                               
                                             
                                             
                                            </tr>
                                            <tr>
                                                <td>Child's Picture</td>
                                                <td>Child's Picture</td>
                                                <td className="download-icon"><Link to="#"><img src={download} alt="#" /></Link><Link to="#"><img src={print} alt="" /></Link></td>
                                            </tr>
                                            <tr>
                                                <td>Child's Picture</td>
                                                <td>Child's Picture</td>
                                                <td className="download-icon"><Link to="#"><img src={download} alt="" /></Link><Link to="#"><img src={print} alt="" /></Link></td>
                                            </tr>
                                            <tr>
                                                <td>Child's Picture</td>
                                                <td>Child's Picture</td>
                                                <td className="download-icon"><Link to=""><img src={download} alt="" /></Link><Link to=""><img src={print} alt="" /></Link></td>
                                            </tr>


                                        </table> */}

                    <DataTable
                      columns={columns}
                      data={data}
                      pagination={true}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FlowChart;
