import Footer from "../../Footer";
import Header from "../../Header";
import placementagree from "../../../images/assets/myinfo/placementagree.svg";
import download from "../../../images/assets/myinfo/download.svg";
import edit from "../../../images/assets/myinfo/edit.svg";
import print from "../../../images/assets/myinfo/print.svg";
//import search from "../../../images/assets/myinfo/search.svg";

// import acmanage from '../../images/assets/images/ManageInfo.svg';
import SideBar from "../../sidebar/sidebar";
import "../PlacementAgreement/PlacementAgreement.scss";
//import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { post } from "../../../utils/common";
import DataTable from "react-data-table-component";
import mail from "../../../images/mail.png";
import LoadSpinner from "../../Loader/LoadSpinner";
import { DownloadURL } from "../../common/commonURL";
import sfm from "../../../images/assets/images/sfm.svg";
import logoplacement from "../../../images/logo-placement.jpg";
import dompurify from 'dompurify';

function PlacementAgreementPdfDesign() {
  const [data, setData] = useState([]);
  const fetchData = async () => {
    let clientid = parseInt(sessionStorage.getItem("CLIENT_ID_FOR_PA"));
    let providerid = parseInt(sessionStorage.getItem("providerSessionId"));
    let ElecSignPlacementAgreementIDFORPA = parseInt(sessionStorage.getItem(
      "ElecSignPlacementAgreementIDFORPA"
    ));
    let data = await post("/api/placementagreement/get-xml-data", {
      clientid,
      providerid,
      ElecSignPlacementAgreementIDFORPA,
    });
    setData(data.data.data[0]);
  };



  const title = data.AgreementText;
  const sanitizer = dompurify.sanitize;
  console.log(data);
  useEffect(() => {
    fetchData();
   
  }, []);

  return (
    <div
      class="container"
      style={{ "margin-left": "30px", overflow: "word-break" }}
    >
      <div>
        <div
          style={{
            "text-align": "right",
            "marging-top": "0px",
            position: "relative",
            "margin-left": "300px",
          }}
        >
          <img className="footer-logoo" src={logoplacement} alt="" />
        </div>
        <h4
          className="title"
          style={{
            "text-align": "left",
            "font-size": "1.45em",
            "font-weight": "bold",
            "margin-top": "0px",
            "margin-left": "10px",
          }}
        >
          Saint Francis Ministries, Inc <br></br>
          Placement Agreement
        </h4>
      </div>

      {/* <h4
        className="title"
        style={{
          "text-align": "left",
          "font-size": "1.2em",
          "font-weight": "bold",
          "margin-top": "50px",
          "margin-left": "10px",
        }}
      >
        Placement Agreement{" "}
      </h4> */}

<span dangerouslySetInnerHTML={{ __html: sanitizer(title)
 }} />
      <br></br>
      {/* <div className="form-row">
        <div class="ot-field half-input">
          <label class="form-label">Electronically Sign</label>
         <input type="text"></input>
        </div>
      </div> */}
    </div>
  );
}

export default PlacementAgreementPdfDesign;
