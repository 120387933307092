import Footer from "../../Footer";
import Header from "../../../frontendPages/Header";
import placeinner from "../../../images/assets/currentChildren/placeinner.svg";
import download from "../../../images/assets/currentChildren/download.svg";
import edit from "../../../images/assets/myinfo/edit.svg";
import print from "../../../images/assets/currentChildren/print.svg";
import "../../CurrentChildren/ImmidiateAssesmentCurrentChildren/ImmidiateAssesmentCurrentChildren.scss";
import CurrentChildrenSideBar from "../CurrentChildrenSidebar/CurrentChildrenSidebar";
import HeaderTopDetail from "../HeaderTopDetail/HeaderTopDetail";
import { Link } from "react-router-dom";
import { useState } from "react";
import { post } from "../../../utils/common";
import DataTable from "react-data-table-component";
import LoadSpinner from "../../Loader/LoadSpinner";
import { useEffect } from "react";
import mail from "../../../images/mail.png";
import { DownloadURL } from "../../common/commonURL";


function PlacementReferal() {
  const [data, setData] = useState([]);
  const [updateDiv, setShowUpdateDiv] = useState(false);
  const [selectedDataForEdit, setSelectedDataForEdit] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  // const fetchData = async () => {
  //     const id = sessionStorage.getItem('providerSessionId');
  //     let data = await post('/api/placementreferral/getPlacementreferralbyproviderId', { id });
  //     console.log(data)
  //     setData(data.data.data);

  //     let children_id = data.data.data[0].ClientID;
  //     let provider_id = id
  //     let detailData = await post('/api/currentchildren/children-detail', { provider_id, children_id });
  //     console.log("detailData",detailData)

  //     setData(detailData.data.data);
  // };

  const requestEditPlacementReferal = (rowData) => {
    setShowUpdateDiv(true);
    setUpdateData(data[rowData]);
    setSelectedDataForEdit(data[rowData]);
    // console.log(LicenseDataForEdit)
  };

  const hideUpdateDiv = () => {
    setShowUpdateDiv(false);
  };

  const onUpdateClick = async () => {
    // setIsLoading(true)

    let username = sessionStorage.getItem("username");
    setShowUpdateDiv(false);
    setUpdateData([]);
    await post("/api/placementReferral/update-placementReferral", {
      username,
      updateData,
    });
    alert("Request Update  Emailed Successfully !");
    setIsLoading(false);
    // window.location.reload(false)
    // alert("Submitted Successfully");
    //window.location.reload(false)
  };

  const [childrenData, setChildrenData] = useState([]);
  const fetchChildData = async () => {
    const id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/currentchildren/index", { id });
    setChildrenData(data.data.data);

    let children_id = data.data.data[0].ClientID;
    setActiveId(children_id);

    let provider_id = id;
    let detailData = await post("/api/currentchildren/placement-referral", {
      provider_id,
      children_id,
    });
    console.log("detailData", detailData);

    setData(detailData.data.data);
  };

  const [isLoading, setIsLoading] = useState(false);

  const [activeId, setActiveId] = useState();

  const handleCurrentChildGlobalPlacementReferal = async (children_id) => {
    setIsLoading(true);
    setActiveId(children_id);
    setShowUpdateDiv(false);

    sessionStorage.setItem("childrenId", children_id);
    children_id = sessionStorage.getItem("childrenId");
    const provider_id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/currentchildren/placement-referral", {
      provider_id,
      children_id,
    });
    // setselectedChildrenData(data.data.data);
    let detaildata = await post("/api/currentchildren/children-detail", {
      provider_id,
      children_id,
    });
    sessionStorage.setItem(
      "current_child",
      detaildata.data.data.length > 0
        ? detaildata.data.data[0].ClientName
        : "Null"
    );
    // setData(detaildata.data.data)
    setData(data.data.data);
    setIsLoading(false);
  };
  console.log(data);

  useEffect(() => {
    // fetchData();
    fetchChildData();
  }, []);

  const columns = [
    {
      name: "Date Entered",
      selector: row => row.Date_Entered,
      cell: (row, j) => row.Date_Entered?(row.Date_Entered).slice(0,10):'NULL',

      //  cell: (row, j) => (row.Date_Entered).slice(0,10),

      // cell: (row, j) => (new Date(row.EnteredDate).toLocaleDateString()),
      sortable: true,
    },
    {
      name: "Description",
      selector: row => row.Description,
      sortable: true,
    },

    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div class="inline-flex">
          <Link
            target="_blank" download
            to={{pathname:DownloadURL.url+row.Download+DownloadURL.tokenString}}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={download} alt="#"></img>
          </Link>
          &nbsp;
          <Link onClick={()=>window.open(DownloadURL.url+row.Download+DownloadURL.tokenString)}
            to="#"
            class="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          >
            <img src={print} alt="#"></img>
          </Link>
          &nbsp;
          <Link
            to="#"
            onClick={() => requestEditPlacementReferal(j)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={mail} alt="tag"></img>
          </Link>
        </div>
      ),
    },
  ];
  return (
    <div className="main">
      <Header />
      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <CurrentChildrenSideBar />
              <div className="tab-detail">
                <div className="tab-detail-top childprofile-tabs">
                  <div className="children-section">
                    <img src={placeinner} alt="" />
                    <div className="pagesubtitle">Placement Referral</div>
                  </div>
                </div>
                <div className="children-tab">
                  {childrenData.map((child) => {
                    return (
                      <span
                        className={activeId === child.ClientID ? "active" : ""}
                        onClick={() =>
                          handleCurrentChildGlobalPlacementReferal(
                            child.ClientID
                          )
                        }
                      >
                        {child.ClientName}
                      </span>
                    );
                  })}
                  {/* <span className="active">Easton Elliott</span> | <span>Easton Easton</span> | <span>Anna Litchfield</span> */}
                </div>
                {isLoading ? <LoadSpinner /> : ""}

                <div className="tab-detail-bottom children-data">
                  <form className="children-doc-form">
                    {updateDiv ? (
                      <div className="req-update-textarea">
                        <label>
                          <span>
                            <b>Request update for</b> - Placement Referal |
                            <b>
                              {" "}
                              Child: {sessionStorage.getItem(
                                "current_child"
                              )} |{" "}
                            </b>
                            {/* Data Entered: {selectedDataForEdit.Data_Entered? selectedDataForEdit.Data_Entered: ''}    | */}
                            Description:{" "}
                            {selectedDataForEdit
                              ? selectedDataForEdit.Description
                              : ""}{" "}
                            | Date Entered:{" "}
                            {selectedDataForEdit
                              ? selectedDataForEdit.Date_Entered.slice(0, 10)
                              : ""}{" "}
                            |<br></br>
                            <br></br>
                            <b> Description for Update:</b>
                          </span>
                        </label>
                        <textarea
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                        <div className="req-update-action">
                          <Link
                            to="#"
                            onClick={hideUpdateDiv}
                            className="close"
                          >
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={onUpdateClick}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <table>
                                            <tr>
                                                <th>Date  Entered</th>
                                                <th>Description</th>
                                                <th className="download-action" ></th>
                                            </tr>
                                            <tr>
                                                <td>06/15/2021</td>
                                                <td>Placement Referral Created Before Placment</td>
                                                <td className="download-icon"><Link to="#"><img src={download} alt="" /></Link><Link to="#"><img src={print} alt="" /></Link></td>
                                            </tr>
                                            <tr>
                                                <td>06/15/2021</td>
                                                <td>Placement Referral Created Before Placment</td>
                                                <td className="download-icon"><Link to="#"><img src={download} alt="" /></Link><Link to="#"><img src={print} alt="" /></Link></td>
                                            </tr>
                                        </table> */}
                    <DataTable
                      columns={columns}
                      data={data}
                      pagination={true}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PlacementReferal;
