import Footer from '../../Footer';
import Header from '../../../frontendPages/Header';
import '../../PreviousChildren/PreviousChildrenDetail/PreviousChildrenDetail.scss'
import PreviousChildrenSidebar from '../PreviousChildrenSidebar/PreviousChildrenSidebar';
import HeaderTopDetail from '../../CurrentChildren/HeaderTopDetail/HeaderTopDetail';
import previouschildinner from '../../../images/assets/currentChildren/previouschildinner.svg';
import { useEffect, useState } from 'react';
import edit from '../../../images/assets/myinfo/edit.svg';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { post } from '../../../utils/common';
import LoadSpinner from '../../Loader/LoadSpinner';
import mail from '../../../images/mail.png'



function PreviousChildrenIndex() {
    const [updateDiv, setShowUpdateDiv] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDataForEdit, setSelectedDataForEdit]=useState([])
    const [updateData, setUpdateData] = useState([])


    const showUpdateDiv = () => {
        setShowUpdateDiv(true)
    }

    const hideUpdateDiv = () => {
        setShowUpdateDiv(false)
    }

    const onupdateClick = async () => {
       // setIsLoading(true)


        let username = sessionStorage.getItem('username');
        setShowUpdateDiv(false)
        setUpdateData([])
        await post('/api/previousChildren/update-previousChildren', { username, updateData });
        alert('Request Update  Emailed Successfully !');
        setIsLoading(false)
       // window.location.reload(false)
        // alert("Submitted Successfully");
        // window.location.reload(false)
    }


    const [prevChildData, setPrevChildData] = useState([]);
    const [data, setData] = useState([])
    const [prevChildClickedData, setPrevChildClickedData] = useState([]);

    const fetchData = async () => {
        const id = sessionStorage.getItem('providerSessionId');
        let data = await post('/api/previouschildren/index', { id });

        // let data = await fetch('/api/previouschildren/index');
        setPrevChildData(data.data.data);
        let childid = data.data.data[0].ClientID;
        setActiveId(childid)
        let providerid = id
        let detailData = await post('/api/previouschildren/prev-children-detail', { providerid, childid });
        console.log("detailData",detailData)

        setData(detailData.data.data);
        setPrevChildClickedData(detailData.data.data)
    };


    const [activeId, setActiveId] = useState();

    const handleGlobalPreviousChild = async (ClientID) => {
        setActiveId(ClientID)
        setIsLoading(true);
        sessionStorage.setItem("PreviousChildId",ClientID);
        const providerid = sessionStorage.getItem('providerSessionId');
        const childid = sessionStorage.getItem('PreviousChildId');

        let detaildata = await post('/api/previouschildren/prev-children-detail', { providerid, childid });

        // let data = await fetch('/api/previouschildren/index');
        setPrevChildClickedData(detaildata.data.data);
        sessionStorage.setItem("previous_child", detaildata.data.data[0].ClientName);
        console.log("Data", data[0])
        setIsLoading(false)
    };



    const requestEditPreviousChildren = (rowData) => {
         setShowUpdateDiv(true)
          setUpdateData(prevChildClickedData[rowData])
          setSelectedDataForEdit(prevChildClickedData[rowData])
        // console.log(LicenseDataForEdit)
    }

    useEffect(() => {
        fetchData();
        // fetchPreviousChildData()

    }, []);


    const columns = [
        {
            name: 'Name',
            selector: row => row.ClientName,
            sortable: true,
            wrap:true
        },
        {
            name: 'Gender',
            selector: row => row.Gender,
            sortable: true,
            wrap:true
        },
        {
            name: 'Age',
            selector: row => row.Age,
            sortable: true,
            wrap:true
        },
        {
            name: 'DOB',
            selector: row => row.DOB,
            cell: (row, j) => (row.DOB).slice(0,10),
            sortable: true,
            wrap:true
        },
        {
            name: 'Race',
            selector: row => row.Race,
            sortable: true,
            wrap:true
        },
        {
            name: 'In Home Dates',
            selector: row => row.InHomeDates,
            sortable: true,
            wrap:true
        },
        {
            name: `For more information, contact child's last office`,
            selector: row => row.OfficeAddress,
            cell: (row, j) =>
            row.OfficeAddress.split("<BR>")[0] + row.OfficeAddress.split("<BR>")[1]+row.OfficeAddress.split("<BR>")[2]+row.OfficeAddress.split("<BR>")[3],
            sortable: true,
            wrap:true
        },


        {
            name: 'Action',
            sortable: true,
            cell: (row, j) => (
                <div class="inline-flex">
                    <button type="button" onClick={() => requestEditPreviousChildren(j)}

                        className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                    >
                        <img src={mail} alt="tag"></img>
                    </button>


                </div >
            ),
        },
    ];

    return (

        <div className="main">
            <Header />
            <div className="main-content" >
                <HeaderTopDetail />
                <div className="pagewithsidebar">
                    <div className="container">
                        <div className="pagewithsidebar-inner">
                            {/* <PreviousChildrenSidebar data={prevChildData} /> */}

                            <div className="sidebar">
                                <h3 className="children-dec">Children</h3>
                                <ul>
                                    {prevChildData ? prevChildData.map(d => (
                                        <li><Link to="#" className="active" onClick={() => handleGlobalPreviousChild(d.ClientID)}><span>{d.ClientName}</span></Link></li>

                                    )) : ''}


                                    {/* <li><Link to="#"><span>Ajualip, Ronaldo</span></Link></li>
                <li><Link to="#"><span>Allison, Austin</span></Link></li> */}
                                </ul>
                            </div>
                            <div className="tab-detail">
                                <div className="tab-detail-top">
                                    <div className="children-section">
                                        <img src={previouschildinner} alt="" />
                                        <div className="pagesubtitle">
                                            Previous Children
                                        </div>
                                    </div>
                                    {/* <Link to="#" onClick={showUpdateDiv} className="request-btn">Request Update</Link> */}
                                </div>
                                {isLoading ? (<LoadSpinner />) : ''}

                                <div className="tab-detail-bottom children-data">
                                    <form className="children-doc-form">

                                        {updateDiv ? <div className="req-update-textarea">
                                            <label><span><b>Request Updation for</b> - Previous Children |
                                            ClientName: {selectedDataForEdit. ClientName? selectedDataForEdit. ClientName: ''}    |
                                            Age: {selectedDataForEdit.Age? selectedDataForEdit.  Age :''} |
                                            InHomeDates: {selectedDataForEdit.InHomeDates? selectedDataForEdit.  InHomeDates :''}
                                            <br></br>
                                            <br></br>
                                            <b>Description for Update:</b> </span></label>
                                            <textarea  onChange={e => setUpdateData({ ...updateData, description: e.target.value })}></textarea>
                                            <div className="req-update-action">
                                                <Link to="#" onClick={hideUpdateDiv} className="close">close</Link>
                                                <Link to="#" onClick={onupdateClick} className="normal">Submit</Link>
                                            </div>
                                        </div>
                                            : ''}
                                        <DataTable columns={columns} data={prevChildClickedData} pagination={true} />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}




export default PreviousChildrenIndex;
