import Footer from '../../Footer';
import Header from '../../../frontendPages/Header';
import ContentManagementSideBar from '../ContentManagementSideBar/ContentManagementSideBar';
import mcinner from '../../../images/assets/cm/mcinner.svg';
import search from '../../../images/assets/cm/search.svg';
import editimage from '../../../images/assets/cm/edit.svg';
import contactimage from '../../../images/assets/cm/contact.svg';
import trashimage from '../../../images/assets/cm/trash.svg';
import ContentHeaderTopDetail from '../ContentHeaderTopDetail/ContentHeaderTopDetail';
import "../AdoptionContact/AdoptionContact.scss"
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { post, put } from '../../../utils/common';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import LoadSpinner from '../../Loader/LoadSpinner';




function AdoptionContact() {
    const [isLoading, setIsLoading] = useState(false);


    const fetchData = async () => {
        setIsLoading(true)
        let url = new URL(window.location.href);
        let search_params = url.searchParams;
        const id = search_params.get('id');
        console.log(id);
        let data = await fetch('/api/adoptioncontact/show/' + id);
        if (!data.ok) {
            return;
        }
        data = await data.json();

        setContactData(data.data[0]);
        console.log(data.data[0])
        setIsLoading(false)

    };

    useEffect(() => {
        fetchData();
    }, []);

    const history = useHistory();

    const [contactData, setContactData] = useState({

        Title: '',
        Name: '',
        Phone: '',
        Email: '',
    });




    const onSubmitButtonClick = async e => {
        setIsLoading(true)
        let url = new URL(window.location.href);
        let search_params = url.searchParams;
        const id = search_params.get('id');
        e.preventDefault();
        await put('/api/adoptioncontact/update/' + id, { contactData });
        alert('Record updated successfully !');
        // window.location.reload(false);
        setIsLoading(false)
        history.push('/contentmanage/adoptioncontact');
        
    };


    return (

        <div className="main">
            <Header />
            <div className="main-content" >
                <ContentHeaderTopDetail />
                <div className="pagewithsidebar">
                    <div className="container">
                        <div className="pagewithsidebar-inner">
                            <ContentManagementSideBar />
                            <div className="tab-detail">
                                <div className="tab-detail-top children-tabs">
                                    <div className="children-section">
                                        <img src={mcinner} alt="" />
                                        <div className="pagesubtitle">
                                            Manage Adoption Contacts
                                        </div>
                                    </div>
                                    {isLoading ? <LoadSpinner /> : ""}

                                </div>
                                <div className="tab-detail-bottom children-data">
                                    <form className="children-doc-form" onSubmit={onSubmitButtonClick}>
                                        <div className="add-childrendata">
                                            <div className="form-row">
                                                <div className="one-fourth">
                                                    <label>Name :</label>
                                                    <input type="text" value={contactData.Name} onChange={e => setContactData({ ...contactData, Name: e.target.value })}
                                                    ></input>
                                                </div>
                                                <div className="one-fourth">
                                                    <label>Title :</label>
                                                    <input type="text" value={contactData.Title} onChange={e => setContactData({ ...contactData, Title: e.target.value })}></input>
                                                </div>
                                                <div className="one-fourth">
                                                    <label>Phone No. :</label>
                                                    <input type="text" value={contactData.Phone} onChange={e => setContactData({ ...contactData, Phone: e.target.value })}></input>
                                                </div>
                                                <div className="one-fourth">
                                                    <label>Email :</label>
                                                    <input type="email" value={contactData.Email} onChange={e => setContactData({ ...contactData, Email: e.target.value })}></input>
                                                </div>
                                            </div>
                                            <div className="req-update-action j-left">
                                                <Link to="/contentmanage/adoptioncontact" className="close">close</Link>
                                                <Link to="#" onClick={onSubmitButtonClick} className="blue-btn">submit</Link>
                                                

                                            </div>
                                        </div>

                                    </form>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}




export default AdoptionContact;
