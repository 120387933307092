import React from 'react';
import './LoadSpinner.css';

const LoadSpinner = () => (
  <div className='fade-bg'>
  <div className="spinner"></div>
  </div>

);

export default LoadSpinner;