import Footer from '../../Footer';
import Header from '../../../frontendPages/Header';
import PreviousChildrenSidebar from '../PreviousChildrenSidebar/PreviousChildrenSidebar';



function PreviousChildrenRequestUpdate() {
    return (


        <div className="main">

            <Header />
            <div className="pagewithsidebar">
                    <div className="container">
                        <div className="pagewithsidebar-inner">  </div>

                        <PreviousChildrenSidebar />
                        </div>
                  
                    </div>

                <Footer />
            </div>
    );
}




export default PreviousChildrenRequestUpdate;
