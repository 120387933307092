import Footer from "../Footer";
import Header from "../Header";

import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import LoadSpinner from "../Loader/LoadSpinner";
import axios from "axios";

function Events() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [LocationData, setLocationData] = useState([]);
  const [fiteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dayArray = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const removeRow = async (id) => {
    setIsLoading(true);
    console.log(id);
    // setIsLoading(true)
    const temp = [...data];
    const primaryKey = id;
    temp.splice(id, 1);
    try {
      await axios.delete(
        `/api/eventcalender/delete/${encodeURIComponent(primaryKey)}`
      );
      setData(temp);
      window.location.reload(false);
    } catch (err) {}
    setIsLoading(false);
  };

  const Edit = async (row) => {
    setIsLoading(true);
    //   const selectedData = ;
    // console.log(row);
    setSelectedDataToDisplay(data[row]);
    // sessionStorage.setItem("EditData", JSON.stringify(data[row]));
    // window.location.href = "/secure/detailview-event-edit";
    sessionStorage.setItem("gridCalDataForEditing", JSON.stringify(data[row]));
    window.location.href = "/edit/eventgrid";
    setIsLoading(false);
  };

  const fetchData = async () => {
    let data = await fetch("/api/eventcalender/index");
    if (!data.ok) {
      return;
    }
    data = await data.json();

    setData(data.data);
    setLocationData(data.data);
    setFilteredData(data.data);
  };

  const closeClicked = () => {
    history.push("/secure/eventscalender");
  };

  const [FilterData, setFilterData] = useState([]);

  const filterData = async () => {
    // console.log(new Date(d.CalendarDate).toDateString());
    console.log(new Date(FilterData.CalendarDate).toLocaleDateString());

    // console.log(new Date(d.EndTime).toDateString());

    console.log(new Date(FilterData.EndTime).toLocaleDateString());
    console.log(FilterData);


    let res;
    if (FilterData.Location && FilterData.Location == "ALL" && FilterData.CalendarDate && FilterData.EndTime) {
      res = data.filter(
        (d) =>
          // d.Location == FilterData.Location &&
          d.CalendarDate > FilterData.CalendarDate &&
          d.EndTime < FilterData.EndTime
      );
      setLocationData(res);
      console.log("0", res);
    } 
   else if (FilterData.Location && FilterData.Location !== "ALL" && FilterData.CalendarDate && FilterData.EndTime) {
      res = data.filter(
        (d) =>
          d.Location == FilterData.Location &&
          d.CalendarDate > FilterData.CalendarDate &&
          d.EndTime < FilterData.EndTime
      );
      setLocationData(res);
      console.log("1", res);
    } else if (
      !FilterData.Location &&
      (FilterData.CalendarDate || FilterData.EndTime)
    ) {
      res = data.filter(
        (d) =>
          d.CalendarDate > FilterData.CalendarDate &&
          d.EndTime < FilterData.EndTime
      );
      setLocationData(res);
      console.log("2", res);
    } else if (
      FilterData.Location &&
      (!FilterData.CalendarDate || !FilterData.EndTime) && FilterData.Location !== "ALL"
    ) {
      res = data.filter((d) => d.Location == FilterData.Location);
      setLocationData(res);
      console.log("4", res);
    } else if (
      FilterData.Location == "ALL" &&
      (!FilterData.CalendarDate || !FilterData.EndTime)
    ) {
      setLocationData(data);
      console.log("3", LocationData);
    }
    // console.log(FilterData);
    // console.log(res);
  };

  const [selectedDataToDisplay, setSelectedDataToDisplay] = useState([]);
  const [showSelectedData, setShowSelectedData] = useState(false);

  const showDetail = (row) => {
    //   const selectedData = ;
    console.log(row);
    setSelectedDataToDisplay(data[row]);
    // sessionStorage.setItem(
    //   "gridCalDataForEditing",
    //   JSON.stringify(data[row])
    // );
    // window.location.href = "/edit/eventgrid";
    sessionStorage.setItem("EventDetailView", JSON.stringify(data[row]));
    window.location.href = "/secure/detailview-event";
    //  setShowSelectedData(true);
  };

  const getTime = (startDate, endDate) => {
    let start = startDate;
    let end = endDate;
    let startHour = startDate.slice(11, 13);
    let endHour = endDate.slice(11, 13);
    let startAMPM;
    let endAMPM;

    let newStartHour;
    let newEndHour;

    if (parseInt(startHour) < 12) {
      startAMPM = "AM";
      newStartHour = startHour;
    } else {
      startAMPM = "PM";
      newStartHour = startHour - 12;
    }

    if (parseInt(endHour) < 12) {
      endAMPM = "AM";
      newEndHour = endHour;
    } else {
      endAMPM = "PM";
      newEndHour = endHour - 12;
    }

    return (
      newStartHour +
      ":" +
      startDate.slice(14, 16) +
      startAMPM +
      "-" +
      newEndHour +
      ":" +
      endDate.slice(14, 16) +
      endAMPM
    );

    return 5;
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="main">
      <Header />
      <div className="parent-main">
        <div className="container">
          <h2>Upcoming Events</h2>

          <form className="child-case-form event-page">
            <div className="form-row child-case-form-row">
              <div className="one-fourth">
                <label className="form-label">Location</label>
                <select
                  onChange={(e) =>
                    setFilterData({ ...FilterData, Location: e.target.value })
                  }
                >
                  <option value="ALL">All</option>
                  {data
                    .filter((LocationData) => LocationData.Location !== null)
                    .map((LocationData) => (
                      <option value={LocationData.Location}>
                        {LocationData.Location}
                      </option>
                    ))}
                </select>
              </div>
              <div class="one-fourth">
                <label class="form-label">Start Date:</label>
                <input
                  type="date"
                  onChange={(e) =>
                    setFilterData({
                      ...FilterData,
                      CalendarDate: e.target.value,
                    })
                  }
                  class="form-control"
                  id="exampleFormControlInput1"
                />
              </div>
              {isLoading ? <LoadSpinner /> : ""}
              <div class="one-fourth">
                <label class="form-label">End Date:</label>
                <input
                  type="date"
                  onChange={(e) =>
                    setFilterData({ ...FilterData, EndTime: e.target.value })
                  }
                  class="form-control"
                  id="exampleFormControlInput1"
                />
              </div>
              <div className="action-btn">
                <Link to="#" onClick={filterData} className="green-btn">
                  Filter Search
                </Link>
              </div>
            </div>

            {/* <div className="form-col">
              <div class="ot-field">
                <label class="form-label">End Date:</label>
                <input
                  type="date"
                  onChange={(e) =>
                    setFilterData({ ...FilterData, EndTime: e.target.value })
                  }
                  class="form-control"
                  id="exampleFormControlInput1"
                />
              </div>
              <div className="action-btn">
                <Link to="#" onClick={filterData} className="green-btn">
                  Filter Search
                </Link>
              </div>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div> */}

            <div className="container">
              {LocationData.map((d, index) => (
                <div
                  className="event-detail-list"
                  // onClick={() => showDetail(index)}
                >
                  <h3>
                    {" "}
                    <b>
                      Location:{"  "}
                      {d.Location}
                    </b>
                    <br />
                    Title:{"  "}
                    {d.Title}
                    <br></br>
                    <small>
                      Date:{"  "} {dayArray[new Date(d.CalendarDate).getDay()]}{" "}
                      {d.CalendarDate.slice(0, 10)}{" "}
                      {getTime(d.CalendarDate, d.EndTime)}
                    </small>
                  </h3>
                  <p className="parag">
                    Description:{"  "}
                    {d.Description}
                  </p>
                  <br />
                  Link:{"  "}
                  <Link to={{ pathname: d.LinkPath }} target="_blank">
                    {d.LinkPath}
                  </Link>
                  <div>
                    <Link
                      to="#"
                      className="close"
                      onClick={() => removeRow(d.CalendarEventID)}
                    >
                      Delete
                    </Link>
                    <Link to="#" className="normal" onClick={() => Edit(index)}>
                      Edit
                    </Link>
                    <Link
                      to="#"
                      onClick={() => showDetail(index)}
                      className="normal"
                    >
                      More
                    </Link>
                  </div>
                  <br></br>
                  <br></br>
                </div>
              ))}
            </div>
          </form>
        </div>
        <Footer />
      </div>
    </div>
  );
}
export default Events;
