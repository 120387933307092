import Footer from '../../Footer';
import Header from '../../../frontendPages/Header';



function CreateProviderManagementCenter() {
    return (
        <div className="main">

        <Header />
        <div className="parent-main">
            <div className="container">
                <h2>Provider MANAGEMENT  CENTER</h2>
                <div className="form-row dandt mt-0 ai-c">
                    <div className="one-third">
                        <label for="birthday">Provider Types:</label>
                        <select>
                            <option value="0">Select car:</option>
                            <option value="1">Audi</option>
                            <option value="2">BMW</option>
                            <option value="3">Citroen</option>
                        </select>
                    </div>
                    <div className="one-third">
                        <label for="birthday">Provider:</label>
                        <select>
                            <option value="0">Select car:</option>
                            <option value="1">Audi</option>
                            <option value="2">BMW</option>
                            <option value="3">Citroen</option>
                        </select>
                    </div>
                    <div className="one-third">
                    <a href="" className="normal">Select User</a>
                    </div>
                </div>
                <h3 className="blue-heading">CREATE NEW PROVIDER ACCOUNT</h3>
                <h4 className="blue-bg-heading">Create Provider’s Secure Login</h4>
                <div className="create-login">
                    <span>
                    <label for="birthday">User Name:</label>
                    <input type="text"/>
                    </span>
                    <span>
                    <label for="birthday">Email:</label>
                    <input type="text"/>
                    </span>
                    <span>
                    <label for="birthday">Home Training Date:</label>
                    <input type="date"/>
                    </span>
                    <div className="login-action">
                    <a href="#" className="normal">Create User</a>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
    );
}




export default CreateProviderManagementCenter;
