import MyProfile from '../../images/assets/images/myprofile.svg';
import Accountinfo from '../../images/assets/images/AccountInfo.svg';
import AccountManagement from '../../images/assets/images/AccountManagement.svg';
import HouseHold from '../../images/assets/images/MyHouseHold.svg';
import AttachedDocument from '../../images/assets/images/AttachedDocuments.svg';
import Criticalincident from '../../images/assets/images/critical.svg';
import ImmediateAssessment from '../../images/assets/images/ImmediateAssessment.svg';
import LevelOfCare from '../../images/assets/images/LevelofCare.svg';
import Lodes from '../../images/assets/images/Lodes.svg';
import LeastDesired from '../../images/assets/images/LeastDesired.svg';
import Licensing from '../../images/assets/images/LicenseException.svg';
import ReimbursementImage from '../../images/assets/images/Reimbursement History.svg';
import PlacementAgreement from '../../images/assets/images/PlacementAgreement.svg';
import Preferences from '../../images/assets/images/Preferences.svg';
import School from '../../images/assets/images/School.svg';
import AccountActivity from '../../images/assets/images/ActivityLode.svg';
import Training from '../../images/assets/images/Training 1.svg';
import sidebarStyle from '../sidebar/sidebar.scss';


function SideBar() {
    return (


        <div className="sidebar">
            <ul>
                <li><a href="/myprofile"><img src={MyProfile} alt="" /><span>My Profile</span></a></li>
                <li><a href="/accountinformation"><img src={Accountinfo} alt="" /><span>Account Information</span></a></li>
                {sessionStorage.getItem('RoleInfo')== 'FCHFieldStaff'?
                <li  aria-disabled="true"><a href="/accountmanage"   style={{pointerEvents: "none" ,color:'#95B9C7'}}  ><img src={AccountManagement} alt=""  /><span  style={{pointerEvents: "none" ,color:'#95B9C7'}} aria-disabled="true">Account Management </span></a></li>:''}
                {/* style={{pointerEvents: "none" ,color:'#95B9C7'}} */}
                {/*s for span style={{pointerEvents: "none" ,color:'#95B9C7'}} aria-disabled="true" */}
                <li><a href="/address"><img src={AccountManagement} alt="" /><span>Address</span></a></li>
                <li><a href="/myhousehold"><img src={HouseHold} alt="" /><span>My Household</span></a></li>
                <li><a href="/attachdocuments"><img src={AttachedDocument} alt="" /><span>Attached Documents</span></a></li>
                <li><a href="/criticalunusualincident"><img src={Criticalincident} alt="" /><span>Critical/Unusual Incidents</span></a></li>
                <li><a href="/immidiateassesment"><img src={ImmediateAssessment} alt="" /><span>Immediate Assessment</span></a></li>
                <li><a href="/leastdesiredcondition"><img src={LeastDesired} alt="" /><span>Least Desired Conditions</span></a></li>
                <li><a href="/levelofcare"><img src={LevelOfCare} alt="" /><span>Level of Care</span></a></li>
                <li><a href="/lodes"><img src={Lodes} alt="" /><span>Lodes</span></a></li>
                <li><a href="/licenseexception"><img src={Licensing} alt="" /><span>Licensing</span></a></li>
                <li><a href="/reimbursmenthistory"><img src={ReimbursementImage} alt="" /><span>Reimbursement History</span></a></li>
                <li><a href="/placementagreement"><img src={PlacementAgreement} alt="" /><span>Placement Agreement</span></a></li>
                <li><a href="/preference"><img src={Preferences} alt="" /><span>Preferences</span></a></li>
                <li><a href="/school"><img src={School} alt="" /><span>School</span></a></li>
                <li><a href="/sfcsoffice"><img src={School} alt="" /><span>SFM Office</span></a></li>
                <li><a href="/sfcsworker"><img src={School} alt="" /><span>SFM Worker</span></a></li>
                <li><a href="/activitylog"><img src={AccountActivity} alt="" /><span>Account Activity</span></a></li>
                <li><a href="/training"><img src={Training} alt="" /><span>Training</span></a></li>
            </ul>
        </div>


    );
}


export default SideBar;

