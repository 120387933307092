/* eslint-disable react-hooks/exhaustive-deps */
import Footer from "../../Footer";
import Header from "../../Header";
import "../CriticalUnusualIncidentForm/CriticalUnusualIncidentForm.scss";
import "../ImmidiateAssesmentForm/ImmidiateAssesmentForm.scss";
import immediateinner from "../../../images/assets/formimages/immediateinner.svg";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import FormSideBar from "../FormSidebar/FormSidebar";
import { Link } from "react-router-dom";
//import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { post } from "../../../utils/common";
import { str } from "envalid";
import LoadSpinner from "../../Loader/LoadSpinner";

function ImmidiateAssesmentForm() {
  // const history = useHistory();
  const [immidiateassesmenteditdata, setImmidiateassesmenteditdata] = useState(false);

  const [AddFormData, setAddFormData] = useState([]);
  const [selectChildData, setSelectChildData] = useState([]);
  const [GeneraBodylLocation, setGeneralBodyLocation] = useState([]);
  // const [personSigningTheForm, setSigningTheForm] = useState([]);

  const [safetyAssessMentTool, setsafetyAssessMentTool] = useState([]);
  const [questionToBeAskedOne, setQuestionToBeAskedOne] = useState([]);
  const [questionToBeAskedTwo, setQuestionToBeAskedTwo] = useState([]);
  const [questionToBeAskedThree, setQuestionToBeAskedThree] = useState([]);
  const [childSelectedForData, setChildSelectedForData] = useState([]);
  const [FormdatabyIncidentIdLoaded, setFormDatabyIncidentIdLoaded] = useState(
    []
  );
  const [personReportingData, setPersonReportingData] = useState([]);

  const [
    FormdatabyIncidentIdLoadedStatus,
    setFormDatabyIncidentIdLoadedStatus,
  ] = useState(false);


  const [checkboxes, setCheckboxes] = useState([
    { id: 4, label: 'Assaultive behavior', checked: false },
    { id: 2, label: 'Feeling unsafe', checked: false },
    { id: 12, label: 'History of incarceration', checked: false },
    { id: 5, label: 'History of trauma', checked: false },
    { id: 1, label: 'Losing control', checked: false },
    { id: 10, label: 'Physical abuse', checked: false },
    { id: 3, label: 'Seclusion / restraint', checked: false },
    { id: 6, label: 'Self-injury', checked: false },
    { id: 9, label: 'Setting fires', checked: false },
    { id: 8, label: 'Sexual abuse', checked: false },
    { id: 7, label: 'Suicides attempts', checked: false },
    { id: 11, label: 'Verbal abuse', checked: false },
  ]);

  const [checkboxes1, setCheckboxes1] = useState([
    { id: 18, label: 'Anniversaries (dates)', checked: false },
    { id: 14, label: 'Being isolated', checked: false },
    { id: 13, label: 'Being touched', checked: false },
    { id: 19, label: 'Loud noise', checked: false },
    { id: 22, label: 'Overcrowding', checked: false },
    { id: 16, label: 'Particular time of day', checked: false },
    { id: 17, label: 'People in uniform', checked: false },
    { id: 21, label: 'Seeing others upset', checked: false },
    { id: 24, label: 'Specific person (who)', checked: false },
    { id: 20, label: 'Time of year or month', checked: false },
    { id: 15, label: 'Weather', checked: false },
    { id: 23, label: 'Yelling', checked: false },
  ]);

  const [checkboxes2, setCheckboxes2] = useState([
    { id: 34, label: 'Eat', checked: false },
    { id: 25, label: 'Hit or hurt others', checked: false },
    { id: 29, label: 'Hurt animals', checked: false },
    { id: 38, label: 'Hurt self', checked: false },
    { id: 39, label: 'Pace the floor', checked: false },
    { id: 37, label: 'Set fires', checked: false },
    { id: 31, label: 'Slam doors', checked: false },
    { id: 32, label: 'Sleep', checked: false },
    { id: 36, label: 'Steal', checked: false },
    { id: 28, label: 'Swear', checked: false },
    { id: 27, label: 'Threaten others', checked: false },
    { id: 26, label: 'Throw things', checked: false },
    { id: 33, label: 'Use drugs / alcohol', checked: false },
    { id: 30, label: 'Want sexual activity', checked: false },
    { id: 35, label: 'Yell or shout', checked: false },
  ]);

  const [checkboxes3, setCheckboxes3] = useState([
    { id: 52, label: 'Counting to 10', checked: false },
    { id: 55, label: 'Draw', checked: false },
    { id: 50, label: 'Exercise', checked: false },
    { id: 56, label: 'Focusing on an object', checked: false },
    { id: 45, label: 'Going for a walk', checked: false },
    { id: 41, label: 'Listening to music', checked: false },
    { id: 53, label: 'Pacing', checked: false },
    { id: 49, label: 'Punching a pillow', checked: false },
    { id: 54, label: 'Putting hands in water', checked: false },
    { id: 57, label: 'Putting ice on wrists', checked: false },
    { id: 44, label: 'Reading', checked: false },
    { id: 42, label: 'Talking to parents', checked: false },
    { id: 46, label: 'Talking to peers', checked: false },
    { id: 43, label: 'Talking with a therapist', checked: false },
    { id: 40, label: 'Talking with foster parent', checked: false },
    { id: 51, label: 'Voluntary time out', checked: false },
    { id: 47, label: 'Watching T.V.', checked: false },
    { id: 48, label: 'Wrapping up in a blanket', checked: false },
  ]);
  //const [AddFormData, setChildSelectedData] = useState([]);

  const fetchFormDataLoad = async () => {
    const SFCSFamiliesIAID = sessionStorage.getItem("SFCSFamiliesIAID");
    const providerid = sessionStorage.getItem("providerSessionId");
    FormdatabyIncidentIdLoaded.NameOfFosterParent = sessionStorage.getItem("ProviderName");
    let data = await post("/api/immidiateassesment/getformdataload", {
      providerid, SFCSFamiliesIAID
    });

    setSelectChildData(data.data.data.recordsets[0]);
    setGeneralBodyLocation(data.data.data.recordsets[6]);
    setPersonReportingData(data.data.data.recordsets[1]);
    //setSigningTheForm(data.data.data.recordsets[1]);
    setsafetyAssessMentTool(data.data.data.recordsets[2]);
    setQuestionToBeAskedOne(data.data.data.recordsets[3]);
    setQuestionToBeAskedTwo(data.data.data.recordsets[4]);
    setQuestionToBeAskedThree(data.data.data.recordsets[5]);
  };
  const SFCSFamiliesBodyPartGeneralLocationID = localStorage.getItem("iabodyformdata")?.SFCSFamiliesBodyPartGeneralLocationID;
  const FetchFormDataLoadById = () => { };

  function toNumber(value) {
    return Number(value);
  }

  const selectedImmidiateAssesmentList = []

  const onSubmitButtonClick = async (str) => {
    // if(AddFormData.ClientID == undefined || AddFormData.DOB == undefined || AddFormData.PlacementTime == undefined ||
    //   AddFormData.date_of_placement == undefined){
    //      alert("Please enter the mandatory fields");
    //      return;
    //    }

    if (str === 'draft') {
      AddFormData.IsFinalized = 0;
    }
    else
      AddFormData.IsFinalized = 1;

    let CalmingTypeID = [];
    let IndicatorTypeID = [];
    let HistoryTypeID = [];
    let TriggerTypeID = [];
    for (let i = 0; i < checkedids.length; i++) {
      if (checkedids[i] <= 12) {

        (HistoryTypeID).push(checkedids[i]);
      }
      else if (checkedids[i] > 12 && checkedids[i] <= 24) {

        (TriggerTypeID).push(checkedids[i]);
      }
      else if (checkedids[i] > 24 && checkedids[i] <= 39) {

        (IndicatorTypeID).push(checkedids[i]);
      }
      else if (checkedids[i] > 39 && checkedids[i] <= 57) {

        (CalmingTypeID).push(checkedids[i]);
      }
      else {

      }
    }



    // let list = userinfoHistory.vehicle2;
    // let nums = list.map(toNumber);
    //  let  HistoryTypeID = nums;

    //  let list1 = userinfoTrigger.vehicle2;
    //  let nums1 = list1.map(toNumber);
    //   let  TriggerTypeID  = nums1;

    // let list2 = userinfoIndicator.vehicle2;
    // let nums2 = list2.map(toNumber);
    //  let  IndicatorTypeID  = nums2;

    //  let list3 = userinfoCalming.vehicle2;
    //  let nums3 = list3.map(toNumber);
    //   let  CalmingTypeID  = nums3;

    // console.log("selectedUnusualIncidentList", selectedUnusualIncidentList);

    //  console.log("FormData", FormdatabyIncidentIdLoaded);
    //e.preventDefault();
    AddFormData.SFCSFamiliesBodyPartDetailLocationID = parseInt(AddFormData.SFCSFamiliesBodyPartDetailLocationID);
    let provider_ID = sessionStorage.getItem("providerSessionId");
    let providerid = parseInt(provider_ID);
    let ClientID = parseInt(sessionStorage.ClientID);
    if (isNaN(AddFormData.SFCSFamiliesBodyPartDetailLocationID)) {
      AddFormData.SFCSFamiliesBodyPartDetailLocationID = parseInt(bodyData.SFCSFamiliesBodyPartDetailLocationID);
    }
    else {
      AddFormData.SFCSFamiliesBodyPartDetailLocationID = parseInt(AddFormData.SFCSFamiliesBodyPartDetailLocationID);

    }
    if (AddFormData.IsFinalized === 1) {
      AddFormData.IsFinalized = "Yes";

    };

    AddFormData.DateOfPlacement = new Date(AddFormData.DateOfPlacement);
    AddFormData.DOB = new Date(AddFormData.DOB);
    AddFormData.DateOfPlacement = (AddFormData.DateOfPlacement).getFullYear() + "-" + ("0" + ((AddFormData.DateOfPlacement).getMonth() + 1)).slice(-2) + "-" + ("0" + (AddFormData.DateOfPlacement).getDate()).slice(-2);
    AddFormData.DOB = (AddFormData.DOB).getFullYear() + "-" + ("0" + ((AddFormData.DOB).getMonth() + 1)).slice(-2) + "-" + ("0" + (AddFormData.DOB).getDate()).slice(-2) + "T00:00:00.000Z";
    let changedBy = sessionStorage.getItem("username");
    AddFormData.SFCSFamiliesBodyPartGeneralLocationID = sessionStorage.SFCSFamiliesBodyPartGeneralLocationID;
    const SFCSFamiliesIAID = parseInt(sessionStorage.getItem("SFCSFamiliesIAID"));
    let ActualProviderName =sessionStorage.getItem("ProviderName");

    setFormDatabyIncidentIdLoadedStatus(true);
    //e.preventDefault();
    //here goes the api
    let data = await post("/api/immidiateassesment/updateform", {
      AddFormData, HistoryTypeID, TriggerTypeID, IndicatorTypeID, CalmingTypeID, providerid, changedBy, SFCSFamiliesIAID, ClientID,ActualProviderName
    });

    console.log(AddFormData);
    alert("Record Added successfully !");
    let fromButtonClick = localStorage.getItem("fromButtonClick");
    let fromIA = localStorage.getItem("FromIA");
    if (fromButtonClick === "true" || fromIA === "true") {
      window.location.href = "/immidiateassesment";
    }
    else if (fromIA === "false") {
      window.location.href = "/currentchildren/currentchildrenimmidiateassesment";
    }
    else {

    }

    // window.location.reload(false);
    //  history.push("/form/formindex");
  };



  const [userinfoHistory, setUserInfoHistory] = useState({
    vehicle2: [],
    response: [],
  });
  //
  const handleChangeHistory = (e, id) => {
    // Destructuring
    const { value, checked } = e.target;
    const { vehicle2 } = userinfoHistory;

    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setUserInfoHistory({
        vehicle2: [...vehicle2, id],
        response: [...vehicle2, id],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setUserInfoHistory({
        vehicle2: vehicle2.filter((e) => e !== id),
        response: vehicle2.filter((e) => e !== id),
      });
    }

    for (let i = 1; i < 12; i++) {
      if (!checkedids.includes(id) && checked == true) {
        checkedids.push(id);
      }
      else if (checkedids[i] == id && checked == false) {
        checkedids.splice(i, 1);
      }
      else {

      }
    }

    console.log("userinfo.response", userinfoHistory.response);
  };

  const shouldCheckboxBeChecked = (id) => checkedids.includes(id);
  const shouldCheckboxBeChecked1 = (id1) => checkedids.includes(id1);
  const shouldCheckboxBeChecked2 = (id2) => checkedids.includes(id2);
  const shouldCheckboxBeChecked3 = (id3) => checkedids.includes(id3);

  const [userinfoTrigger, setUserInfoTrigger] = useState({
    vehicle2: [],
    response: [],
  });
  //
  const handleChangeTrigger = (e, id1) => {
    // Destructuring
    const { value, checked } = e.target;
    const { vehicle2 } = userinfoTrigger;

    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setUserInfoTrigger({
        vehicle2: [...vehicle2, value],
        response: [...vehicle2, value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setUserInfoTrigger({
        vehicle2: vehicle2.filter((e) => e !== value),
        response: vehicle2.filter((e) => e !== value),
      });
    }

    for (let i = 0; i < 12; i++) {
      if (!checkedids.includes(id1) && checked == true) {
        checkedids.push(id1);
      }
      else if (checkedids[i] == id1 && checked == false) {
        checkedids.splice(i, 1);
      }
      else {

      }
    }

    console.log("userinfo.response", userinfoTrigger.response);
  };

  const [userinfoIndicator, setUserInfoIndicator] = useState({
    vehicle2: [],
    response: [],
  });
  //
  const handleChangeIndicator = (e, id3) => {
    // Destructuring
    const { value, checked } = e.target;
    const { vehicle2 } = userinfoIndicator;

    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setUserInfoIndicator({
        vehicle2: [...vehicle2, value],
        response: [...vehicle2, value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setUserInfoIndicator({
        vehicle2: vehicle2.filter((e) => e !== value),
        response: vehicle2.filter((e) => e !== value),
      });
    }

    for (let i = 0; i < 15; i++) {
      if (!checkedids.includes(id3) && checked == true) {
        checkedids.push(id3);
      }
      else if (checkedids[i] == id3 && checked == false) {
        checkedids.splice(i, 1);
      }
      else {

      }
    }

    console.log("userinfo.response", userinfoIndicator.response);
  };

  const [userinfoCalming, setUserInfoCalming] = useState({
    vehicle2: [],
    response: [],
  });
  //
  const handleChangeCalming = (e, id4) => {
    // Destructuring
    const { value, checked } = e.target;
    const { vehicle2 } = userinfoCalming;

    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setUserInfoCalming({
        vehicle2: [...vehicle2, value],
        response: [...vehicle2, value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setUserInfoCalming({
        vehicle2: vehicle2.filter((e) => e !== value),
        response: vehicle2.filter((e) => e !== value),
      });
    }

    for (let i = 0; i < 18; i++) {
      if (!checkedids.includes(id4) && checked == true) {
        checkedids.push(id4);
      }
      else if (checkedids[i] == id4 && checked == false) {
        checkedids.splice(i, 1);
      }
      else {

      }
    }

    console.log("userinfo.response", userinfoCalming.response);
  };



  const onSubmitButtonClickSub = async (e) => {
    e.preventDefault();
    alert("Record submit successfully !");
    window.location.reload(false);
    let fromButtonClick = localStorage.getItem("fromButtonClick");
    if (fromButtonClick === "true")
      window.location.href = "/immidiateassesment";
    // history.push("/form/formindex");
  };

  const closeClicked = () => {
    window.location.href = "/immidiateassesment"
  };

  const [DetailBodyLocation, setDetailedBodyLocation] = useState([]);



  const clearEditForBodyLocations = () => {
    setDetailedBodyLocation([]);
  };

  const [genBodyLocationId, setGeneralBodyLocationId] = useState(false);

  const getAllDetailbodyLocation = async (e) => {
    let id = null;
    setGeneralBodyLocationId(true);
    if (e !== undefined) {
      id = e.target.value;
    }
    else {
      id = sessionStorage.detailID;
    }

    AddFormData.SFCSFamiliesBodyPartGeneralLocationID = parseInt(id);
    sessionStorage.setItem("SFCSFamiliesBodyPartGeneralLocationID", AddFormData.SFCSFamiliesBodyPartGeneralLocationID);
    console.log(id);
    let detailBodyLocation = await post("/api/immidiateassesment/detailbody", {
      id,
    });
    console.log("detailbody", detailBodyLocation);

    setDetailedBodyLocation(detailBodyLocation.data.DetailLocation);
    setImmidiateassesmenteditdata(true);

  };
  // console.log(personSign/ingTheForm)
  const [IAHistoryFromString, setIAHistoryFromString] = useState([]);
  const [IACalmingFromString, setIACalmingFromString] = useState([]);
  const [IAIndicatorsFromString, setIAIndicatorsFromString] = useState([]);
  const [IATriggersFromString, setIATriggersFromString] = useState([]);
  const [checkedids, setCheckedids] = useState([])
  let arrayofIAIds = []
  const [IADetailLocation, setIADetailLocation] = useState([]);
  const [IAGeneralLocation, setIAGeneralLocation] = useState([]);



  const [data, setData] = useState([]);
  const [bodyData, setbodyData] = useState([]);
  const clickedForDownload = async () => {
    const SFCSFamiliesIAID = sessionStorage.getItem("SFCSFamiliesIAID");
    const providerid = sessionStorage.getItem("providerSessionId");
    //let iacheckedids = sessionStorage.getItem("iacheckedids");
    let dataIA = await post("/api/immidiateassesment/getiaform", {
      providerid,
      SFCSFamiliesIAID
    });
    dataIA.data.data[0].Description = dataIA.data.bodyData[0].Description;
    dataIA.data.data[0].DetailLocation = dataIA.data.bodyData[0].DetailLocation;
    setIADetailLocation(dataIA.data.data[0].DetailLocation);
    dataIA.data.data[0].GeneralLocation = dataIA.data.bodyData[0].GeneralLocation;
    setIAGeneralLocation(dataIA.data.data[0].GeneralLocation);
    dataIA.data.data[0].Notes = dataIA.data.bodyData[0].Notes;
    sessionStorage.setItem("ClientID", dataIA.data.data[0].ClientID)


    if (dataIA.data.data[0].iacheckedids != null) {
      let string = dataIA.data.data[0].iacheckedids;
      arrayofIAIds = string.split(",");
      let arrayofIANum = arrayofIAIds.map(Number);
      arrayofIANum.sort((a, b) => a - b)


      setCheckedids(arrayofIANum);
    }
    setAddFormData(dataIA.data.data[0]);

    if (dataIA.data.data[0].IsHarmfulThoughts === "No")
    dataIA.data.data[0].IsHarmfulThoughts = false;
    else
    dataIA.data.data[0].IsHarmfulThoughts = true;

    if (dataIA.data.data[0].IsRunningAway === "No")
    dataIA.data.data[0].IsRunningAway = false;
    else
    dataIA.data.data[0].IsRunningAway = true;

    if (dataIA.data.data[0].IsRunningAwayPlan === "No")
    dataIA.data.data[0].IsRunningAwayPlan = false;
    else
    dataIA.data.data[0].IsRunningAwayPlan = true;
    // setCheckedids(dataIA.data.data[0]);IsHarmfulThoughts

    if (dataIA.data.data[0].IsOtherHistory === "No")
    dataIA.data.data[0].IsOtherHistory = false;
    else
    dataIA.data.data[0].IsOtherHistory = true;

    if (dataIA.data.data[0].IsOtherTrigger === "No")
    dataIA.data.data[0].IsOtherTrigger = false;
    else
    dataIA.data.data[0].IsOtherTrigger = true;

    if (dataIA.data.data[0].IsOtherUpsettingIndicators === "No")
    dataIA.data.data[0].IsOtherUpsettingIndicators = false;
    else
    dataIA.data.data[0].IsOtherUpsettingIndicators = true;

    if (dataIA.data.data[0].IsOtherCalmingActivities === "No")
    dataIA.data.data[0].IsOtherCalmingActivities = false;
    else
    dataIA.data.data[0].IsOtherCalmingActivities = true;

    
    setbodyData(dataIA.data.bodyData[0]);
    sessionStorage.setItem("detailID", dataIA.data.bodyData[0].SFCSFamiliesBodyPartGeneralLocationID)

    console.log(checkedids);

    // if(dataIA.data.data[0].iacheckedids != null){
    //   let string = dataIA.data.data[0].iacheckedids;
    //    arrayofIAIds = string.split(",");
    //    let arrayofIANum = arrayofIAIds.map(Number);
    //   setCheckedids(arrayofIANum);
    // }

    //  // const iaFinalFormData = iaformdata ? JSON.parse(iaformdata) : [];
    // if(dataIA.data.data[0].SAHistory != null){
    //   let string = dataIA.data.data[0].SAHistory;
    //   let arrayofIAHistory = string.split("\r\n");
    //   setIAHistoryFromString(arrayofIAHistory);

    // }
    // if(dataIA.data.data[0].SACalming != null){
    //     let string1 = dataIA.data.data[0].SACalming;
    //     let arrayofIACalming = string1.split("\r\n");
    //     setIACalmingFromString(arrayofIACalming);
    // }
    // if(dataIA.data.data[0].SAIndicators != null){
    //     let string2 = dataIA.data.data[0].SAIndicators;
    //     let arrayofIAIndicators = string2.split("\r\n");
    //     setIAIndicatorsFromString(arrayofIAIndicators);
    // }
    // if(dataIA.data.data[0].SATriggers != null){
    //     let string3 = dataIA.data.data[0].SATriggers;
    //     let arrayofIATriggers = string3.split("\r\n");
    //     setIATriggersFromString(arrayofIATriggers);
    // }
    setTimeout(() => {
      getAllDetailbodyLocation()
    }, 4000);

  };





  const childSelectedData = (event) => {
    const childId = event.target.value;
    selectChildData.map((childData) => {
      if (childData.ClientID == childId) {
        setChildSelectedForData(childData);
        AddFormData.ClientID = parseInt(childId);
        let DOP = childData.date_of_placement.split("T")[0];
        AddFormData.DOB = childData.DOB;
        AddFormData.date_of_placement = DOP;
      }
    });
    // selectChildData.filter(child=>child.ClientID == childId)
  };

  const [FormData, setFormData] = useState([])


  useEffect(() => {
    clickedForDownload();
    fetchFormDataLoad();



  }, []);

  return (
    <div className="main">

      <Header />

      <div className="main-content">
        {immidiateassesmenteditdata ? <HeaderTopDetail /> : <LoadSpinner />}
        
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <FormSideBar />
              <div className="tab-detail Critical">
                <div className="tab-detail-top">
                  <div className="Critical-unusual">
                    <img src={immediateinner} alt="" />
                    Immediate Assessment
                  </div>
                </div>
                <div className="tab-detail-bottom">
                  <form>
                    <div className="form-row dandt">
                      <div className="one-fourth">
                        <label for="birthday" className="increasedFont">
                          Child:<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="increasedFont"
                          value={AddFormData.ClientName}
                        />
                        {/* <select>
                                                    <option value="0">Select</option>

                                                </select> */}
                      </div>
                      <div className="one-fourth">
                        <label for="appt" className="increasedFont">
                          Date of Birth:<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          className="increasedFont"
                          type="text"

                          id="appt"
                          name="appt"
                          value={AddFormData.DOB}
                        />
                      </div>
                      <div className="one-fourth">
                        <label for="birthday" className="increasedFont">Date of Placement:<span style={{ color: "red" }}>*</span></label>
                        <input
                          className="increasedFont"
                          type="text"

                          id="birthday"
                          name="birthday"
                          value={AddFormData.DateOfPlacement}
                        />
                      </div>
                      <div className="one-fourth">
                        <label for="appt">Placement Time:<span style={{ color: "red" }}>*</span></label>
                        <input type="time" id="appt" name="appt" onChange={(e) =>
                          setAddFormData({
                            ...AddFormData,
                            PlacementTime: e.target.value,
                          })
                        } value={AddFormData.PlacementTime} />
                      </div>
                    </div>
                    <div className="form-row mt-0">
                      <div className="full-input">
                        <label for="appt">
                          1. List any medication allergies or special
                          conditions/limitations
                        </label>
                        <input type="text" onChange={(e) =>
                          setAddFormData({
                            ...AddFormData,
                            SpecialConditions: e.target.value,
                          })
                        } value={AddFormData.SpecialConditions} />
                      </div>
                    </div>
                    <div className="form-row mt-0">
                      <div className="full-input">
                        <label for="appt">
                          2. List any food allergies/special diets/eating or
                          nutritional abnormalities
                        </label>
                        <input type="text" onChange={(e) =>
                          setAddFormData({
                            ...AddFormData,
                            SpecialDiets: e.target.value,
                          })
                        } value={AddFormData.SpecialDiets} />
                      </div>
                    </div>

                    <div className="g-background">
                      <div className="form-row">
                        <label for="appt">
                          3. Description and location of tattoo's, cuts, bruises
                          or skin rashes:
                        </label>
                        <small>
                          <i>gen
                            (This is not intended to indicate need to unclothe
                            child to conduct search. Ask child/youth if there
                            are any such marks that are not visible, indicate
                            where they are and write "reported by child /
                            youth")
                          </i>
                        </small>
                        <div className="one-half">
                          <label for="appt">General Body Location:<span style={{ color: "red" }}>*</span>{SFCSFamiliesBodyPartGeneralLocationID}</label>


                          <select onChange={getAllDetailbodyLocation} >
                            <option value="0">Select</option>

                            {GeneraBodylLocation.map((genbody) => (
                              <option key={genbody.ID} value={genbody.ID}
                                selected={IAGeneralLocation ? genbody.BodyName === IAGeneralLocation : false}>
                                {genbody.BodyName}
                              </option>

                            ))}
                            {/* <option value="1">Audi</option>
                                                        <option value="2">BMW</option>
                                                        <option value="3">Citroen</option> */}
                          </select>
                          {/* <select onChange={(e) => getAllDetailbodyLocation({
                            ...AddFormData,
                           GeneralLocation : e.target.value,
                          })}>
                            <option value="0">Select</option>
                            {GeneraBodylLocation
                            .filter(
                              (childData) =>
                                childData.BodyName === AddFormData.GeneralLocation
                            )
                            .map((childData) => (i
                              <option selected value ={childData.ID}>
                                {childData.BodyName}
                              </option>
                            ))}

                          {/* <option selected value={EditLoadData.ClientID}>{EditLoadData.ClientName}</option> */}
                          {/* {GeneraBodylLocation.map((child) => (
                            <option value={child.ID}>
                              {child.BodyName}
                            </option>
                          ))} </select> */}

                        </div>
                        <div className="one-half">
                          <label for="appt">Detail Body Location:<span style={{ color: "red" }}>*</span></label>

                          <select onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              SFCSFamiliesBodyPartDetailLocationID: e.target.value,
                            })
                          }>
                            <option value="0">Select</option>
                            {DetailBodyLocation.map((detbody) => (
                              <option key={detbody.SFCSFamiliesBodyPartDetailLocationID}
                                value={detbody.SFCSFamiliesBodyPartDetailLocationID}
                                selected={IADetailLocation ? detbody.BodyName === IADetailLocation : false}>
                                {detbody.BodyName}
                              </option>
                            ))}
                            {/* <option value="1">Audi</option>
                                                        <option value="2">BMW</option>
                                                        <option value="3">Citroen</option> */}
                          </select>
                          {/* <select   onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              SFCSFamiliesBodyPartDetailLocationID: e.target.value,
                            })
                            
                          }>
                            <option value="0">Select</option>
                            {DetailBodyLocation
                              .filter(
                                (detbody) =>
                                detbody.BodyName === bodyData.DetailLocation
                              )
                              .map((detbody) => (
                              <option selected value={detbody.SFCSFamiliesBodyPartDetailLocationID}>
                                {detbody.BodyName}
                              </option>
                            ))} */}
                          {/* <option value="1">Audi</option>
                                                        <option value="2">BMW</option>
                                                        <option value="3">Citroen</option>  </select> */}

                        </div>
                        {/* {genBodyLocationId ? (
                          <Link
                            class="normal"
                            onClick={() => setDetailedBodyLocation([])}
                          >
                            Clear Edit
                          </Link>
                        ) : (
                          ""
                        )} */}
                        <div className="one-full">
                          <label for="appt">Notes:</label>
                          <textarea
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                Notes: e.target.value,
                              })

                            } value={AddFormData.Notes}></textarea>
                        </div>
                        <div className="one-full">
                          <label for="appt">Description:</label>
                          <textarea

                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                Description: e.target.value,
                              })
                            } value={AddFormData.Description}></textarea>
                        </div>
                      </div>
                    </div>

                    <div className="form-row mt-0">
                      <div className="full-input">
                        <label for="appt">
                          4. Describe any other current physical complaint,
                          illness or pain
                        </label>
                        <input type="text" value={AddFormData.PhysicalComplaints} onChange={(e) =>
                          setAddFormData({
                            ...AddFormData,
                            PhysicalComplaints: e.target.value,
                          })
                        } />
                      </div>
                    </div>
                    <div className="form-row mt-0">
                      <div className="full-input">
                        <label for="appt">
                          5. Describe the mood or attitude of child/youth at the
                          time of placement.
                        </label>
                        <input type="text" value={AddFormData.ClientAttitude} onChange={(e) =>
                          setAddFormData({
                            ...AddFormData,
                            ClientAttitude: e.target.value,
                          })
                        } />
                      </div>
                    </div>

                    <div className="g-background">
                      <div className="form-row">
                        <div className="one-full">
                          <label className="checkbox-label">
                            6. Does child/youth have thoughts of harming self or
                            others?
                            <div className="check-action">
                              {" "}
                              <span for="yes">
                                {" "}
                                Yes{" "}
                                <input
                                  type="checkbox"
                                  id="yes"
                                  name="vehicle3"
                                  value="Boat"
                                  onChange={(e) =>
                                    setAddFormData({
                                      ...AddFormData,
                                      IsHarmfulThoughts: e.target.checked,
                                    })
                                  }
                                  checked={
                                    AddFormData.IsHarmfulThoughts
                                      ? true : false
                                  }
                                />
                              </span>
                              {/* <span for="no">
                                {" "}
                                No{" "}
                                <input
                                  type="radio"
                                  id="no"
                                  name="vehicle3"
                                  value="Boat"  onChange={(e) =>
                                    setAddFormData({
                                      ...AddFormData,
                                      IsHarmfulThoughts: !e.target.checked,
                                    })
                                  }
                                  checked={  

                                    AddFormData.IsHarmfulThoughts
                                       ? true : false
                                  }
                                />
                              </span> */}
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="one-full">
                          <label for="appt">
                            If Yes, what have they thought about and is there a
                            plan?
                          </label>
                        </div>
                        <div className="one-full">
                          <input type="text" onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              HarmfulThoughtsDescription: e.target.value,
                            })
                          } value={AddFormData.HarmfulThoughtsDescription} />
                        </div>


                        <div className="one-full">
                          <label className="checkbox-label">
                            7. Does child/youth feel like running away?{" "}
                            <div className="check-action">
                              <span for="yes">
                                {" "}
                                Yes{" "}
                                <input
                                  type="checkbox"
                                  id="yes"
                                  name="vehicle1"
                                  value=""
                                  onChange={(e) =>
                                    setAddFormData({
                                      ...AddFormData,
                                      IsRunningAway: e.target.checked,
                                    })
                                  }
                                  checked={

                                    AddFormData.IsRunningAway
                                      ? true : false
                                  } />
                              </span>
                              {/* <span for="no">
                                {" "}
                                No{" "}
                                <input
                                  type="radio"
                                  id="no"
                                  name="vehicle1"
                                  value=""
                                  onChange={(e) =>
                                    setAddFormData({
                                      ...AddFormData,
                                      IsRunningAway: !e.target.checked,
                                    })
                                  }
                                  checked={  

                                    AddFormData.IsRunningAway
                                    ? true : false
                                  }
                                />
                              </span> */}
                            </div>
                          </label>
                          <label className="checkbox-label">
                            If Yes, Is there a plan?{" "}
                            <div className="check-action">
                              <span for="yes">
                                {" "}
                                Yes{" "}
                                <input
                                  type="checkbox"
                                  id="yes"
                                  name="vehicle4"
                                  value=""
                                  onChange={(e) =>
                                    setAddFormData({
                                      ...AddFormData,
                                      IsRunningAwayPlan: e.target.checked,
                                    })
                                  } checked={

                                    AddFormData.IsRunningAwayPlan
                                      ? true : false
                                  } />
                              </span>
                              {/* <span for="no">
                                {" "}
                                No{" "}
                                <input
                                  type="radio"
                                  id="no"
                                  name="vehicle4"
                                  value=""
                                  onChange={(e) =>
                                    setAddFormData({
                                      ...AddFormData,
                                      IsRunningAwayPlan: !e.target.checked,
                                    })
                                  }
                                  checked={  
                                    AddFormData.IsRunningAwayPlan
                                    ? true : false
                                  }
                                />
                              </span>{" "} */}
                              <label>Explain</label>
                            </div>
                          </label>
                          <input type="text"
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                RunningAwayPlanDescription: e.target.value,
                              })
                            } value={AddFormData.RunningAwayPlanDescription}
                          />
                        </div>
                        <small>
                          ***IMPORTANT: If answers to questions 6 or 7 are yes,
                          Resource Family will contact FCH worker, remain one on
                          one with child to develop plan, and/or contact case
                          manager emergency number. ***
                        </small>
                      </div>
                    </div>

                    <div className="form-row check-section fd-c">
                      <h6>Safety Assessment Tool</h6>
                      <label for="appt">
                        Does child/youth have a history of any of the following
                        (obtain information from placing agency)?
                      </label>
                      <div className="checkbox-grid">
                        {checkboxes.map((checkbox) => (
                          <div className="one-fourth">
                            <span className="check-option">
                              <input
                                type="checkbox"
                                id={checkbox.id}
                                defaultChecked={true}
                                onChange={(e) => handleChangeHistory(e, checkbox.id)
                                }
                                checked={shouldCheckboxBeChecked(checkbox.id)}


                              />
                              <label for={checkbox.id}>
                                {checkbox.label}
                              </label>
                            </span>
                          </div>
                        ))}

                        <div className="one-fourth">
                          <span className="check-option">
                            <input
                              onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  IsOtherHistory: e.target.checked,

                                })
                              }
                              type="checkbox"
                              id="IsOtherHistory"
                              name="vehicle2"
                              value="Car"
                              checked={AddFormData.IsOtherHistory ? true : false}
                            />
                            <label for="IsOtherHistory">
                              Other (please list)
                              <input type="text" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  OtherHistoryList: e.target.value,
                                })
                              }
                                class="form-controler" value={AddFormData.OtherHistoryList}></input>
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="form-row check-section fd-c">
                      <h6>Ask child / youth following questions</h6>
                      <label for="appt">
                        {" "}
                        These things are "triggers" for child/youth and cause
                        him/her to get upset or mad. (check all that apply)
                      </label>
                      <div className="checkbox-grid">
                        {checkboxes1.map((checkbox) => (
                          <div className="one-fourth">
                            <span className="check-option">
                              <input

                                type="checkbox"
                                id={checkbox.id}
                                name="vehicle2"
                                //value={question.SFCSFamiliesSafetyAssessmentID}
                                onChange={(e) => handleChangeTrigger(e, checkbox.id)
                                }
                                checked={shouldCheckboxBeChecked1(checkbox.id)}
                              />
                              <label for={checkbox.id}>
                                {checkbox.label}
                              </label>
                            </span>
                          </div>
                        ))}

                        <div className="one-fourth">
                          <span className="check-option">
                            <input
                              onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  IsOtherTrigger: e.target.checked,
                                })
                              }
                              type="checkbox"
                              id="IsOtherTrigger"
                              name="vehicle2"
                              value="Car"
                              checked={AddFormData.IsOtherTrigger ? true : false}
                            />
                            <label for="IsOtherTrigger">
                              {" "}
                              Other (please list)
                              <input type="text" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  OtherTriggerList: e.target.value,
                                })
                              } class="form-controler" value={AddFormData.OtherTriggerList}></input>
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="form-row check-section fd-c">
                      <label for="appt">
                        When child/youth is mad or upset, they report they do
                        these things. (check all that apply)
                      </label>
                      <div className="checkbox-grid">
                        {checkboxes2.map((checkbox) => (
                          <div className="one-fourth">
                            <span className="check-option">
                              <input

                                type="checkbox"
                                id={checkbox.id}
                                name="vehicle2"
                                //value={question.SFCSFamiliesSafetyAssessmentID}
                                onChange={(e) => handleChangeIndicator(e, checkbox.id)
                                }
                                checked={shouldCheckboxBeChecked2(checkbox.id)}
                              />
                              <label for={checkbox.id}>
                                {checkbox.label}
                              </label>
                            </span>
                          </div>
                        ))}

                        <div className="one-fourth">
                          <span className="check-option">
                            <input
                              onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  IsOtherUpsettingIndicators: e.target.checked,
                                })
                              }
                              type="checkbox"
                              id="IsOtherUpsettingIndicators"
                              name="vehicle2"
                              value="Car"
                              checked={AddFormData.IsOtherUpsettingIndicators ? true : false}
                            />
                            <label for="IsOtherUpsettingIndicators">
                              Other (please list)
                              <input type="text" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  OtherUpsettingIndicatorsList: e.target.value,
                                })
                              } class="form-controler" value={AddFormData.OtherUpsettingIndicatorsList}></input>
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="form-row check-section fd-c">
                      <label for="appt">
                        {" "}
                        Child/youth reports these things help calm him/her down
                        when they are mad or upset. (check all that apply)
                      </label>
                      <div className="checkbox-grid">
                        {checkboxes3.map((checkbox) => (
                          <div className="one-fourth">
                            <span className="check-option">
                              <input
                                // onChange={handleChangeCalming}
                                type="checkbox"
                                id={checkbox.id}
                                name="vehicle2"
                                onChange={(e) => handleChangeCalming(e, checkbox.id)}
                                //value={question.SFCSFamiliesSafetyAssessmentID}
                                checked={shouldCheckboxBeChecked3(checkbox.id)}
                              />
                              <label for={checkbox.id}>
                                {checkbox.label}
                              </label>
                            </span>
                          </div>
                        ))}

                        <div className="one-fourth">
                          <span className="check-option">
                            <input
                              onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  IsOtherCalmingActivities: e.target.checked,
                                })
                              }
                              type="checkbox"
                              id="IsOtherCalmingActivities"
                              name="vehicle2"
                              value="Car"
                              checked={AddFormData.IsOtherCalmingActivities ? true : false}
                            />
                            <label for="IsOtherCalmingActivities">
                              Other (please list)
                              <input type="text" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  OtherCalmingActivitiesList: e.target.value,
                                })
                              } class="form-controler" value={AddFormData.OtherCalmingActivitiesList}></input>
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>


                    <div className="form-row mt-25">
                      <div className="label15 labelpadtop">
                        <label for="appt">Person Reporting:<span style={{color:"red"}}>*</span></label>
                      </div>
                      <div className="label23">
                        <select
                          onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              ProviderMemberID: e.target.value,
                            })
                          }
                        >
                          {/* <option>Please Select</option> */}
                          {FormdatabyIncidentIdLoadedStatus ? (
                            personReportingData
                              .filter(
                                (personReporting) =>
                                  personReporting.ProviderMemberID ===
                                  FormdatabyIncidentIdLoaded.ProviderMemberID
                              )
                              .map((personReporting) => (
                                <option
                                  selected
                                  value={personReporting.ProviderMemberID}
                                >
                                  {personReporting.ProviderName}
                                </option>
                              ))
                          ) : (
                            <option value="0">Please Select</option>
                          )}
                          {personReportingData.map((personReporting) => (
                            <option value={personReporting.ProviderMemberID}>
                              {personReporting.ProviderName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="label7"></div>
                      <div className="label15 labelpadtop">
                        <label for="birthday">Date Completed:<span style={{color:"red"}}>*</span></label>
                      </div>
                      <div className="label23">
                        <input type="date" />
                      </div>
                    </div>

                    {/* <div className="g-background">
                      <h6>MEDICATIONS THAT ARRIVED WITH CHILD/YOUTH</h6>

                      {
                      
                      MedicationinputList.map((data, index) => {
                        const {MedName, MedDose,MedFrequency,MedPurpose,MedCount}=data
                        return (
                          
                          <div className="form-row rg"  >
                            <div className="one-fifth">
                              <label for="appt">Name of Medication:</label>
                              <input type="text" name="MedName" value={MedName} onChange={(evnt) => handleMedicationChange(index, evnt)}/>
                            </div>
                            <div className="one-fifth">
                              <label for="appt">Dose:</label>
                              <input type="text" name="MedDose" value={MedDose} onChange={(evnt) => handleMedicationChange(index, evnt)}/>
                            </div>
                            <div className="one-fifth">
                              <label for="appt">When Given:</label>
                              <input type="text" name="MedFrequency" value={MedFrequency} onChange={(evnt) => handleMedicationChange(index, evnt)}/>
                            </div>
                            <div className="one-fifth">
                              <label for="appt">What for:</label>
                              <input type="text" name="MedPurpose" value={MedPurpose} onChange={(evnt) => handleMedicationChange(index, evnt)}/>
                            </div>
                            <div className="one-fifth">
                              <label for="appt">Initial Count:</label>
                              <input type="text" name="MedCount" value={MedCount} onChange={(evnt) => handleMedicationChange(index, evnt)}/>
                            </div>
                            <div className="action-btn">
                              {(MedicationinputList.length !== 1)? 
                                <Link 
                                  className="red-btn"
                                  onClick={ handleRemoveClick}
                                >
                                  Clear Edit
                                </Link>:''
                              }
                               
                                <Link
                                  className="blue-btn"
                                  onClick={handleAddClick}>
                                  Add
                                </Link>
                              
                            </div>
                          </div>
                        );
                      })}
                    </div> */}


                    <div className="action-btn">
                      <Link to="#" onClick={closeClicked} className="red-btn">
                        Cancel
                      </Link>
                      <Link
                        to="#"
                        onClick={() => onSubmitButtonClick('draft')}
                        className="blue-btn"
                      >
                        Save As Draft
                      </Link>

                      <Link
                        to="#"
                        onClick={() => onSubmitButtonClick('submit')}
                        className="blue-btn"
                      >
                        Submit
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ImmidiateAssesmentForm;