import React from "react";
import fb from "../../../../images/assets/images/fb.svg";
import tw from "../../../../images/assets/images/tw.svg";
import li from "../../../../images/assets/images/li.svg";
import yt from "../../../../images/assets/images/yt.svg";
import sfm from "../../../../images/assets/images/sfm.svg";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer-inner">
          <div className="footer-content">
            <p>
              785.825.0541 ᛫ 800.423.1342 ᛫ 110 West Otis Avenue ᛫ Salina, KS
              67401{" "}
            </p>

            <p>
              <b> © 2022 Saint Francis Ministries</b> .{" "}
              <Link
                to={{ pathname: "https://saintfrancisministries.org/privacy/" }}
                replace
                target="_blank"
              >
                {" "}
                Privacy (HIPAA)
              </Link>{" "} .{" "}
              <Link
                to={{ pathname: "https://saintfrancisministries.org/sitemap/" }}
                replace
                target="_blank"
              >
                {" "}
                Sitemap
              </Link>{" "}
              .{" "}
              <Link
                to={{
                  pathname: "https://saintfrancisministries.org/terms-of-use/",
                }}
                replace
                target="_blank"
              >
                Terms
              </Link>
              {" "} .{" "}
              <Link
                to={{
                  pathname: "https://saintfrancisministries.org/accreditation/",
                }}
                replace
                target="_blank"
              >
                Accreditation
              </Link>
            </p>
          </div>
          <div className="footer-social">
            <img src={sfm} alt="" width="150" />
            <ul>
              <li>
                <div id="google_translate_element"></div>
              </li>

              <li>
                <Link
                  to={{
                    pathname:
                      "https://www.facebook.com/SaintFrancisMinistries/",
                  }}
                  replace
                  target="_blank"
                >
                  <img src={fb} alt="" />
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "https://twitter.com/SaintFrancisMin/" }}
                  replace
                  target="_blank"
                >
                  <img src={tw} alt="" />
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname:
                      "https://www.linkedin.com/company/saintfrancisministries/",
                  }}
                  replace
                  target="_blank"
                >
                  <img src={li} alt="" />
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: "https://www.youtube.com/user/SaintFrancisCS",
                  }}
                  replace
                  target="_blank"
                >
                  <img src={yt} alt="" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
