import Footer from "../../Footer";
import Header from "../../Header";
import OnlineTrainingSideBar from "../SIdeBar/OnlineTrainingSideBar";
import HeaderTopDetail from '../../CurrentChildren/HeaderTopDetail/HeaderTopDetail';
import ot from '../../../images/assets/currentChildren/ot.svg';
import '../OnlineTrainingOne/OnlineTrainingOne.scss';
import { useHistory } from "react-router";
import { Link } from "react-router-dom";






function OnlineTrainingOne() {
    const history = useHistory()


    const moveToNext = () => {
        { 
            alert("                         **** COMING SOON! ****                         ");
             return;
        }
    }

    return (

        <div className="main">
            <Header />
            <div className="main-content" >
                <HeaderTopDetail />
                <div className="pagewithsidebar">
                    <div className="container">
                        <div className="pagewithsidebar-inner">
                            <OnlineTrainingSideBar />
                            <div className="tab-detail">
                                <div className="tab-detail-top">
                                    <div className="children-section">
                                        <img src={ot} alt="" />
                                        <div className="pagesubtitle">
                                            Online Training
                                        </div>
                                    </div>
                                    <select name="cars" className="select-training" id="cars">
                                        <option value="volvo" >First Select Person Taking Test</option>

                                    </select>
                                </div>
                                <div className="tab-detail-bottom children-data">
                                    <div className="videos-grid">
                                        <div className="video-detail">
                                            <h3>HIPAA Compliance Training - 1 Credits</h3>
                                            <div className="read-more read-more-open">
                                                <p>The following training material is broken down into 5 training modules. After you read each module you will be asked a series of questions in relation to the material. You must answer all questions correctly before moving to the next module. After answering all questions correctly in all modules, you will be sent a certificate to the e-mail you provide on the registration page.
                                                    After you have completed this training you should have a basic knowledge of;
                                                    <ol>
                                                        <li>1. Why HIPAA was enacted?</li>
                                                        <li>2. what health information a foster parent should access.</li>
                                                        <li>3. what you should do if you become aware of a privacy breach.</li>
                                                    </ol>
                                                </p>
                                                <Link to="#"><span onClick={moveToNext}>read more...</span></Link>
                                            </div>
                                        </div>
                                        {/* <div className="video-detail">
                                            <h3>HIPAA Compliance Training - 1 Credits</h3>
                                            <div className="read-more">
                                                <p>The following training material is broken down into 5 training modules. After you read each module you will be asked a series of questions in relation to the material. You must answer all questions correctly before moving to the next module. After answering all questions correctly in all modules, you will be sent a certificate to the e-mail you provide on the registration page.
                                                    After you have completed this training you should have a basic knowledge of;
                                                    <ol>
                                                        <li>1. Why HIPAA was enacted?</li>
                                                        <li>2. What health information a foster parent should access.</li>
                                                        <li>3. What you should do if you become aware of a privacy breach.</li>
                                                    </ol>
                                                </p>
                                                <Link to="#"><span onClick={moveToNext}>read more...</span></Link>
                                            </div>
                                        </div>
                                        <div className="video-detail">
                                            <h3>HIPAA Compliance Training - 1 Credits</h3>
                                            <div className="read-more">
                                                <p>The following training material is broken down into 5 training modules. After you read each module you will be asked a series of questions in relation to the material. You must answer all questions correctly before moving to the next module. After answering all questions correctly in all modules, you will be sent a certificate to the e-mail you provide on the registration page.
                                                    After you have completed this training you should have a basic knowledge of;
                                                    <ol>
                                                        <li>1. Why HIPAA was enacted?</li>
                                                        <li>2. what health information a foster parent should access.</li>
                                                        <li>3. what you should do if you become aware of a privacy breach.</li>
                                                    </ol>
                                                </p>
                                                <Link to="#"><span onClick={moveToNext}>read more...</span></Link>
                                            </div>
                                        </div>
                                        <div className="video-detail">
                                            <h3>HIPAA Compliance Training - 1 Credits</h3>
                                            <div className="read-more">
                                                <p>The following training material is broken down into 5 training modules. After you read each module you will be asked a series of questions in relation to the material. You must answer all questions correctly before moving to the next module. After answering all questions correctly in all modules, you will be sent a certificate to the e-mail you provide on the registration page.
                                                    After you have completed this training you should have a basic knowledge of;
                                                    <ol>
                                                        <li>1. Why HIPAA was enacted?</li>
                                                        <li>2. what health information a foster parent should access.</li>
                                                        <li>3. what you should do if you become aware of a privacy breach.</li>
                                                    </ol>
                                                </p>
                                                <Link to="#"><span onClick={moveToNext}>read more...</span></Link>
                                            </div>
                                        </div>
                                        <div className="video-detail">
                                            <h3>HIPAA Compliance Training - 1 Credits</h3>
                                            <div className="read-more">
                                                <p>The following training material is broken down into 5 training modules. After you read each module you will be asked a series of questions in relation to the material. You must answer all questions correctly before moving to the next module. After answering all questions correctly in all modules, you will be sent a certificate to the e-mail you provide on the registration page.
                                                    After you have completed this training you should have a basic knowledge of;
                                                    <ol>
                                                        <li>1. Why HIPAA was enacted?</li>
                                                        <li>2. what health information a foster parent should access.</li>
                                                        <li>3. what you should do if you become aware of a privacy breach.</li>
                                                    </ol>
                                                </p>
                                                <Link to="#"><span onClick={moveToNext}>read more...</span></Link>
                                            </div>
                                        </div>
                                        <div className="video-detail">
                                            <h3>HIPAA Compliance Training - 1 Credits</h3>
                                            <div className="read-more">
                                                <p>The following training material is broken down into 5 training modules. After you read each module you will be asked a series of questions in relation to the material. You must answer all questions correctly before moving to the next module. After answering all questions correctly in all modules, you will be sent a certificate to the e-mail you provide on the registration page.
                                                    After you have completed this training you should have a basic knowledge of;
                                                    <ol>
                                                        <li>1. Why HIPAA was enacted?</li>
                                                        <li>2. what health information a foster parent should access.</li>
                                                        <li>3. what you should do if you become aware of a privacy breach.</li>
                                                    </ol>
                                                </p>
                                                <Link to="#"><span onClick={moveToNext}>read more...</span></Link>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="video-page">
                                        <span>Training Courses</span>
                                         <span onClick={moveToNext}>Next</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    );
}




export default OnlineTrainingOne;
