import '../../Forms/CriticalUnusualIncidentForm/CriticalUnusualIncidentForm.scss'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { post } from '../../../utils/common';
import { useHistory } from 'react-router';
import download from '../../../images/assets/myinfo/download.svg';
import SideBar from '../../sidebar/sidebar';
import HeaderTopDetail from '../../CurrentChildren/HeaderTopDetail/HeaderTopDetail';
import Footer from '../../Footer';
import Header from '../../Header';

import React, { useRef } from "react";
import ReactToPrint from "react-to-print";

const ComponentToPrint = React.forwardRef((props, ref) =>

(

    // <table ref={ref}>

    <div className="main" ref={ref}>

        {/* <Header /> */}

        <div className="main-content" >
            {/* <HeaderTopDetail /> */}
            <div className="pagewithsidebar">
                <div className="container">
                    <div className="pagewithsidebar-inner">


                        {/* <FormSideBar /> */}
                        <div className="tab-detail Critical">
                            {/* <div className="tab-detail-top">
                        <div className="Critical-unusual">
                            <img src={Myinfoinner} alt="" />
                            Critical/Unusual Incidents
                        </div>
                    </div> */}
                            <div className="tab-detail-bottom">
                                <form>
                                    <div className="select-incident">
                                        <label>Select Incident:</label>
                                        <div className="form-row">
                                            <select>
                                                <option value="0">Select</option>

                                                <option value="0"></option>


                                            </select>
                                            <Link to="#" className="blue-btn">Select</Link>
                                            <Link to="#" className="red-btn">Delete</Link>
                                        </div>
                                    </div>
                                    <div className="form-row dandt">
                                        <div className="one-fourth">
                                            <label for="birthday">Date of Incident:</label>
                                            <input type="date" id="birthday" name="birthday" />
                                            <small>*Future date will not be allowed.</small>
                                        </div>
                                        <div className="one-fourth">
                                            <label for="appt">Time of Incident:</label>
                                            <input type="time" id="appt" name="appt" />
                                        </div>
                                        <div className="one-fourth">
                                            <label for="birthday">Date of Discovery:</label>
                                            <input type="date" id="birthday" name="birthday" />
                                            <small>*Future date will not be allowed.</small>
                                        </div>
                                        <div className="one-fourth">
                                            <label for="appt">Time of Discovery:</label>
                                            <input type="time" id="appt" name="appt" />
                                        </div>
                                    </div>

                                    <div className="g-background">
                                        <div className="form-row">
                                            <div className="one-half">
                                                <label for="appt">Child:</label>
                                                <select>
                                                    <option value="0">Select</option>

                                                </select>
                                            </div>
                                            <div className="one-half">
                                                <label for="appt">Person Reporting:</label>
                                                <select>
                                                    <option value="0">Select</option>

                                                </select>
                                            </div>
                                            <div className="one-half">
                                                <label for="appt">Extent of Child Injury:</label>
                                                <select>
                                                    <option value="0">Select</option>

                                                </select>
                                            </div>
                                            <div className="one-half">
                                                <label for="appt">Extent of Foster Parent Injury:</label>
                                                <select>
                                                    <option value="0">Select</option>

                                                </select>
                                            </div>

                                            <div className="note">
                                                <b>Help</b>
                                                <ol>
                                                    <li>None - No observable or predictable injury will arise from the incident.</li>
                                                    <li>Low - No pain or mild pain.</li>
                                                    <li>Moderate - Discomforting or distressing pain (treatment may include doctor visit).</li>
                                                    <li>Severe - Intense, severe, unbearable pain (treatment may include emergency room visit).</li>
                                                </ol>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="form-row v-pass">
                                        <p>Did incident take place while on home visit/pass?:</p>
                                        <span>
                                            <input type="radio" id="Yes" name="fav_language" value="Yes" />
                                            <label for="Yes">Yes</label>
                                        </span>
                                        <span>
                                            <input type="radio" id="No" name="fav_language" value="No" />
                                            <label for="No">No</label>
                                        </span>
                                    </div>

                                    <div className="incident-type">
                                        <h6>Type of Incident</h6>
                                        <ul>

                                            <li>
                                                <input type="checkbox" id="vehicle1" name="vehicle1" />
                                                <label for="vehicle1"> </label>
                                            </li>


                                            <li>
                                                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                <label for="vehicle1">  Mobile crises usage</label>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="add-cmt">
                                        <label>Describe Incident:</label>
                                        <textarea ></textarea>
                                        <label>Immediate Action Taken:</label>
                                        <textarea > </textarea>
                                        <label>All Adults Present for Child Incident:</label>
                                        <textarea ></textarea>
                                    </div>
                                    <div className="action-btn">
                                        <Link to="#" className="red-btn">Close</Link>
                                        <Link to="#" className="blue-btn">Submit</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <Footer /> */}
    </div>

    // </table>
));

const CriticalUnusualIncidentFormDownload = () => {
    const componentRef = useRef();
    return (
        <div >
            <ReactToPrint
                trigger={() =>
                    <button

                        class="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                    >
                        <img src={download}></img>
                    </button>
                }
                content={() => componentRef.current}
            />
            <div className="hiddenPrintable">
                <ComponentToPrint ref={componentRef} />
            </div>
        </div>
    );
}

export default CriticalUnusualIncidentFormDownload;
