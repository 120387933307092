import Footer from "../../Footer";
import Header from "../../../frontendPages/Header";
import ContentManagementSideBar from "../ContentManagementSideBar/ContentManagementSideBar";
import mcontentinner from "../../../images/assets/cm/mcontentinner.svg";
import ContentHeaderTopDetail from "../ContentHeaderTopDetail/ContentHeaderTopDetail";
import "../AdoptionContact/AdoptionContact.scss";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import editimage from "../../../images/assets/cm/edit.svg";
import left from "../../../images/assets/left.png";
import right from "../../../images/assets/right.png";
import next from "../../../images/assets/next.png";
import back from "../../../images/assets/back.png";
import trashimage from "../../../images/assets/cm/trash.svg";
import { useHistory } from "react-router";
import DataTable from "react-data-table-component";
import { post, put } from "../../../utils/common";
import axios from "axios";
import LoadSpinner from "../../Loader/LoadSpinner";

function ManageContentLinks() {
  const history = useHistory();
  const [data, setData] = useState([]);

  const [LinkCategory, setLinkCategoryData] = useState([]);

  const [LinkTitleByCategoryId, setLinkTitleByCategoryId] = useState([]);

  const [LinkDataSelected, setLinkDataSelected] = useState({
    IsPublic: "",
    IsSecure: "",
  });
  const [LinkDataSelectedId, setLinkDataSelectedId] = useState([]);
  const [CategoryId, setCategoryId] = useState([]);
  const [showAdd, setShowAdd] = useState(true);
  const [showEdit, setShowEdit] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showAddDivision = () => {
    setShowEdit(false);
    setShowAdd(true);
    setShowSort(false);
  };

  const showEditDivision = () => {
    setShowEdit(true);
    setShowAdd(false);
    setShowSort(false);
  };

  const showSortDivision = () => {
    setShowEdit(false);
    setShowAdd(false);
    setShowSort(true);
  };

  // history.push('managecontentlinks');

  const closeClicked = async () => {
    setIsLoading(true);
    console.log("data to delete", LinkDataSelectedId.Id);
    const primaryKey = LinkDataSelectedId.Id;
    try {
      await axios.delete(
        `/api/contentlink/delete/${encodeURIComponent(primaryKey)}`
      );
    } catch (err) {
      console.log(err);
    }

    // alert("Data deleted Successfully")
    setIsLoading(false);
    window.location.reload(false);
  };

  const onSubmitButtonClickStore = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    LinkDataSelected.LinkCategoryID = CategoryId;
    const data = new FormData();

    console.log(LinkDataSelected);

    if (!LinkDataSelected.FileName) {
      alert("Select File");
      setIsLoading(false);

      // window.location.reload(false);
    }
    data.append("file", LinkDataSelected.FileName);
    data.append("LinkDataSelected", JSON.stringify(LinkDataSelected));
    axios
      .post("/api/contentlink/store/", data, {
        // receive two    parameter endpoint url ,form data
      })
      .then((res) => {
        // then print response status
        alert("Record created successfully !");
        setIsLoading(false);
        window.location.reload(false);
      });
  };

  const onSubmitButtonClickUpdate = async (e) => {
    setIsLoading(true);
    const id = LinkDataSelectedId.Id || 2;
    LinkDataSelected.LinkCategoryID = CategoryId || 3;
    e.preventDefault();
    await put("/api/contentlink/update/" + id, { LinkDataSelected });
    alert("Record updated successfully !");
    setIsLoading(false);
    window.location.reload(false);
  };

  const fetchAllLinkCategoryData = async () => {
    setIsLoading(true);
    let data = await fetch("/api/contentlink/getalllinkcategory");
    let LinkCategoryData = await data.json();
    console.log("all link category", LinkCategoryData);
    setLinkCategoryData(LinkCategoryData.data);
    setIsLoading(false);
  };

  //get all provider by selected  provider tyoe
  const getAllLinkByCategoryId = async (e) => {
    setIsLoading(true);
    const id = e.target.value;
    setCategoryId(id);
    console.log(id);
    let allLinkTitlebyCategoryId = await post(
      "/api/contentlink/getallcontentlinkdatabycategoryid",
      { id }
    );
    console.log("all link by category Id", allLinkTitlebyCategoryId);

    setLinkTitleByCategoryId(allLinkTitlebyCategoryId.data.data);
    setIsLoading(false);
  };

  //get all provider by selected  provider tyoe
  const getAllLinkDataById = async (e) => {
    setIsLoading(true);
    const id = LinkDataSelectedId.Id;
    console.log(id);
    let allLinkDataBySelectedId = await post(
      "/api/contentlink/getallcontentlinkdatabyid",
      { id }
    );
    console.log("all data by selected Id", allLinkDataBySelectedId);
    setLinkDataSelected(allLinkDataBySelectedId.data.data[0]);
    console.log(LinkDataSelected);
    setIsLoading(false);
  };

  const deleteData = async () => {
    setIsLoading(true);
    console.log("data to delete", LinkDataSelectedId.Id);
    const primaryKey = LinkDataSelectedId.Id;
    try {
      await axios.delete(
        `/api/contentlink/delete/${encodeURIComponent(primaryKey)}`
      );
    } catch (err) {
      console.log(err);
    }

    alert("Data deleted Successfully");
    setIsLoading(false);
    window.location.reload(false);
  };

  const fetchData = async () => {
    setIsLoading(true);
    let data = await fetch("/api/contentlink/index");
    if (!data.ok) {
      return;
    }
    data = await data.json();
    console.log(data);
    setData(data.data);
    setIsLoading(false);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    alert("Updated");
    setIsLoading(false);
    window.location.reload(true);
  };

  const [shortDataPublic, setShortDataPublic] = useState([]);
  const [shortDataSecure, setShortDataSecure] = useState([]);

  const [selectedCatId, setSelectedCatId] = useState([]);
  const handleShortDataView = () => {
    setIsLoading(true);
    const catId = selectedCatId.CatID;
    let res = data.filter((dItem) => dItem.LinkCategoryID == catId);
    // if (selectedCatId.IsSecure) {
      res = data.filter((dItem) => dItem.IsSecure == true);
      setShortDataSecure(res);
    // }
    // if (selectedCatId.IsPublic) {
      let resPublic = res.filter((dItem) => dItem.IsPublic == true);
      setShortDataPublic(resPublic);
    // }
    // console.log(catId)
    // console.log(data)

    // console.log(res)
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    fetchAllLinkCategoryData();
  }, []);

  const columns = [
    {
      name: "Link Title",
      selector: row => row.LinkTitle,
      sortable: true,
    },
    {
      name: "LinkPath",
      selector: row => row.LinkPath,
      sortable: true,
    },
    {
      name: "LinkCategoryID",
      selector: row => row.LinkCategoryID,
      sortable: true,
    },
    {
      name: "Description",
      selector: row => row.Description,
      sortable: true,
    },
    {
      name: "IsSecure",
      selector: row => row.IsSecure,
      sortable: true,
    },

    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div class="inline-flex">
          <button>
            <Link to="/add``">
              <img src={editimage} alt="" />
            </Link>
          </button>
          &nbsp;&nbsp;
          <button>
            <Link to="#">
              <img src={trashimage} alt="" />
            </Link>
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="main">
      <Header />
      <div className="main-content">
        <ContentHeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <ContentManagementSideBar />
              <div className="tab-detail">
                <div className="tab-detail-top children-tabs">
                  <div className="children-section">
                    <img src={mcontentinner} alt="" />
                    <div className="pagesubtitle">Manage Content Links</div>
                  </div>
                  <div className="manage-content-action">
                    <span
                      className={`${showAdd ? "active" : ""}`}
                      onClick={showAddDivision}
                    >
                      Add
                    </span>
                    |{" "}
                    <span
                      className={`${showEdit ? "active" : ""}`}
                      onClick={showEditDivision}
                    >
                      Edit
                    </span>
                    |{" "}
                    <span
                      className={`${showSort ? "active" : ""}`}
                      onClick={showSortDivision}
                    >
                      Sort
                    </span>
                  </div>
                </div>
                {isLoading ? <LoadSpinner /> : ""}

                <div className="tab-detail-bottom children-data">
                  {showAdd ? (
                    <form className="children-doc-form manage-content-form">
                      <div className="form-grey mt-25">
                        <h6 className="small-text">Add Control:</h6>
                        <div className="form-row ai-fe">
                          <div className="one-fourth">
                            <label> Category</label>
                            <select onChange={getAllLinkByCategoryId}>
                              <option>Select</option>
                              {LinkCategory
                                ? LinkCategory.map((links) => (
                                    <option value={links.LinkCategoryID}>
                                      {links.Name}
                                    </option>
                                  ))
                                : "No Data"}
                            </select>
                          </div>
                          <div className="url-field">
                            <label>
                              Link Titles Currently in the Selected Category:
                            </label>
                            <select
                              onChange={(e) =>
                                setLinkDataSelectedId({
                                  ...LinkDataSelectedId,
                                  Id: e.target.value,
                                })
                              }
                            >
                              <option>Select</option>
                              {LinkTitleByCategoryId.map((linktitle) => (
                                <option value={linktitle.MainPageLinkID}>
                                  {linktitle.LinkTitle}
                                </option>
                              ))}
                            </select>
                          </div>
                          {/* <Link to="#" onClick={getAllLinkDataById} className="normal">Select</Link> */}
                        </div>
                      </div>

                      <div className="form-row gap-20">
                        <div className="full-input">
                          <label>Link Title:</label>
                          <input
                            value={LinkDataSelected.LinkTitle}
                            onChange={(e) =>
                              setLinkDataSelected({
                                ...LinkDataSelected,
                                LinkTitle: e.target.value,
                              })
                            }
                            type="text"
                          />
                        </div>
                        <div className="one-fourth">
                          <label>Select Picture:</label>
                          <input
                            onChange={(e) =>
                              setLinkDataSelected({
                                ...LinkDataSelected,
                                FileName: e.target.files[0],
                              })
                            }
                            type="file"
                          />
                        </div>
                      </div>

                      <div className="form-row gap-20">
                        <div className="one-half">
                          <label>Link Path:</label>
                          <input
                            value={LinkDataSelected.LinkPath}
                            onChange={(e) =>
                              setLinkDataSelected({
                                ...LinkDataSelected,
                                LinkPath: e.target.value,
                              })
                            }
                            type="text"
                          />
                        </div>
                        <div className="one-half">
                          <label>Description:</label>
                          <input
                            value={LinkDataSelected.Description}
                            onChange={(e) =>
                              setLinkDataSelected({
                                ...LinkDataSelected,
                                Description: e.target.value,
                              })
                            }
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="checkbox-design mb-20">
                        <span>
                          <input
                            type="checkbox"
                            value="false"
                            checked={
                              LinkDataSelected.IsPublic ? "checked" : false
                            }
                            onChange={(e) =>
                              setLinkDataSelected({
                                ...LinkDataSelected,
                                IsPublic: e.target.checked,
                              })
                            }
                            name="Public"
                          />
                          <label for="Public">Public</label>
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            value="false"
                            checked={
                              LinkDataSelected.IsSecure ? "checked" : false
                            }
                            onChange={(e) =>
                              setLinkDataSelected({
                                ...LinkDataSelected,
                                IsSecure: e.target.checked,
                              })
                            }
                            name="Secure"
                          />
                          <label for="Secure">Secure</label>
                        </span>
                      </div>

                      {LinkDataSelectedId.Id ? (
                        <div className="mc-form-action">
                          <Link to="#" className="close" onClick={deleteData}>
                            Delete
                          </Link>
                          {/* <Link to="#" className="green">Save</Link> */}
                          <Link
                            to="#"
                            onClick={onSubmitButtonClickUpdate}
                            className="normal"
                          >
                            Save
                          </Link>

                          <Link
                            to="#"
                            onClick={onSubmitButtonClickUpdate}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      ) : (
                        <div className="mc-form-action">
                          <Link to="#" className="close" onClick={deleteData}>
                            Delete
                          </Link>
                          {/* <Link to="#" className="green">Save</Link> */}
                          <Link
                            to="#"
                            onClick={onSubmitButtonClickStore}
                            className="normal"
                          >
                            Save
                          </Link>

                          <Link
                            to="#"
                            onClick={onSubmitButtonClickStore}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      )}
                    </form>
                  ) : (
                    ""
                  )}

                  {showEdit ? (
                    <form className="children-doc-form manage-content-form">
                      <div className="form-grey mt-25">
                        <h6 className="small-text">Edit Control:</h6>
                        <div className="form-row ai-fe">
                          <div className="one-fourth">
                            <label>Category</label>
                            <select onChange={getAllLinkByCategoryId}>
                              <option>Select</option>
                              {LinkCategory
                                ? LinkCategory.map((links) => (
                                    <option value={links.LinkCategoryID}>
                                      {links.Name}
                                    </option>
                                  ))
                                : "No Data"}
                            </select>
                          </div>
                          <div className="url-field">
                            <label>
                              Link Titles Currently in the Selected Category:
                            </label>
                            <select
                              onChange={(e) =>
                                setLinkDataSelectedId({
                                  ...LinkDataSelectedId,
                                  Id: e.target.value,
                                })
                              }
                            >
                              {LinkTitleByCategoryId.map((linktitle) => (
                                <option value={linktitle.MainPageLinkID}>
                                  {linktitle.LinkTitle}
                                </option>
                              ))}
                            </select>
                          </div>
                          <Link
                            to="#"
                            onClick={getAllLinkDataById}
                            className="normal"
                          >
                            Select
                          </Link>
                        </div>
                      </div>

                      <div className="form-row gap-20">
                        <div className="full-input">
                          <label>Link Title:</label>
                          <input
                            value={LinkDataSelected.LinkTitle}
                            onChange={(e) =>
                              setLinkDataSelected({
                                ...LinkDataSelected,
                                LinkTitle: e.target.value,
                              })
                            }
                            type="text"
                          />
                        </div>
                        <div className="one-fourth">
                          <label>Select Picture:</label>
                          <input type="file" />
                        </div>
                      </div>

                      <div className="form-row gap-20">
                        <div className="one-half">
                          <label>Link Path:</label>
                          <input
                            value={LinkDataSelected.LinkPath}
                            onChange={(e) =>
                              setLinkDataSelected({
                                ...LinkDataSelected,
                                LinkPath: e.target.value,
                              })
                            }
                            type="text"
                          />
                        </div>
                        <div className="one-half">
                          <label>Description:</label>
                          <input
                            value={LinkDataSelected.Description}
                            onChange={(e) =>
                              setLinkDataSelected({
                                ...LinkDataSelected,
                                Description: e.target.value,
                              })
                            }
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="checkbox-design mb-20">
                        <span>
                          <input
                            type="checkbox"
                            checked={LinkDataSelected.IsPublic ? "checked" : ""}
                            onChange={(e) =>
                              setLinkDataSelected({
                                ...LinkDataSelected,
                                IsPublic: e.target.checked,
                              })
                            }
                            name="Public"
                            value="Public"
                          />
                          <label for="Public">Public</label>
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            checked={LinkDataSelected.IsSecure ? "checked" : ""}
                            onChange={(e) =>
                              setLinkDataSelected({
                                ...LinkDataSelected,
                                IsSecure: e.target.checked,
                              })
                            }
                            name="Secure"
                          />
                          <label for="Secure">Secure</label>
                        </span>
                      </div>
                      <div className="mc-form-action">
                        <Link to="#" onClick={deleteData} className="close">
                          Delete
                        </Link>
                        {/* <Link to="#" className="green">Save</Link> */}
                        <Link
                          to="#"
                          onClick={onSubmitButtonClickUpdate}
                          className="normal"
                        >
                          Submit
                        </Link>
                        <Link
                          to="#"
                          onClick={onSubmitButtonClickUpdate}
                          className="normal"
                        >
                          Save
                        </Link>
                      </div>
                    </form>
                  ) : (
                    ""
                  )}
                  {showSort ? (
                    <div className="mc-sort">
                      <div className="form-grey mt-25">
                        <h6 className="small-text">Sorting Controls:</h6>
                        <div className="form-row ai-fe">
                          <div className="full-input">
                            <select
                              className="mb-0"
                              name="cars"
                              id="cars"
                              onChange={(e) =>
                                setSelectedCatId({
                                  ...selectedCatId,
                                  CatID: e.target.value,
                                })
                              }
                            >
                              <option>Select</option>
                              {LinkCategory
                                ? LinkCategory.map((links) => (
                                    <option value={links.LinkCategoryID}>
                                      {links.Name}
                                    </option>
                                  ))
                                : "No Data"}
                            </select>
                          </div>
                          <Link
                            to="#"
                            onClick={handleShortDataView}
                            className="normal"
                          >
                            Select
                          </Link>
                        </div>
                        <div className="mc-form-action mt-25">
                          <Link
                            to="#"
                            onClick={handleSubmit}
                            className="normal"
                          >
                            Submit
                          </Link>
                          <Link to="#" onClick={handleSubmit} className="green">
                            Resort
                          </Link>
                          <Link to="#" onClick={closeClicked} className="close">
                            Close
                          </Link>
                        </div>
                      </div>
                      <div className="dragdrop">
                        <div className="selected-item">
                          <span>
                            <input
                              type="checkbox"
                              id="vehicle1"
                              name="vehicle1"
                              value="Bike"
                              onChange={(e) =>
                                setSelectedCatId({
                                  ...selectedCatId,
                                  IsPublic: e.target.checked,
                                })
                              }
                            />
                            <label for="vehicle1"> Public Main Page</label>
                          </span>
                          <div>
                            {/* <span> */}
                            {shortDataPublic.map((sDPublic) => (
                              <>
                                {/* <br></br> */}
                                <input
                                  type="checkbox"
                                  id="vehicle1"
                                  name="vehicle1"
                                  value="Bike"
                                />
                                <label for="vehicle1">
                                  {" "}
                                  {sDPublic.LinkTitle}
                                </label>
                              </>
                            ))}
                            {/* </span> */}
                          </div>
                        </div>
                        <div className="action-item">
                          <span>
                            <img src={back} />
                          </span>
                          <span>
                            <img src={left} />
                          </span>
                          <span>
                            <img src={right} />
                          </span>
                          <span>
                            <img src={next} />
                          </span>
                        </div>
                        <div className="deselected-item">
                          <span>
                            <input
                              type="checkbox"
                              id="vehicle1"
                              name="vehicle1"
                              value="Bike"
                              onChange={(e) =>
                                setSelectedCatId({
                                  ...selectedCatId,
                                  IsSecure: e.target.checked,
                                })
                              }
                            />
                            <label for="vehicle1"> Secure Main Page</label>
                          </span>
                          <div>
                            {shortDataSecure.map((sDSecure) => (
                              <>
                                {/* <br></br> */}
                                <input
                                  type="checkbox"
                                  id="vehicle1"
                                  name="vehicle1"
                                  value="Bike"
                                />
                                <label for="vehicle1">
                                  {" "}
                                  {sDSecure.LinkTitle}
                                </label>
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* <DataTable columns={columns} data={data} pagination={true} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ManageContentLinks;
