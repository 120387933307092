import Footer from "../LandingPages/HeaderFooter/Footer/Footer";
import Header from "../LandingPages/HeaderFooter/Header/Header";

import landingcss from "../LandingPages/LandingMain/LandingMain.scss";
import headerscss from "../LandingPages/HeaderFooter/Header/Header.scss";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useState } from "react";
import LoadSpinner from "../Loader/LoadSpinner";
import designimage from "../../images/222design.png";
import { post } from "../../utils/common";
import axios from "axios";

function ForgotPassword() {
  const history = useHistory();
  const [email, setEmail] = useState();
  const [isLoading, setIsLoading] = useState(false);


  const handleForgotPassword = async() =>{
    setIsLoading(true)
     console.log(email)
   
       
        // let data = await post('/api/provider/forgot-password', { email });
        let data=  await axios.post("/api/provider/forgot-password", {email},{
      }).then(res => {
          console.log(res);
          window.alert("Password Change Request Send  , Please check Email")
          // window.location.reload(false);
      }).catch(error=>{
        // if(res.status == 500){
          window.alert("Forgot Password is currently available only for Providers. If you are a staff, please use the chat to submit a Query.")
        // }
      })

      

        // if(data.status == "500"){
        //   window.alert("Please Try Again , Having issue in sending mail")
        // }
        // if(data.data.data[0].FCHWebUserName){
        //     window.alert("Password Change Request Send  , Please check Email")

        // }
        // else{
        //     window.alert("Please Retry Again")
        // }

        console.log(data)
   


     setIsLoading(false)

  }

  return (
    <div className="flex h-screen overflow-hidden">
      <Header />
      <div className="hero-section">
        <div className="container">
          <div className="hero-text">
            <h2>SAINT FRANCIS MINISTRIES | SFMFAMILIES.ORG</h2>
            <p>
              SFM Families is an on-line community for our valued foster and
              kinship families. It's a place to find responsive support,
              up-to-date information, easily accessible tools and training, and
              professional resources. If you are not a foster parent and would
              like to explore this opportunity for service, please contact us.
              Your decision could bring the change a child needs to reach his or
              her full potential.
            </p>
            <div className="action-btn">
              <Link
                to={{
                  pathname: "https://saintfrancisministries.org/foster-care/",
                }}
                target="_blank"
                title="More Questions...?"
              >
                Learn more
              </Link>
              <Link
                to={{ pathname: "/form/fosterparentreferalform" }}
                title="Ready to foster"
              >
                I’m Ready to Start
              </Link>
              <Link
                to="/forms/public/powerofyou"
                title="Recommend/Refer A Family..."
              >
                Power of You
              </Link>
            </div>
          </div>

          {isLoading ? <LoadSpinner /> : ""}
          <div className="login-form">
            <form>
              <h3>My Account</h3>
              <div className="form-row fd-c">
                <div class="log-field">
                  <label class="form-label">Enter Email To Reset Password:</label>
                  <input
                    name="email"
                    type="text"
                    onChange={(e) => setEmail(e.target.value)}
                    class="form-control"
                  />
                </div>
               
               
                <div class="log-action">
                  {/* <a href="#" className="yellow-btn"> */}
                    {" "}
                    <Link to="#" onClick={handleForgotPassword} className="yellow-btn">
                      Submit{" "}
                    </Link>
                    <Link to="/index">
                      Back To Login{" "}
                    </Link>
                  {/* </a> */}

                  {/* <a href="#">forgot Password?</a> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="about-section">
        {/* <div className="container">
          <div className="about-section-img">
            <img src={aboutimg} alt=""></img>
          </div>
          <div className="about-section-text">
            <p>
              <h4 className="about-section-text-h4 mb-20"> MYTH VS REALITY</h4>
              <p ><b className="about-section-text-h5">MYTH</b> – I can’t foster because I have no parenting experience, or my children are grown and out of the house.
              </p>
              <p ><b className="about-section-text-h5">REALITY</b> =There is no age requirement other than being at least 21 years old. Foster parents are responsible
                people who have made a
                commitment to all children regardless of relation. Many “empty nesters” find fostering a rewarding experience.</p>


            </p>
          </div>
        </div> */}
        <div className="container img__cont">
          {/* <div className="about-section-img"> */}
          <img src={designimage} alt=""></img>

          {/* </div> */}
          <div className="button-class">
            <Link
              to={{
                pathname:
                  "https://www.canva.com/design/DAE3UnTySP8/NKUur8taU7SRa1Lpea0EdQ/watch?utm_content=DAE3UnTySP8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
              }}
              target="_blank"
            >
              <button className="xys">Click to learn more!</button>
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ForgotPassword;
