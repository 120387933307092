import Footer from "../Footer";
import Header from "../Header";

import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useEffect } from "react";
import LoadSpinner from "../Loader/LoadSpinner";
import { useState } from "react";
import { post } from "../../utils/common";

function SearchForPlacement() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setdata] = useState(false);

  const [dataForSearch, setDataForSearch] = useState([]);
  // const [raceData, setRaceData] = useState(false);

  const closeClicked = () => {
    setIsLoading(true);
    history.push("/secure/placementSearch");
    setIsLoading(false);
  };

  const [showResultDiv, setShowResultDiv] = useState(false);
  const fetchResultData = async () => {
    let data = await post("/api/searchforplacement/index", { dataForSearch });
    if (!data.ok) {
      return;
    }
    data = await data.json();
    console.log(data);
    setShowResultDiv(true);
    setRaceData(true);
    // setSecQuestion(data.data);

    setdata(data.data);
  };

  // const [DropDownData, setDropDownData] = useState([]);

  // const [dataToSearch,setDataToSearch] = useState([])

  const [RaceData, setRaceData] = useState([])
  const [AgeData, setAgeData] = useState([])
  const [LevelOfCareData, setLevelOfCareData] = useState([])
  const [GenderData, setGenderData] = useState([])
  const [CountryData, setCountryData] = useState([])
  const fetchData = async () => {
    let data = await post("/api/searchforplacement/searchforplacementdopdown");
  

    setRaceData(data.data.RaceData);
    setAgeData(data.data.AgeData)
    setLevelOfCareData(data.data.LevelOfCareData)
    setGenderData(data.data.GenderData)
    setCountryData(data.data.CountryData)


  };



  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="main">
      <Header />
      <div className="parent-main">
        <div className="container">
          <h2>SEARCH FOR PLACEMENT</h2>

          {/* <form className="myprofile-form">
          <div className="form-row">
            <div class="ot-field">
              <label class="form-label">Race</label>
              <input
                type="text"
                class="form-control"
                // onChange={e => setProviderData({ ...ProviderData, ProviderName: e.target.value })}
                id="exampleFormControlInput1"
              />
            </div>
            <div class="ot-field">
              <label class="form-label">Gender</label>

              <select>
                <option value="0">All</option>
                <option value="0">Femail</option>
                <option value="0">Male</option>
                <option value="0">Other</option>
              </select>
            </div>
            <div class="ot-field">
              <label class="form-label">Age</label>
              <select>
                <option value="0">All</option>
                <option value="0">Less than 1</option>
                <option value="0">Less than 2</option>
                <option value="0">Less than 3</option>
                <option value="0">Less than 12</option>
                <option value="0">Less than 16</option>
                <option value="0">Greater than 3</option>
                <option value="0">Greater than 5</option>
                <option value="0">Greater than 10</option>
                <option value="0">Greater than 16</option>
                <option value="0">Between 0 and 3</option>
                <option value="0">Between 5 and 12</option>
                <option value="0">Between 12 and 16</option>
                <option value="0">Enter a Range</option>
              </select>
            </div>
            <div class="ot-field">
              <label class="form-label">Yongest</label>
              <input
                type="email"
                // onChange={e => setProviderData({ ...ProviderData, Email: e.target.value })}
                class="form-control"
                id="exampleFormControlInput1"
                readOnly
              />
            </div>
            <div class="ot-field">
              <label class="form-label">Oldest</label>
              <input
                type="email"
                // onChange={e => setProviderData({ ...ProviderData, Email: e.target.value })}
                class="form-control"
                id="exampleFormControlInput1"
                readOnly
              />
            </div>
          </div>
          <div className="form-row">
            <div class="ot-field">
              <label class="form-label">Country</label>
              <input type="text" lang="en-US" />
            </div>
            <div class="ot-field">
              <label class="form-label">Level Of Care</label>
              <select>
                <option value="0">All</option>
                <option value="0">Basic 1</option>
                <option value="0">Basic 2</option>
                <option value="0">Intensive 1</option>
                <option value="0">Intensive 2</option>
                <option value="0">Treatment</option>
                <option value="0">Transition</option>
                <option value="0">Basic</option>
                <option value="0">Moderate</option>
                <option value="0">Intensive Plus</option>
                <option value="0">Tier 1</option>
                <option value="0">Tier 2</option>
                <option value="0">Tier 3</option>
                <option value="0">Tier 4</option>
                <option value="0">Tier 5</option>
              </select>
            </div>
          </div>
          <div className="form-row">
            <div class="ot-field">
              <label class="form-label">Result Per Page</label>
              <select>
                <option value="0">5</option>
                <option value="0">10</option>
                <option value="0">15</option>
                <option value="0">20</option>
                <option value="0">25</option>
                <option value="0">50</option>
                <option value="0">100</option>
              </select>
            </div>
            <div className="action-btn">
              <Link to="#" onClick={closeClicked} className="green-btn">
                Search
              </Link>
            </div>
          </div>
        </form> */}

          <form className="child-case-form">
            <div className="form-col">
              {isLoading ? <LoadSpinner /> : ""}

              <div class="ot-field">
                <label class="form-label">Race</label>
                <select  onChange={e => setDataForSearch({ ...dataForSearch, intRaceID: e.target.value })}>
                <option>Select</option>

                  {RaceData.map((race) => (
                    <option value={race.raceid}>{race.race}</option>
                  ))}
                </select>

              </div>
              <div class="ot-field">
                <label class="form-label">Gender</label>

                <select onChange={e => setDataForSearch({ ...dataForSearch, intGenderID: e.target.value })}>
                <option>Select</option>
                {GenderData.map((gender) => (
                    <option value={gender.genderid}>{gender.code}</option>
                  ))}

                  {/* <option value="0">All</option>
                  <option value="0">Female</option>
                  
                  <option value="0">Male</option>
                  <option value="0">Other</option> */}
                </select>
              </div>
            </div>
            <div className="form-col">
              <div class="ot-field">
                <label class="form-label">Age</label>
                <select onChange={e => setDataForSearch({ ...dataForSearch, strAgeRange: e.target.value })}>
                  <option>Select</option>
                {AgeData.map((age) => (
                    <option value={age.agegroupid}>{age.agegroup}</option>
                  ))}

                  {/* <option value="0">All</option>
                  <option value="0">Less than 1</option>
                  <option value="0">Less than 2</option>
                  <option value="0">Less than 3</option>
                  <option value="0">Less than 12</option>
                  <option value="0">Less than 16</option>
                  <option value="0">Greater than 3</option>
                  <option value="0">Greater than 5</option>
                  <option value="0">Greater than 10</option>
                  <option value="0">Greater than 16</option>
                  <option value="0">Between 0 and 3</option>
                  <option value="0">Between 5 and 12</option>
                  <option value="0">Between 12 and 16</option>
                  <option value="0">Enter a Range</option> */}
                </select>
              </div>
              <div class="ot-field">
                <label class="form-label">Yongest</label>
                <input
                  type="email"
                  disabled
                  // onChange={e => setProviderData({ ...ProviderData, Email: e.target.value })}
                  class="form-control"
                  id="exampleFormControlInput1"
                />
              </div>
            </div>
            <div className="form-col">
              <div class="ot-field">
                <label class="form-label">Oldest</label>
                <input
                  type="email"
                  disabled
                  // onChange={e => setProviderData({ ...ProviderData, Email: e.target.value })}
                  class="form-control"
                  id="exampleFormControlInput1"
                />
              </div>
              <div class="ot-field">
                <label class="form-label">Country</label>
                <select onChange={e => setDataForSearch({ ...dataForSearch, intCountyID: e.target.value })}>

                <option>Select</option>

                {CountryData.map((country) => (
                    <option value={country.countyid}>{country.countyname}</option>
                  ))}
                </select>
                {/* <div><span> <label class="form-label">Country</label><input type="checkbox" /></span></div> */}
              </div>
              <span>
                <input type="checkbox" onclick="return false"></input>
              </span>
              <span>Include Contiguous Counties</span>
            </div>
            <div className="form-col">
              <div class="ot-field">
                <label class="form-label">Level Of Care</label>
                <select onChange={e => setDataForSearch({ ...dataForSearch, intLevelCareID: e.target.value })}>
                <option>Select</option>
                {LevelOfCareData.map((levelofcare) => (
                    <option value={levelofcare.levelofserviceid}>{levelofcare.levelofservice}</option>
                  ))}
                  {/* <option value="0">All</option>
                  <option value="0">Basic 1</option>
                  <option value="0">Basic 2</option>
                  <option value="0">Intensive 1</option>
                  <option value="0">Intensive 2</option>
                  <option value="0">Treatment</option>
                  <option value="0">Transition</option>
                  <option value="0">Basic</option>
                  <option value="0">Moderate</option>
                  <option value="0">Intensive Plus</option>
                  <option value="0">Tier 1</option>
                  <option value="0">Tier 2</option>
                  <option value="0">Tier 3</option>
                  <option value="0">Tier 4</option>
                  <option value="0">Tier 5</option> */}
                </select>
              </div>
              <span>
                <input type="checkbox"></input>
              </span>
              <span>Adoption Kids Only</span>

              <div class="ot-field">
                <label class="form-label">Result Per Page</label>
                <select>
                  <option value="0">5</option>
                  <option value="0">10</option>
                  <option value="0">15</option>
                  <option value="0">20</option>
                  <option value="0">25</option>
                  <option value="0">50</option>
                  <option value="0">100</option>
                </select>
              </div>
              <div className="action-btn">
                <Link to="#" onClick={fetchResultData} className="green-btn">
                  Search
                </Link>
                {/* <Link to="#" onClick={closeClicked} className="close-btn">
                Close
              </Link> */}
              </div>
            </div>
          </form>

          {showResultDiv ? (
            <h4>Result : {data.length > 0 ? data : "No result Found "}</h4>
          ) : (
            ""
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
}
export default SearchForPlacement;
