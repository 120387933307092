import { isEmpty } from "class-validator";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { post } from "../../../utils/common";
import { Link } from "react-router-dom";

function HeaderTopDetail(props) {
  const [LocationData, setLocationData] = useState([]);
  const [ProviderData, setProviderData] = useState([]);
  const [ProviderResourceWorkerData, setProviderResourceWorkerData] = useState([]);
  const [ProviderLocationData, setProviderLocationData] =useState([]);
  const [AddressData, setAddressData] = useState([]);


  let providerId = sessionStorage.getItem("providerSessionId");

  const getSessionTopHeaderData = async () => {
    let LocationD = await sessionStorage.getItem("LocationData");
    let City = await sessionStorage.getItem("City");
    let ResourceWorkerD = await sessionStorage.getItem(
      "ProviderResourceWorkerData"
    );
    //let ProviderLocation = await sessionStorage.getItem("ProviderLocationData");
    let ProviderD = await sessionStorage.getItem("ProviderData");
    let AddressD = await sessionStorage.getItem("AddressData");
    if (ResourceWorkerD !== "undefined") {
      setProviderResourceWorkerData(JSON.parse(ResourceWorkerD));
    }

    if (AddressD !== "undefined") {
      setAddressData(JSON.parse(AddressD));
    }
    if (ProviderD !== "undefined") {
      setProviderData(JSON.parse(ProviderD));
    }
    if (LocationD !== "undefined") {
      setLocationData(JSON.parse(LocationD));
    }
      if (City !== "undefined") {
    setProviderLocationData(JSON.parse(City));
      }

    sessionStorage.setItem("ProviderName", JSON.parse(ProviderD).ProviderName);
  };



  useEffect(() => {
    getSessionTopHeaderData();
  }, []);

  return (
    <div className="headertopdetail">
      <div className="top-link">
        <div className="container">
          {providerId ? (
            <Link
              className={
                window.location.pathname === "/myprofile" ||
                window.location.pathname === "/myhousehold" ||
                window.location.pathname === "/accountinformation" ||
                window.location.pathname === "/accountmanage" ||
                window.location.pathname === "/attachdocuments" ||
                window.location.pathname === "/criticalunusualincident" ||
                window.location.pathname === "/immidiateassesment" ||
                window.location.pathname === "/leastdesiredcondition" ||
                window.location.pathname === "/levelofcare" ||
                window.location.pathname === "/lodes" ||
                window.location.pathname === "/licenseexception" ||
                window.location.pathname === "/reimbursmenthistory" ||
                window.location.pathname === "/placementagreement" ||
                window.location.pathname === "/preference" ||
                window.location.pathname === "/school" ||
                window.location.pathname === "/activitylog" ||
                window.location.pathname === "/training" ||
                window.location.pathname.split("/")[0] === "myinformation"
                  ? "active"
                  : ""
              }
              to="/myprofile"
            >
              My Information |
            </Link>
          ) : (
            ""
          )}
          <Link
            to="/currentchildren/children"
            className={
              window.location.pathname.split("/")[1] === "currentchildren"
                ? "active"
                : ""
            }
          >
            Current Children
          </Link>{" "}
          |
          <Link
            to="/previouschildren/index"
            className={
              window.location.pathname.split("/")[1] === "previouschildren"
                ? "active"
                : ""
            }
          >
            Previous Children
          </Link>{" "}
          |
          <Link
            to="/form/formindex"
            className={
              window.location.pathname.split("/")[1] === "form" ||
              window.location.pathname.split("/")[1] === "forms"
                ? "active"
                : ""
            }
          >
            {" "}
            Forms
          </Link>{" "}
          |
          <Link
            to="/onlinetraining/one"
            className={
              window.location.pathname.split("/")[1] === "onlinetraining"
                ? "active"
                : ""
            }
          >
            Online Trainings
          </Link>
          |
          {/* <Link to="#">Search for placement</Link> | */}
          <Link to="/secure/HomeHelp" className={window.location.pathname.split('/')[1] === 'secure/HomeHelp' ? "active" : ''}>Help Guide</Link>
        </div>
      </div>
      <div className="brenda-detail">
        <div className="container">
          <h2 className="small-headings">
            {ProviderData ? ProviderData.ProviderName : "NULL"}
          </h2>
          <ul>
            <li>
              <p>
                <b>Current Address:</b>
                {LocationData ? LocationData.Address1 : "NULL"}
              </p>
            </li>
            <li>
              <p>
                <b>City:</b>
                {ProviderLocationData ? ProviderLocationData.City : "test"}
              </p>
            </li>
            <li>
              <p>
                <b>Zip code:</b>
                {AddressData ? AddressData.Zipcode : "NULL"}
              </p>
            </li>
            <li>
              <p>
                <b>Resource Worker:</b>
                {ProviderResourceWorkerData
                  ? ProviderResourceWorkerData.ResourceWorker
                  : "NULL"}
              </p>
            </li>
            <li>
              <p>
                <b>Office Phone Number:</b>
                {AddressData ? AddressData.Office_Phone_Number : "NULL"}
              </p>
            </li>
            <li>
              <p>
                <b>Office Address:</b>
                {AddressData ? AddressData.Office_Address : ""}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default HeaderTopDetail;
