import Footer from "../LandingPages/HeaderFooter/Footer/Footer";
import Header from "../LandingPages/HeaderFooter/Header/Header";

import landingcss from "../LandingPages/LandingMain/LandingMain.scss";
import headerscss from "../LandingPages/HeaderFooter/Header/Header.scss";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadSpinner from "../Loader/LoadSpinner";
import designimage from "../../images/222design.png";
import { post } from "../../utils/common";
import querystring from "querystring";
import { useLocation } from "react-router-dom";

function ForgotPasswordMainPage(props) {
  const { token } = useParams();

  const history = useHistory();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [tokenFromParam, setTokenFromParam] = useState(token);

  console.log("tokem", tokenFromParam);

  const handleForgotPasswordChange = async () => {
    setIsLoading(true);
    if (password == "" || confirmPassword == "") {
      window.alert("password Should Not be Empty");
      setIsLoading(false);
      return false;
    }
    if (password !== confirmPassword) {
      window.alert("password Should Be Same");
      setIsLoading(false);
      return false;
    }
    let data = await post("/api/provider/reset-password", { password, token });
    window.alert("Password Reset SuccessFully,Please Login");
    window.location.href = "/index";
    setIsLoading(false);
  };

  useEffect(async () => {
    setIsLoading(true);
    let data = await post("/api/provider/token-expire", { token });
    console.log("data", data);
    if (data.data.data.length == 0) {
      window.alert(
        "Your Password Reset Link is Expired !! Please Login or Resend The Link"
      );
      window.location.href = "/index";
    }
    setIsLoading(false);
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      <Header />
      <div className="hero-section">
        <div className="container">
          <div className="hero-text">
            <h2>SAINT FRANCIS MINISTRIES | SFMFAMILIES.ORG</h2>
            <p>
              SFM Families is an on-line community for our valued foster and
              kinship families. It's a place to find responsive support,
              up-to-date information, easily accessible tools and training, and
              professional resources. If you are not a foster parent and would
              like to explore this opportunity for service, please contact us.
              Your decision could bring the change a child needs to reach his or
              her full potential.
            </p>
            <div className="action-btn">
              <Link
                to={{
                  pathname: "https://saintfrancisministries.org/foster-care/",
                }}
                target="_blank"
                title="More Questions...?"
              >
                Learn more
              </Link>
              <Link
                to={{ pathname: "/form/fosterparentreferalform" }}
                title="Ready to foster"
              >
                I’m Ready to Start
              </Link>
              <Link
                to="/forms/public/powerofyou"
                title="Recommend/Refer A Family..."
              >
                Power of You
              </Link>
            </div>
          </div>

          {isLoading ? <LoadSpinner /> : ""}
          <div className="login-form">
            <form>
              <h3>My Account</h3>
              <div className="form-row fd-c">
                <div className="log-field">
                  <label className="form-label">Password</label>
                  <input
                    name="password"
                    type="text"
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control"
                  />
                </div>

                <div className="log-field">
                  <label className="form-label">Confirm Password</label>
                  <input
                    name="email"
                    type="text"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="form-control"
                  />
                </div>

                <div className="log-action">
                  {/* <a href="#" className="yellow-btn"> */}{" "}
                  <Link
                    to="#"
                    onClick={handleForgotPasswordChange}
                    className="yellow-btn"
                  >
                    Submit{" "}
                  </Link>
                  <Link to="/index">Back To Login </Link>
                  {/* </a> */}
                  {/* <a href="#">forgot Password?</a> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="about-section">
        {/* <div className="container">
          <div className="about-section-img">
            <img src={aboutimg} alt=""></img>
          </div>
          <div className="about-section-text">
            <p>
              <h4 className="about-section-text-h4 mb-20"> MYTH VS REALITY</h4>
              <p ><b className="about-section-text-h5">MYTH</b> – I can’t foster because I have no parenting experience, or my children are grown and out of the house.
              </p>
              <p ><b className="about-section-text-h5">REALITY</b> =There is no age requirement other than being at least 21 years old. Foster parents are responsible
                people who have made a
                commitment to all children regardless of relation. Many “empty nesters” find fostering a rewarding experience.</p>


            </p>
          </div>
        </div> */}
        <div className="container img__cont">
          {/* <div className="about-section-img"> */}
          <img src={designimage} alt=""></img>

          {/* </div> */}
          <div className="button-class">
            <Link
              to={{
                pathname:
                  "https://www.canva.com/design/DAE3UnTySP8/NKUur8taU7SRa1Lpea0EdQ/watch?utm_content=DAE3UnTySP8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
              }}
              target="_blank"
            >
              <button className="xys">Click to learn more!</button>
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ForgotPasswordMainPage;
