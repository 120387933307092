import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from '../componets/auth';
import Index from '../frontendPages';
import Staff from '../frontendPages/staff';
import FosterFamilyInquiry from '../frontendPages/fosterFamilyInquiry/fosterFamilyInquiry';
import Myprofile from '../frontendPages/myprofile/myprofile';
import AccountInformation from '../frontendPages/accountinformation/accountinformation';
import AccountManage from '../frontendPages/accountmanage/accountmanage';
import Myhousehold from '../frontendPages/myhousehold/myhousehold';
import LandingMain from '../frontendPages/LandingPages/LandingMain/LandingMain';
import WhoWeAre from '../frontendPages/LandingPages/WhoWeAre/WhoWeAre';
import NewsRoom from '../frontendPages/LandingPages/NewsRoom/NewsRoom';
import WhereWeServe from '../frontendPages/LandingPages/WhereWeServe/WhereWeServe';
import WhatWeDo from '../frontendPages/LandingPages/WhatWeDo/WhatWeDo';
import ActivityLog from '../frontendPages/MyInformation/ActivityLog/ActivityLog';
import AttachDocuments from '../frontendPages/MyInformation/AttachDocuments/AttachDocuments';
import Address from '../frontendPages/MyInformation/Address/Address';
import ImmidiateAssesment from '../frontendPages/MyInformation/ImmidiateAssesment/ImmidiateAssesment';
import LeastDesiredCondition from '../frontendPages/MyInformation/LeastDesiredCondition/LeastDesiredCondition';
import LevelOfCare from '../frontendPages/MyInformation/LevelOfCare/LevelOfCare';
import LicenseException from '../frontendPages/MyInformation/LicenseException/LicenseException';
import Lodes from '../frontendPages/MyInformation/Lodes/Lodes';
import PlacementAgreement from '../frontendPages/MyInformation/PlacementAgreement/PlacementAgreement';
import Preference from '../frontendPages/MyInformation/Preference/Preference';
import ReimbusrsmentHistory from '../frontendPages/MyInformation/ReimbursmentHistory/ReimbusrsmentHistory';
import School from '../frontendPages/MyInformation/School/School';
import SFCSWorker from '../frontendPages/MyInformation/SFCSWorker/Sfcsworker';
import SFCSOffice from '../frontendPages/MyInformation/SFCSOffice/Sfcsoffice';

import Training from '../frontendPages/MyInformation/Training/Training';
import CriticalUnusualincident from '../frontendPages/MyInformation/CriticalUnusualIncident/CriticalUnusualincident';
import FormIndex from '../frontendPages/Forms/FormIndex/FormIndex';
import FosterParentReferalForm from '../frontendPages/Forms/FosterParentReferalForm/FosterParentReferalForm';
import ImmidiateAssesmentForm from '../frontendPages/Forms/ImmidiateAssesmentForm/ImmidiateAssesmentForm';
import LogOfDailyEvents from '../frontendPages/Forms/LogOfDailyEvents/LogOfDailyEvents';
import PowerOfYou from '../frontendPages/Forms/PowerOfYou/PowerOfYou';
import MilageReimbursment from '../frontendPages/Forms/MilageReimbursment/MilageReimbursment';
import CriticalUnusualIncidentForm from '../frontendPages/Forms/CriticalUnusualIncidentForm/CriticalUnusualIncidentForm';
import CurrentChildrenActivityLog from '../frontendPages/CurrentChildren/ActivityLog/ActivityLog';
import CurrentChildrenAttachDocuments from '../frontendPages/CurrentChildren/AttachedDocuments/AttachedDocuments';
import CaseTeam from '../frontendPages/CurrentChildren/CaseTeam/CaseTeam';
import ChildProfile from '../frontendPages/CurrentChildren/ChildProfile/ChildProfile';
import Children from '../frontendPages/CurrentChildren/Children/Children';
import ChildStrength from '../frontendPages/CurrentChildren/ChildStrength/ChildStrength';
import CourtDates from '../frontendPages/CurrentChildren/CourtDates/CourtDates';
import DayCare from '../frontendPages/CurrentChildren/DayCare/DayCare';
import FlowChart from '../frontendPages/CurrentChildren/FlowChart/FlowChart';
import CurrentChildrenImmidiateAssesment from '../frontendPages/CurrentChildren/ImmidiateAssesmentCurrentChildren/ImmidiateAssesmentCurrentChildren';
import Medication from '../frontendPages/CurrentChildren/Medication/Medication';
import PlacementHistory from '../frontendPages/CurrentChildren/PlacementHistory/PlacementHistory';
import PlacementReferal from '../frontendPages/CurrentChildren/PlacementReferal/PlacementReferal';
import PreventiveMeasure from '../frontendPages/CurrentChildren/PreventativeMeasure/PreventiveMeasure';
import RespiteUsed from '../frontendPages/CurrentChildren/RespiteUsed/RespiteUsed';
import PreviousChildrenIndex from '../frontendPages/PreviousChildren/PreviousChildrenIndex/PreviousChildrenIndex';
import PreviousChildrenDetail from '../frontendPages/PreviousChildren/PreviousChildrenDetail/PreviousChildrenDetail';
import PreviousChildrenRequestUpdate from '../frontendPages/PreviousChildren/PreviousChildrenRequestUpdate/PreviousChildrenRequestUpdate';
import OnlineTrainingFive from '../frontendPages/OnlineTraining/OnlineTrainingFive/OnlineTrainingFive';
import OnlineTrainingOne from '../frontendPages/OnlineTraining/OnlineTrainingOne/OnlineTrainingOne';
import OnlineTrainingFour from '../frontendPages/OnlineTraining/OnlineTrainingFour/OnlineTrainingFour';
import OnlineTrainingThree from '../frontendPages/OnlineTraining/OnlineTrainingThree/OnlineTrainingThree';
import OnlineTrainingTwo from '../frontendPages/OnlineTraining/OnlineTrainingTwo/OnlineTrainingTwo';
import MyProfileUpdate from '../frontendPages/myprofile/MyProfileUpdate';
import SearchForPlacement from '../frontendPages/SearchForPlacement/SearchForPlacement';
import LevelOfCareRequestUpdate from '../frontendPages/MyInformation/LevelOfCare/LevelOfCareRequestUpdate';
import AdoptionContact from '../frontendPages/ContentManagement/AdoptionContact/AdoptionContact';
import CalenderOfEvents from '../frontendPages/ContentManagement/CalenderOfEvents/CalenderOfEvents';
// import ManageAgencyForm from '../frontendPages/ContentManagement/ManageAgencyForm/ManageAgencyForm';
import ManageAnnouncement from '../frontendPages/ContentManagement/ManageAnnouncement/ManageAnnouncement';
import AnnouncementList from '../frontendPages/ContentManagement/AnnouncementList/AnnoucementList';
import ManageContentLinks from '../frontendPages/ContentManagement/ManageContentLinks/ManageContentLinks';
import ManageCorousel from '../frontendPages/ContentManagement/ManageCorousel/ManageCorousel';
import ManageNewsLetter from '../frontendPages/ContentManagement/ManageNewsLetter/ManageNewsLetter';
import ManageParentDocuments from '../frontendPages/ContentManagement/ManageParentDocuments/ManageParentDocuments';
import ManageTraining from '../frontendPages/ContentManagement/ManageTraining/ManageTraining';
import SFMCareGiverRecognition from '../frontendPages/ContentManagement/SFMCareGiverRecognition/SFMCareGiverRecognition';
import InformationUpdate from '../frontendPages/ContentManagement/InformationUpdate/InformationUpdate';
import ProviderManagementCenter from '../frontendPages/ManagementCenter/ProviderManagementCenter/ProviderManagementCenter';
import CreateProviderManagementCenter from '../frontendPages/ManagementCenter/CreateProviderManagementCenter/CreateProviderManagementCenter';
import EditProviderManagementCenter from '../frontendPages/ManagementCenter/EditProviderManagementCenter/EditProviderManagementCenter';
import ResetPasswordProviderManagementCenter from '../frontendPages/ManagementCenter/ResetPasswordProviderManagementCenter/ResetPasswordProviderManagementCenter';
import FosterHomeRegulation from '../frontendPages/ParentDocuments/FosterHomeRegulation/FosterHomeRegulation';
import ParentHandBook from '../frontendPages/ParentDocuments/ParentHandBook/ParentHandBook';
import PaymentSchedule from '../frontendPages/ParentDocuments/PaymentSchedule/PaymentSchedule';
import AddAdoptionContact from '../frontendPages/ContentManagement/AdoptionContact/AddAdoptionContact';
import CriticalUnusualIncidentFormDownload from '../frontendPages/downloadableForms/CriticalUnusualIncidentDownload/CriticalUnusualIncidentFormDownload';
import CriticalUnusualIncidentFormPrint from '../frontendPages/downloadableForms/CriticalUnusualIncidentDownload/CriticalUnusualIncidentPrint';
import ImmidiateAssesmentFormDownload from '../frontendPages/downloadableForms/ImmidiateAssesmentDownload/ImmidiateAssesmentFormDownload';
import ImmidiateAssesmentFormPrint from '../frontendPages/downloadableForms/ImmidiateAssesmentDownload/ImmidiateAssesmentPrint';
import LogOfDailyEventsDownload from '../frontendPages/downloadableForms/LogOfDailyEventsDownload/LogOfDailyEventsDownload';
import LogOfDailyEventsPrint from '../frontendPages/downloadableForms/LogOfDailyEventsDownload/LogOfDailyEventsPrint';
import ManageDownLodableForm from '../frontendPages/Forms/ManageDownlodableForm/ManageDownLodableForm';
import PowerOfYouPublic from '../frontendPages/Forms/PowerOfYouPublic/PowerOfYouPublic';
import Events from '../frontendPages/Events/Events';
import ForgotPassword from '../frontendPages/ForgotPassword/ForgotPassword';
import ForgotPasswordMainPage from '../frontendPages/ForgotPassword/ForgotPasswordMainPage';
import DetailView from '../frontendPages/ContentManagement/AnnouncementList/DetailView';
import EventDetailView from '../frontendPages/Events/EventDetailView';
import MilageRemburseMentPrintFormView from '../frontendPages/downloadableForms/MilageRembursementForm/MileageRembursementFormView';
import ReimbursementFormHistoryView from '../frontendPages/downloadableForms/ReimbursementHistory/ReimbursementHistory';
import LogOfDailyEventsEdit from '../frontendPages/Forms/LogOfDailyEvents/LogOfDailyEventsEdit';
import ImmidiateAssesmentEdit from '../frontendPages/Forms/ImmidiateAssesmentForm/ImmidiateAssesmentEdit';
import MyWorker from '../frontendPages/MyWorker/MyWorker';
import HelpGuid from '../frontendPages/HelpGuid/HelpGuid';
import CalenderOfEventsEdit from '../frontendPages/ContentManagement/CalenderOfEvents/CalendarOfEventEdit';
import PlacementAgreementElectronicSign from '../frontendPages/MyInformation/PlacementAgreement/PlacementAgreementElectronicSign';
import PlacementAgreementPdfDesign from '../frontendPages/MyInformation/PlacementAgreement/PlacementAgreementPdfDesign';
import ManageCaraouselDocument from '../frontendPages/ContentManagement/ManageCorousel/ManageCaraouselDocument';
import PlacementagreementFchStaffAck from '../frontendPages/MyInformation/PlacementAgreement/PlacementagreementFchStaffAck';
import EnforcePasswordChange from '../frontendPages/ForgotPassword/EnForcePasswordChange';
import PlacementAgreementView from '../frontendPages/MyInformation/PlacementAgreement/PlacementAgreementView';
import PlacementAgreementPrintDownload from '../frontendPages/MyInformation/PlacementAgreement/PlacementAgreementPrintDownload';
import PlacementAgreementPrint from '../frontendPages/MyInformation/PlacementAgreement/PlacementAgreementPrint';






function saveToken(userToken) {
  sessionStorage.setItem('token', JSON.stringify(userToken));
}

function getToken() {
  return sessionStorage.getItem('token');
}

function Router() {
  const [token, setToken] = useState(getToken());

  const setTokenHandler = _token => {
    saveToken(_token);
    setToken(_token);
  };

  console.log("I am running everytime")

  return (
    <>
      <Switch>

        {/* Downloadable form */}
        <Route exact path="/form/ImmidiateAssesmentEdit">
          <ImmidiateAssesmentEdit/>
        </Route>
        <Route exact path="/form/logofdailyevents-edit">
          <LogOfDailyEventsEdit />
        </Route>
        <Route exact path="/print/rehistory">
          <ReimbursementFormHistoryView />
        </Route>
        <Route exact path="/secure/detailview-event">
          <EventDetailView />
        </Route>
        <Route exact path="/contentmanage/detailview-announcement">
          <DetailView />
        </Route>
        <Route exact path="/forgot">
          <ForgotPassword />
        </Route>
        <Route exact path="/forgot">
          <ForgotPassword />
        </Route>
        <Route exact path="/reset-password/:token">
          <ForgotPasswordMainPage />
        </Route>
        <PrivateRoute exact path="/forms/downlodableformmanage">
          <ManageDownLodableForm />
        </PrivateRoute>
        {/* criticalunusualincident */}
        <PrivateRoute exact path="/download/criticalunusualform">
          <CriticalUnusualIncidentFormDownload />
        </PrivateRoute>
        <Route exact path="/print/criticalunusualform">
          <CriticalUnusualIncidentFormPrint />
        </Route>

        <Route exact path="/print/mileageformview">
          <MilageRemburseMentPrintFormView />
        </Route>
        

        <Route exact path="/print/immidiateassesmentform">
          <ImmidiateAssesmentFormPrint />
        </Route>

        <Route exact path="/print/lodesform">
          <LogOfDailyEventsPrint />
        </Route>

        <Route exact path="/print/placementagreementform">
          <PlacementAgreementView/>
        </Route>

        <Route exact path="/print/placementagreementDownload">
          <PlacementAgreementPrintDownload/>
        </Route>

        <Route exact path="/print/placementagreementPrint">
          <PlacementAgreementPrint/>
        </Route>

        {/* immidiateassesment */}
        <PrivateRoute exact path="/download/immidiateassesment">
          <ImmidiateAssesmentFormDownload />
        </PrivateRoute>
        <Route exact path="/print/immidiateassesment">
          <ImmidiateAssesmentFormPrint />
        </Route>

        {/* LogOfDailyEventsFormPrint */}

        <PrivateRoute exact path="/download/logofdailyevents">
          <LogOfDailyEventsDownload />
        </PrivateRoute>
        <PrivateRoute exact path="/print/logofdailyevents">
          <LogOfDailyEventsPrint />
        </PrivateRoute>

        {/* parent documents */}
        <PrivateRoute exact path="/parentdocument/fosterhomeregulation">
          <FosterHomeRegulation />
        </PrivateRoute>
        <PrivateRoute exact path="/parentdocument/parenthandbook">
          <ParentHandBook />
        </PrivateRoute>
        <PrivateRoute exact path="/parentdocument/paymentschedule">
          <PaymentSchedule />
        </PrivateRoute>

        {/* provider management center */}
        <PrivateRoute exact path="/managementcenter/provider">
          <ProviderManagementCenter />
        </PrivateRoute>
        <PrivateRoute exact path="/managementcenter/createprovider">
          <CreateProviderManagementCenter />
        </PrivateRoute>
        <PrivateRoute exact path="/managementcenter/edit">
          <EditProviderManagementCenter />
        </PrivateRoute>
        <PrivateRoute exact path="/managementcenter/resetpassword">
          <ResetPasswordProviderManagementCenter />
        </PrivateRoute>


        {/* content management */}
        <PrivateRoute exact path="/contentmanage/adoptioncontact">
          <AdoptionContact />
        </PrivateRoute>

        <PrivateRoute exact path="/contentmanage/adoptioncontact/add">
          <AddAdoptionContact />
        </PrivateRoute>


        <PrivateRoute exact path="/contentmanage/calenderofevents">
          <CalenderOfEvents />
        </PrivateRoute>
        {/* <PrivateRoute exact path="/contentmanage/manageagencyforms">
          <ManageAgencyForm />
        </PrivateRoute> */}
        <PrivateRoute exact path="/contentmanage/manageannouncements">
          <ManageAnnouncement />
        </PrivateRoute>
        {/* <PrivateRoute exact path="/contentmanage/announcementlist">
          <AnnouncementList />
        </PrivateRoute> */}
        <PrivateRoute exact path="/contentmanage/managecontentlinks">
          <ManageContentLinks />
        </PrivateRoute>
        <PrivateRoute exact path="/contentmanage/managecorousel">
          <ManageCorousel />
        </PrivateRoute>
        <PrivateRoute exact path="/contentmanage/caraouseldoc">
          <ManageCaraouselDocument />
        </PrivateRoute>
        <PrivateRoute exact path="/contentmanage/managenewsletter">
          <ManageNewsLetter />
        </PrivateRoute>
        <PrivateRoute exact path="/contentmanage/manageparentdocuments">
          <ManageParentDocuments />
        </PrivateRoute>
        <PrivateRoute exact path="/contentmanage/managetranining">
          <ManageTraining />
        </PrivateRoute>
        <PrivateRoute exact path="/contentmanage/sfmcaregiverrecognition">
          <SFMCareGiverRecognition />
        </PrivateRoute>
        <Route exact path="/contentmanage/informationupdate">
          <InformationUpdate />
        </Route>
       


        {/* Online Training */}

        <PrivateRoute exact path="/onlinetraining/one">
          <OnlineTrainingOne />
        </PrivateRoute>
        <PrivateRoute exact path="/onlinetraining/two">
          <OnlineTrainingTwo />
        </PrivateRoute>
        <PrivateRoute exact path="/onlinetraining/three">
          <OnlineTrainingThree />
        </PrivateRoute>


        <PrivateRoute exact path="/onlinetraining/four">
          <OnlineTrainingFour />
        </PrivateRoute>


        <PrivateRoute exact path="/onlinetraining/five">
          <OnlineTrainingFive />
        </PrivateRoute>






        {/* previous children route */}

        <PrivateRoute exact path="/previouschildren/index">
          <PreviousChildrenIndex />
        </PrivateRoute>
        <PrivateRoute exact path="/previouschildren/view">
          <PreviousChildrenDetail />
        </PrivateRoute>

        <PrivateRoute exact path="/previouschildren/update">
          <PreviousChildrenRequestUpdate />
        </PrivateRoute>
        {/* current children route */}

        <PrivateRoute exact path="/currentchildren/activitylog">
          <CurrentChildrenActivityLog />
        </PrivateRoute>

        <PrivateRoute exact path="/currentchildren/attachdocuments">
          <CurrentChildrenAttachDocuments />
        </PrivateRoute>
        <PrivateRoute exact path="/currentchildren/caseteam">
          <CaseTeam />
        </PrivateRoute>

        <PrivateRoute exact path="/currentchildren/childprofile">
          <ChildProfile />
        </PrivateRoute>
        <PrivateRoute exact path="/currentchildren/children">
          <Children />
        </PrivateRoute>
        <PrivateRoute exact path="/currentchildren/childstrength">
          <ChildStrength />
        </PrivateRoute>
        <PrivateRoute exact path="/currentchildren/courtdates">
          <CourtDates />
        </PrivateRoute>

        <PrivateRoute exact path="/currentchildren/daycare">
          <DayCare />
        </PrivateRoute>
        <PrivateRoute exact path="/currentchildren/flowchart">
          <FlowChart />
        </PrivateRoute>
        <PrivateRoute exact path="/currentchildren/currentchildrenimmidiateassesment">
          <CurrentChildrenImmidiateAssesment />
        </PrivateRoute>
        <PrivateRoute exact path="/currentchildren/medication">
          <Medication />
        </PrivateRoute>
        <PrivateRoute exact path="/currentchildren/placementhistory">
          <PlacementHistory />
        </PrivateRoute>
        <PrivateRoute exact path="/currentchildren/placementreferal">
          <PlacementReferal />
        </PrivateRoute>
        <PrivateRoute exact path="/currentchildren/preventivemeasure">
          <PreventiveMeasure />
        </PrivateRoute>
        <PrivateRoute exact path="/currentchildren/respiteused">
          <RespiteUsed />
        </PrivateRoute>
        {/* end */}

        <PrivateRoute exact path="/index">
          <Index />
        </PrivateRoute>

        <Route exact path="/">
          <LandingMain token={token} setToken={token => setTokenHandler(token)} />
        </Route>


        <PrivateRoute exact path="/staff">
          <Staff />
        </PrivateRoute>

        <PrivateRoute exact path="/fosterfamilyinquiry">
          <FosterFamilyInquiry />
        </PrivateRoute>



        <PrivateRoute exact path="/myprofile">
          <Myprofile />
        </PrivateRoute>
        <PrivateRoute exact path="/myprofile/update">
          <MyProfileUpdate />
        </PrivateRoute>

        <PrivateRoute exact path="/accountinformation">
          <AccountInformation />
        </PrivateRoute>

        <PrivateRoute exact path="/accountmanage">
          <AccountManage />
        </PrivateRoute>

        

        <PrivateRoute exact path="/myhousehold">
          <Myhousehold />
        </PrivateRoute>

        <Route exact path="/landingmain">
          <LandingMain />
        </Route>

        <Route exact path="/whoweare">
          <WhoWeAre />
        </Route>

        <Route exact path="/newsroom">
          <NewsRoom />
        </Route>

        <Route exact path="/whereweserve">
          <WhereWeServe />
        </Route>

        <Route exact path="/whatwedo">
          <WhatWeDo />
        </Route>

        <PrivateRoute exact path="/activitylog">
          <ActivityLog />
        </PrivateRoute>

        <PrivateRoute exact path="/attachdocuments">
          <AttachDocuments />
        </PrivateRoute>

        <PrivateRoute exact path="/address">
          <Address />
        </PrivateRoute>

        <PrivateRoute exact path="/immidiateassesment">
          <ImmidiateAssesment />
        </PrivateRoute>
        <PrivateRoute exact path="/leastdesiredcondition">
          <LeastDesiredCondition />
        </PrivateRoute>
        <PrivateRoute exact path="/levelofcare">
          <LevelOfCare />
        </PrivateRoute>

        <PrivateRoute exact path="/levelofcare/update">
          <LevelOfCareRequestUpdate />
        </PrivateRoute>


        <PrivateRoute exact path="/licenseexception">
          <LicenseException />
        </PrivateRoute>

        <PrivateRoute exact path="/lodes">
          <Lodes />
        </PrivateRoute>

        <PrivateRoute exact path="/placementagreement">
          <PlacementAgreement />
        </PrivateRoute>

        <PrivateRoute exact path="/preference">
          <Preference />
        </PrivateRoute>

        <PrivateRoute exact path="/reimbursmenthistory">
          <ReimbusrsmentHistory />
        </PrivateRoute>


        <PrivateRoute exact path="/school">
          <School />
        </PrivateRoute>

        <PrivateRoute exact path="/sfcsworker">
          <SFCSWorker />
        </PrivateRoute>
        
        <PrivateRoute exact path="/sfcsoffice">
          <SFCSOffice />
        </PrivateRoute>

        <PrivateRoute exact path="/training">
          <Training />
        </PrivateRoute>

        <PrivateRoute exact path="/criticalunusualincident">
          <CriticalUnusualincident />
        </PrivateRoute>
        {/* form  */}
        <PrivateRoute exact path="/form/criticalunusualincidentform">
          <CriticalUnusualIncidentForm />
        </PrivateRoute>

        <PrivateRoute exact path="/form/formindex">
          <FormIndex />
        </PrivateRoute>
        <Route exact path="/form/fosterparentreferalform">
          <FosterParentReferalForm />
        </Route>
        <PrivateRoute exact path="/form/immidiateassesmentform">
          <ImmidiateAssesmentForm />
        </PrivateRoute>


        <PrivateRoute exact path="/form/logofdailyevents">
          <LogOfDailyEvents />
        </PrivateRoute>
        <PrivateRoute exact path="/forms/powerofyou">
          <PowerOfYou />
        </PrivateRoute>
        <Route exact path="/forms/public/powerofyou">
          <PowerOfYouPublic />
        </Route>
        <PrivateRoute exact path="/forms/milagereimbursment">
          <MilageReimbursment />
        </PrivateRoute>


        <PrivateRoute exact path="/contentmanage/announcementlist">
          <AnnouncementList />
        </PrivateRoute>

        <PrivateRoute exact path="/secure/placementSearch">
          <SearchForPlacement />
        </PrivateRoute>

        <PrivateRoute exact path="/secure/eventscalender">
          <Events />
        </PrivateRoute>

        <PrivateRoute exact path="/secure/myworker">
          <MyWorker />
        </PrivateRoute>

        <PrivateRoute exact path="/secure/HomeHelp">
          <HelpGuid />
        </PrivateRoute>

        <PrivateRoute exact path="/secure/detailview-event-edit">
          <CalenderOfEventsEdit />
        </PrivateRoute>

        <PrivateRoute exact path="/edit/eventgrid">
          <CalenderOfEventsEdit />
        </PrivateRoute>
        <Route exact path="/electronic-sign">
          <PlacementAgreementElectronicSign />
        </Route>
        <Route exact path="/fch-acknowledgement">
          <PlacementagreementFchStaffAck />
        </Route>
        <Route exact path="/placement-agreement-pdf">
          <PlacementAgreementPdfDesign />
        </Route>

        <Route exact path="/enforce-password-change">
          <EnforcePasswordChange />
        </Route>

        {/* /electronic-sign */}
       





      </Switch>

    </>
  );
}

export default Router;
