import Footer from '../../Footer';
import Header from '../../../frontendPages/Header';
import childstrength from '../../../images/assets/currentChildren/childstrength.svg';
import edittwo from '../../../images/assets/currentChildren/edittwo.svg';
import '../../CurrentChildren/ChildStrength/ChildStrength.scss'
import CurrentChildrenSideBar from '../CurrentChildrenSidebar/CurrentChildrenSidebar';
import HeaderTopDetail from '../HeaderTopDetail/HeaderTopDetail';
import { Link } from 'react-router-dom';
import edit from '../../../images/assets/myinfo/edit.svg';
import { useState } from 'react';
import DataTable from 'react-data-table-component';
import LoadSpinner from '../../Loader/LoadSpinner';
import { post } from '../../../utils/common';
import { useEffect } from 'react';
import mail from '../../../images/mail.png';
import plus from '../../../images/assets/cm/plus.svg';



function ChildStrength() {
    const [updateDiv, setShowUpdateDiv] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [addChildDiv, setAddChildDiv] = useState(false)
    const [selectedDataForEdit, setSelectedDataForEdit] = useState([])

    const showUpdateDiv = () => {
        setShowUpdateDiv(false)
        setAddChildDiv(true)
    }



    const hideUpdateDiv = () => {
        setShowUpdateDiv(false)
    }

    const hideAddChildDiv = () => {
        setAddChildDiv(false)
    }


    const [childrenData, setChildrenData] = useState([]);
    const [selectedChildrenData, setselectedChildrenData] = useState([]);
    // const [isLoading, setIsLoading] = useState(false);
    const fetchChildData = async () => {
        setIsLoading(true)

        const id = sessionStorage.getItem('providerSessionId');
        let data = await post('/api/currentchildren/index', { id });
        setChildrenData(data.data.data);


        let child_id = parseInt(data.data.data[0].ClientID);
        setActiveId(child_id)
        let provider_id = id
        let detailData = await post('/api/childrenstrength/index', { provider_id, child_id });
        console.log("detailData", detailData)

        setData(detailData.data.data);
        setIsLoading(false)

    };


    const [data, setData] = useState([]);

    // const fetchData = async () => {
    //     // fchweb.spWeb_gridClientStrength  @ClientID 
    //     const child_id = sessionStorage.getItem("childrenId");

    //     if (!data.ok) {

    //         return;
    //     }
    //     data = await data.json();
    //     console.log(data);
    //     setData(data.data.data);
    // };
    const requestEditChildStrength = (rowData) => {
        setShowUpdateDiv(true)
        setAddChildDiv(false)
        
        setUpdateData(data[rowData])
        setSelectedDataForEdit(data[rowData])
        // setShowUpdateDiv(false)
        setAddChildDiv(false)
        // console.log(LicenseDataForEdit)
    }

    const onChildAdditionSubmit = async () => {
       
       // setIsLoading(true)
      

        let username = sessionStorage.getItem('username');
        setShowUpdateDiv(false)
        setAddChildDiv(false)
        setUpdateData([])
        await post('/api/childrenstrength/update-childrenstrength-addition', { username, updateData });
        alert('Request Additon  Emailed Successfully !');
        setIsLoading(false)
      //  window.location.reload(false)
    }


    const [updateData, setUpdateData] = useState([])

    const onupdateClick = async () => {
       
       // setIsLoading(true)


        let username = sessionStorage.getItem('username');
        setShowUpdateDiv(false)
        setAddChildDiv(false)
        setUpdateData([])
        await post('/api/childrenstrength/update-childrenstrength', { username, updateData });
        alert('Request Update  Emailed Successfully !');
        setIsLoading(false)
      //  window.location.reload(false)
        // alert("Submitted Successfully");
        // window.location.reload(false)
    }

    const [activeId, setActiveId] = useState();

    const handleCurrentChildStrengthGlobal = async (children_id) => {
        setShowUpdateDiv(false)
        setAddChildDiv(false)
        setActiveId(children_id)
        setIsLoading(true);
        sessionStorage.setItem("childrenId", children_id)
        let child_id = sessionStorage.getItem("childrenId")
        child_id = children_id;
        let data = await post('/api/childrenstrength/index', { child_id });
      
       
        const provider_id = sessionStorage.getItem('providerSessionId');
        let detaildata = await post('/api/currentchildren/children-detail', { provider_id, children_id });
        // setData(data.data.data)
        setData(data.data.data);

        sessionStorage.setItem("current_child", detaildata.data.data.length > 0 ? detaildata.data.data[0].ClientName : "Null");
      
        setIsLoading(false);
    }
    console.log(data)


    useEffect(() => {
        // fetchData();
        fetchChildData()
    }, []);

  //  console.log("child", childrenData)

    const columns = [

        {
            name: 'Strength',
            selector: row => row.Strength,
            // cell: (row, j) => (row.Strength[0]),
            sortable: true,
            wrap:true
        },


        {
            name: 'Information Source',
            selector: row => row.Information_Source,
            // cell: (row, j) => (row.StrengthInformationSource[0]),
            sortable: true,
            wrap:true
        },
        {
            name: 'Explanation',
            selector: row => row.Explanation,
            // cell: (row, j) => (row.Explanation[0]),
            sortable: true,
            wrap:true
        },
        {
            name: 'Action',
            sortable: true,
            cell: (row, j) => (
                <div class="inline-flex">

                    <Link to="#" onClick={() => requestEditChildStrength(j)}

                        className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                    >
                        <img src={mail} alt="tag"></img>
                    </Link>

                </div >
            ),
        },




    ];

    return (

        <div className="main">
            <Header />
            <div className="main-content" >
                <HeaderTopDetail />
                <div className="pagewithsidebar">
                    <div className="container">
                        <div className="pagewithsidebar-inner">
                            <CurrentChildrenSideBar />
                            <div className="tab-detail">
                                <div className="tab-detail-top childprofile-tabs">
                                    <div className="children-section">
                                        <img src={childstrength} alt="" />
                                        <div className="pagesubtitle">
                                            Child’s Strengths
                                        </div>
                                    </div>
                                    <Link to="#" onClick={showUpdateDiv}><img src={plus} style={{"height": "18px"}} alt="#"/>Request Addition</Link>
                                </div>
                                <div className="children-tab">
                                    {childrenData.map(child => {
                                        return <span className={activeId === child.ClientID ? "active" : ""} onClick={() => handleCurrentChildStrengthGlobal(child.ClientID)}>{child.ClientName}</span>
                                    })}
                                    {/* <span className="active">Easton Elliott</span> | <span>Easton Easton</span> | <span>Anna Litchfield</span> */}
                                </div>
                                <div className="tab-detail-bottom children-data">
                                    <form className="children-doc-form">
                                        {addChildDiv ? <div className="req-update-textarea">
                                            <label><span><b>Request Addition for</b>- Child's strengths
                                                <br></br>
                                                <br></br>
                                                <b> Description for Additon:</b></span></label>
                                            <textarea onChange={e => setUpdateData({ ...updateData, description: e.target.value })}></textarea>
                                            <div className="req-update-action">
                                                <Link to="#" onClick={hideAddChildDiv} className="close">close</Link>
                                                <Link to="#" onClick={onChildAdditionSubmit} className="normal">Submit</Link>
                                            </div>
                                        </div> : ''}



                                        {updateDiv ? <div className="req-update-textarea">
                                            <label><span><b>Request Update for</b> Child's strengths |
                                                <b> Child: {sessionStorage.getItem("current_child")} | </b>
                                                Strength: {selectedDataForEdit.Strength ? selectedDataForEdit.Strength : ''}    |
                                                Information_Source: {selectedDataForEdit.Information_Source ? selectedDataForEdit.Information_Source : ''} |
                                                Strength: {selectedDataForEdit.Strength ? selectedDataForEdit.Strength : ''}
                                                <br></br>
                                                <br></br>
                                                <b> Description for Update:</b></span></label>
                                            <textarea onChange={e => setUpdateData({ ...updateData, description: e.target.value })}></textarea>
                                            <div className="req-update-action">
                                                <Link to="#" onClick={hideUpdateDiv} className="close">close</Link>
                                                <Link to="#" onClick={onupdateClick} className="normal">Submit</Link>
                                            </div>
                                        </div> : ''}


                                        {isLoading ? (<LoadSpinner />) : ''}

                                        {/* <table>
                                            <tr>
                                                <th>Strength</th>
                                                <th>Information Source</th>
                                                <th>Explanation</th>
                                                <th className="Edit-icon"></th>
                                            </tr>
                                            <tr>
                                                <td>Able to complete tasks</td>
                                                <td>NA</td>
                                                <td>Can complete simple tasks when asked, likes to help out.</td>
                                                <td className="download-icon"><Link to="#"><img src={edittwo} alt="" /></Link></td>
                                            </tr>
                                            <tr>
                                                <td>Able to complete tasks</td>
                                                <td>NA</td>
                                                <td>Can complete simple tasks when asked, likes to help out.</td>
                                                <td className="download-icon"><Link to="#"><img src={edittwo} alt="" /></Link></td>
                                            </tr>
                                            <tr>
                                                <td>Able to complete tasks</td>
                                                <td>NA</td>
                                                <td>Can complete simple tasks when asked, likes to help out.</td>
                                                <td className="download-icon"><Link to="#"><img src={edittwo} alt="" /></Link></td>
                                            </tr>
                                        </table> */}
                                        <DataTable columns={columns} data={data} pagination={true} />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}




export default ChildStrength;
