




function ContentHeaderTopDetail() {

   

    return (

        <div className="headertopdetail">
            <div className="top-link">
                <div className="container">
                    <a href="/staff" className={window.location.pathname === '/staff'?'active':''}>Set Provider</a> |
                    <a href="/managementcenter/provider"  >Management Center</a> |
                    <a href="/contentmanage/adoptioncontact"  className={window.location.pathname.split('/')[1] ==='contentmanage'?'active':''}>Content Management</a>

                </div>
            </div>
        </div>



    );
}
export default ContentHeaderTopDetail;


