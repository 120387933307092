import Footer from '../Footer';
import Header from '../Header';
import '../myprofile/myprofile.scss'
import mprofile from '../../images/assets/images/mprofile.svg';
import SideBar from '../sidebar/sidebar';
import HeaderTopDetail from '../CurrentChildren/HeaderTopDetail/HeaderTopDetail';
import { useState } from 'react';



function MyProfileUpdate() {

    const [addAddressDivShow, setAddAddressShowDiv] = useState(false);

    const showAddAddress = () => {
        setAddAddressShowDiv(true);
    }
    const hideAddAddress = () => {
        setAddAddressShowDiv(false);
    }
    return (


        <div className="main">

            <Header />

            <div className="main-content" >
                <HeaderTopDetail />
                <div className="pagewithsidebar">
                    <div className="container">
                        <div className="pagewithsidebar-inner">


                            <SideBar />
                            <div className="tab-detail">
                                <div className="tab-detail-top">
                                    <div className="m-profile">
                                        <img src={mprofile} alt="" />
                                        my profile
                                    </div>
                                    {/* <a href="#">request update</a> */}
                                </div>
                                <div className="tab-detail-bottom">
                                    <form className="myprofile-form">
                                        <div className="form-row">
                                            <div class="ot-field">
                                                <label class="form-label">Provider Name:</label>
                                                <input type="text" class="form-control" id="exampleFormControlInput1" />
                                            </div>
                                            <div class="ot-field">
                                                <label class="form-label">Telephone Number:</label>
                                                <input type="text" class="form-control" id="exampleFormControlInput1" />
                                            </div>
                                            <div class="ot-field">
                                                <label class="form-label">Email Address:</label>
                                                <input type="email" class="form-control" id="exampleFormControlInput1" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div class="ot-field">
                                                <label class="form-label">License Number:</label>
                                                <input type="text" class="form-control" id="exampleFormControlInput1" />
                                            </div>
                                            <div class="ot-field">
                                                <label class="form-label">License Renewal Date:</label>
                                                <input type="text" class="form-control" id="exampleFormControlInput1" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div class="ot-field">
                                                <label class="form-label">Service Address:</label>
                                                <input type="text" class="form-control" id="exampleFormControlInput1" />
                                            </div>
                                            <div class="ot-field">
                                                <label class="form-label">Billing Address:</label>
                                                <input type="text" class="form-control" id="exampleFormControlInput1" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div class="ot-field">
                                                <label class="form-label">State:</label>
                                                <input type="text" class="form-control" id="exampleFormControlInput1" />
                                            </div>
                                            <div class="ot-field">
                                                <label class="form-label">Zip Code:</label>
                                                <input type="text" class="form-control" id="exampleFormControlInput1" />
                                            </div>
                                        </div>
                                    </form>

                                    {addAddressDivShow ?
                                        <form className="myprofile-form">
                                            <div className="form-row">
                                                <div class="ot-field">
                                                    <label class="form-label">Provider Name:</label>
                                                    <input type="text" class="form-control" id="exampleFormControlInput1" />
                                                </div>
                                                <div class="ot-field">
                                                    <label class="form-label">Telephone Number:</label>
                                                    <input type="text" class="form-control" id="exampleFormControlInput1" />
                                                </div>
                                                <div class="ot-field">
                                                    <label class="form-label">Email Address:</label>
                                                    <input type="email" class="form-control" id="exampleFormControlInput1" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div class="ot-field">
                                                    <label class="form-label">License Number:</label>
                                                    <input type="text" class="form-control" id="exampleFormControlInput1" />
                                                </div>
                                                <div class="ot-field">
                                                    <label class="form-label">License Renewal Date:</label>
                                                    <input type="text" class="form-control" id="exampleFormControlInput1" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div class="ot-field">
                                                    <label class="form-label">Service Address:</label>
                                                    <input type="text" class="form-control" id="exampleFormControlInput1" />
                                                </div>
                                                <div class="ot-field">
                                                    <label class="form-label">Billing Address:</label>
                                                    <input type="text" class="form-control" id="exampleFormControlInput1" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div class="ot-field">
                                                    <label class="form-label">State:</label>
                                                    <input type="text" class="form-control" id="exampleFormControlInput1" />
                                                </div>
                                                <div class="ot-field">
                                                    <label class="form-label">Zip Code:</label>
                                                    <input type="text" class="form-control" id="exampleFormControlInput1" />
                                                </div>
                                            </div>
                                        </form>
                                        : ''}
                                    <div className="action-btn">
                                        <a href="#" onClick={hideAddAddress} className="close-btn">close</a>
                                        <a href="#" className="submit-btn">submit</a>
                                        <a href="#" onClick={showAddAddress} className="add-address-btn">add address</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}




export default MyProfileUpdate;
