import Footer from "../Footer";
import Header from "../Header";
import { Link } from "react-router-dom";
import HeaderTopDetail from '../CurrentChildren/HeaderTopDetail/HeaderTopDetail';
// import profile1 from "../../images/assets/images/profile1.png";
// import children1 from "../../images/assets/images/children1.png";
// import previous1 from "../../images/assets/images/previous1.png";
// import search from "../../images/assets/images/search.png";
// import loads1 from "../../images/assets/images/loads1.png";
// import mileage1 from "../../images/assets/images/mileage1.png";
// import cretical1 from "../../images/assets/images/cretical1.png";
// import immediate1 from "../../images/assets/images/immediate1.png";
// import power1 from "../../images/assets/images/power1.png";

import criticalunusualincidentImage from "../../images/helpguideimages/critical_unusual_incident.png";
import currentchildren from "../../images/helpguideimages/current_children.png";
import immidiateassessment from "../../images/helpguideimages/immidiate_assessment.png";
import lodes from "../../images/helpguideimages/lodes.png";
import mileage_reimbursement from "../../images/helpguideimages/mileage_reimbursement.png";
import power_of_you from "../../images/helpguideimages/power_of_you.png";
import previous_children from "../../images/helpguideimages/previous_children.png";
import profile from "../../images/helpguideimages/profile.png";

//import acmanage from '../../images/assets/images/ManageInfo.svg';

function HelpGuid() {
  return (
    <div className="main">
      <Header />
      <div className="main-content">
      <HeaderTopDetail />
      <div className="parent-main">
        <div className="container" style={{ "font-size": "1.4em" }}>
          <h2>Click the Name of the Section:</h2>
        </div>
        <br></br>

        <div
          className="container"
          style={{ textAlign: "center", "font-size": "1.2em" }}
        >
          <div>
            <a href="#profile" alt="">
              Profile Page
            </a>
          </div>

          <div>
            <a href="#currentchildren" alt="">
              Current Children
            </a>
          </div>
          <div>
            <a href="#previouschildren" alt="">
              {" "}
              Previous Children
            </a>
          </div>
          {/* <div>
            <a href="#search" alt="">
              Search for Placement
            </a>
          </div> */}
          <div>
            <a href="#loads" alt="">
              Lodes
            </a>
          </div>

          <div>
            <a href="#mileage" alt="">
              Mileage Reimbursement
            </a>
          </div>

          <div>
            <a href="#critical" alt="">
              {" "}
              Critical/Unusual Incident
            </a>
          </div>
          {/* <div>
            <a href="#" alt="">
              Electronic Signature - Placement Agreement
            </a>
          </div> */}
          <div>
            <a href="#immediate" alt="">
              Immediate Assessment
            </a>
          </div>
          <div>
            <a href="#power" alt="">
              Power Of You
            </a>
          </div>
        </div>
 

        <div id="profile" className="mt-50">
          <div className="helpheader">
            Profile Page:
          </div>
          <div className="mt-10 fullpad">
            <b>Step 1: </b>Log in, click 'My Resources', then click the ‘My
            Information’ link.
            <br />
            <b>Step 2: </b>Click through the menu to find information about the
            home.
            <br />
            <img className="helpimg"
              src={profile}
            // style={{ width: "100%", border: "1px solid #555" }}
            />
          </div>
        </div>

        <div id="currentchildren" className="mt-25">
          <div className="helpheader">
            Current Children:
          </div>
          <div className="mt-10 fullpad">
            <b>Step 1: </b>Log in, click 'My Resources', then click the ‘Current
            Children’ link.
            <br />
            <b>Step 2: </b>Click through the menu to find information about the
            children in the home.
            <br />
            <img className="helpimg"
              src={currentchildren}

            />
          </div>
        </div>

        <div id="previouschildren" className="mt-25">
          <div className="helpheader">
            Previous Children:
          </div>
          <div className="mt-10 fullpad">
            <b>Step 1: </b>Log in, click 'My Resources', then click the ‘Previous
            Children’ link.
            <br />
            <b>Step 2: </b>
            Click through the menu to find information about the children that
            were previously in the home.
            <br />
            <img className="helpimg"
              src={previous_children}

            />
          </div>
        </div>

        {/* <div id="search" style={{ width: "420px" }}>
          <b>Step 1: </b>Log in, click 'My Resources', then click the ‘Search
          for Placement’ link.
          <br />
          <br />
          <b>Step 2: </b>Fill out the desired search criteria and click
          ‘Search’.
          <br />
          <br />
          <img
            src={search}
            style={{ border: "1px solid #555", width: "1600px" }}
          />
        </div> */}
        <div id="loads"  className="mt-25">
          <div className="helpheader">
            Lodes:
          </div>
          <div className="mt-10 fullpad">
            <b>Step 1: </b>Log in, click 'My Resources', then click the ‘Forms’
            link.
            <br />
            <b>Step 2: </b>Click ‘LODES’.
            <img
              src={lodes}
              className="helpimg"
            />
          </div>
        </div>
        <div id="mileage" className="mt-25">
          <div className="helpheader">
            Mileage Reimbursement:
          </div>
          <div className="mt-10 fullpad">
            <b>Step 1: </b>Log in, click 'My Resources', then click the ‘Forms’
            link.
            <br />
            <b>Step 2: </b>Click ‘Mileage Reimbursement’.
            <img
              src={mileage_reimbursement}
              className="helpimg"
            />
          </div>
        </div>
        <div id="critical" className="mt-25">
          <div className="helpheader">
            Critical/Unusual Incident:
          </div>
          <div className="mt-10 fullpad">
            <b>Step 1: </b>Log in, click 'My Resources', then click the ‘Forms’
            link.
            <br />
            <b>Step 2: </b>Click ‘Critical / Unusual Incident’.
            <img
              src={criticalunusualincidentImage}
              className="helpimg"
            />
          </div>
        </div>
        <div id="immediate" className="mt-25">
          <div className="helpheader">
            Immediate Assessment:
          </div>
          <div className="mt-10 fullpad">
            <b>Step 1: </b>Log in, click 'My Resources', then click the ‘Forms’
            link.
            <br />
            <b>Step 2: </b>Click ‘Immediate Assessment’.
            <img
              src={immidiateassessment}
              className="helpimg"
            />
          </div>
        </div>
        <div id="power" className="mt-25">
          <div className="helpheader">
            Power Of You:
          </div>
          <div className="mt-10 fullpad">
            <b>Step 1: </b>Log in, click 'My Resources', then click the ‘Forms’
            link.
            <br />
            <b>Step 2: </b>Click ‘Power Of You’.
            <img
              src={power_of_you}
              className="helpimg"
            />
          </div>
        </div>
        <Footer />
      </div>
    </div>
    </div>
  );
}

export default HelpGuid;
