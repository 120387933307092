import Footer from "../../Footer";
import Header from "../../Header";
//import "../CriticalUnusualIncidentForm/CriticalUnusualIncidentForm.scss";
//import "../ImmidiateAssesmentForm/ImmidiateAssesmentForm.scss";
import '../PowerOfYou/PowerOfYou.scss';
import pouinner from "../../../images/assets/formimages/pouinner.svg";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import FormSideBar from "../FormSidebar/FormSidebar";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { post } from "../../../utils/common";
import { useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
import LoadSpinner from "../../Loader/LoadSpinner";

function PowerOfYou() {
  const history = useHistory();
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  let token = sessionStorage.getItem("token");
  const [AddFormData, setAddFormData] = useState([]);

  const onSubmitButtonClick = async (e) => {
    if(AddFormData.FirstName == undefined || AddFormData.LastName == undefined ||
      AddFormData.Contact == undefined || AddFormData.Email == undefined )
     { 
     alert("Please enter the Mandatory fields");
      return;
     }     
    e.preventDefault();
    html2canvas(inputRef.current).then(async (canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      var doc = new jsPDF("p", "mm", "a4");
      var pageHeight = 295;  
    var imgWidth = (canvas.width *31) / 210 ; 
    var imgHeight = canvas.height * imgWidth / canvas.width;
    var heightLeft = imgHeight;
    var position = 31;

    doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight; 
    }
    setIsLoading(true)

      const out = doc.output("datauristring");  
      const providerid = sessionStorage.getItem("providerSessionId");
      const providername= sessionStorage.getItem("ProviderName");
      const response = await axios.post("/api/general/powerofyouLoginmail",
        {
          pdf: out.split("base64,")[1],
          AddFormData,
          providername
        }
      );
      alert("Email Sent");
      
      window.location.reload(false);
    });

    console.log(AddFormData);
      let StateID = stateData;
      let AddFormData1 = JSON.stringify(AddFormData);
      const providerid = sessionStorage.getItem("providerSessionId");
      const providername= sessionStorage.getItem("ProviderName");

      let data = await post("/api/general/powerofyouafterloginsave", {
        AddFormData1,
        providerid,
        stateData,
        
      
      });

    // let username = sessionStorage.getItem("username");
    // await post("/api/general/powerofyoumail", { AddFormData, username });
    // alert("Emailed  successfully !");
    // window.location.reload(false);
    // history.push('/contentmanage/adoptioncontact');
    history.push("/form/formindex");
  };

  const closeClicked = () => {
    history.push("/form/formindex");
  };

  const [stateData, setStateData] = useState([]);

  const fetchStateData = async () => {
    let data = await fetch("/api/general/state");
    if (!data.ok) {
      return;
    }
    data = await data.json();
    console.log(data);
    setStateData(data.data);
  };

  useEffect(() => {
    fetchStateData();
  }, []);

  console.log("state", stateData);
  return (
    <div className="main">
      {token ? <Header /> : ""}

      <div className="main-content">
        {token ? <HeaderTopDetail /> : ""}
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              {token ? <FormSideBar /> : ""}

              {token ? (
                ""
              ) : (
                <div className="homelink">
                  <Link to="/">Home</Link>
                </div>
              )}

              <div  ref={inputRef}
                className={`tab-detail Critical ${
                  token ? "" : "formwithout-login"
                }`}
              >
                <div className="tab-detail-top" >
                  <div className="Critical-unusual">
                    <img src={pouinner} alt="" />
                    Power of You - SFM FOster Care Homes
                  </div>
                </div>
                <div className="tab-detail-bottom">
                  <div className="form-with-sidebar">
                    <form>
                    <div className="foster-form-section"><h2> Family I am referring</h2></div> 
                      <div className="form-row dandt mt-0 section-4">
                        <div className="power-one-half">
                          <label for="birthday">First Name:<span style={{color:"red"}}>*</span></label>
                          <input
                            type="text"
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                FirstName: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="power-one-half">
                          <label for="birthday">Last Name:<span style={{color:"red"}}>*</span></label>
                          <input
                            type="text"
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                LastName: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-row dandt mt-0 section-4">
                        <div className="power-one-half">
                          <label for="birthday">Contact Number:<span style={{color:"red"}}>*</span></label>
                          <input
                            type="text"
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                Contact: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="power-one-half">
                          <label for="birthday">Email Address:<span style={{color:"red"}}>*</span></label>
                          <input
                            type="email"
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                Email: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-row dandt section-5">
                      <div className="power-one-half">
                          <label for="birthday">Address:</label>
                          <input
                            type="email"
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                Address: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-row dandt mt-0 ai-c section-6">
                        <div className="one-third">
                          <label for="birthday">City:</label>
                          <input
                            type="text"
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                City: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="one-third">
                          <label for="birthday">State:</label>
                          <select
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                State: e.target.value,
                              })
                            }
                          >
                            <option value="0">Select:</option>
                            {stateData.map((states) => (
                              <option value={states.State}>
                                {states.State}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="one-third">
                          <label for="birthday">Zip:</label>
                          <input
                            type="text"
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                Zip: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="power-one-half">
                        <label>
                          Comment:
                        </label>
                        <textarea
                          className="m-0"
                          onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              Comment: e.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                      {/* <h6 className="small-text"> Family I am referring</h6>

                                            <div className="form-row dandt mt-0">
                                                <div className="one-half">
                                                    <label for="birthday">First Name:</label>
                                                    <input type="text" />
                                                </div>
                                                <div className="one-half">
                                                    <label for="birthday">Last Name:</label>
                                                    <input type="text" />
                                                </div>
                                            </div>
                                            <div className="form-row dandt mt-0">
                                                <div className="one-half">
                                                    <label for="birthday">Contact Nnumber:</label>
                                                    <input type="text" />
                                                </div>
                                                <div className="one-half">
                                                    <label for="birthday">Email Address:</label>
                                                    <input type="email" />
                                                </div>
                                                        </div> */}

                      
                  
                    <div className="req-update-textarea pt-0 mt-25 b-0">
                        <div className="req-update-action">
                          <Link
                            href="#"
                            onClick={closeClicked}
                            className="close"
                          >
                            Close
                          </Link>
                          <Link
                            href="#"
                            onClick={onSubmitButtonClick}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                        {isLoading ? (<LoadSpinner />) : ''}
                      </div>
                      </form>
                    {/* <div className="form-note">
                      <div className="form-grey">
                        <ul>
                          <li>
                            The<b> Power Of You</b> program honors your
                            dedication and important role you play in recruiting
                            new foster families by providing a bonus when you
                            refer a new foster family to Saint Francis. You'll
                            receive the bonus when the family becomes licensed
                            and takes their first placement. You can even
                            receive an additional incentive if that first
                            placement is a teen, age 13 or older. Respite,
                            Emergency, or traditional placements all count
                          </li>
                          {/* <li>* Future dates will not be allowed.</li>
                                                    <li>* You will only be allowed to select a child if we have them listed as being in your home on the selected date.</li> */}
                        {/* </ul> */}
                      {/* </div> */}
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PowerOfYou;