import Footer from "../../Footer";
import Header from "../../../frontendPages/Header";
import ContentManagementSideBar from "../ContentManagementSideBar/ContentManagementSideBar";
import manageparent from "../../../images/assets/cm/manageparent.svg";
import ContentHeaderTopDetail from "../ContentHeaderTopDetail/ContentHeaderTopDetail";
import "../AdoptionContact/AdoptionContact.scss";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { post, put } from "../../../utils/common";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import LoadSpinner from "../../Loader/LoadSpinner";
import "../../../css/style.scss"

function ManageParentDocuments() {
  const [ParentDocumentData, setParentDocumentData] = useState([]);
  const [ParentDocumentDataType, setParentDocumentDataType] = useState([]);
  const [selectedTitleData, setselectedTitleData] = useState([]);
  const [dataToPopulate, setDataToPopulate] = useState([]);
  const [addvsupdate, setAddvsupdate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewForm, setIsNewForm] = useState(true);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      let data = await fetch("/api/parentdocument/getParentDocument");
      if (!data.ok) {
        return;
      }
      data = await data.json();
      console.log(data);
      setParentDocumentData(data.data);
      setIsLoading(false);
    } catch (err) {
      alert("Unable to fetch Document Title")
    }

  };

  const updateData = async (e) => {
    if (dataToPopulate.ParentDocumentsID) {
      if (dataToPopulate.FilePath) {
        setIsLoading(true);
        const id = selectedTitleData.Id;
        const formData = new FormData();
        formData.append("file", dataToPopulate.FilePath);
        formData.append("parentDocumentData", JSON.stringify(dataToPopulate));
        axios
          .put("/api/parentdocument/update/" + id, formData, {
            // receive two parameter endpoint url ,form data
          })
          .then((res) => {
            // then print response status
            console.log(res.statusText);
            window.alert("Document Updated Successfully ");
            window.location.reload(false);
          });

        setIsLoading(false);
      } else {
        alert('Please Upload PDF Document')
      }
    } else {
      alert('No Previous data Present. Please Select new!');
    }

  };

  console.log("data to populate", dataToPopulate);

  const storeData = async (e) => {
    if (dataToPopulate.ParentDocumentTypeID && dataToPopulate.Title) {
      if (dataToPopulate.FilePath) {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("file", dataToPopulate.FilePath);
        formData.append("parentDocumentData", JSON.stringify(dataToPopulate));

        axios
          .post("/api/parentdocument/store", formData, {
            // receive two parameter endpoint url ,form data
          })
          .then((res) => {
            // then print response status
            console.log(res.statusText);
            alert("Document Uploaded Successfully ");
            window.location.reload(false)
          });

        setIsLoading(false);
      } else {
        alert('Please Upload PDF Document')
      }
    } else {
      alert('Please Fill Document Title and Document Type!')
    }
  };

  const fetchParentDocumentDataType = async () => {
    let data = await fetch("/api/parentdocument/getparentdocumenttype");
    if (!data.ok) {
      return;
    }
    data = await data.json();
    console.log(data);
    setParentDocumentDataType(data.data);
  };

  const [dataselectedLoaded, setDataSelectedLoaded] = useState(false);
  const setSelectData = async () => {
    if (selectedTitleData.Id) {
      try {
        setIsLoading(true)
        const id = selectedTitleData.Id;
        console.log(id);
        setAddvsupdate(false);
        const data = await post("/api/parentdocument/getparentdocumentbyid", {
          id,
        });
        if (!data.data) {
          alert("No Existing Data Found")
          setIsLoading(false)
          return
        }
        setDataToPopulate(data.data.data[0]);
        console.log(dataToPopulate);
        setDataSelectedLoaded(true)
        setIsLoading(false)
        setIsNewForm(false);
      } catch (err) {
        alert("Unable to fetch Data")
      }
    } else {
      AddNewForm();
      alert('For the Selected Document Title No Data Present!')
      setAddvsupdate(true)
      setIsNewForm(true)

    }
  };

  const AddNewForm = () => {
    setDataToPopulate([]);
    setAddvsupdate(true);
    setDataSelectedLoaded(false)
    setIsNewForm(true);
    setDataToPopulate((prevState) => ({
      ...prevState,
      Description: "",

    }));
    // dataToPopulate.ParentDocumentTypeID || ""
  };

  const clearDocumentTitle = () => {
    setselectedTitleData((prev) => ({
      ...prev,
      Id: "0"
    }))
  }

  //    const removeRow = async index => {
  //       const temp = [...data];
  //       const primaryKey = temp[index].AdoptionContactID;
  //       temp.splice(index, 1);
  //       try {
  //           await axios.delete(`/api/adoptioncontact/delete/${encodeURIComponent(primaryKey)}`);
  //           setData(temp);
  //       } catch (err) { }
  //   };
  const deleteParentDocument = async () => {
    if (dataToPopulate.ParentDocumentsID) {
      setIsLoading(true)
      const temp = [...ParentDocumentData];
      try {
        await axios.delete(
          `/api/parentdocument/delete/${encodeURIComponent(
            dataToPopulate.ParentDocumentsID
          )}`
        );
        setParentDocumentData(temp);
      } catch (err) { }
      // alert("Deleted Data Successfully");
      setIsLoading(false)
      window.location.reload(false);
    } else {
      alert('Please Select Document to Delete!')
    }
  };

  useEffect(() => {
    fetchData();
    fetchParentDocumentDataType();
  }, []);

  return (
    <div className="main">
      <Header />
      <div className="main-content">
        <ContentHeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <ContentManagementSideBar />
              <div className="tab-detail">
                <div className="tab-detail-top children-tabs">
                  <div className="children-section">
                    <img src={manageparent} alt="" />
                    <div className="pagesubtitle">Manage Parent Documents</div>
                  </div>
                </div>
                <div className="tab-detail-bottom children-data">
                  <form className="children-doc-form manage-parent-form">

                    <div className="one-fourth d-flex gap-20 mb-4" style={{ marginBottom: '20px' }}>
                      <Link to="#" onClick={() => { setAddvsupdate(false); clearDocumentTitle(); }} className="normal">
                        Edit
                      </Link>
                      <Link to="#" onClick={() => { setAddvsupdate(true); AddNewForm(); clearDocumentTitle() }} className="normal">
                        New
                      </Link>
                    </div>
                    {(addvsupdate !== null && addvsupdate == false) &&
                      <div className="form-row mb-30 gap-20 ai-fe">
                        <div className="one-third pl-3">
                          <label>Document Title:</label>
                          <select
                            onChange={(e) => {
                              setselectedTitleData({
                                ...selectedTitleData,
                                Id: e.target.value,
                              });
                              selectedTitleData.Id = e.target.value;
                              addvsupdate ? AddNewForm() : setSelectData();
                            }
                            }
                            value={selectedTitleData.Id}
                            className="mb-0"
                            name="cars"
                            id="cars"
                          >
                            <option value="0">Please Select</option>
                            {ParentDocumentData.map((pdoc) => (
                              <option
                                key={pdoc.ParentDocumentsID}
                                value={pdoc.ParentDocumentsID === null ? "" : pdoc.ParentDocumentsID}
                              >
                                {pdoc.Title}
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* <div className="one-fourth d-flex gap-20">
                        <Link to="#" onClick={setSelectData} className="normal">
                          Select
                        </Link>
                        <Link to="#" onClick={AddNewForm} className="normal">
                          Add Form
                        </Link>
                      </div> */}
                      </div>

                    }
                    {isLoading ? <LoadSpinner /> : ""}
                    {(addvsupdate !== null && (addvsupdate ? true : selectedTitleData.Id !== "0")) &&
                      <div className="form-grey mt-25">
                        {addvsupdate ? (
                          <h6 className="small-text">Adding a New Record</h6>
                        ) : (
                          <h6 className="small-text">
                            Editing an Existing Record
                          </h6>
                        )}
                        <div className="form-row">
                          <div className="one-third pl-3">
                            <label>Document Title:<span style={{ 'color': 'red' }}>*</span></label>
                            <input
                              value={
                                dataToPopulate.Title ? dataToPopulate.Title : ""
                              }
                              onChange={(e) =>
                                setDataToPopulate({
                                  ...dataToPopulate,
                                  Title: e.target.value,
                                })
                              }
                              type="text"
                              disabled={!isNewForm}
                            />
                          </div>
                          <div className="one-third pl-3">
                            <label>Document Type:<span style={{ 'color': 'red' }}>*</span></label>
                            <select
                              className="mb-0"
                              name="cars"
                              id="cars"
                              value={dataToPopulate?.ParentDocumentTypeID ? dataToPopulate?.ParentDocumentTypeID : ""}
                              onChange={(e) =>
                                setDataToPopulate({
                                  ...dataToPopulate,
                                  ParentDocumentTypeID: e.target.value,
                                })
                              }
                              disabled={!isNewForm}
                            >
                              {/* <option>Select</option> */}


                              {dataselectedLoaded ? (
                                ParentDocumentDataType
                                  .filter(
                                    (dtype) =>
                                      dtype.ParentDocumentTypeID ===
                                      dataToPopulate.ParentDocumentTypeID
                                  )
                                  .map((dtype) => (
                                    (dtype.ProviderName) && <option
                                      selected
                                      value={dtype.ParentDocumentType}
                                    >
                                      {dtype.ProviderName}
                                    </option>
                                  ))
                              ) : (
                                <option value="0">Please Select</option>
                              )}
                              {ParentDocumentDataType.map((dtype) => (
                                <option
                                  key={dtype.ParentDocumentTypeID}
                                  value={dtype.ParentDocumentTypeID}
                                >
                                  {dtype.ParentDocumentType}
                                </option>
                              ))}

                            </select>
                          </div>
                          <div className="one-third ai-fe pl-3">
                            {/* <div className="one-half"> */}
                            <label>Upload PDF Document:<span style={{ 'color': 'red' }}>*</span></label>

                            <input
                              type="file"
                              onChange={(e) => {
                                setDataToPopulate({
                                  ...dataToPopulate,
                                  FilePath: e.target.files[0]
                                })
                                console.log(e.target.files[0])
                                setDataSelectedLoaded(false)
                              }
                              }
                              id="myFile"
                              name="filename"
                            ></input>
                            {/* {dataselectedLoaded ? dataToPopulate?.FilePath : dataToPopulate?.FilePath?.name} */}
                            {/* </div> */}
                            <span>{dataselectedLoaded ? dataToPopulate?.FilePath : dataToPopulate?.FilePath?.name}</span>
                          </div>
                        </div>

                        {/* <div className={"form-row mb-30 gap-20 ai-fe " + (dataselectedLoaded ? (dataToPopulate?.FilePath ? "" : "margin-top") : (dataToPopulate?.FilePath?.name ? "" : "margin-top"))}> */}
                        <div className={"form-row mb-30 gap-20 ai-fe "}>
                          <div className="one-third pl-3">
                            <label>Link:</label>
                            <input
                              value={
                                dataToPopulate.Link ? dataToPopulate.Link : ""
                              }
                              onChange={(e) =>
                                setDataToPopulate({
                                  ...dataToPopulate,
                                  Link: e.target.value,
                                })
                              }
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="checkbox-design mt-25">
                          <span>
                            <input
                              checked={
                                dataToPopulate.IsFosterFamily ? "checked" : ""
                              }
                              onChange={(e) =>
                                setDataToPopulate({
                                  ...dataToPopulate,
                                  IsFosterFamily: e.target.checked,
                                })
                              }
                              type="checkbox"
                              name="Kansas Foster Family"
                              value="Kansas Foster Family"
                            />
                            <label for="Kansas Foster Family">
                              {" "}
                              Kansas Foster Family
                            </label>
                          </span>
                          <span>
                            <input
                              checked={
                                dataToPopulate?.IsKinshipFamily ? "checked" : ""
                              }
                              onChange={(e) =>
                                setDataToPopulate({
                                  ...dataToPopulate,
                                  IsKinshipFamily: e.target.checked,
                                })
                              }
                              type="checkbox"
                              name="Kansas Kinship Family"
                              value="Kansas Kinship Family"
                            />
                            <label for="Kansas Kinship Family">
                              Kansas Kinship Family
                            </label>
                          </span>
                          <span>
                            <input
                              checked={
                                dataToPopulate?.IsTherapeuticFamily
                                  ? "checked"
                                  : ""
                              }
                              onChange={(e) =>
                                setDataToPopulate({
                                  ...dataToPopulate,
                                  IsTherapeuticFamily: e.target.checked,
                                })
                              }
                              type="checkbox"
                              name="Kansas Therapeutic Family"
                              value="Kansas Therapeutic Family"
                            />
                            <label for="Kansas Therapeutic Family">
                              Kansas Therapeutic Family
                            </label>
                          </span>
                          <span>
                            <input
                              checked={
                                dataToPopulate?.IsNebraskaFamily ? "checked" : ""
                              }
                              onChange={(e) =>
                                setDataToPopulate({
                                  ...dataToPopulate,
                                  IsNebraskaFamily: e.target.checked,
                                })
                              }
                              type="checkbox"
                              name="Nebraska Foster Family"
                              value="Nebraska Foster Family"
                            />
                            <label for="Nebraska Foster Family">
                              Nebraska Foster Family
                            </label>
                          </span>
                          <span>
                            <input
                              type="checkbox"
                              name="Nebraska Kinship Family"
                              value="Nebraska Kinship Family"
                            />
                            <label for="Nebraska Kinship Family">
                              Nebraska Kinship Family
                            </label>
                          </span>
                          <span>
                            <input type="checkbox" name="Others" value="Others" />
                            <label for="Others">Others</label>
                          </span>
                        </div>
                        <div className="req-update-textarea pl-0 pr-0">
                          <label style={{ "fontSize": '16px' }}>Description:</label>

                          {/* <Editor
                          apiKey="43knvham046kn3i9brlvbog2h3aahv3yb590n0p7tcmhu9kh"
                          initialValue={dataToPopulate?.Description}
                          onChange={(e) =>
                            setDataToPopulate({
                              ...dataToPopulate,
                              Description: e.level.content,
                            })
                          }
                          init={{
                            selector: "textarea",
                            // plugins: 'link image table',
                            contextmenu:
                              "link image imagetools table spellchecker lists",
                            height: 500,
                            menubar: true,
                            plugins: [
                              "advlist autolink lists link image",
                              "charmap print preview anchor help",
                              "searchreplace visualblocks code",
                              "insertdatetime media table paste wordcount",
                              "link image imagetools table spellchecker lists",
                            ],
                            toolbar:
                              "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help",
                          }}
                        /> */}
                          <textarea className="form-control"
                            value={dataToPopulate?.Description}
                            onChange={(e) =>
                              setDataToPopulate({
                                ...dataToPopulate,
                                Description: e.target.value,
                              })
                            }></textarea>
                          <div className="req-update-action" style={{ justifyContent: 'center' }}>
                            {addvsupdate ? (
                              ""
                            ) : (
                              <div>
                                {" "}
                                <Link
                                  to="#"
                                  onClick={() => { addvsupdate ? storeData() : updateData() }}
                                  className="normal"
                                >
                                  Submit
                                </Link>
                                <Link
                                  to="#"
                                  onClick={deleteParentDocument}
                                  className="close"
                                >
                                  Delete
                                </Link>
                                {" "}
                              </div>
                            )}
                            {addvsupdate ? (
                              <div>
                                {" "}
                                <Link
                                  to="#"
                                  onClick={() => { addvsupdate ? storeData() : updateData() }}
                                  className="normal"
                                >
                                  Submit
                                </Link>
                                <Link
                                  to="#"
                                  // onClick={deleteParentDocument}
                                  className="close"
                                >
                                  Cancel
                                </Link>
                              </div>
                            ) : (
                              ""
                            )}
                            {/*<Link to="#" onClick={storeData} className="normal">Add</Link> */}
                            {/* <Link to="#" onClick={deleteParentDocument} className="close">Delete</Link>  */}
                          </div>
                        </div>
                      </div>
                    }
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
      <Footer />
    </div >
  );
}

export default ManageParentDocuments;
