import Footer from "../../Footer";
import Header from "../../Header";
import logoplacement from "../../../images/logo-placement.jpg";
import placementagree from "../../../images/assets/myinfo/placementagree.svg";
import download from "../../../images/assets/myinfo/download.svg";
import edit from "../../../images/assets/myinfo/edit.svg";
import print from "../../../images/assets/myinfo/print.svg";
import search from "../../../images/assets/myinfo/search.svg";
import dompurify from 'dompurify';

// import acmanage from '../../images/assets/images/ManageInfo.svg';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import SideBar from "../../sidebar/sidebar";
import "../PlacementAgreement/PlacementAgreement.scss";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { post } from "../../../utils/common";
import DataTable from "react-data-table-component";
import mail from "../../../images/mail.png";
import LoadSpinner from "../../Loader/LoadSpinner";
import { DownloadURL } from "../../common/commonURL";
import {BlobServiceClient} from "@azure/storage-blob";


function PlacementAgreementPD() {
    const inputRef = useRef(null);
  const [data, setData] = useState([]);
  const [dataSet, setDataSet] = useState([]);

  // const commonFunc =async () =>{
  //   if(sessionStorage.fromButton == "true")
  //   fetchData();

  //   else 
  //   fetchDataPrint();
  // }



  function htmlToBlob(htmlContent){
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const htmlBlob =new Blob([doc.documentElement.outerHTML],{type: "text/html"});
    return htmlBlob
}

function handleDownloadClick() {
  //  event.preventDefault();
    const htmlContent = document.getElementById("divToPrint").innerHTML;
    const htmlBlob = htmlToBlob(htmlContent);
    saveBlobToStorage(htmlBlob);      // call function to save the blob to storage

}

async function saveBlobToStorage(blob) {
    const conString = "DefaultEndpointsProtocol=https;AccountName=sfcsblobstorageeastus2;AccountKey=PRV5XjUJy6ZKdIbX0ZVcrWHplQiJ61cF7LNfZKnE0bBnTSFrcj9huSoGOkERTU7tleZCkrshN547tOI4YLc4TA==;EndpointSuffix=core.windows.net";
    const containerName = "fmw-pdfreports"
    const blobServiceClient = new BlobServiceClient(conString);
    const containerClient = blobServiceClient.getBlockBlobClient(containerName);
    const blobName = "<sfcsblobstorageeastus2>";
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    await blockBlobClient.upload(blob, blob.size);


}

  const fetchData = async () => {

    let clientid = parseInt(sessionStorage.getItem("CLIENT_ID_FOR_PA"));
    let providerid = parseInt(sessionStorage.getItem("providerSessionId"));
    let pastatus = (sessionStorage.getItem("PAStatus"));
    let ElecSignPlacementAgreementIDFORPA = parseInt(sessionStorage.getItem(
      "ElecSignPlacementAgreementIDFORPA"
    ));
    let data = await post("/api/placementagreement/get-xml-data", {
      clientid,
      providerid,
      ElecSignPlacementAgreementIDFORPA,
      
    });

    data.data.data[0].Comment = "";

    if (data.data.data[0].CC_ElecSignDate != null){data.data.data[0].CC_ElecSignDate = data.data.data[0].CC_ElecSignDate.split("T")[0]}
    if(data.data.data[0].Provider_ElecSignDate != null){data.data.data[0].Provider_ElecSignDate = data.data.data[0].Provider_ElecSignDate.split("T")[0]}
    data.data.data[0].PAStatus = pastatus;
    if(data.data.data[0].Provider_Comment !== "undefined" && data.data.data[0].Provider_Comment !== null){
      data.data.data[0].Comment =  data.data.data[0].ProviderMemberName + " - " +  data.data.data[0].Provider_Comment;

    }

    if(data.data.data[0].Accept_Reject !== "undefined" && data.data.data[0].Accept_Reject !== null){
      data.data.data[0].Comment = data.data.data[0].Comment + "\n" + data.data.data[0].CC_StaffName + " - " +  data.data.data[0].Accept_Reject ;

    }
    setData(data.data.data[0]);

    html2canvas(inputRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight; 
  
        var doc = new jsPDF("p", "mm");
        var position = 0;
  
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
  
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save("download.pdf");
        window.location.href = "/PlacementAgreement";
      });
      


      function htmlToBlob(htmlContent){
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, "text/html");
        const htmlBlob =new Blob([doc.documentElement.outerHTML],{type: "text/html"});
        return htmlBlob
    }
    
    function handleDownloadClick() {
      //  event.preventDefault();
        const htmlContent = document.getElementById("divToPrint").innerHTML;
        const htmlBlob = htmlToBlob(htmlContent);
        saveBlobToStorage(htmlBlob);      // call function to save the blob to storage
    
    }
    
    async function saveBlobToStorage(blob) {
        const conString = "DefaultEndpointsProtocol=https;AccountName=sfcsblobstorageeastus2;AccountKey=PRV5XjUJy6ZKdIbX0ZVcrWHplQiJ61cF7LNfZKnE0bBnTSFrcj9huSoGOkERTU7tleZCkrshN547tOI4YLc4TA==;EndpointSuffix=core.windows.net";
        const containerName = "fmw-pdfreports"
        const blobServiceClient = new BlobServiceClient(conString);
        const containerClient = blobServiceClient.getBlockBlobClient(containerName);
        const blobName = "<sfcsblobstorageeastus2>";
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    
        await blockBlobClient.upload(blob, blob.size);
    
    
    }
   
  };
//   alert("Placement Agreement getting Downloaded!");

// const fetchDataPrint = async () => {

//   let clientid = parseInt(sessionStorage.getItem("CLIENT_ID_FOR_PA"));
//   let providerid = parseInt(sessionStorage.getItem("providerSessionId"));
//   let pastatus = (sessionStorage.getItem("PAStatus"));
//   let ElecSignPlacementAgreementIDFORPA = parseInt(sessionStorage.getItem(
//     "ElecSignPlacementAgreementIDFORPA"
//   ));
//   let data = await post("/api/placementagreement/get-xml-data", {
//     clientid,
//     providerid,
//     ElecSignPlacementAgreementIDFORPA,
    
//   });

//   data.data.data[0].Comment = "";

//   if (data.data.data[0].CC_ElecSignDate != null){data.data.data[0].CC_ElecSignDate = data.data.data[0].CC_ElecSignDate.split("T")[0]}
//   if(data.data.data[0].Provider_ElecSignDate != null){data.data.data[0].Provider_ElecSignDate = data.data.data[0].Provider_ElecSignDate.split("T")[0]}
//   data.data.data[0].PAStatus = pastatus;
//   if(data.data.data[0].Provider_Comment !== "undefined" && data.data.data[0].Provider_Comment !== null){
//     data.data.data[0].Comment =  data.data.data[0].ProviderMemberName + " - " +  data.data.data[0].Provider_Comment;

//   }

//   if(data.data.data[0].Accept_Reject !== "undefined" && data.data.data[0].Accept_Reject !== null){
//     data.data.data[0].Comment = data.data.data[0].Comment + "\n" + data.data.data[0].CC_StaffName + " - " +  data.data.data[0].Accept_Reject ;

//   }
//   setData(data.data.data[0]);

//     let printContents = document.getElementById("divToPrint").innerHTML;
//     let originalContents = document.body.innerHTML;
//     document.body.innerHTML = printContents;
//     window.print();
//     document.body.innerHTML = originalContents;
//     window.location.reload(false);
//       window.location.href = "/PlacementAgreement";
    
    
 
// };

  
  const title = data.AgreementText;
  const sanitizer = dompurify.sanitize;
  

  useEffect(() => {
    fetchData();
    handleDownloadClick();
   // getEsignData();
  
  }, []);
  return (
    <div id="divToPrint" ref={inputRef} class="divprint">
    <div className="main">
     

      <div
        style={{
          display: "flex",
          "align-items": "center",
          "justify-content": "center",
          "margin-left": "10px",
          "padding-left": "10px",
        }}
      >
     <div
      class="container"
      style={{ "margin-left": "30px", overflow: "word-break" }}
    >
      <div>
        <div
          style={{
            "text-align": "right",
            "marging-top": "0px",
            position: "relative",
            "margin-left": "300px",
          }}
        >
          <img className="footer-logoo" src={logoplacement} alt="" />
        </div>
        <h4
          className="title"
          style={{
            "text-align": "left",
            "font-size": "1.45em",
            "font-weight": "bold",
            "margin-top": "0px",
            "margin-left": "10px",
            "borderBlock": "1px",
          }}
        >
          Saint Francis Ministries, Inc <br></br>
          Placement Agreement
        </h4>
      </div>

      {/* <h4
        className="title"
        style={{
          "text-align": "left",
          "font-size": "1.2em",
          "font-weight": "bold",
          "margin-top": "50px",
          "margin-left": "10px",
        }}
      >
        Placement Agreement{" "}
      </h4> */}

      

      <span dangerouslySetInnerHTML={{ __html: sanitizer(title)}} />
 
      <br></br>
     
    </div>
      </div>
      
        <form className="child-case-form">
          
          <div class="col-md-12"  style={{"display":"flex","margin-left": "90px"}}>
            <div class="col-md-6">
            <label for="appt"style={{  "font-size": ".9em","font-weight": "bold"}}>Placement Provider:</label>
            <input style={{  "font-size": ".9em","font-weight": "bold"}}
                        type="text" value={data.ProviderMemberName}> 
                           
                        </input>
            </div>
            <div class="col-md-6" style={{"margin-left": "190px"}}>
            
                        <label for="appt"style={{  "font-size": ".9em","font-weight": "bold"}}>Date:</label>
            <input style={{  "font-size": ".9em","font-weight": "bold"}}
                        type="text" value={data.Provider_ElecSignDate}> 
                           
                        </input>
            </div>
          </div>
         
         
        </form>
        <form className="child-case-form">
         
          <div class="col-md-12"  style={{"display":"flex","margin-left": "90px","margin-top": "20px","margin-bottom": "40px"}}>
            <div class="col-md-6">
            <label for="appt"style={{  "font-size": ".9em","font-weight": "bold"}}>SFM Admissions:</label>
            <input style={{  "font-size": ".9em","font-weight": "bold"}}
                        type="text" value={data.CC_StaffName}> 
                           
                        </input>
            </div>
            <div class="col-md-6" style={{"margin-left": "200px"}}>
            <label for="appt"style={{  "font-size": ".9em","font-weight": "bold"}}>Date:</label>
            <input style={{  "font-size": ".9em","font-weight": "bold"}}
                        type="text" value={data.CC_ElecSignDate}> 
                           
                        </input>
            </div>

          </div>
          
          {/* value={data.Accept_Reject} */}
        </form>

       

        <form className="child-case-form"   > 

        <div class="col-md-6" style={{"margin-left": "90px"}}>
           <label class="form-label" style={{  "font-size": ".9em","font-weight": "bold"}}>Status:</label>
              <div style={{ "width":" 150%"}}>
      <input  type="text" value={data.PAStatus}  style={{ "font-size": ".9em","font-weight": "bold","width":"100%","display":"flex"}} ></input>
      </div> 
           </div>
         
         <div class="col-md-12"  style={{"display":"flex","margin-top": "70px","margin-bottom": "40px", "margin-left": "-190px"}}>

         
           <div class="col-md-6">
           <label class="form-label"style={{  "font-size": ".9em","font-weight": "bold"}}>Notes:</label> 
              <div style={{ "width":" 300%"}}>
      <textarea value={data.Comment} cols="30" rows="1" style={{ "font-size": ".9em","font-weight": "bold","border": "1px solid #888","display":"flex"}} ></textarea>
      </div> 
           </div>
      
         </div>
         
        
       </form>
     
    </div>
    </div>
  );
  
}
 
export default PlacementAgreementPD;
