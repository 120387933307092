import Footer from "../Footer";
import Header from "../Header";
import schoolinner from "../../images/assets/myinfo/schoolinner.svg";
import DataTable from "react-data-table-component";
import download from "../../images/assets/myinfo/download.svg";
import edittwo from "../../images/assets/myinfo/edittwo.svg";
import print from "../../images/assets/myinfo/print.svg";

// import acmanage from '../../images/assets/images/ManageInfo.svg';
import SideBar from "../sidebar/sidebar";



import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { post } from "../../utils/common";
import mail from "../../images/mail.png";
import LoadSpinner from "../Loader/LoadSpinner";
import { email } from "envalid";

function MyWorker() {


  const [updateDiv, setShowUpdateDiv] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [updateData, setUpdateData] = useState([]);




  const showUpdateDiv = () => {
    setShowUpdateDiv(true);
  };


  const hideUpdateDiv = () => {
    setShowUpdateDiv(false);
  };


  const onupdateClick = async () => {
    // alert('Request Submitted Successfully')
    // setIsLoading(true)

    let username = sessionStorage.getItem("username");
    setShowUpdateDiv(false)

    let data = JSON.parse(sessionStorage.getItem("ProviderResourceWorkerData")).Email;

  

    setUpdateData([])
    await post("/api/sfcsworker/row-submit-myworker", {
      username,
      updateData,
      data
      
    });
    alert("Request Update Emailed Successfully !");
    setIsLoading(false)
    window.location.reload(false);

    // alert("Submitted Successfully");
    // window.location.reload(false)
  };












  return (
    <div className="main">
      <Header />

      <div className="main-content">
        {/* <HeaderTopDetail /> */}
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              {/* <SideBar /> */}
              <div className="tab-detail">
                <div className="tab-detail-top">
                  <div className="m-profile">
                    {/* <img src={schoolinner} alt="" /> */}
                    <div className="pagesubtitle">
                      {/* SFM Worker */}
                      {/* <p>Below are the schools in my district.</p> */}
                    </div>
                  </div>

                  {/* <Link to="#" onClick={showUpdateDiv} className="request-btn">Request Addition</Link> */}
                </div>
                {isLoading ? (<LoadSpinner />) : ''}
                <div className="tab-detail-bottom">
                  <form className="attach-doc-form">
                     {/* {updateDiv ? (  */}
                      <div className="req-update-textarea">
                        <label>
                          <span>
                            <b>Email SFM Worker </b>

                            <br></br>
                            <br></br>
                            <b> Message Body:</b>
                          </span>

                        </label>
                        <textarea
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                        <div className="req-update-action">
                          <Link to="/index" className="close">
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={onupdateClick}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                     
                    {/* ) : (
                      ""
                    )} */}


                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MyWorker;
