import React, { useState } from 'react';
import './css/style.scss';
import Index from './frontendPages';
// import './charts/ChartjsConfig';
import Routes from './router';

import useAutoLogout from "./utils/useAutoLogout";
import LandingMain from './frontendPages/LandingPages/LandingMain/LandingMain';
// Import pages


function saveToken(userToken) {
  sessionStorage.setItem('token', JSON.stringify(userToken));
}

function getToken() {
  return sessionStorage.getItem('token');
}

function App() {


  // const timer = useAutoLogout(300);

  // if (timer === 0) {
  //   sessionStorage.removeItem('username');
  //   sessionStorage.removeItem('token');
  //   sessionStorage.removeItem('providerSessionId');
  //   return <LandingMain />;
  // }

  // if (timer < 8) {
  //   return <div>In {timer} seconds you will be automatically logged out</div>;
  // }
  return <Routes />;

}

export default App;
