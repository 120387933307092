import "../../Forms/ImmidiateAssesmentForm/ImmidiateAssesmentForm.scss";
import immediateinner from "../../../images/assets/formimages/immediateinner.svg";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { post } from "../../../utils/common";

function ImmidiateAssesmentFormPrint() {
  // const history = useHistory()

  // const onSubmitButtonClick = async e => {
  //     e.preventDefault();
  //     // await post('/api/unusualincident/setallformdata', { AddFormData });
  //     alert('Record Added successfully !');
  //     // window.location.reload(false);
  //     // history.push('/contentmanage/adoptioncontact');
  // };

  // const closeClicked = () => {
  //     history.push('/form/formindex');
  // }

  // const [personSigningTheForm, setSigningTheForm] = useState([])

  // const [GeneraBodylLocation, setGeneralBodyLocation] = useState([])

  // const [DetailBodyLocation, setDetailedBodyLocation] = useState([])

  // const fetchData = async () => {
  //     let data = await fetch('/api/immidiateassesment/bodylocations');
  //     if (!data.ok) {
  //         return;
  //     }
  //     console.log("here",data)
  //     data = await data.json();
  //     setDetailedBodyLocation(data.DetailLocation)
  //     setGeneralBodyLocation(data.GeneralLocation)

  // };

  // const fetchPersonSigning = async () => {
  //     let data = await fetch('/api/immidiateassesment/personsigningtheform');
  //     if (!data.ok) {
  //         return;
  //     }
  //     data = await data.json();
  //     setSigningTheForm(data.ElectronicallySigningTheForm)

  // };

  // useState(() => {
  //     fetchData();
  //     fetchPersonSigning()
  // }, []);

  // console.log(personSigningTheForm)

  const [IAHistoryFromString, setIAHistoryFromString] = useState([]);
  const [IACalmingFromString, setIACalmingFromString] = useState([]);
  const [IAIndicatorsFromString, setIAIndicatorsFromString] = useState([]);
  const [IATriggersFromString, setIATriggersFromString] = useState([]);

  const [data, setData] = useState([]);
  const [bodyData, setbodyData] = useState([]);
  const clickedForDownload = async () => {
  const iaformdata = sessionStorage.getItem("iaformdata");
  const iaFinalFormData = iaformdata ? JSON.parse(iaformdata) : [];
if(iaFinalFormData.SAHistory != null){
  let string = iaFinalFormData.SAHistory;
  let arrayofIAHistory = string.split("\r\n");
  setIAHistoryFromString(arrayofIAHistory);

}
if(iaFinalFormData.SACalming != null){
    let string1 = iaFinalFormData.SACalming;
    let arrayofIACalming = string1.split("\r\n");
    setIACalmingFromString(arrayofIACalming);
}
if(iaFinalFormData.SAIndicators != null){
    let string2 = iaFinalFormData.SAIndicators;
    let arrayofIAIndicators = string2.split("\r\n");
    setIAIndicatorsFromString(arrayofIAIndicators);
}
if(iaFinalFormData.SATriggers != null){
    let string3 = iaFinalFormData.SATriggers;
    let arrayofIATriggers = string3.split("\r\n");
    setIATriggersFromString(arrayofIATriggers);
}
    

    const bodyformdata = sessionStorage.getItem("iabodyformdata");
    console.log("bodyformdata", bodyformdata);
    const bodyFinalFormData = bodyformdata ? JSON.parse(bodyformdata) : [];
    // console.log(iaformdata);
    setbodyData(bodyFinalFormData);
    setData(iaFinalFormData);

   
  };

  console.log(data);

  useEffect(() => {
    clickedForDownload();
  }, []);

  return (
    <div className="main">
      {/* <Header /> */}

      <div className="main-content">
        {/* <HeaderTopDetail /> */}
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              {/* <FormSideBar /> */}
              <div className="tab-detail Critical pagealignpad">
                <div className="tab-detail-top">
                  <div className="Critical-unusual">
                    <img src={immediateinner} alt="" />
                    <span className="headerpad">Immediate Assessment</span>
                  </div>
                </div>
                <div>
                  <form>
                    <div className="form-row dandt">
                      <div className="one-fourth">
                        <label for="birthday" className="increasedFont">
                          Child:
                        </label>
                        <input
                          type="text"
                          className="increasedFont"
                          value={data.ClientName}
                        />
                        {/* <select>
                                                    <option value="0">Select</option>

                                                </select> */}
                      </div>
                      <div className="one-fourth">
                        <label for="appt" className="increasedFont">
                          Date of Birth:
                        </label>
                        <input
                          className="increasedFont"
                          type="text"
                          value={data.DOB}
                          id="appt"
                          name="appt"
                        />
                      </div>
                      <div className="one-fourth">
                        <label for="birthday" className="increasedFont">Date of Placement:</label>
                        <input
                          className="increasedFont"
                          type="text"
                          value={data.DateOfPlacement}
                          id="birthday"
                          name="birthday"
                        />
                      </div>
                      <div className="one-fourth">
                        <label for="appt" className="increasedFont">Placement Time:</label>
                        <input
                        className="increasedFont"
                          type="text"
                          value={data.PlacementTime}
                          id="appt"
                          name="appt"
                        />
                      </div>
                    </div>

                    <div className="form-row mt-20">
                      <div className="full-input">
                        <label for="appt" className="increasedFont">
                          1. List any medication allergies or special
                          conditions/limitations
                        </label>
                        <input type="text" className="increasedFont" value={data.SpecialConditions} />
                      </div>
                    </div>
                    <div className="form-row mt-10">
                      <div className="full-input">
                        <label for="appt" className="increasedFont">
                          2. List any food allergies/special diets/eating or
                          nutritional abnormalities
                        </label>
                        <input type="text" className="increasedFont" value={data.SpecialDiets}/>
                      </div>
                    </div>

                    <div className="g-background mt-15">
                      <div className="form-row">
                        <label for="appt" className="increasedFont">
                          3. Description and location of tattoo's, cuts, bruises
                          or skin rashes:
                        </label>
                        <small>
                          <i>
                            (This is not intended to indicate need to unclothe
                            child to conduct search. Ask child/youth if there
                            are any such marks that are not visible, indicate
                            where they are and write "reported by child /
                            youth")
                          </i>
                        </small>
                        <div className="form-row mt-25">
                          <div className="label25 labelpadtop">
                            <label for="appt" className="increasedFont">General Body Location:</label>
                          </div>
                          <div className="label25">
                            <input
                            className="increasedFont"
                              type="text"
                              value={bodyData.GeneralLocation}
                            />
                            {/* <select>
                                                        <option value="0">Select</option> */}

                            {/* {GeneraBodylLocation.map(genbody => (
                                                            <option value={genbody.BodyName}>{genbody.BodyName}</option>
                                                        )) */}
                            {/* } */}
                            {/* <option value="1">Audi</option>
                                                        <option value="2">BMW</option>
                                                        <option value="3">Citroen</option> */}
                            {/* </select> */}
                          </div>
                          {/* <div className="label3"></div> */}
                          <div className="label25 labelpadtop">
                            <label for="appt" className="increasedFont">Detail Body Location:</label>
                          </div>
                          <div className="label25">
                            <input
                            className="increasedFont"
                              type="text"
                              value={bodyData.DetailLocation}
                            />
                            {/* <select> */}
                            {/* <option value="0">Select</option> */}
                            {/* {DetailBodyLocation.map(detbody => (
                                                            <option value={detbody.BodyName}>{detbody.BodyName}</option>

                                                        ))
                                                        } */}
                            {/* <option value="1">Audi</option>
                                                        <option value="2">BMW</option>
                                                        <option value="3">Citroen</option> */}
                            {/* </select> */}
                          </div>
                        </div>
                        <div className="one-full">
                          <label for="appt" className="increasedFont">Notes:</label>
                          <textarea
                            className="textareaheight increasedFont"
                            value={bodyData.Notes}
                          ></textarea>
                        </div>
                        <div className="one-full">
                          <label for="appt" className="increasedFont">Description:</label>
                          <textarea  className="textareaheight increasedFont"
                           value={bodyData.Description}></textarea>
                        </div>
                      </div>
                    </div>

                    <div className="form-row mt-10">
                      <div className="full-input">
                        <label for="appt" className="increasedFont">
                          4. Describe any other current physical complaint,
                          illness or pain
                        </label>
                        <input type="text" className="increasedFont" value={data.PhysicalComplaints}/>
                      </div>
                    </div>
                    <div className="form-row mt-10">
                      <div className="full-input">
                        <label for="appt" className="increasedFont">
                          5. Describe the mood or attitude of child/youth at the
                          time of placement.
                        </label>
                        <input type="text" className="increasedFont" value={data.ClientAttitude} />
                      </div>
                    </div>

                    <div className="g-background mt-10">
                      <div className="form-row">
                        <div className="one-full">
                          <label className="checkbox-label increasedFont">
                            6. Does child/youth have thoughts of harming self or
                            others? <b className="increasedFont">{data.IsHarmfulThoughts}</b>
                            {/* <div className="check-action">
                              {" "}
                              <span for="yes">
                                {" "}
                                Yes{" "}
                                <input
                                  type="checkbox"
                                  id="yes"
                                  name="vehicle3"
                                  value="Boat"
                                />
                              </span>
                              <span for="no">
                                {" "}
                                No{" "}
                                <input
                                  type="checkbox"
                                  id="no"
                                  name="vehicle3"
                                  value="Boat"
                                />
                              </span>
                            </div> */}
                          </label>
                          <label for="appt" className="increasedFont">
                            If Yes, what have they thought about and is there a
                            plan? {data.HarmfulThoughtsDescription}
                          </label>
                          {/* <input type="text" /> */}
                        </div>
                        <div className="one-full">
                          <label className="checkbox-label increasedFont">
                            7. Does child/youth feel like running away?{" "}
                            <b increasedFont>{data.IsRunningAway}</b>
                          </label>
                          <label className="check-action increasedFont">
                            If Yes, Is there a plan?{data.RunningAwayPlanDescription}
                            {/* <span for="yes">
                              {" "}
                              Yes{" "}
                              <input
                                type="checkbox"
                                id="yes"
                                name="vehicle3"
                                value="Boat"
                              />
                            </span> */}
                            <span>
                              <b className="increasedFont">{data.IsRunningAwayPlan}</b>{" "}
                              {/* <input
                                type="checkbox"
                                id="yes"
                                name="vehicle3"
                                value="Boat"
                              /> */}
                            </span>
                            {/* <span for="no">
                              {" "}
                              No{" "}
                              <input
                                type="checkbox"
                                id="no"
                                name="vehicle3"
                                value="Boat"
                              />{" "}
                            </span>{" "} */}
                            {/* <label for="appt">Explain</label>{" "} */}
                          </label>
                          {/* <label for="appt"></label>
                          <input type="text" /> */}
                        </div>
                        <small>
                          ***IMPORTANT: If answers to questions 6 or 7 are yes,
                          Resource Family will contact FCH worker, remain one on
                          one with child to develop plan, and/or contact case
                          manager emergency number. ***
                        </small>
                      </div>
                    </div>

                    <div className="form-row check-section fd-c">
                      <h6 className="increasedFont">Safety Assessment Tool</h6>
                      <label for="appt">
                        Does child/youth have a history of any of the following
                        (obtain information from placing agency)?
                      </label>
                      <div className="label75">
                        <ul>
                          {IAHistoryFromString.length>0?IAHistoryFromString.slice(
                            0,
                            IAHistoryFromString.length - 1
                          ).map((iaData, index) => (
                            <li>
                              {/* <input
                                type="checkbox"
                                id="vehicle2"
                                name="vehicle2"
                              /> */}
                              <label for="vehicle2" className="increasedFont">
                                {" "}
                                {iaData}{" "}
                              </label>
                            </li>
                          )):''}
                        </ul>
                      </div>
                      <div className="checkbox-grid">
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Assaultive Behavior</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Feeling Unsafe</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2">
                              {" "}
                              History of Incarceration
                            </label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> History of Trauma</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2">Losing Control</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Physical Abuse</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Seclusion / Restraint</label>
                          </span>
                        </div> */}

                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Self Injury</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Setting Fires</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2">Sexual Abuse</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Suicides Attempts</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Verbal Abuse</label>
                          </span>
                        </div> */}

                        <div className="one-fourth">
                          <span className="check-option">
                            {/* <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            /> */}
                            <label for="vehicle2" className="increasedFont">
                              OtherHistory (please list)
                              <input
                                      className="increasedFont"
                                        type="text"
                                        value={data.OtherHistoryList== "undefined"? '': data.OtherHistoryList}
                                      />
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="form-row check-section fd-c">
                      <h6 className="increasedFont">Ask child / youth following questions</h6>
                      <label for="appt" className="increasedFont">
                        {" "}
                        These things are "triggers" for child/youth and cause
                        him/her to get upset or mad. (check all that apply)
                      </label>
                      <div className="label75">
                        <ul>
                          {IATriggersFromString.length>0?IATriggersFromString.slice(
                            0,
                            IATriggersFromString.length - 1
                          ).map((iaData3, index) => (
                            <li>
                              {/* <input
                                type="checkbox"
                                id="vehicle2"
                                name="vehicle2"
                              /> */}
                              <label for="vehicle2" className="increasedFont">
                                {" "}
                                {iaData3}{" "}
                              </label>
                            </li>
                          )):''}
                        </ul>
                      </div>


                      <div className="checkbox-grid">
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Anniversaries (dates)</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Being Isolated</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Being Touched</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Loud Noise</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Overcrowding</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2">
                              {" "}
                              Particular Time of Day
                            </label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> People in Uniform</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Seeing Others Upset</label>
                          </span>
                        </div> */}

                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Specific Person (Who)</label>
                          </span>
                        </div> */}

                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Time of Year or Month</label>
                          </span>
                        </div> */}

                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Weather</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Yelling</label>
                          </span>
                        </div> */}
                        <div className="one-fourth">
                          <span className="check-option">
                            {/* <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            /> */}
                            <label for="vehicle2" className="increasedFont">
                              {" "}
                              Other (please list)
                              <input
                                      className="increasedFont"
                                        type="text"
                                        value={data.OtherTriggerList== "undefined"? '': data.OtherTriggerList}
                                      />
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="form-row check-section fd-c">
                      <label for="appt" className="increasedFont">
                        When child/youth is mad or upset, they report they do
                        these things. (check all that apply)
                      </label>
                      <div className="label75">
                        <ul>
                          {IAIndicatorsFromString.length>0?IAIndicatorsFromString.slice(
                            0,
                            IAIndicatorsFromString.length - 1
                          ).map((iaData2, index) => (
                            <li>
                              {/* <input
                                type="checkbox"
                                id="vehicle2"
                                name="vehicle2"
                              /> */}
                              <label for="vehicle2" className="increasedFont">
                                {" "}
                                {iaData2}{" "}
                              </label>
                            </li>
                          )):''}
                        </ul>
                      </div>
                      <div className="checkbox-grid">
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Eat</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Hit or Hurt Others</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Hurt Animals</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Hurt Self</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Set Fires</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Slam Doors</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Sleep</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Steal</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Swear</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Threaten Others</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Throw Things</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Use Drugs / Alcohol</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2">
                              Other (please list)
                              <input type="text" class="form-controler"></input>
                            </label>
                          </span>
                        </div> */}

                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Want Sexual Activity</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Yell or Shout</label>
                          </span>
                        </div> */}
                        <div className="one-fourth">
                          <span className="check-option">
                            {/* <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            /> */}
                            <label for="vehicle2" className="increasedFont">
                              Other (please list)
                              <input
                                      className="increasedFont"
                                        type="text"
                                        value={data.OtherUpsettingIndicatorsList == "undefined"? '': data.OtherUpsettingIndicatorsList}
                                      />
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="form-row check-section fd-c">
                      <label for="appt" className="increasedFont">
                        {" "}
                        Child/youth reports these things help calm him/her down
                        when they are mad or upset. (check all that apply)
                      </label>
                      <div className="label75">
                        <ul>
                          {IACalmingFromString.length>0?IACalmingFromString.slice(
                            0,
                            IACalmingFromString.length - 1
                          ).map((iaData1, index) => (
                            <li>
                              {/* <input
                                type="checkbox"
                                id="vehicle2"
                                name="vehicle2"
                              /> */}
                              <label for="vehicle2" className="increasedFont">
                                {" "}
                                {iaData1}{" "}
                              </label>
                            </li>
                          )):''}
                        </ul>
                      </div>
                      <div className="checkbox-grid">
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Counting to 10</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Draw</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Exercise</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Focusing on an Object</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Going for a Walk</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Listening to Music</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Pacing</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Punching a Pillow</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2">
                              {" "}
                              Putting Hands in Water
                            </label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Putting Ice on Wrists</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Reading</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Talking to Parents</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Talking to Peers</label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2">
                              {" "}
                              Talking with a Therapist
                            </label>
                          </span>
                        </div> */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2">
                              {" "}
                              Talking with Foster Parent
                            </label>
                          </span>
                        </div>{" "} */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Voluntary Time Out</label>
                          </span>
                        </div>{" "} */}
                        {/* <div className="one-fourth">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            />
                            <label for="vehicle2"> Watching T.V.</label>
                          </span>
                        </div> */}

                        <div className="one-fourth">
                          <span className="check-option">
                            {/* <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                            /> */}
                            <label for="vehicle2">
                              Other (please list)
                              <input
                                      className="increasedFont"
                                        type="text"
                                        value={data.OtherCalmingActivitiesList  == "undefined"? '': data.OtherCalmingActivitiesList}
                                      />
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>



                    <div className="form-row mt-25">
                      <div className="label15 labelpadtop">
                        <label for="appt" className="increasedFont">
                          Person Reporting:
                        </label>
                      </div>
                      <div className="label23">
                        <input
                          type="text"
                          id="birthday"
                          name="birthday"
                          className="increasedFont"
                          onChange={(e) =>
                            setData({
                              ...data,
                              Person_Reporting: e.target.value,
                            })
                          }
                          value={data.Person_Reporting}
                        />

                        {/* <select>
                                                        <option value="0">Select</option>

                                                    </select> */}
                      </div>
                      <div className="label7"></div>
                      <div className="label15 labelpadtop">
                        <label for="birthday" className="increasedFont">
                          Date Completed:
                        </label>
                      </div>
                      <div className="label23">
                        <input type="text" className="increasedFont"   onChange={(e) =>
                          setData({
                            ...data,
                            Date_Completed: e.target.value,
                          })
                        }
                        value={data.Date_Completed}/>
                      </div>
                    </div>

                    {/* <div className="g-background mt-10">
                      <h6>MEDICATIONS THAT ARRIVED WITH CHILD/YOUTH</h6>
                      <div className="form-row">
                        <div className="label20">
                          <label for="appt" className="increasedFont">Name of Medication:</label>
                        </div>
                        <div className="label20">
                          <label for="appt" className="increasedFont">Dose:</label>
                        </div>
                        <div className="label20">
                          <label for="appt" className="increasedFont">When Given:</label>
                        </div>
                        <div className="label20">
                          <label for="appt" className="increasedFont">What for:</label>
                        </div>
                        <div className="label20">
                          <label for="appt" className="increasedFont">Initial Count:</label>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="label20">
                          <input type="text"  className="increasedFont"/>
                        </div>
                        <div className="label20">
                          <input type="text" className="increasedFont"/>
                        </div>
                        <div className="label20">
                          <input type="text" className="increasedFont"/>
                        </div>
                        <div className="label20">
                          <input type="text" className="increasedFont"/>
                        </div>
                        <div className="label20">
                          <input type="text" className="increasedFont" />
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="form-row g-background">
                      <div className="label25 ">
                        <label for="birthday" className="increasedFont">
                          Person Electronically Signing the Form:
                        </label>
                        <input type="text" className="increasedFont" value={data.ProviderMember} />
                         <select>
                                                    <option value="0">Select</option>
                                                    {personSigningTheForm.map(signperson => (
                                                        <option value={signperson.ProviderAdultName}>{signperson.ProviderAdultName}</option>

                                                    ))
                                                    }
                                                </select>
                      </div> */}
                      {/* <div className="label5"></div>
                      <div className="label40  i-agree">
                        <span className="check-option">
                          <input
                          className="increasedFont"
                            type="checkbox"
                            id="vehicle2"
                            name="vehicle2"
                            value="Car"
                          />
                          <label className="padtop increasedFont" for="vehicle2">
                            I, name have reviewed the above document and agree
                            it is correct.
                          </label>
                        </span>
                        <span>
                          <label for="appt" className="increasedFont">Login Password *:</label>
                          <input type="text" className="increasedFont" />
                        </span>
                        <small className="red-text">
                          *You must “Sign and Submit” to complete this
                          electronic form.
                        </small>
                      </div>
                      <div className="label5"></div>
                      <div className="label25 ">
                        <ul className="immediate-assessment">
                          <li>Provide original to foster care worker.</li>
                          <li>Copy to Resource Family.</li>
                          <li>Copy to oversight agency for the child.</li>
                        </ul>
                      </div>
                    </div> */}

                    {/* <div className="action-btn">
                      <Link to="#" onClick={closeClicked} className="red-btn">Close</Link>
                                            <Link to="#" onClick={onSubmitButtonClick} className="blue-btn">Submit</Link>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default ImmidiateAssesmentFormPrint;
