// import '../../CriticalUnusualIncidentForm/CriticalUnusualIncidentForm.scss'
import '../../Forms/ImmidiateAssesmentForm/ImmidiateAssesmentForm.scss'
import immediateinner from '../../../images/assets/formimages/immediateinner.svg';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import React, { useState } from 'react';
import download from '../../../images/assets/myinfo/download.svg';
import ReactToPrint from 'react-to-print';
import { useRef } from 'react';



const ComponentToPrint = React.forwardRef((props, ref) => (

    // function ImmidiateAssesmentFormDownload() {
    // const history = useHistory()

    // const onSubmitButtonClick = async e => {
    //     e.preventDefault();
    //     // await post('/api/unusualincident/setallformdata', { AddFormData });
    //     alert('Record Added successfully !');
    //     // window.location.reload(false);
    //     // history.push('/contentmanage/adoptioncontact');
    // };

    //     const closeClicked = () => {
    //         history.push('/form/formindex');
    //     }

    //     const [personSigningTheForm, setSigningTheForm] = useState([])

    //     const [GeneraBodylLocation, setGeneralBodyLocation] = useState([])

    //     const [DetailBodyLocation, setDetailedBodyLocation] = useState([])

    //     const fetchData = async () => {
    //         let data = await fetch('/api/immidiateassesment/bodylocations');
    //         if (!data.ok) {
    //             return;
    //         }
    //         console.log("here",data)
    //         data = await data.json();
    //         setDetailedBodyLocation(data.DetailLocation)
    //         setGeneralBodyLocation(data.GeneralLocation)

    //     };

    //     const fetchPersonSigning = async () => {
    //         let data = await fetch('/api/immidiateassesment/personsigningtheform');
    //         if (!data.ok) {
    //             return;
    //         }
    //         data = await data.json();
    //         setSigningTheForm(data.ElectronicallySigningTheForm)

    //     };

    //     useState(() => {
    //         fetchData();
    //         fetchPersonSigning()
    //     }, []);


    // console.log(personSigningTheForm)

    //     return (

    <div className="main" ref={ref}>

        {/* <Header /> */}

        <div className="main-content" >
            {/* <HeaderTopDetail /> */}
            <div className="pagewithsidebar">
                <div className="container">
                    <div className="pagewithsidebar-inner">


                        {/* <FormSideBar /> */}
                        <div className="tab-detail Critical">
                            <div className="tab-detail-top">
                                <div className="Critical-unusual">
                                    <img src={immediateinner} alt="" />
                                    Immediate Assessment
                                </div>
                            </div>
                            <div className="tab-detail-bottom">
                                <form>
                                    <div className="form-row dandt">
                                        <div className="one-fourth">
                                            <label for="birthday">Child:</label>
                                            <select>
                                                <option value="0">Select</option>

                                            </select>
                                        </div>
                                        <div className="one-fourth">
                                            <label for="appt">Date of Birth:</label>
                                            <input type="time" id="appt" name="appt" />
                                        </div>
                                        <div className="one-fourth">
                                            <label for="birthday">Date of Placement:</label>
                                            <input type="date" id="birthday" name="birthday" />
                                        </div>
                                        <div className="one-fourth">
                                            <label for="appt">Placement Time:</label>
                                            <input type="time" id="appt" name="appt" />
                                        </div>
                                    </div>
                                    <div className="form-row mt-0">
                                        <div className="full-input">
                                            <label for="appt">1. List any medication allergies or special conditions/limitations</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="form-row mt-0">
                                        <div className="full-input">
                                            <label for="appt">2. List any food allergies/special diets/eating or nutritional abnormalities</label>
                                            <input type="text" />
                                        </div>
                                    </div>

                                    <div className="g-background">
                                        <div className="form-row">
                                            <label for="appt">3. Description and location of tattoo's, cuts, bruises or skin rashes:</label>
                                            <small><i>(This is not intended to indicate need to unclothe child to conduct search.
                                                Ask child/youth if there are any such marks that are not visible, indicate where they are and write "reported by child / youth")</i></small>
                                            <div className="one-half">
                                                <label for="appt">General Body Location:</label>
                                                <select>
                                                    <option value="0">Select</option>

                                                        <option value=""></option>
                                                
                                                    {/* <option value="1">Audi</option>
                                                        <option value="2">BMW</option>
                                                        <option value="3">Citroen</option> */}
                                                </select>
                                            </div>
                                            <div className="one-half">
                                                <label for="appt">Detail Body Location:</label>
                                                <select>
                                                    <option value="0">Select</option>

                                                    <option value="" ></option>

                                                
                                                    
                                                {/* <option value="1">Audi</option>
                                                        <option value="2">BMW</option>
                                                        <option value="3">Citroen</option> */}
                                            </select>
                                        </div>
                                        <div className="one-full">
                                            <label for="appt">Notes:</label>
                                            <textarea></textarea>
                                        </div>
                                        <div className="one-full">
                                            <label for="appt">Description:</label>
                                            <textarea></textarea>
                                        </div>
                                    </div>

                            </div>

                            <div className="form-row mt-0">
                                <div className="full-input">
                                    <label for="appt">4. Describe any other current physical complaint, illness or pain</label>
                                    <input type="text" />
                                </div>
                            </div>
                            <div className="form-row mt-0">
                                <div className="full-input">
                                    <label for="appt">5. Describe the mood or attitude of child/youth at the time of placement.</label>
                                    <input type="text" />
                                </div>
                            </div>

                            <div className="g-background">
                                <div className="form-row">
                                    <div className="one-full">
                                        <label className="checkbox-label">6. Does child/youth have thoughts of harming self or others? <div className="check-action"> <span for="yes"> Yes <input type="checkbox" id="yes" name="vehicle3" value="Boat" /></span><span for="no"> No <input type="checkbox" id="no" name="vehicle3" value="Boat" /></span></div></label>
                                        <label for="appt">If Yes, what have they thought about and is there a plan?</label>
                                        <input type="text" />
                                    </div>
                                    <div className="one-full">
                                        <label className="checkbox-label">7. Does child/youth feel like running away? <div className="check-action"> <span for="yes"> Yes <input type="checkbox" id="yes" name="vehicle3" value="Boat" /></span><span for="no"> No <input type="checkbox" id="no" name="vehicle3" value="Boat" /></span></div></label>
                                        <label className="checkbox-label">If Yes, Is there a plan?</label>
                                        <input type="text" />
                                    </div>
                                    <small>***IMPORTANT: If answers to questions 6 or 7 are yes, Resource Family will contact FCH worker, remain one on one with child to develop plan, and/or contact case manager emergency number. ***</small>
                                </div>

                            </div>


                            <div className="form-row check-section fd-c">
                                <h6>Safety Assessment Tool</h6>
                                <label for="appt">Does child/youth have a history of any of the following (obtain information from placing agency)?</label>
                                <div className="checkbox-grid">
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Assaultive Behavior</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Feeling Unsafe
                                            </label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> History of Incarceration

                                            </label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> History of Trauma</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2">Losing Control</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Physical Abuse</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Seclusion / Restraint
                                            </label>
                                        </span>
                                    </div>


                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Self Injury


                                            </label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Setting Fires



                                            </label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2">Sexual Abuse
                                            </label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Suicides Attempts</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Verbal Abuse
                                            </label>
                                        </span>
                                    </div>

                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2">Other (please list)
                                                <input type="text" class="form-controler"></input>
                                            </label>
                                        </span>
                                    </div>

                                </div>
                            </div>


                            <div className="form-row check-section fd-c">
                                <h6>Ask child / youth following questions</h6>
                                <label for="appt"> These things are "triggers" for child/youth and cause him/her to get upset or mad. (check all that apply)</label>
                                <div className="checkbox-grid">
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Anniversaries (dates)</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Being Isolated</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Being Touched</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Loud Noise</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Overcrowding</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Particular Time of Day</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> People in Uniform</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Seeing Others Upset</label>
                                        </span>
                                    </div>


                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Specific Person (Who)</label>
                                        </span>
                                    </div>


                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Time of Year or Month</label>
                                        </span>
                                    </div>

                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Weather</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Yelling</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Other (please list)
                                                <input type="text" class="form-controler"></input></label>
                                        </span>
                                    </div>

                                </div>
                            </div>


                            <div className="form-row check-section fd-c">
                                <label for="appt">When child/youth is mad or upset, they report they do these things. (check all that apply)</label>
                                <div className="checkbox-grid">
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Eat</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Hit or Hurt Others</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Hurt Animals</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Hurt Self</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Set Fires</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Slam Doors</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Sleep</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Steal</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Swear</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Threaten Others</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Throw Things</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Use Drugs / Alcohol</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2">Other (please list)
                                                <input type="text" class="form-controler"></input></label>
                                        </span>
                                    </div>

                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Want Sexual Activity</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Yell or Shout</label>
                                        </span>
                                    </div>

                                </div>
                            </div>



                            <div className="form-row check-section fd-c">
                                <label for="appt"> Child/youth reports these things help calm him/her down when they are mad or upset. (check all that apply)</label>
                                <div className="checkbox-grid">
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Counting to 10
                                            </label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Draw</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Exercise</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Focusing on an Object</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Going for a Walk</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Listening to Music</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Pacing</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Punching a Pillow</label>
                                        </span>
                                    </div>


                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Putting Hands in Water
                                            </label>
                                        </span>
                                    </div>


                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Putting Ice on Wrists</label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Reading
                                            </label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Talking to Parents
                                            </label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Talking to Peers
                                            </label>
                                        </span>
                                    </div>

                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Talking with a Therapist
                                            </label>
                                        </span>
                                    </div>

                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Talking with Foster Parent

                                            </label>
                                        </span>
                                    </div> <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Voluntary Time Out

                                            </label>
                                        </span>
                                    </div> <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Watching T.V.

                                            </label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2"> Wrapping up in a Blanket
                                            </label>
                                        </span>
                                    </div>
                                    <div className="one-fourth">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2">Other (please list)
                                                <input type="text" class="form-controler"></input></label>
                                        </span>
                                    </div>

                                </div>
                            </div>


                            <div className="g-background">
                                <h6>MEDICATIONS THAT ARRIVED WITH CHILD/YOUTH</h6>
                                <div className="form-row">
                                    <div className="one-fifth">
                                        <label for="appt">Name of Medication:</label>
                                        <input type="text" />
                                    </div>
                                    <div className="one-fifth">
                                        <label for="appt">Dose:</label>
                                        <input type="text" />
                                    </div>
                                    <div className="one-fifth">
                                        <label for="appt">When Given:</label>
                                        <input type="text" />
                                    </div>
                                    <div className="one-fifth">
                                        <label for="appt">What for:</label>
                                        <input type="text" />
                                    </div>
                                    <div className="one-fifth">
                                        <label for="appt">Initial Count:</label>
                                        <input type="text" />
                                    </div>
                                </div>

                            </div>

                            {/* <div className="form-row dandt">
                                <div className="one-third">
                                    <label for="birthday">Person Electronically Signing the Form:</label>
                                    <select>
                                        <option value="0">Select</option>

                                        <option value=""></option>


                                    </select>
                                </div>
                                <div className="one-third i-agree">
                                    <div className="g-background">
                                        <span className="check-option">
                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                            <label for="vehicle2">I, name have reviewed the above document and agree it is correct.</label>
                                        </span>
                                        <span>
                                            <label for="appt">Login Password *:</label>
                                            <input type="text" />
                                        </span>
                                        <small className="red-text">*You must “Sign and Submit” to complete this electronic form.</small>
                                    </div>
                                </div>
                                <div className="one-third">
                                    <ul className="immediate-assessment">
                                        <li>Provide original to foster care worker.</li>
                                        <li>Copy to Resource Family.</li>
                                        <li>Copy to oversight agency for the child.</li>
                                    </ul>
                                </div>
                            </div> */}


                            <div className="action-btn">
                                <Link to="#" className="red-btn">Close</Link>
                                <Link to="#" className="blue-btn">Submit</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </div >
    {/* <Footer /> */ }
    </div >

));
// }


// export default ImmidiateAssesmentFormDownload;



const ImmidiateAssesmentFormDownload = () => {
    const componentRef = useRef();
    return (
        <div >
            <ReactToPrint
                trigger={() =>
                    <button

                        class="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                    >
                        <img src={download}></img>
                    </button>
                }
                content={() => componentRef.current}
            />
            <div className="hiddenPrintable">
                <ComponentToPrint ref={componentRef} />
            </div>
        </div>
    );
}

export default ImmidiateAssesmentFormDownload;