import Footer from "../../Footer";
import Header from "../../Header";
import levelofcareinner from "../../../images/assets/myinfo/levelofcareinner.svg";
import SideBar from "../../sidebar/sidebar";
import "../LevelOfCare/LevelofCare.scss";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import locpdf from "../../../documents/loc.pdf";
import focl from "../../../documents/Foster_Care_Levels_of_Service.pdf";
import LoadSpinner from "../../Loader/LoadSpinner";
import plus from '../../../images/assets/cm/plus.svg';

import { post } from "../../../utils/common";

function LevelOfCare() {
  const [data, setData] = useState([]);
  const [updateDiv, showUpdateSection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showUpdateDiv = () => {
    showUpdateSection(true);
  };
  const hideUpdateDiv = () => {
    showUpdateSection(false);
  };

  const onPdfClick = () => {
    // const newWindow = window.open();
    window.location.href = focl;
  };
  const fetchData = async () => {
    const id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/levelofcare/index", { id });
    console.log(data);
    // if (!data.ok) {
    //     return;
    // }
    // // data = await data.json();
    // console.log(data);
    setData(data.data.data);
  };

  const [updateData, setUpdateData] = useState([]);

  const onupdateClick = async () => {
    //setIsLoading(true);

    let username = sessionStorage.getItem("username");
    showUpdateSection(false)
    setUpdateData([])

    await post("/api/levelofcare/update-level-care", { username, updateData });
    alert("Request Update  Emailed Successfully !");
   // window.location.reload(false);
    // alert("Submitted Successfully");
    setIsLoading(false);
    window.location.reload(false);
  };

  console.log(data);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="main">
      <Header />
      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <SideBar />
              <div className="tab-detail">
                <div className="tab-detail-top">
                  <div className="m-profile">
                    <img src={levelofcareinner} alt="" />
                    <div className="pagesubtitle">
                      Level of Care
                      <p>
                        Below are the levels of care that you are willing and
                        able to care for.
                      </p>
                    </div>
                  </div>
                  <Link to="#" onClick={showUpdateDiv} className="request-btn"><img src={plus} style={{"height": "18px"}} alt="#"/>
                    request update
                  </Link>
                </div>
                {isLoading ? <LoadSpinner /> : ""}
                <div className="tab-detail-bottom">
                  <form className="levelofcare">
                    {updateDiv ? (
                      <div className="req-update-textarea">
                        <label>
                          <span>
                            <b>Request Update-</b> Level of Care
                            <br></br>
                            <br></br>
                            <b> Description for Update:</b>
                          </span>
                        </label>
                        <textarea
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                        <div className="req-update-action">
                          <Link
                            to="#"
                            onClick={hideUpdateDiv}
                            className="close"
                          >
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={onupdateClick}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="checkbox-section oe-design">
                      <div className="form-row">
                        {data.length > 0 ? (
                          data.map((levelofcare, index) => (
                            <div className="one-fourth">
                              <span className="checkbox-design">
                                {levelofcare.CheckME == true ? (
                                  <input
                                    type="checkbox"
                                    id="vehicle2"
                                    name="vehicle1"
                                    value="Bike"
                                    checked="true"
                                    //   {...levelofcare.checkMe == true?checked:unchecked}
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    id="vehicle2"
                                    name="vehicle1"
                                    value="Bike"
                                    
                                  />
                                )}
                                {/* <input
                                  type="checkbox"
                                  id="vehicle2"
                                  name="vehicle1"
                                  value="Bike"
                                  checked="true"
                                  //   {...levelofcare.checkMe == true?checked:unchecked}
                                /> */}
                                <label for="vehicle1">
                                  {levelofcare.FCHLevelOfCare}
                                </label>
                              </span>
                            </div>
                          ))
                        ) : (
                          <div className="one-fourth">
                            <span className="checkbox-design">
                              <input
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                value="Bike"
                              />
                              <label for="vehicle1">
                                No Data Available For this Provider
                              </label>
                            </span>
                            {/* {/* </div>} */}

                            {/* <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                        <label for="vehicle1">Respite</label>
                                                    </span>
                                                </div> */}
                            {/* <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                        <label for="vehicle1">Respite( weekend only) </label>
                                                    </span>
                                                </div> */}
                            {/* <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                        <label for="vehicle1">Emergency</label>
                                                    </span>
                                                </div> */}
                            {/* <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                        <label for="vehicle1">Basic</label>
                                                    </span>
                                                </div> */}
                            {/* <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                        <label for="vehicle1">Moderate</label>
                                                    </span>
                                                </div> */}
                            {/* <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                        <label for="vehicle1">Intensive</label>
                                                    </span>
                                                </div> */}
                            {/* <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                        <label for="vehicle1">Treatment Transition (Intensive plus)</label>
                                                    </span>
                                                </div> */}
                            {/* <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                        <label for="vehicle1">Informal Care</label>
                                                    </span>
                                                </div> */}
                            {/* <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                        <label for="vehicle1">Pre-Adoptive</label>
                                                    </span>
                                                </div> */}
                            {/* <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                        <label for="vehicle1">Specific Children</label>
                                                    </span>
                                                </div>
                                                <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                        <label for="vehicle1">HCBS</label>
                                                    </span>
                                                </div> */}
                            {/* <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                        <label for="vehicle1">JJFC</label>
                                                    </span>
                                                </div> */}
                          </div>
                        )}
                      </div>

                      <Link
                        to="#"
                        onClick={() => window.open(focl, "_blank")}
                        className="yellow-btn"
                      >
                        Level of Care Definition
                      </Link>
                      {/* {() => window.open("https://stfranciscommunity-my.sharepoint.com/:b:/g/personal/pallavi_c_st-francis_org/Ef6T1CU3pSNNsTTeXkYRSW4Bm3MBHImO212-E_W3mt29KA", "_blank")} className="yellow-btn">Level of Care Definition</Link> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LevelOfCare;
