import criticalform from "../../../images/assets/formimages/criticalform.svg";
import immediateform from "../../../images/assets/formimages/immediateform.svg";
import lodgeform from "../../../images/assets/formimages/lodgeform.svg";
import milageform from "../../../images/assets/formimages/milageform.svg";
import powerform from "../../../images/assets/formimages/powerform.svg";


function FormSideBar() {
  
  const redirectPath = (form) =>{
    localStorage.setItem('fromButtonClick', false);
    if(form == "critical")
    window.location.href = "/form/criticalunusualincidentform";
    else if(form == "immidiate")
    window.location.href = "/form/immidiateassesmentform";
    else if(form == "lodes")
    window.location.href = "/form/logofdailyevents";
    else if(form == "milagereimbursment")
    window.location.href = "/forms/milagereimbursment";
    
  }
  
  return (
    <div className="sidebar">
      <ul>
        <li>
          <a href="#"
          onClick={() => redirectPath("critical")}>
            <img src={criticalform} alt="" />
            <span>Critical/Unusual Incidents</span>
          </a>
        </li>
        <li>
          <a href="#" onClick={() => redirectPath("immidiate")}>
            <img src={immediateform} alt="" / >
            <span>Immediate Assessment</span>
          </a>
        </li>
        <li>
          <a href="#" onClick={() => redirectPath("lodes")}>
            <img src={lodgeform} alt="" />
            <span>Log of Daily Event</span>
          </a>
        </li>
        <li>
          <a href="#"onClick={() => redirectPath("milagereimbursment")}>
            <img src={milageform} alt="" />
            <span>Mileage Reimbursement</span>
          </a>
        </li>
        <li>
          <a href="/forms/powerofyou">
            <img src={powerform} alt="" />
            <span>Power Of You</span>
          </a>
        </li>

        {sessionStorage.getItem("RoleInfo") == "Admin" ||
        sessionStorage.getItem("RoleInfo") == "FCHAdminStaff" ||
        sessionStorage.getItem("RoleInfo") == "ITStaff" ? (
          <li aria-disabled="true">
            <a href="/forms/downlodableformmanage">
              <img src={powerform} alt="" />
              <span>Manage Downloadable Forms</span>
            </a>
          </li>
        ) : (
          ""
        )}
      </ul>
    </div>
  )
}

export default FormSideBar;