import '../../Forms/CriticalUnusualIncidentForm/CriticalUnusualIncidentForm.scss'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { post } from '../../../utils/common';
import { useHistory } from 'react-router';
import download from '../../../images/assets/myinfo/download.svg';
import pouinner from '../../../images/assets/formimages/pouinner.svg';


import React, { useRef } from "react";
import ReactToPrint from "react-to-print";

const ComponentToPrint = React.forwardRef((props, ref) => (




    <div className="main" ref={ref}>
        <div className="main-content" >
            <div className="pagewithsidebar">
                <div className="container">
                    <div className="pagewithsidebar-inner">


                        <div className="homelink">
                            <Link to="/">Home</Link>

                        </div>

                        <div className="tab-detail Critical">
                            <div className="tab-detail-top">
                                <div className="Critical-unusual">
                                    <img src={pouinner} alt="" />
                                    Power of You - SFM FOster Care Homes
                                </div>
                            </div>
                            <div className="tab-detail-bottom">
                                <div className="form-with-sidebar">
                                    <form>
                                        <h6 className="small-text"> Family Contact</h6>
                                        <div className="form-row dandt mt-0">
                                            <div className="one-half">
                                                <label for="birthday">First Name:</label>
                                                <input type="text" />
                                            </div>
                                            <div className="one-half">
                                                <label for="birthday">Last Name:</label>
                                                <input type="text" />
                                            </div>
                                        </div>
                                        <div className="form-row dandt mt-0">
                                            <div className="one-half">
                                                <label for="birthday">Contact Nnumber:</label>
                                                <input type="text" />
                                            </div>
                                            <div className="one-half">
                                                <label for="birthday">Email Address:</label>
                                                <input type="email" />
                                            </div>
                                        </div>
                                        <div className="form-row dandt">
                                            <div className="full-input">
                                                <label for="birthday">Address:</label>
                                                <input type="text" />
                                            </div>
                                        </div>
                                        <div className="form-row dandt mt-0 ai-c">
                                            <div className="one-third">
                                                <label for="birthday">City:</label>
                                                <input type="text" />
                                            </div>
                                            <div className="one-third">
                                                <label for="birthday">State:</label>
                                                <select>
                                                    <option value="0">Select:</option>

                                                    <option value=""></option>

                                                </select>
                                            </div>
                                            <div className="one-third">
                                                <label for="birthday">Zip:</label>
                                                <input type="text" />
                                            </div>
                                        </div>
                                        <div className="req-update-textarea pt-0 b-0">
                                            <label><span><b>Comment:</b></span></label>
                                            <textarea className="m-0"></textarea>
                                        </div>
                                        <h6 className="small-text">  Referral Contact</h6>

                                        <div className="form-row dandt mt-0">
                                            <div className="one-half">
                                                <label for="birthday">First Name:</label>
                                                <input type="text" />
                                            </div>
                                            <div className="one-half">
                                                <label for="birthday">Last Name:</label>
                                                <input type="text" />
                                            </div>
                                        </div>
                                        <div className="form-row dandt mt-0">
                                            <div className="one-half">
                                                <label for="birthday">Contact Nnumber:</label>
                                                <input type="text" />
                                            </div>
                                            <div className="one-half">
                                                <label for="birthday">Email Address:</label>
                                                <input type="email" />
                                            </div>
                                        </div>

                                        <div className="req-update-textarea pt-0 mt-25 b-0">
                                            <div className="req-update-action">
                                                <Link href="#" className="close">Close</Link>
                                                <Link href="#" className="normal">Submit</Link>
                                            </div>
                                        </div>

                                    </form>
                                    <div className="form-note">
                                        <div className="form-grey">
                                            <ul>
                                                <li>The<b> Power Of You</b> program honors your dedication and important role you play in recruiting new foster families by providing a$250 bonus when you refer a new foster family to Saint Francis Community services. You'll receive the bonus when the family becomes licensed and takes their first placement. You can even receive an additional $100 if that first placement is a teen, age 13 or older. Respite, Emergency, or traditional placements all count</li>
                                                {/* <li>* Future dates will not be allowed.</li>
                                                    <li>* You will only be allowed to select a child if we have them listed as being in your home on the selected date.</li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

));

const PowerOfYouFormPrint = () => {
    const componentRef = useRef();
    return (
        <div >
            <ReactToPrint
                trigger={() =>

                    <Link to="#">Print Form</Link>
                }
                content={() => componentRef.current}
            />
            <div className="hiddenPrintable">
                <ComponentToPrint ref={componentRef} />
            </div>
        </div>
    );
}

export default PowerOfYouFormPrint;
