import Footer from "../Footer";
import Header from "../Header";
import "../myprofile/myprofile.scss";
import mprofile from "../../images/assets/images/mprofile.svg";
import SideBar from "../sidebar/sidebar";
import HeaderTopDetail from "../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { isEmpty } from "class-validator";
import { post, put } from "../../utils/common";
import LoadSpinner from "../Loader/LoadSpinner";
import plus from '../../images/assets/cm/plus.svg';



function Myprofile() {
  const [isLoading, setIsLoading] = useState(false);

  const [addAddressDivShow, setAddAddressShowDiv] = useState(false);
  const [updateDiv, showUpdateDiv] = useState(false);

  const showAddAddress = () => {
    setAddAddressShowDiv(true);
  };
  const showUpdateDivision = () => {
    showUpdateDiv(true);
  };
  const hideAddAddress = () => {
    setAddAddressShowDiv(false);
  };

  const hideUpdateDivision = () => {
    showUpdateDiv(false);
  };

  const [LocationData, setLocationData] = useState([]);
  const [ProviderData, setProviderData] = useState([]);
  const [LicenseData, setLicensedata] = useState([]);
  const [LicenseRenewalData, setLicenseRenewalData] = useState([]);
  const [updateProviderData, setUpdateProviderData] = useState([]);
  const[AddressData,setAddressData] = useState([])

  const [ProviderResourceWorkerData, setProviderResourceWorkerData] = useState(
    []
  );

  // const [ProfileData,setProfileData] = useState([]);

  const [showTopHeaderDetail, setShowTopHeaderDetail] = useState(false);
  const fetchData = async () => {
    sessionStorage.removeItem("showTOPHEADERDATA");
    let providerId = sessionStorage.getItem("providerSessionId");

    let allProviderDataByProviderId = await post(
      "/api/provider/getallproviderdatabyproviderid",
      { providerId }
    );
    console.log(allProviderDataByProviderId);
    if (allProviderDataByProviderId.data.ProviderLocationData.length !== 0) {
      setLocationData(allProviderDataByProviderId.data.ProviderLocationData[0]);
    }

    // if (allProviderDataByProviderId.data.AddressData.length !== 0) {
    //   setAddressData(allProviderDataByProviderId.data.AddressData[0]);
    // }

   

    

    if (allProviderDataByProviderId.data.ProviderData.length !== 0) {
      setProviderData(allProviderDataByProviderId.data.ProviderData[0]);
    }

    if (allProviderDataByProviderId.data.ProviderLicenseData.length !== 0) {
      setLicensedata(allProviderDataByProviderId.data.ProviderLicenseData[0]);
    }
    if (
      allProviderDataByProviderId.data.ProviderLicenseRenewalData.length !== 0
    ) {
      setLicenseRenewalData(
        allProviderDataByProviderId.data.ProviderLicenseRenewalData[0]
      );
    }
    if (
      allProviderDataByProviderId.data.ProviderResourceWorkerData.length !== 0
    ) {
      setProviderResourceWorkerData(
        allProviderDataByProviderId.data.ProviderResourceWorkerData[0]
      );
    }

    if (allProviderDataByProviderId.data.ProviderLocationData.length !== 0) {
      sessionStorage.removeItem("LocationData");
      sessionStorage.setItem(
        "LocationData",
        JSON.stringify(allProviderDataByProviderId.data.ProviderLocationData[0])
      );
    }

    if (allProviderDataByProviderId.data.ProviderLocationData.length !== 0) {
      sessionStorage.setItem(
        "City",
        JSON.stringify(allProviderDataByProviderId.data.ProviderLocationData[0])
      );
    }

    if (allProviderDataByProviderId.data.AddressData.length !== 0) {
      sessionStorage.removeItem("AddressData");
      sessionStorage.setItem(
        "AddressData",
        JSON.stringify(
          allProviderDataByProviderId.data.AddressData[0]
        )
      );
      setAddressData(allProviderDataByProviderId.data.AddressData[0]);
    }
    if (
      allProviderDataByProviderId.data.ProviderResourceWorkerData.length !== 0
    ) {
      sessionStorage.removeItem("ProviderResourceWorkerData");
      sessionStorage.setItem(
        "ProviderResourceWorkerData",
        JSON.stringify(
          allProviderDataByProviderId.data.ProviderResourceWorkerData[0]
        )
      );
    }
    if (allProviderDataByProviderId.data.ProviderData.length !== 0) {
      sessionStorage.removeItem("ProviderData");
      sessionStorage.setItem(
        "ProviderData",
        JSON.stringify(allProviderDataByProviderId.data.ProviderData[0])
      );
    }
    setShowTopHeaderDetail(true);
    sessionStorage.setItem("showTOPHEADERDATA", true);
  };

  // const submitUpdate = () => {
  //     setIsLoading(true);
  //     alert("Submitted ");
  //     window.location.reload(false)
  //     setIsLoading(false)

  // }

  const [updateData, setUpdateData] = useState([]);

  const onSubmitButtonClick = async () => {
    //setIsLoading(true)
    let username = sessionStorage.getItem("username");
    await post("/api/provider/update-profile", { username, updateData });
    alert("Request Update  Emailed Successfully !");
   // setIsLoading(false)
   // window.location.reload(false);



    // let providerId = sessionStorage.getItem('providerSessionId');

    // let UpdatedData = await put('/api/provider/updateproviderprofile/' + providerId, { ProviderData });
  };

  useEffect(() => {
    // if(sessionStorage.getItem('Password') == 'Welcome@123' ||sessionStorage.getItem('Password') == 'Setup@123' ){
    //   window.location = "/enforce-password-change";

    // }
    fetchData();
    var x = sessionStorage.getItem("showTOPHEADERDATA");
    if (x) {
      setShowTopHeaderDetail(true);
    }
    
  }, []);

  return (
    <div className="main">
      <Header />
      {/* [LocationData,ProviderResourceWorkerData,ProviderData] */}

      <div className="main-content">
        {showTopHeaderDetail ? <HeaderTopDetail /> : <LoadSpinner />}
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <SideBar />
              <div className="tab-detail pl">
                <div className="tab-detail-top">
                  <div className="m-profile">
                    <img src={mprofile} alt="" />
                    my profile
                  </div>

                  <Link to="#" onClick={showUpdateDivision}><img src={plus} style={{"height": "18px"}} alt="#"/>
                    Request Update
                  </Link>
                </div>

                {/* {isLoading ? (<LoadSpinner />) : ''} */}
                <div className="tab-detail-bottom">
                  {updateDiv ? (
                    <div className="req-update-textarea">
                      <label>
                        <span>
                          <b>Request Update for</b> - Profile
                          <br></br>
                          <br></br>
                          <b> Description for Update:</b>
                        </span>
                      </label>
                      <textarea
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            description: e.target.value,
                          })
                        }
                      ></textarea>
                      <div className="req-update-action">
                        <Link
                          to="#"
                          onClick={hideUpdateDivision}
                          className="close"
                        >
                          close
                        </Link>
                        <Link
                          to="#"
                          onClick={onSubmitButtonClick}
                          className="normal"
                        >
                          Submit
                        </Link>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <br></br>
                  {/* {isLoading ? <LoadSpinner /> : ""} */}
                  {/* {updateDiv ? '' : */}
                  <form className="myprofile-form">
                    <div className="form-row">
                      <div className="ot-field">
                        <label className="form-label">Provider Name:</label>
                        {/* {JSON.parse(sessionStorage.getItem("ProviderData")).ProviderName} */}
                        {/* <input type="text" className="form-control" value={ProviderData.ProviderName ? ProviderData.ProviderName : 'null'}
                                                    // onChange={e => setProviderData({ ...ProviderData, ProviderName: e.target.value })}
                                                    id="exampleFormControlInput1" readonly /> */}
                        <input
                          type="text"
                          className="form-control"
                          value={
                            
                            sessionStorage.getItem("ProviderData")
                              ? JSON.parse(
                                sessionStorage.getItem("ProviderData")
                              ).ProviderName
                              : "Null"
                          }
                          // onChange={e => setProviderData({ ...ProviderData, ProviderName: e.target.value })}
                          id="exampleFormControlInput1"
                          readOnly
                        />
                      </div>
                      <div className="ot-field">
                        <label className="form-label">Telephone Number:</label>
                        {/* {JSON.parse(sessionStorage.getItem("ProviderData"))} */}
                        {/* <input type="text"  value={ProviderData.Phone ? ProviderData.Phone : 'null'}
                                                    // onChange={e => setProviderData({ ...ProviderData, Phone: e.target.value })}
                                                    className="form-control" id="exampleFormControlInput1"  readonly/> */}
                        <input
                          type="text"
                          value={
                            sessionStorage.getItem("LocationData")
                              ? JSON.parse(
                                sessionStorage.getItem("LocationData")
                              ).Phone
                              : "Null"
                          }
                          // onChange={e => setProviderData({ ...ProviderData, Phone: e.target.value })}
                          className="form-control"
                          id="exampleFormControlInput1"
                          readonly
                        />
                      </div>
                      <div className="ot-field">
                        <label className="form-label">Email Address:</label>
                        {/* <input type="email"  value={ProviderData.Email ? ProviderData.Email : 'null'}
                                                 // onChange={e => setProviderData({ ...ProviderData, Email: e.target.value })}
                                                  className="form-control" id="exampleFormControlInput1"readonly /> */}

                        <input
                          type="email"
                          value={
                            sessionStorage.getItem("ProviderData")
                              ? JSON.parse(
                                sessionStorage.getItem("ProviderData")
                              ).Email
                              : "Null"
                          }
                          // onChange={e => setProviderData({ ...ProviderData, Email: e.target.value })}
                          className="form-control"
                          id="exampleFormControlInput1"
                          readonly
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="ot-field">
                        <label className="form-label">License Number:</label>
                        <input
                          type="text"
                          value={
                            LicenseData.LicenseNo
                              ? LicenseData.LicenseNo
                              : "null"
                          }
                          //onChange={e => setProviderData({ ...ProviderData, LicenseNo: e.target.value })}
                          className="form-control"
                          id="exampleFormControlInput1"
                          readonly
                        />
                      </div>
                      <div classname="ot-field">
                        <label className="form-label">License Renewal Date:</label>
                        <input
                          type="text"
                          value={
                            LicenseRenewalData.Recent_Renewal_EndDate
                              ? new Date(
                                LicenseRenewalData.Recent_Renewal_EndDate
                              ).toLocaleDateString()
                              : "null"
                          }
                          // onChange={e => setProviderData({ ...ProviderData, Recent_Renewal_EndDate: e.target.value })}
                          className="form-control"
                          id="exampleFormControlInput1"
                          readonly
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="ot-field">
                        <label className="form-label">Service Address:</label>
                        <input
                          type="text"
                          value={
                            AddressData.Service_Address
                              ? AddressData.Service_Address
                              : "null"
                          }
                          // onChange={e => setProviderData({ ...ProviderData, Address1: e.target.value })}
                          className="form-control"
                          id="exampleFormControlInput1"
                          readonly
                        />
                      </div>
                      <div className="ot-field">
                        <label className="form-label">Billing Address:</label>
                        <input
                        value={
                          AddressData.Billing_Address
                            ? AddressData.Billing_Address
                            : " "
                        }
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      {/* <div className="ot-field">
                        <label className="form-label">State:</label>
                        <input
                          type="text"
                          value={
                            ProviderData.State ? ProviderData.State : "null"
                          }
                          //onChange={e => setProviderData({ ...ProviderData, State: e.target.value })}
                          className="form-control"
                          id="exampleFormControlInput1"
                          readonly
                        />
                      </div> */}
                      {/* <div className="ot-field">
                        <label className="form-label">Zip Code:</label>
                        <input
                          type="text"
                          readonly
                          value={
                            ProviderData.Zipcode ? ProviderData.Zipcode : "null"
                          }
                          //onChange={e => setProviderData({ ...ProviderData, Zipcode: e.target.value })}
                          className="form-control"
                          id="exampleFormControlInput1"
                        />
                      </div> */}
                    </div>
                  </form>
                  {/* } */}

                  {/* {updateDiv ? 
                                    
                                    <form className="myprofile-form">
                                        <div className="form-row">
                                            <div className="ot-field">
                                                <label className="form-label">Provider Name:</label>
                                                <input type="text" onChange={e => setProviderData({ ...ProviderData, ProviderName: e.target.value })} value={ProviderData.ProviderName } className="form-control" id="exampleFormControlInput1" />
                                            </div>
                                            <div className="ot-field">
                                                <label className="form-label">Telephone Number:</label>
                                                <input type="text" onChange={e => setProviderData({ ...ProviderData, Phone: e.target.value })} value={ProviderData.Phone } className="form-control" id="exampleFormControlInput1" />
                                            </div>
                                            <div className="ot-field">
                                                <label className="form-label">Email Address:</label>
                                                <input type="email" onChange={e => setProviderData({ ...ProviderData, Email: e.target.value })} value={ProviderData.Email } className="form-control" id="exampleFormControlInput1" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="ot-field">
                                                <label className="form-label">License Number:</label>
                                                <input type="text" onChange={e => setLicensedata({ ...LicenseData, LicenseNo: e.target.value })} value={LicenseData.LicenseNo } className="form-control" id="exampleFormControlInput1" />
                                            </div>
                                            <div className="ot-field">
                                                <label className="form-label">License Renewal Date:</label>
                                                <input type="text" onChange={e => setLicenseRenewalData({ ...LicenseRenewalData, Recent_Renewal_EndDate: e.target.value })} value={LicenseRenewalData.Recent_Renewal_EndDate } className="form-control" id="exampleFormControlInput1" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="ot-field">
                                                <label className="form-label">Service Address:</label>
                                                <input type="text" onChange={e => setProviderData({ ...ProviderData, Address1: e.target.value })} value={ProviderData.Address1 } className="form-control" id="exampleFormControlInput1" />
                                            </div>
                                            <div className="ot-field">
                                                <label className="form-label">Billing Address:</label>
                                                <input type="text" onChange={e => setProviderData({ ...ProviderData, Address2: e.target.value })} value={ProviderData.Address2 } className="form-control" id="exampleFormControlInput1" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="ot-field">
                                                <label className="form-label">State:</label>
                                                <input type="text" onChange={e => setProviderData({ ...ProviderData, State: e.target.value })} value={ProviderData.State } className="form-control" id="exampleFormControlInput1" />
                                            </div>
                                            <div className="ot-field">
                                                <label className="form-label">Zip Code:</label>
                                                <input type="text" onChange={e => setProviderData({ ...ProviderData, Zipcode: e.target.value })} value={ProviderData.Zipcode } className="form-control" id="exampleFormControlInput1" />
                                            </div>
                                        </div>

                                        {updateDiv ? <div className="action-btn">
                                            <Link to="#" onClick={hideUpdateDivision} className="close-btn">close</Link>
                                            <Link to="#" onClick={onSubmitButtonClick} className="submit-btn">submit</Link>
                                            <Link to="#" onClick={showAddAddress} className="add-address-btn">add address</Link>
                                        </div>
                                            : ''} */}

                  {/* {addAddressDivShow ?
                                            <div>
                                                <div className="form-row">
                                                    <div className="ot-field">
                                                        <label className="form-label">Service Address:</label>
                                                        <input type="text" className="form-control"   onChange={e => setProviderData({ ...ProviderData, Address1: e.target.value })} value={updateProviderData.Address1} id="exampleFormControlInput1" />
                                                    </div>
                                                    <div className="ot-field">
                                                        <label className="form-label">Billing Address:</label>
                                                        <input type="text" className="form-control"  value={updateProviderData.Address2} onChange={e => setProviderData({ ...ProviderData, Address2: e.target.value })} id="exampleFormControlInput1" />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="ot-field">
                                                        <label className="form-label">State:</label>
                                                        <input type="text" className="form-control"  value={updateProviderData.State} onChange={e => setProviderData({ ...ProviderData, State: e.target.value })} id="exampleFormControlInput1" />
                                                    </div>
                                                    <div className="ot-field">
                                                        <label className="form-label">Zip Code:</label>
                                                        <input type="text" className="form-control"  value={updateProviderData.Zipcode} onChange={e => setProviderData({ ...ProviderData, Zipcode: e.target.value })} id="exampleFormControlInput1" />
                                                    </div>
                                                </div>
                                            </div>
                                            : ''} */}
                  {/* {addAddressDivShow ?
                                            <div className="action-btn">
                                                <Link to="#" onClick={hideAddAddress} className="close-btn">close</Link>
                                                <Link to="#" onClick={onSubmitButtonClick} className="submit-btn">submit</Link>
                                                <Link to="#" onClick={showAddAddress} className="add-address-btn">add address</Link>
                                            </div>
                                            : ''} */}
                  {/* </form>

                                        : ''} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Myprofile;
