import Footer from "../../Footer";
import Header from "../../Header";
import "../CriticalUnusualIncidentForm/CriticalUnusualIncidentForm.scss";
import "../ImmidiateAssesmentForm/ImmidiateAssesmentForm.scss";
import lodinner from "../../../images/assets/formimages/lodinner.svg";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import FormSideBar from "../FormSidebar/FormSidebar";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { post } from "../../../utils/common";
import { useEffect, useState } from "react";

function LogOfDailyEvents() {
  const history = useHistory();

  const [AddFormData, setAddFormData] = useState([]);

  const onSubmitButtonClick = async (status) => {
    if(AddFormData.LodeDate == undefined || AddFormData.LodeDate == undefined ||
      AddFormData.ProviderMemberID == undefined || AddFormData.ProviderMemberID == undefined ||
      AddFormData.ClientID == undefined || AddFormData.ClientID == undefined  ){
        alert("Please enter the Mandatory fields");
        return;
      }
  let Status;
    //e.preventDefault();
    if(status == "fromDraft")
    AddFormData.Status = "Saved as Draft";
    if(status == "fromSubmit")
    AddFormData.Status = "Completed";


    //  @MedicalAppointment varchar(500) = NULL, Y
    //     @SchoolAppointment varchar(500) = NULL, Y
    //     @CasePlanAppointment varchar(500) = NULL, Y
    //     @PsychiatricAppointment varchar(500) = NULL,
    //     @DentistAppointment varchar(500) = NULL,
    //     @CourtAppointment varchar(500) = NULL,
    //     @OtherAppointment varchar(500) = NULL,
    //     @VisionAppointment varchar(500) = NULL,
    //     @HomeVisitDate varchar(250) = NULL,
    //     @HomeVisitNameRelationShipOfContact varchar(100) = NULL,
    //     @PhoneCallDate varchar(250) = NULL,
    //     @PhoneCallNameRelationShipOfContact varchar(100) = NULL,
    //     @OtherDate varchar(250) = NULL,
    //     @OtherNameRelationShipOfContact varchar(100) = NULL,y
    //     @Notes varchar(max) = NULL, y
    // if(!AddFormData.SchoolAppointment || !AddFormData.CasePlanAppointment || !AddFormData.PsychiatricAppointment || !AddFormData.DentistAppointment
    //   || !AddFormData.CourtAppointment || !AddFormData.OtherAppointment || !AddFormData.VisionAppointment || !AddFormData.HomeVisitDate
    //   || !AddFormData.HomeVisitNameRelationShipOfContact || !AddFormData.PhoneCallDate || !AddFormData.PhoneCallNameRelationShipOfContact 
    //   ||!AddFormData.OtherDate || !AddFormData.OtherNameRelationShipOfContact || !AddFormData.Notes){
    //     alert("Please Fill All the fields ");
    //     window.location.reload(false);
    //     // return false
    //   }

    AddFormData.Notes = (AddFormData.Notes).replace(/'/g,"''");
    let EnteredBy = sessionStorage.getItem('username');
    let ProviderID = sessionStorage.getItem('providerSessionId');
    await post('/api/lodes/insertlodes', { AddFormData,ProviderID,EnteredBy,Status });
    alert("Record Added successfully !");
    let fromButtonClick= localStorage.getItem("fromButtonClick");
    if(fromButtonClick === "true")
    window.location.href ="/lodes";
    history.push("/form/formindex");
  };


  // const [saveForDraft, setsaveForDraft] = useState(false);
  //   // SaveforDraft

  //   const saveForDraft = () =>{
      
  //   }


  const closeClicked = () => {
    history.push("/lodes");
  };



  const [selectChildData, setSelectChildData] = useState([]);
  const [personSigningTheForm, setSigningTheForm] = useState([]);

  const fetchFormDataLoad = async () => {
    let providerid = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/immidiateassesment/getformdataload", {
      providerid,
    });

    setSelectChildData(data.data.data.recordsets[0]);

    setSigningTheForm(data.data.data.recordsets[1]);
  };

  useEffect(() => {
    fetchFormDataLoad();
  }, []);

  return (
    <div className="main">
      <Header />

      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <FormSideBar />
              <div className="tab-detail Critical">
                <div className="tab-detail-top">
                  <div className="Critical-unusual">
                    <img src={lodinner} alt="" />
                    LoG of Daily Event
                  </div>
                </div>
                <div className="tab-detail-bottom">
                  <form>
                    <div className="form-row dandt">
                      <div className="one-third">
                        <label for="birthday">Date:<span style={{color:"red"}}>*</span></label>
                        <input
                          type="date"
                          onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              LodeDate: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="one-third">
                        <label for="birthday">Person Completing Lode:<span style={{color:"red"}}>*</span></label>
                        <select
                          onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              ProviderMemberID: e.target.value,
                            })
                          }
                        >
                          <option value="0">Select:</option>

                          {personSigningTheForm.map((person) => (
                            <option value={person.ProviderMemberID}>
                              {person.ProviderName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="one-third">
                        <label for="appt">Youth:<span style={{color:"red"}}>*</span></label>
                        <select
                          onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              ClientID: e.target.value,
                            })
                          }
                        >
                          <option value="0">Select:</option>

                          {selectChildData.map((child) => (
                            <option value={child.ClientID}>
                              {child.ClientName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="imp-note mt-25">
                      <span>TIPS FOR COMPLETING OBSERVATIONS</span>
                      <ol>
                        <li>
                          Please be factual. Please share positives and
                          negatives.
                        </li>
                        <li>
                          Highlight progress of case plan goals/objectives.
                        </li>
                        <li>
                          Your lode is an official record of youth's care
                          provision.
                        </li>
                        <li>
                          Please return to your worker at home visit, email or
                          SFM Families Website.
                        </li>
                      </ol>
                    </div>

                    <div className="form-row fr-w mt-25">
                      <h6 className="small-text">Appointments</h6>
                      <label className="p-0" for="birthday">
                        Please address appointments in your daily observation
                        log below.
                      </label>
                    </div>
                    <div className="form-row dandt fr-w mt-25">
                      <div className="one-half">
                        <label for="birthday">Medical:</label>
                        <input
                          type="text"
                          onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              MedicalAppointment: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="one-half">
                        <label for="birthday">School Meetings:</label>
                        <input
                          type="text"
                          onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              SchoolAppointment: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="one-half">
                        <label for="birthday">Case Plan:</label>
                        <input
                          type="text"
                          onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              CasePlanAppointment: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="one-half">
                        <label for="birthday">Mental Health:</label>
                        <input
                          type="text"
                          onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              PsychiatricAppointment: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="one-half">
                        <label for="birthday">Dentist:</label>
                        <input
                          type="text"
                          onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              DentistAppointment: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="one-half">
                        <label for="birthday">Court:</label>
                        <input
                          type="text"
                          onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              CourtAppointment: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="one-half">
                        <label for="birthday">Vision:</label>
                        <input
                          type="text"
                          onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              VisionAppointment: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="one-half">
                        <label for="birthday">Other:</label>
                        <input
                          type="text"
                          onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              OtherAppointment: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="form-grey mt-25">
                      <h6 className="small-text">BIRTH FAMILY CONTACT</h6>
                      <div className="form-row">
                        <div className="one-third">
                          <label>Date and Time (Home Visit):</label>
                          <input
                            type="text"
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                HomeVisitDate: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="one-third">
                          <label>Date and Time (Phone Calls):</label>
                          <input
                            type="text"
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                PhoneCallDate: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="one-third">
                          <label>Date and Time (Other):</label>
                          <input
                            type="text"
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                OtherDate: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="one-third">
                          <label>Name / Relationship:</label>
                          <input
                            type="text"
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                HomeVisitNameRelationShipOfContact:
                                  e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="one-third">
                          <label>Name / Relationship:</label>
                          <input
                            type="text"
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                PhoneCallNameRelationShipOfContact:
                                  e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="one-third">
                          <label>Name / Relationship:</label>
                          <input
                            type="text"
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                OtherNameRelationShipOfContact: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="req-update-textarea pt-0">
                      <label>
                        <span>
                          <b>Narrative</b>
                        </span>
                      </label>
                      <textarea
                        onChange={(e) =>
                          setAddFormData({
                            ...AddFormData,
                            Notes: e.target.value,
                          })
                        }
                      ></textarea>
                      <div className="req-update-action">
                        <Link to="#" onClick={closeClicked} className="close">
                          Close
                        </Link>
                        <Link
                          to="#"
                          onClick={ ()=>onSubmitButtonClick("fromDraft")}
                          className="normal"
                        >
                          Save as Draft
                        </Link>
                        <Link
                          to="#"
                          onClick={ ()=>onSubmitButtonClick("fromSubmit")}
                          className="normal"
                        >
                          Submit
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LogOfDailyEvents;
