import Footer from '../Footer';
import Header from '../Header';
import accountmanage from '../accountmanage/accountmanage.scss'
import acicon from '../../images/assets/images/Account_Info.svg';
import acmanage from '../../images/assets/images/ManageInfo.svg';
import SideBar from '../sidebar/sidebar';
import HeaderTopDetail from '../CurrentChildren/HeaderTopDetail/HeaderTopDetail';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';



function AccountManage() {

    const [showChangeSecurityQuestion, setShowChangeSecurityQuestionDiv] = useState(false);
    const [showEmailChange, setShowEmailChangeDiv] = useState(false);
    const [showPasswordChange, setPasswordChangeDiv] = useState(true);


    const showSecurityQuestions = () => {
        setShowChangeSecurityQuestionDiv(true);
        setPasswordChangeDiv(false)
        setShowEmailChangeDiv(false);
    }

    const showEmailChangeOption = () => {
        setShowChangeSecurityQuestionDiv(false);
        setPasswordChangeDiv(false)
        setShowEmailChangeDiv(true);
    }

    const showPasswordChangeOption = () => {

        setShowChangeSecurityQuestionDiv(false);
        setPasswordChangeDiv(true)
        setShowEmailChangeDiv(false);
    }


    const [secQuestion, setSecQuestion] = useState([])


    const fetchData = async () => {
        let data = await fetch('/api/accountmanage/index');
        if (!data.ok) {
            return;
        }
        data = await data.json();
        console.log(data);
        setSecQuestion(data.data);
    };

 //TODO

 //onchange email address submit 
const [changeEmail,setChangeEmail] = useState([])
const onChangeEmail = () =>{

}



 //on change security question submit 
 const [changeSecQuestion,setChangeSecQuestion] = useState([]);

 const onChangeSecurityQuestion = () =>{
     
}



 //on change password submit 

 const [changePassword,setChangePassword] = useState([]);

 const onChangePasswordSubmit = () =>{
     const userId = sessionStorage.getItem("userId");
     alert("Submitted SuccessFully")

     //get user details by provider id 

     //match the old password 

     //match the new and confirm password 


    //submit the password and change 

 }





    useEffect(() => {
        fetchData();
    }, []);



    return (


        <div className="main">

            <Header />

            <div className="main-content" >
                <HeaderTopDetail />
                <div className="pagewithsidebar">
                    <div className="container">
                        <div className="pagewithsidebar-inner">


                            <SideBar />
                            <div className="tab-detail pl">
                                <div className="tab-detail-top">
                                    <div className="m-profile">
                                        <img src={acmanage} alt="" />
                                        Account Management
                                    </div>
                                </div>
                                <div className="tab-detail-bottom">
                                    <form className="myprofile-form">
                                        <div className="tabui">
                                            <ul>
                                                <li><Link to="#" className={showPasswordChange ? 'active' : ''} onClick={showPasswordChangeOption} >Change Password</Link></li>
                                                <li><Link to="#" className={showChangeSecurityQuestion ? 'active' : ''} onClick={showSecurityQuestions}>Change Security Question</Link></li>
                                                <li><Link to="#" className={showEmailChange ? 'active' : ''} onClick={showEmailChangeOption}>Change Email Address</Link></li>
                                            </ul>
                                        </div>

                                        <div className="form-tabs account-manage">
                                            {showPasswordChange ?
                                                <div className="form-tab-1 m-bottom">
                                                    <h6>Change Password</h6>
                                                    <div className="form-row">
                                                        <div class="ot-field">
                                                            <label class="form-label">Temporary/Previous Password:</label>
                                                            <input type="text" onChange={e => setChangePassword({ ...changePassword, old_password: e.target.value })} class="form-control" id="exampleFormControlInput1" />
                                                        </div>
                                                        <div class="ot-field">
                                                            <label class="form-label">New Password:</label>
                                                            <input type="text" onChange={e => setChangePassword({ ...changePassword, new_password: e.target.value })} class="form-control" id="exampleFormControlInput1" />
                                                        </div>
                                                        <div class="ot-field">
                                                            <label class="form-label">Confirm New Password:</label>
                                                            <input type="email" onChange={e => setChangePassword({ ...changePassword, confirm_password: e.target.value })} class="form-control" id="exampleFormControlInput1" />
                                                        </div>
                                                    </div>
                                                    <Link to="#" className="request-btn">Change Password</Link>

                                                    <div className="imp-note">
                                                        <span>Password Rules</span>
                                                        <ol>
                                                            <li>The password must be a minimum of 7 characters long.</li>
                                                            <li>The password must contain a minimum of one letter [A-z].</li>
                                                            <li>The password must contain a minimum of one number [0-9].</li>
                                                            <li>The new password must be a minimum of three characters different from the temporary/previous password.</li>
                                                        </ol>
                                                    </div>

                                                </div>

                                                : ''}
                                            {showChangeSecurityQuestion ?
                                                <div className="form-tab-1 m-bottom">
                                                    <h6>Change Security Question</h6>
                                                    <div className="form-row">
                                                        <div class="ot-field">
                                                            <label class="form-label">Password:</label>
                                                            <input type="text" class="form-control" id="exampleFormControlInput1" />
                                                        </div>
                                                        <div class="ot-field">
                                                            <label class="form-label">Security Question:</label>
                                                            <select name="cars" id="cars">
                                                                {secQuestion.map(question => (
                                                                    <option value={question.SecurityQuestionID}>{question.SecurityQuestion}</option>
                                                                ))}
                                                                {/* <option value="">What is your mother's middle name?
                                                                </option>
                                                                <option value="">What street did you grow up on?</option>
                                                                <option value="">What City were you born in?</option>
                                                                <option value="">What was your high school mascot?</option>
                                                                <option value="">What was your best friends name growing up?</option>
                                                                <option value="">What was your first pets name?</option>
                                                                <option value="">What is your favorite sports team?</option> */}
                                                            </select>
                                                        </div>
                                                        <div class="ot-field">
                                                            <label class="form-label">Security Answer:</label>
                                                            <input type="email" class="form-control" id="exampleFormControlInput1" />
                                                        </div>
                                                    </div>
                                                    <Link to="#" className="request-btn">Change Security Question</Link>
                                                </div>
                                                : ''}


                                            {showEmailChange ?
                                                <div className="form-tab-3 m-bottom">
                                                    <h6>Change Email Address</h6>
                                                    <div className="form-row">
                                                        <div class="ot-field">
                                                            <label class="form-label">Email Address:</label>
                                                            <input type="text" class="form-control" id="exampleFormControlInput1" />
                                                        </div>
                                                    </div>
                                                    <Link to="#" className="request-btn">Change Email Address</Link>
                                                </div>
                                                : ''}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}




export default AccountManage;
