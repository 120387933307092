import Footer from '../../Footer';
import Header from '../../../frontendPages/Header';
import pdf from '../../../images/assets/pdf.png';
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";
import LoadSpinner from '../../Loader/LoadSpinner';
import axios from 'axios';


function PaymentSchedule() {
  const [ParentDocumentData, setParentDocumentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true)
    let data = await fetch("/api/parentdocument/index");
    if (!data.ok) {
      return;
    }
    data = await data.json();
    console.log(data);
    setParentDocumentData(data.data);
    setIsLoading(false)

  };

  const removeRow = async (id) => {
    setIsLoading(true)
    console.log(id)
    // setIsLoading(true)
    const temp = [...ParentDocumentData];
    const primaryKey = id;
    temp.splice(id, 1);
    try {
      await axios.delete(`/api/parentdocument/delete/${encodeURIComponent(primaryKey)}`);
      setParentDocumentData(temp);
      window.location.reload(false);
    } catch (err) { }
    setIsLoading(false)
  };


  const DowloadPdf = async (name) => {
    console.log(name)
    return await fetch("/api/parentdocument/files/" + name).then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
      })
    })
  }

  useEffect(() => {
    fetchData();
    // fetchParentDocumentDataType();
  }, []);
  return (
    <div className="main">

      <Header />
      <div className="parent-main">
        <div className="container">
          <h2>DOWNLOADABLE FOSTER PARENT PAYMENT SCHEDULE</h2>
          <p>A program that can view PDF files is required. The most common free program is Adobe Acrobat Reader, which can be downloaded from the official Adobe website: &nbsp;
            <Link
              to={{ pathname: "https://www.adobe.com/acrobat/pdf-reader.html" }}
              replace
              target="_blank"
            >https://www.adobe.com/acrobat/pdf-reader.html
            </Link> {" "}
          </p>
          <ul>
            {ParentDocumentData.filter(
              (pItem) => pItem.ParentDocumentTypeID == "2"
            ).map((pItem) => {
              return (
                <li className="download-item">
                  <h3>{pItem.Title} </h3>

                  {/* <h6>{pItem.Description} </h6> */}
                  <div dangerouslySetInnerHTML={{ __html: pItem.Description }} />
                  <Link to="#" onClick={() => DowloadPdf(pItem.FilePath)}>
                    <img src={pdf} alt="x" />
                    Download
                  </Link>
                  <Link className='delete-btn' onClick={() => removeRow(pItem.ParentDocumentsID)} to="#" >
                    Delete
                  </Link>
                  <p>{pItem.FilePath}</p>
                </li>
              );
            })}
            {/* <li className="download-item">
                    <h3>2021 Foster Parent Semi-Monthlt Payment Schedule</h3>
                    <Link to="#" ><img src={pdf}  alt="x"/>Download</Link>
                    <p>2021 Foster Parent Semi-Monthlt Payment Schedule</p>
                </li>
                <li className="download-item">
                    <h3>2021 Foster Parent Semi-Monthlt Payment Schedule</h3>
                    <Link to="#"><img src={pdf}  alt="x"/>Download</Link>
                    <p>2021 Foster Parent Semi-Monthlt Payment Schedule</p>
                </li> */}

          </ul>
        </div>
        {isLoading ? <LoadSpinner /> : ""}

      </div>
      <Footer />
    </div>
  );
}
export default PaymentSchedule;
