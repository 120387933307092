import Footer from "../../Footer";
import Header from "../../Header";
import lodesinner from "../../../images/assets/myinfo/lodesinner.svg";
import search from "../../../images/assets/myinfo/search.svg";
import edit from "../../../images/assets/myinfo/edit.svg";
// import acmanage from '../../images/assets/images/ManageInfo.svg';
import SideBar from "../../sidebar/sidebar";
import "../AttachDocuments/AttachDocuments.scss";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { post } from "../../../utils/common";
import mail from "../../../images/mail.png";
import LoadSpinner from "../../Loader/LoadSpinner";
import lodinner from "../../../images/assets/formimages/lodinner.svg";
import print from "../../../images/assets/myinfo/print.svg";
import logoplacement from "../../../images/logo-placement.jpg";
import plus from '../../../images/assets/cm/plus.svg';

import html2canvas from "html2canvas";
import ReactDOMServer from "react-dom/server";
import jsPDF from "jspdf";
const doc = new jsPDF();

function Lodes() {
  const inputRef = useRef(null);

  const [updateDiv, setShowUpdateDiv] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showUpdateDiv = () => {
    setShowUpdateDiv(true);
  };

  const [selectedForEditRequest, setSelectedForRequestEdit] = useState([]);

  const hideUpdateDiv = () => {
    setShowUpdateDiv(false);
  };

  const [data, setData] = useState([]);
  const fetchData = async () => {
    const id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/lodes/getlodesbyproviderid/", { id });
    setData(data.data.data);
  };
  const [AddDiv, setAddDiv] = useState(false);

  // const showAddDiv = () => {
  //     setAddDiv(true)
  //     setShowUpdateDiv(false)
  // }

  const [updateData, setUpdateData] = useState([]);

  const onSubmitByRow = async () => {
    //setIsLoading(true)

    let username = sessionStorage.getItem("username");
    setShowUpdateDiv(false);
    setUpdateData([]);
    await post("/api/lodes/send-mail-update", { username, updateData });
    alert("Request Update  Emailed Successfully !");
    setIsLoading(false);
    window.location.reload(false);
  };

  const hideAddDiv = () => {
    setAddDiv(false);
  };

  const requestEdit = (j) => {
    setSelectedForRequestEdit(data[j]);
    setAddDiv(true);
  };

  const onLodesForm = () =>{
    localStorage.setItem('fromButtonClick', true);
    window.location.href = "/form/logofdailyevents";
    
  }

  const editLodesForm = (j) => {
    if(data[j].Status == "Completed"){
      alert("Form Is Completed, You Cannot Edit")
      return;
    }
    setIsLoading(true);
    sessionStorage.setItem("setLodesForEditId", data[j].LodesID);
    localStorage.setItem('fromButtonClick', true);
    // setLodesForEditId("")
    window.location.href = "/form/logofdailyevents-edit";
    setIsLoading(false);
    // data[j]
  };

  const clickedForDownload = async (row) => {
    // fchwb.spWeb_getLodesReport
    let LodesID = data[row].LodesID;
    const providerid = sessionStorage.getItem("providerSessionId");
    let dataIA = await post("/api/lodes/getlodesform", { providerid, LodesID });

    sessionStorage.removeItem("lodesformdata");
    sessionStorage.setItem(
      "lodesformdata",
      JSON.stringify(dataIA.data.data[0])
    );
    window.open("/print/lodesform", "PRINT", "height=1000,width=1000");
    // window.print(<CriticalUnusualIncidentFormPrint/>)
  };

  const [PdfData, setPdfData] = useState([]);
  // const [PdfBodyData, setPdfBodyData] = useState([]);
  const PrintDiv = async (row) => {
    //console.log('print');
    // const printData = async (row) => {
    let LodesID = data[row].LodesID;
    const providerid = sessionStorage.getItem("providerSessionId");
    let dataIA = await post("/api/lodes/getlodesform", {
      providerid,
      LodesID,
    });
    setPdfData(dataIA.data.data);
    let printContents = document.getElementById("divToPrint").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(false);
    //  window.open();
  };

  const DownLoadPdf = async (row) => {
    let LodesID = data[row].LodesID;

    const providerid = sessionStorage.getItem("providerSessionId");
    let dataIA = await post("/api/lodes/getlodesform", {
      providerid,
      LodesID,
    });

    setPdfData(dataIA.data.data[0]);

    let printContents = document.getElementById("divToPrint").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(false);
//old code 
    // html2canvas(inputRef.current).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/png");
    //   var imgWidth = 210;
    //   var pageHeight = 295;
    //   var imgHeight = (canvas.height * imgWidth) / canvas.width;
    //   var heightLeft = imgHeight;

    //   var doc = new jsPDF("p", "mm");
    //   var position = 0;

    //   doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    //   heightLeft -= pageHeight;

    //   // while (heightLeft >= 0) {
    //   //   position = heightLeft - imgHeight;
    //   //   doc.addPage();
    //   //   doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    //   //   heightLeft -= pageHeight;
    //   // }
    //   doc.save("download.pdf");
    // });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    // {

    //     name: 'Lodes ID',
    //     selector: 'LodesID',
    //     sortable: true,
    // },
    {
      name: "Client Name",
      selector: row => row.Client_Name,
      sortable: true,
    },
    {
      name: "Lode Date",
      selector: row => row.Lode_Date,
      // cell: (row, j) => (new Date(row.LodeDate).toLocaleDateString()),
      sortable: true,
    },
    {
      name: "Status",
      selector: row => row.Status,
      // cell: (row, j) => (new Date(row.LodeDate).toLocaleDateString()),
      sortable: true,
    },


    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div class="inline-flex">
          <Link
            to="#"
            onClick={() => clickedForDownload(j)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={search}></img>
          </Link>
          &nbsp;
          <Link
            onClick={() => editLodesForm(j)}
            class="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            to="#"
          >
            <img src={edit}></img>
          </Link>
          &nbsp;
          <Link
            onClick={() => requestEdit(j)}
            class="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            to="#"
          >
            <img src={mail}></img>
          </Link>
          &nbsp;
          <Link
            onClick={() => DownLoadPdf(j)}
            // onClick={()=>PrintDiv(j)}
            to="#"
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={print} alt="print"></img>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div className="main">
      <Header />

      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <SideBar />
              <div className="tab-detail pl">
                <div className="tab-detail-top">
                  <div className="m-profile">
                    <img src={lodesinner} alt="" />
                    <div className="pagesubtitle">
                      Lodes
                      <p>
                        Below is a record of LODES (Log of Daily Events) you
                        have completed.
                      </p>
                    </div>
                  </div>
                  { <Link to="#" onClick={onLodesForm} className="request-btn"><img src={plus} style={{"height": "18px"}} alt="#"/>Add New Form</Link> }
                  {/* <Link to="#" onClick={showUpdateDiv} className="request-btn">Request Update</Link> */}
                </div>

                <div id="divToPrint" ref={inputRef} class="divprint">
                  <div className="main">
                    <div className="main-content">
                      {/* <HeaderTopDetail /> */}
                      <div className="pagewithsidebar">
                        <div className="container">
                          <div className="pagewithsidebar-inner">
                            {/* <FormSideBar /> */}
                            <div className="tab-detail Critical pagealignpad">
                              <div className="tab-detail-top">
                                {/* <div className="Critical-unusual">
                                  <img src={lodinner} alt="" />
                                  LoG of Daily Events
                                </div> */}
                                <div className="pdfheader">
                                  <div
                                    style={{
                                      "text-align": "right",
                                      "marging-top": "0px",
                                      "position": "relative",
                                      "margin-left": "300px",
                                    }}
                                  >
                                    <img
                                      className="footer-logoo"
                                      src={logoplacement}
                                      alt=""
                                    />
                                  </div>
                                  <h4
                                    className="title"
                                    style={{
                                      "text-align": "center",
                                      "font-size": "1.45em",
                                      "font-weight": "bold",
                                      "margin-top": "0px",
                                      "margin-left": "200px",
                                    }}
                                  >
                                    Saint Francis Ministries, Inc <br></br>
                                    Log Of Daily Events
                                  </h4>
                                </div>
                              </div>

                              <form>
                    <div className="form-row ">
                      <div className="label8 labelpadtop">
                        <label for="birthday">Date:</label>
                      </div>
                      <div className="label15">
                        <input type="text" value={PdfData.LodeDate} />
                      </div>
                      <div className="label7"></div>
                      <div className="label15 labelpadtop">
                        <label for="birthday">Person Completing Lode:</label>
                      </div>
                      <div className="label20">
                        <input type="text" value={PdfData.ProviderMemberName} />
                        {/* <select> */}
                        {/* <option value="0">Select:</option> */}
                        {/* <option value="1">Audi</option>
                                                    <option value="2">BMW</option>
                                                    <option value="3">Citroen</option> */}
                        {/* </select> */}
                      </div>
                      <div className="label7"></div>
                      <div className="label8 labelpadtop">
                        <label for="appt">Youth:</label>
                      </div>
                      <div className="label20">
                        <input type="text" value={PdfData.ClientName} />
                        {/* <select>
                                                <option value="0">Select:</option> */}
                        {/* <option value="1">Audi</option>
                                                    <option value="2">BMW</option>
                                                    <option value="3">Citroen</option> */}
                        {/* </select> */}
                      </div>
                    </div>

                    <div className="imp-note mt-25">
                      <span>TIPS FOR COMPLETING OBSERVATIONS</span>
                      <ol>
                        <li>
                          Please be factual. Please share positives and
                          negatives.
                        </li>
                        <li>
                          Highlight progress of case plan goals/objectives.
                        </li>
                        <li>
                          Your lode is an official record of youth's care
                          provision.
                        </li>
                        <li>
                          Please return to your worker at home visit, email or
                          SFM Families Website.
                        </li>
                      </ol>
                    </div>

                    <div className="form-row fr-w mt-25">
                      <h6 className="small-text">Appointments</h6>
                      <label className="p-0" for="birthday">
                        Please address appointments in your daily observation
                        log below.
                      </label>
                    </div>
                    <div className="form-row  fr-w mt-25">
                      <div className="label15 labelpadtop">
                        <label for="birthday">Medical:</label>
                      </div>
                      <div className="label15">
                        <input type="text" value={PdfData.MedicalAppointment} />
                      </div>
                      <div className="label5"></div>
                      <div className="label15 labelpadtop">
                        <label for="birthday">School Meetings:</label>
                      </div>
                      <div className="label15">
                        <input type="text" value={PdfData.SchoolAppointment} />
                      </div>
                      <div className="label5"></div>
                      <div className="label15 labelpadtop">
                        <label for="birthday">Case Plan:</label>
                      </div>
                      <div className="label15">
                        <input type="text" value={PdfData.CasePlanAppointment} />
                      </div>
                    </div>
                    <div className="form-row  fr-w mt-5">
                      <div className="label15 labelpadtop">
                        <label for="birthday">Mental Health:</label>
                      </div>
                      <div className="label15">
                        <input
                          type="text"
                          value={PdfData.PsychiatricAppointment}
                        />
                      </div>
                      <div className="label5"></div>
                      <div className="label15 labelpadtop">
                        <label for="birthday">Dentist:</label>
                      </div>
                      <div className="label15">
                        <input type="text" value={PdfData.DentistAppointment} />
                      </div>
                      <div className="label5"></div>
                      <div className="label15 labelpadtop">
                        <label for="birthday">Court:</label>
                      </div>
                      <div className="label15">
                        <input type="text" value={PdfData.CourtAppointment} />
                      </div>
                    </div>
                    <div className="form-row  fr-w mt-5">
                      <div className="label15 labelpadtop">
                        <label for="birthday">Vision:</label>
                      </div>
                      <div className="label15">
                        <input type="text" value={PdfData.VisionAppointment} />
                      </div>
                      <div className="label5"></div>
                      <div className="label15 labelpadtop">
                        <label for="birthday">Other:</label>
                      </div>
                      <div className="label15">
                        <input type="text" value={PdfData.OtherAppointment} />
                      </div>
                    </div>
                    <div className="form-grey ">
                      <h6 className="small-text">BIRTH FAMILY CONTACT</h6>
                      <div className="form-row  fr-w mt-5">
                        <div className="label18 labelpadtop">
                          <label>Date and Time (Home Visit):</label>
                        </div>
                        <div className="label25">
                          <input type="text" value={PdfData.HomeVisitDate} />
                        </div>
                        <div className="label5"></div>
                        <div className="label18 labelpadtop">
                          <label>Name / Relationship:</label>
                        </div>
                        <div className="label25">
                          <input
                            type="text"
                            value={PdfData.HomeVisitNameRelationShipOfContact ? PdfData.HomeVisitNameRelationShipOfContact : ''}
                          />
                        </div>
                      </div>
                      <div className="form-row  fr-w mt-5">
                        <div className="label18 labelpadtop">
                          <label>Date and Time (Home Visit):</label>
                        </div>
                        <div className="label25">
                          <input type="text" value={PdfData.PhoneCallDate} />
                        </div>
                        <div className="centergap"></div>
                        <div className="label18 labelpadtop">
                          <label>Name / Relationship:</label>
                        </div>
                        <div className="label25">
                          <input
                            type="text"
                            value={PdfData.PhoneCallNameRelationShipOfContact ? PdfData.PhoneCallNameRelationShipOfContact : ''}
                          />
                        </div>
                      </div>
                      <div className="form-row  fr-w mt-5">
                        <div className="label18 labelpadtop">
                          <label>Date and Time (Home Visit):</label>
                        </div>
                        <div className="label25">
                          <input type="text" value={PdfData.OtherDate} />
                        </div>
                        <div className="centergap"></div>
                        <div className="label18 labelpadtop">
                          <label>Name / Relationship:</label>
                        </div>
                        <div className="label25">
                          <input
                            type="text"
                            value={PdfData.OtherNameRelationShipOfContact ? PdfData.OtherNameRelationShipOfContact : ''}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="req-update-textarea pt-0">
                      <label>
                        <span>
                          <b>Narrative</b>
                        </span>
                      </label>
                      <textarea value={PdfData.Notes}></textarea>
                      {/* <div className="req-update-action">
                                            <Link to="#" className="close">Close</Link>
                                            <Link to="#" className="normal">Save and Close</Link>
                                            <Link to="#" className="normal">Submit</Link>
                                        </div> */}
                    </div>
                  </form>













                              {/* <div className="mt-50">
                                <form>

                                  <div className="form-row ">
                                    <div className="label7 labelpadtop">
                                      <label for="birthday" className="increasedFont">Date:</label>
                                    </div>
                                    <div className="label15">
                                      <input
                                        type="text"
                                        className="increasedFont"
                                        value={PdfData.LodeDate}
                                      />
                                    </div>
                                    <div className="label8"></div>
                                    <div className="label15 labelpadtop">
                                      <label for="birthday" className="increasedFont ">Person Completing Lode:</label>
                                    </div>
                                    <div className="label20">
                                      <input
                                      className="increasedFont"
                                        type="text"
                                        value={PdfData.ProviderMemberName}
                                      />
                                      {/* <select> */}
                                      {/* <option value="0">Select:</option> */}
                                      {/* <option value="1">Audi</option>
                                                    <option value="2">BMW</option>
                                                    <option value="3">Citroen</option> */}
                                      {/* </select> */}
                                    {/* </div>
                                    <div className="label8"></div>
                                    <div className="label7 labelpadtop">
                                      <label for="appt" className="increasedFont">Youth:</label>
                                    </div>
                                    <div className="label20">
                                      <input
                                        type="text"
                                        className="increasedFont"
                                        value={PdfData.ClientName}
                                      /> */}
                                      {/* <select>
                                                <option value="0">Select:</option> */}
                                      {/* <option value="1">Audi</option>
                                                    <option value="2">BMW</option>
                                                    <option value="3">Citroen</option> */}
                                      {/* </select> */}
                                    {/* </div>
                                  </div>

                                  <div className="imp-note mt-25 increasedFont" style={{"fontSize":"18px"}}>
                                    <span>
                                      TIPS FOR COMPLETING OBSERVATIONS
                                    </span>
                                    <ol>
                                      <li style={{"fontSize":"18px"}}>
                                        Please be factual. Please share
                                        positives and negatives.
                                      </li>
                                      <li style={{"fontSize":"18px"}}>
                                        Highlight progress of case plan
                                        goals/objectives.
                                      </li>
                                      <li style={{"fontSize":"18px"}}>
                                        Your lode is an official record of
                                        youth's care provision.
                                      </li>
                                      <li style={{"fontSize":"18px"}}>
                                        Please return to your worker at home
                                        visit, email or SFM Families Website.
                                      </li>
                                    </ol>
                                  </div>

                                  <div className="form-row   mt-25">
                                    <div className="form-container">
                                      <div className="formrowalign">
                                        <label for="birthday" className="label50 labelpadtop increasedFont" >Medical:</label>
                                        <input className="label50 increasedFont"
                                          type="text"
                                          value={PdfData.MedicalAppointment}
                                        />

                                      </div>
                                      <div className="formrowalign">
                                        <label for="birthday" className="label50 labelpadtop increasedFont"style={{"marginLeft":"25px"}}>School Meetings:</label>
                                        <input className="label50 increasedFont"
                                          type="text"
                                          value={PdfData.SchoolAppointment}
                                        />

                                      </div>
                                      <div className="formrowalign">
                                        <label for="birthday" className="label50 labelpadtop increasedFont" style={{"marginLeft":"25px"}}>Case Plan:</label>
                                        <input className="label50 increasedFont"
                                          type="text"
                                          value={PdfData.CasePlanAppointment}
                                        />

                                      </div>
                                    </div>
                                    <div className="form-container">
                                      <div className="formrowalign">
                                        <label for="birthday" className="label50 labelpadtop increasedFont" >
                                          Mental Health:
                                        </label>
                                        <input className="label50 increasedFont"
                                          type="text"
                                          value={PdfData.PsychiatricAppointment}
                                        />

                                      </div>
                                      <div className="formrowalign">
                                        <label for="birthday" className="label50 labelpadtop increasedFont" style={{"marginLeft":"25px"}}>Dentist:</label>
                                        <input className="label50 increasedFont"
                                          type="text"
                                          value={PdfData.DentistAppointment}
                                        />

                                      </div>
                                      <div className="formrowalign">
                                        <label for="birthday" className="label50 labelpadtop increasedFont" style={{"marginLeft":"25px"}}>Court:</label>
                                        <input className="label50 increasedFont"
                                          type="text"
                                          value={PdfData.CourtAppointment}
                                        />

                                      </div>
                                    </div>
                
                                    <div className="form-container">
                                      <div className="formrowalign">
                                        <label for="birthday" className="label50 labelpadtop increasedFont" >Vision:</label>
                                        <input className="label50 increasedFont"
                                          type="text"
                                          value={PdfData.VisionAppointment}
                                        />

                                      </div>
                                      <div className="formrowalign">
                                        <label for="birthday" className="label50 labelpadtop increasedFont" style={{"marginLeft":"25px"}}>Other:</label>
                                        <input className="label50 increasedFont"
                                          type="text"
                                          value={PdfData.OtherAppointment}
                                        />

                                      </div>
                                      <div className="formrowalign"></div>

                                    </div>
                                  </div>

                                  <div className="form-grey mt-25">
                                    <h6 className="increasedFont">
                                      BIRTH FAMILY CONTACT
                                    </h6>
                                    <div className="form-row   mt-5">
                                      <div className="label18 labelpadtop increasedFont">
                                        <label className="increasedFont">Date and Time (Home Visit):</label>
                                      </div>
                                      <div className="label25">
                                        <input
                                        className="increasedFont"
                                          type="text"
                                          value={PdfData.HomeVisitDate}
                                        />
                                      </div>
                                      <div className="centergap"></div>
                                      <div className="label18 labelpadtop">
                                        <label className="increasedFont">Name / Relationship:</label>
                                      </div>
                                      <div className="label25">
                                        <input
                                          type="text"
                                          className="increasedFont"
                                          value={
                                            PdfData.HomeVisitNameRelationShipOfContact
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="form-row  fr-w mt-5">
                                      <div className="label18 labelpadtop">
                                        <label className="increasedFont">Date and Time (Home Visit):</label>
                                      </div>
                                      <div className="label25">
                                        <input
                                        className="increasedFont"
                                          type="text"
                                          value={PdfData.PhoneCallDate}
                                        />
                                      </div>
                                      <div className="centergap"></div>
                                      <div className="label18 labelpadtop">
                                        <label className="increasedFont">Name / Relationship:</label>
                                      </div>
                                      <div className="label25">
                                        <input
                                        className="increasedFont"
                                          type="text"
                                          value={
                                            PdfData.PhoneCallNameRelationShipOfContact
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="form-row  fr-w mt-5">
                                      <div className="label18 labelpadtop">
                                        <label className="increasedFont">Date and Time (Home Visit):</label>
                                      </div>
                                      <div className="label25">
                                        <input
                                        className="increasedFont"
                                          type="text"
                                          value={PdfData.OtherDate}
                                        />
                                      </div>
                                      <div className="centergap"></div>
                                      <div className="label18 labelpadtop">
                                        <label className="increasedFont">Name / Relationship:</label>
                                      </div>
                                      <div className="label25">
                                        <input
                                          type="text"
                                          className="increasedFont"
                                          value={
                                            PdfData.OtherNameRelationShipOfContact
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="req-update-textarea pt-0">
                                    <label>
                                      <span>
                                        <b className="increasedFont">Narrative</b>
                                      </span>
                                    </label>

                                    <p className="textborder">
                                      <p className="textpad increasedFont">{PdfData.Notes}</p></p>
                                   
                                  </div>
                                </form>
                              </div> */} 
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-detail-bottom">
                  <form className="attach-doc-form">
                    {isLoading ? <LoadSpinner /> : ""}
                    {AddDiv ? (
                      <div className="req-update-textarea">
                        <label>
                          <span>
                            <b>Request update for</b> - LODES | LodesID:{" "}
                            {selectedForEditRequest.LodesID
                              ? selectedForEditRequest.LodesID
                              : ""}{" "}
                            | Client Name:{" "}
                            {selectedForEditRequest.Client_Name
                              ? selectedForEditRequest.Client_Name
                              : ""}{" "}
                            | Lode Date:{" "}
                            {selectedForEditRequest.Lode_Date
                              ? selectedForEditRequest.Lode_Date
                              : ""}
                            <br></br>
                            <br></br>
                            <b> Description for Update:</b>{" "}
                          </span>
                        </label>
                        <textarea
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                        <div className="req-update-action">
                          <Link to="#" onClick={hideAddDiv} className="close">
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={onSubmitByRow}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </form>
                  {/* <table>
                                            <tr>
                                                <th>Client Name</th>
                                                <th>Lode Date</th>
                                                <th></th>
                                            </tr>
                                            <tr>
                                                <td>Alfreds Futterkiste</td>
                                                <td>Maria Anders</td>
                                                <td className="table-act"><Link to=""><img src={search} alt="" /></Link><Link to=""><img src={edit} alt="" /></Link></td>
                                            </tr>
                                            <tr>
                                                <td>Alfreds Futterkiste</td>
                                                <td>Maria Anders</td>
                                                <td className="table-act"><Link to=""><img src={search} alt="" /></Link><Link to=""><img src={edit} alt="" /></Link></td>
                                            </tr>

                                        </table> */}

                  <DataTable columns={columns} data={data} pagination={false} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Lodes;