import Footer from "../../Footer";
import Header from "../../Header";
import logoplacement from "../../../images/logo-placement.jpg";
import placementagree from "../../../images/assets/myinfo/placementagree.svg";
import download from "../../../images/assets/myinfo/download.svg";
import edit from "../../../images/assets/myinfo/edit.svg";
import print from "../../../images/assets/myinfo/print.svg";
import search from "../../../images/assets/myinfo/search.svg";
import dompurify from 'dompurify';

// import acmanage from '../../images/assets/images/ManageInfo.svg';
import SideBar from "../../sidebar/sidebar";
import "../PlacementAgreement/PlacementAgreement.scss";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { post } from "../../../utils/common";
import DataTable from "react-data-table-component";
import mail from "../../../images/mail.png";
import LoadSpinner from "../../Loader/LoadSpinner";
import { DownloadURL } from "../../common/commonURL";

function PlacementAgreementElectronicSign() {
  const [data, setData] = useState([]);
  const [dataSet, setDataSet] = useState([]);
  const [sfcsWorker, setsfcsWorker] = useState([]);

  
  const fetchData = async () => {

    let clientid = parseInt(sessionStorage.getItem("CLIENT_ID_FOR_PA"));
    let providerid = parseInt(sessionStorage.getItem("providerSessionId"));
    let pastatus = (sessionStorage.getItem("PAStatus"));
    let ElecSignPlacementAgreementIDFORPA = parseInt(sessionStorage.getItem(
      "ElecSignPlacementAgreementIDFORPA"
    ));
    let data = await post("/api/placementagreement/get-xml-data", {
      clientid,
      providerid,
      ElecSignPlacementAgreementIDFORPA,
      
    });

    //value={sfcsWorker.Resource_Worker}
  
    data.data.data[0].Comment = "";
    

    if (data.data.data[0].CC_ElecSignDate != null){data.data.data[0].CC_ElecSignDate = data.data.data[0].CC_ElecSignDate.split("T")[0]}
    if(data.data.data[0].Provider_ElecSignDate != null){data.data.data[0].Provider_ElecSignDate = data.data.data[0].Provider_ElecSignDate.split("T")[0]}
    data.data.data[0].PAStatus = pastatus;
    if(data.data.data[0].Provider_Comment !== "undefined" && data.data.data[0].Provider_Comment !== null){
      data.data.data[0].Comment =  data.data.data[0].ProviderMemberName + " - " +  data.data.data[0].Provider_Comment;

    }

    if(data.data.data[0].Accept_Reject !== "undefined" && data.data.data[0].Accept_Reject !== null){
      data.data.data[0].Comment = data.data.data[0].Comment + "\n" + sessionStorage.SFCSWorkerName + " - " +  data.data.data[0].Accept_Reject ;

    }
    setData(data.data.data[0]);
    
  };

  const fetchSFCSWorker = async () => {
    // const providerid = sessionStorage.getItem('providerSessionId');
  
    const provider_id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/sfcsworker/index", { provider_id });
    // if (!data.ok) {
    //     return;
    // }
    // data = await data.json();
    sessionStorage.setItem("SFCSWorkerName", data.data.data[0].Resource_Worker);
    console.log("dcsd", data);
    setsfcsWorker(data.data.data[0]);
    fetchData();
  };
  
  const title = data.AgreementText;
  const sanitizer = dompurify.sanitize;
  

  useEffect(() => {
    
    fetchSFCSWorker();
   // getEsignData();
  }, []);
  return (
    <div className="main">
     

      <div
        style={{
          display: "flex",
          "align-items": "center",
          "justify-content": "center",
          "margin-left": "10px",
          "padding-left": "10px",
        }}
      >
     <div
      class="container"
      style={{ "margin-left": "30px", overflow: "word-break" }}
    >
      <div>
        <div
          style={{
            "text-align": "right",
            "marging-top": "0px",
            position: "relative",
            "margin-left": "300px",
          }}
        >
          <img className="footer-logoo" src={logoplacement} alt="" />
        </div>
        <h4
          className="title"
          style={{
            "text-align": "left",
            "font-size": "1.45em",
            "font-weight": "bold",
            "margin-top": "0px",
            "margin-left": "10px",
            "borderBlock": "1px",
          }}
        >
          Saint Francis Ministries, Inc <br></br>
          Placement Agreement
        </h4>
      </div>

      {/* <h4
        className="title"
        style={{
          "text-align": "left",
          "font-size": "1.2em",
          "font-weight": "bold",
          "margin-top": "50px",
          "margin-left": "10px",
        }}
      >
        Placement Agreement{" "}
      </h4> */}

      

      <span dangerouslySetInnerHTML={{ __html: sanitizer(title)}} />
 
      <br></br>
     
    </div>
      </div>
      
        <form className="child-case-form">
          
          <div class="col-md-12"  style={{"display":"flex","margin-left": "90px"}}>
            <div class="col-md-6">
            <label for="appt">Placement Provider:</label>
            <input
                        type="text" value={data.ProviderMemberName}> 
                           
                        </input>
            </div>
            <div class="col-md-6" style={{"margin-left": "190px"}}>
            
                        <label for="appt">Date:</label>
            <input
                        type="text" value={data.Provider_ElecSignDate}> 
                           
                        </input>
            </div>
          </div>
         
         
        </form>
        <form className="child-case-form">
         
          <div class="col-md-12"  style={{"display":"flex","margin-left": "90px","margin-top": "20px","margin-bottom": "40px"}}>
            <div class="col-md-6">
            <label for="appt">SFCS Admissions:</label>
            <input
                        type="text" value={data.CC_StaffName}> 
                           
                        </input>
            </div>
            <div class="col-md-6" style={{"margin-left": "190px"}}>
            <label for="appt">Date:</label>
            <input
                        type="text" value={data.CC_ElecSignDate}> 
                           
                        </input>
            </div>

          </div>
          
          {/* value={data.Accept_Reject} */}
        </form>

        <form className="child-case-form">

        <div class="col-md-6" style={{"margin-left": "90px"}}>
           <label class="form-label">Status:</label>
              <div style={{ "width":" 150%"}}>
      <input type="text" value={data.PAStatus}  style={{ "width":"100%","display":"flex"}} ></input>
      </div> 
           </div>
         
         <div class="col-md-12"  style={{"display":"flex","margin-top": "70px","margin-bottom": "40px", "margin-left": "-190px"}}>

         
           <div class="col-md-6">
           <label class="form-label">Notes:</label> 
              <div style={{ "width":" 300%"}}>
      <textarea value={data.Comment} cols="30" rows="1" style={{ "border": "1px solid #888","display":"flex"}} ></textarea>
      </div> 
           </div>
      
         </div>
         
        
       </form>

        {/* <div className="form-col">
            <div class="ot-field">
              <label class="form-label">Provider Comments:</label>
              <div style={{ "width":" 50%"}}>
      <textarea value={data.Provider_Comment} cols="30" rows="1" style={{ "width":" 50%","border": "1px solid #888","display":"flex"}} ></textarea>
      </div> 
      <label class="form-label">Staff Comments:</label>
              <div style={{ "width":" 50%","margin-left":"50%"}}>
      <textarea value={data.Accept_Reject} cols="30" rows="1" style={{ "width":" 50%","border": "1px solid #888","display":"flex"}} ></textarea>
      </div> 
            
            </div>
           
            
          </div> */}
        {/* <div  style= {{"display": "flex",
            "justify-content": "space-between",
            "align-items": "center",
        }}>
          
                   <div>
                        <label for="appt">Placement Provider:</label>
                    
                      <div>
                        <input
                        type="text" value={data.ProviderMemberName} >
                           
                        </input>
                      </div>
                      </div>  
                      
                    
                    
                      <div >
                        <label for="appt">SFCS Admissions:</label>
                      
                      <div className="label23">
                        <input
                        type="text" value={data.CC_StaffName}> 
                           
                        </input>
                      </div>
                      </div> </div>
                      <div>
                        <label for="birthday">Date:</label>
                     
                      
                        <input  type="text" name="birthday" value={data.Provider_ElecSignDate}/>
                     
                      </div>
                     
                       
                      <div >
                        <label for="birthday">Date:</label>
                      </div>
                      <div >
                        <input type="text" name="birthday" value={data.CC_ElecSignDate}/>
                     
                      </div> */}

{/* <div className="one-third i-agree">
  <div className="g-background">
    <span className="check-option">
      <input
        type="checkbox"
        id="vehicle2"
        name="vehicle2"
        value="Car"
        onChange={(e) =>
          setDataSet({
            ...dataSet,
            IsFinalized: e.target.checked,
          })
        }
      />
      <label for="vehicle2">
        I, name have reviewed the above document and agree
        it is correct.
      </label>
    </span>
    <span>
      <label for="appt">Login Password *:</label>
      <input type="text" />
    </span>
    <small className="red-text">
      *You must “Sign and Submit” to complete this
      electronic form.
    </small>
  </div>
</div> */}

{/* <div className="one-third">
<div className="g-background">
<ul className="immediate-assessment">    
 <li>Provide original to foster care worker.</li>
 <li>Copy to Resource Family.</li>
<li>Copy to oversight agency for the child.</li>
</ul>
</div></div> */}

          
 

     
    </div>
  );
  
}
 
export default PlacementAgreementElectronicSign;
