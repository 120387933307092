import childrensidebar from '../../../images/assets/currentChildren/childrensidebar.svg';
import attachdocsidebar from '../../../images/assets/currentChildren/attachdocsidebar.svg';
import caseteamsidebar from '../../../images/assets/currentChildren/caseteamsidebar.svg';
import courtdatesidebar from '../../../images/assets/currentChildren/courtdatesidebar.svg';
import childprofilesidebar from '../../../images/assets/currentChildren/childprofilesidebar.svg';
import childstrengthsidebar from '../../../images/assets/currentChildren/childstrengthsidebar.svg';
import daycaresidebar from '../../../images/assets/currentChildren/daycaresidebar.svg';
import flowchartsidebar from '../../../images/assets/currentChildren/flowchartsidebar.svg';
import immediatesidebar from '../../../images/assets/currentChildren/immediatesidebar.svg';
import medicationsidebar from '../../../images/assets/currentChildren/medicationsidebar.svg';
import placementsidebar from '../../../images/assets/currentChildren/placementsidebar.svg';
import placementrefsidebar from '../../../images/assets/currentChildren/placementrefsidebar.svg';
import prevensidebar from '../../../images/assets/currentChildren/prevensidebar.svg';
import respitesidebar from '../../../images/assets/currentChildren/respitesidebar.svg';
import activitylog from '../../../images/assets/currentChildren/activitylog.svg';
import HeaderTopDetail from '../HeaderTopDetail/HeaderTopDetail';




function CurrentChildrenSideBar() {
    return (



            <div className="sidebar">
                <ul>
                    <li><a href="/currentchildren/children"><img src={childrensidebar} alt="" /><span>Children</span></a></li>
                    <li><a href="/currentchildren/attachdocuments"><img src={attachdocsidebar} alt="" /><span>Attached Documents</span></a></li>
                    <li><a href="/currentchildren/caseteam"><img src={caseteamsidebar} alt="" /><span>Case Team</span></a></li>
                    <li><a href="/currentchildren/courtdates"><img src={courtdatesidebar} alt="" /><span>Court Dates</span></a></li>
                    <li><a href="/currentchildren/childprofile"><img src={childprofilesidebar} alt="" /><span>Child's Profile</span></a></li>
                    <li><a href="/currentchildren/childstrength"><img src={childstrengthsidebar} alt="" /><span>Child's Strengths</span></a></li>
                    {/* <li><a href="/currentchildren/daycare"><img src={daycaresidebar} alt="" /><span>Daycare</span></a></li> */}
                    <li><a href="/currentchildren/flowchart"><img src={flowchartsidebar} alt="" /><span>Flowchart</span></a></li>
                    <li><a href="/currentchildren/currentchildrenimmidiateassesment"><img src={immediatesidebar} alt="" /><span>Immediate Assessment</span></a></li>
                    <li><a href="/currentchildren/medication"><img src={medicationsidebar} alt="" /><span>Medications</span></a></li>
                    <li><a href="/currentchildren/placementhistory"><img src={placementsidebar} alt="" /><span>Placement History</span></a></li>
                    <li><a href="/currentchildren/placementreferal"><img src={placementrefsidebar} alt="" /><span>Placement Referral</span></a></li>
                    <li><a href="/currentchildren/preventivemeasure"><img src={prevensidebar} alt="" /><span>Preventative Measure</span></a></li>
                    <li><a href="/currentchildren/respiteused"><img src={respitesidebar} alt="" /><span>Respites Used</span></a></li>
                    <li><a href="/currentchildren/activitylog"><img src={activitylog} alt="" /><span>Activity Log</span></a></li>
                </ul>
            </div>




    );
}
export default CurrentChildrenSideBar;