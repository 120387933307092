import Footer from "../../Footer";
import Header from "../../Header";
import placementagree from "../../../images/assets/myinfo/placementagree.svg";
import download from "../../../images/assets/myinfo/download.svg";
import edit from "../../../images/assets/myinfo/edit.svg";
import print from "../../../images/assets/myinfo/print.svg";
import search from "../../../images/assets/myinfo/search.svg";

// import acmanage from '../../images/assets/images/ManageInfo.svg';
import SideBar from "../../sidebar/sidebar";
import "../PlacementAgreement/PlacementAgreement.scss";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { post, get } from "../../../utils/common";
import DataTable from "react-data-table-component";
import mail from "../../../images/mail.png";
import LoadSpinner from "../../Loader/LoadSpinner";
import { DownloadURL } from "../../common/commonURL";

function PlacementAgreementElectronicSign() {
  const [ProviderMember, setProviderMember] = useState([]);
  const fetchProviderMemberData = async () => {
    // let data = await fetch('/api/unusualincident/index');
    const id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/placementagreement/getprovidermember", { id });
       console.log(data);
    setProviderMember(data.data.data);
  };

  const [pwdTypeData, setpwdTypeData] = useState([]);
  const [isPasswordValid, setIsPasswordValid] = useState(true);


  const fetchpwdData = async () => {
    try{
    const username = sessionStorage.getItem("username");
     const data = await post("/api/placementagreement/getpwd", { username });
     //console.log("API response data", data);
    //let pwdTypeData = await data.json();
    //const passwordData = data.data[0];
    setpwdTypeData(data.data.data[0]);
  //console.log("pwd data", passwordData);

  // if ( data.data.data[0] !== undefined &&  dataSet.IsPasswordCorrect !== undefined && data.data.data[0].Password !== dataSet.IsPasswordCorrect ) {
  //   //setIsPasswordValid(false);
  //   alert("Invalid password");
  //   return;
  // }

 } catch (error) {
  console.error("Error fetching password data:", error);
}
  };

  const [dataSet, setDataSet] = useState([]);

  const [sfcsWorker, setsfcsWorker] = useState([]);

  const fetchSFCSWorker = async () => {
    // const providerid = sessionStorage.getItem('providerSessionId');
    const provider_id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/sfcsworker/index", { provider_id });
  
    // if (!data.ok) {
    //     return;
    // }
    // data = await data.json();
    console.log("dcsd", data);
    setsfcsWorker(data.data.data[0]);
   
  };
  
  const AcceptPlacementAgreement = async () => {

    await fetchpwdData();
    if ( pwdTypeData !== undefined &&  dataSet.IsPasswordCorrect !== undefined && pwdTypeData.Password !== dataSet.IsPasswordCorrect ) {
      //setIsPasswordValid(false);
      alert("Invalid password");
      return;
    }
    let providerName = sessionStorage.getItem("ProviderName");
    let providerId = sessionStorage.getItem("providerSessionId");
    let sfcsMailId = sfcsWorker.EmailSend;
   
   
    let ProviderMemberNameRow = ProviderMember.filter(
      (Pmember) => Pmember.ProviderMemberID == dataSet.ProviderMemberID
    );
    let ProviderMemberName = ProviderMemberNameRow[0].ProvidermemberName;
    console.log("ProviderMemberName", ProviderMemberNameRow);
    let ProviderMemberID = dataSet.ProviderMemberID;
    let providerCommentDate = new Date();
    let Provider_Comment = (providerCommentDate.getMonth()+1)+"-"+providerCommentDate.getDate()+"-"+providerCommentDate.getFullYear() +" - " + dataSet.Provider_Comment;
    let Provider_IsApproved = 1;
    let ElecSignPlacementAgreementID = sessionStorage.getItem(
      "ElecSignPlacementAgreementIDFORPA"
    );
    let CLIENT_NAME_FOR_PA = sessionStorage.getItem("CLIENT_NAME_FOR_PA");
    let data = await post("/api/placementagreement/accept-pa", {
      ProviderMemberName,
      ProviderMemberID,
      Provider_Comment,
      Provider_IsApproved,
      ElecSignPlacementAgreementID,
      providerName,
      providerId,
      CLIENT_NAME_FOR_PA,
      sfcsMailId
    });
    alert("Signed Successfully");
    window.location.href = "/placementagreement";
  

   };
  const RejectPlacementAgreement = async () => {

    if(sessionStorage.Password !== dataSet.IsPasswordCorrect && dataSet.IsPasswordCorrect !== undefined){
      alert("Invalid password");
      return;
     }
     else if(dataSet.IsPasswordCorrect === undefined){ 
       alert(" Please fill the password!");
       return;
     }
       
    let sfcsMailId = sfcsWorker.EmailSend;
    let providerName = sessionStorage.getItem("ProviderName");
    let providerId = sessionStorage.getItem("providerSessionId");
    let CLIENT_NAME_FOR_PA = sessionStorage.getItem("CLIENT_NAME_FOR_PA");
    let ProviderMemberNameRow = ProviderMember.filter(
      (Pmember) => Pmember.ProviderMemberID == dataSet.ProviderMemberID
    );
    //let providerCommentDate = new Date();
    let ProviderMemberName = ProviderMemberNameRow[0].ProvidermemberName;
    console.log("ProviderMemberName", ProviderMemberNameRow);
    let ProviderMemberID = dataSet.ProviderMemberID;
    let Provider_Comment = dataSet.Provider_Comment;
    let Provider_IsApproved = 1;
    let ElecSignPlacementAgreementID = sessionStorage.getItem(
      "ElecSignPlacementAgreementIDFORPA"
    );
    let data = await post("/api/placementagreement/avoid-pa", {
      ProviderMemberName,
      ProviderMemberID,
      Provider_Comment,
      Provider_IsApproved,
      ElecSignPlacementAgreementID,
      providerName,
      providerId,
      CLIENT_NAME_FOR_PA,
      sfcsMailId
    });
    alert("Rejected!!");
    window.location.href = "/placementagreement";
  };

  const [SFCSAdmission, SetSFCSAdmission] = useState([]);

  const getSFCSAdmission = async () => {

    let clientid = parseInt(sessionStorage.getItem("CLIENT_ID_FOR_PA"));
    let providerid = parseInt(sessionStorage.getItem("providerSessionId"));
    let ElecSignPlacementAgreementIDFORPA = parseInt(sessionStorage.getItem(
      "ElecSignPlacementAgreementIDFORPA"
    ));
    let data = await post("/api/placementagreement/get-xml-data", {
      clientid,
      providerid,
      ElecSignPlacementAgreementIDFORPA,
    });

    SetSFCSAdmission(data.data.data[0]);
   
  };
  // useEffect(() => {
  //   if (!isPasswordValid) {
  //     alert("not authorized to sign");
  //   }
  // }, [isPasswordValid]);

  useEffect(() => {
    fetchProviderMemberData();
    fetchSFCSWorker();
    getSFCSAdmission();
    fetchpwdData();
  }, []);

  
  console.log("dataSet", dataSet);

  return (
    <div className="main">
      <Header />

      <div
        style={{
           display: "flex",
          "align-items": "center",
          "justify-content": "center",
          "margin-left": "10px",
          "padding-left": "10px",
        }}
      >
        <iframe
          marginwidth="100"
          marginheight="10"
          hspace="10"
          vspace="5"
          frameborder="1px"
          scrolling="yes"
          src="https://sfmfamilies.org/placement-agreement-pdf"
          //src="http://localhost:3000/placement-agreement-pdf"
          height="600"
          width="800"
        ></iframe>
      </div>
      <div
        style={{
          "align-items": "center",
          "justify-content": "center",
        }}
      >
        <form className="child-case-form">
          <div className="form-col">
          <div className="g-background">
          <div class="ot-field">
          <label class="form-label">Person Electronically Signing the Form:</label>
          <select
            onChange={(e) =>
              setDataSet({
                ...dataSet,
                ProviderMemberID: e.target.value,
              })
            }
          >
            <option>Select</option>
            {ProviderMember.map((pmember) => (
              <option value={pmember.ProviderMemberID}>
                {pmember.ProvidermemberName}
              </option>
            ))}
          </select>
        </div>
        <div class="ot-field">
          
          <div class="col-md-6">
            <label for="appt">SFM Admissions:</label>
            <input
                        type="text" value={SFCSAdmission.CC_StaffName}> 
                           
                        </input>
            </div>
        </div>
      </div>
      </div>
           
          

<div className="one-third i-agree">
  <div className="g-background" style ={{"marginTop":"41px"}}>
    <span className="check-option">
      <input
        type="checkbox"
        id="vehicle2"
        name="vehicle2"
        value="Car"
        onChange={(e) =>
          setDataSet({
            ...dataSet,
            IsFinalized: e.target.checked,
          })
        }
      />
      <label for="vehicle2">
        I, name have reviewed the above document and agree
        it is correct.
      </label>
    </span>
    <span>
      <label for="appt">Login Password *:</label>
      <input type="text"   onChange={(e) =>
          setDataSet({
            ...dataSet,
            IsPasswordCorrect: e.target.value,
          })
        } required/>
    </span>
    <small className="red-text">
      *You must “Sign and Submit” to complete this
      electronic form.
    </small>
  </div>
</div>

<div className="one-third">
<div className="g-background">
<ul className="immediate-assessment">    
 <li>Provide original to foster care worker.</li>
 <li>Copy to Resource Family.</li>
<li>Copy to oversight agency for the child.</li>
</ul>
</div>
</div>

          
</form>
        <Link to="#" className="normal" onClick={AcceptPlacementAgreement} style={{"margin-left":"50%"}}>
          Accept
        </Link>
        {"    "}
        <Link to="#" className="close1" onClick={RejectPlacementAgreement} style={{"margin-left": "2.33%"}}>
          Reject
        </Link>
        {"    "}
        <Link to="/placementagreement" className="close1" style={{" margin-left": "0.33%"}}>
          Close
        </Link>
      </div>

      <div className="form-col">
            <div class="ot-field">
              <label class="form-label">Notes:</label>

              <textarea
                onChange={(e) =>
                  setDataSet({
                    ...dataSet,
                    Provider_Comment: e.target.value,
                  })
                }
              ></textarea>
            </div>
          </div>

    

      <Footer />
    </div>
  );
}
//---Person Electronically Signing the Form has to before cancel button
// <div className="form-row dandt">
// <div className="one-third">
//   <label for="birthday">
//     Person Electronically Signing the Form:
//   </label>
//   <select  onChange={(e) =>
//     setAddFormData({
//       ...AddFormData,
//       ProviderMemberID: e.target.checked,
//     })
//   }>
//     <option value="0">Select</option>
//     {personSigningTheForm.map((signperson) => (
//       <option value={signperson.ProviderMemberID}>
//         {signperson.ProviderName}
//       </option>
//     ))}
//   </select>
// </div>

// <div className="one-third">
//   <ul className="immediate-assessment">
//     <li>Provide original to foster care worker.</li>
//     <li>Copy to Resource Family.</li>
//     <li>Copy to oversight agency for the child.</li>
//   </ul>
// </div>
// </div>


export default PlacementAgreementElectronicSign;
