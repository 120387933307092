import Footer from "../../Footer";
import Header from "../../Header";
import MultipleValueTextInput from "react-multivalue-text-input";

import { useRef } from "react";
import reimhistory from "../../../images/assets/myinfo/reimhistory.svg";
import search from "../../../images/assets/myinfo/search.svg";
import edittwo from "../../../images/assets/myinfo/edittwo.svg";
import download from "../../../images/assets/myinfo/download.svg";
import edit from "../../../images/assets/myinfo/edit.svg";
import print from "../../../images/assets/myinfo/print.svg";
// import acmanage from '../../images/assets/images/ManageInfo.svg';
import SideBar from "../../sidebar/sidebar";
import "../ReimbursmentHistory/ReimbusrsmentHistory.scss";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CriticalUnusualIncidentFormDownload from "../../downloadableForms/CriticalUnusualIncidentDownload/CriticalUnusualIncidentFormDownload";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "../../downloadableForms/MilageRembursementForm/MilageRembursementFormDownload";
import MilageRembursementFormDownloadPrint from "../../downloadableForms/MilageRembursementForm/MilageRembursementFormDownload";
import LoadSpinner from "../../Loader/LoadSpinner";
import plus from '../../../images/assets/cm/plus.svg';

import { post } from "../../../utils/common";
import DataTable from "react-data-table-component";
import mail from "../../../images/mail.png";
import html2canvas from "html2canvas";
import ReactDOMServer from "react-dom/server";
import jsPDF from "jspdf";
import axios from "axios";
const doc = new jsPDF();
//const CIFORMPDF = <CriticalUnusualIncidentFormPrint />;

function ReimbusrsmentHistory() {
  const inputRef = useRef(null);

  const [ReHistoryData, setReHistoryData] = useState([]);
  const [MilageReData, setMilageReData] = useState([]);

  const [ReimbursmentHistoryDataForEdit, setReimbursmentHistoryDataForEdit] =
    useState([]);
  const [MilageDataForEdit, setMilageDataForEdit] = useState([]);

  const requesReHistorytEdit = (rowData) => {
    setShowupdateDivReimb(true);
    setShowUpdateDiv(false);
    setopenCommentBoxforProvidermailSent(false);
    setReimbursmentHistoryDataForEdit(ReHistoryData[rowData]);
    console.log(ReimbursmentHistoryDataForEdit);
  };

  const requestMilageReEdit = (rowData) => {
    setShowUpdateDiv(true);
    setShowupdateDivReimb(false);
    // setMilageDataForEdit(true)

    setMilageDataForEdit(MilageReData[rowData]);
    console.log(MilageDataForEdit);
  };

  const [showReimbursement, showReimbursementDiv] = useState(true);
  const [showMileage, showMileageDiv] = useState(false);
  const [showAddButton, setShowAddButtonDiv] = useState(false);
  const showReimbursementDivision = () => {
    setShowAddButtonDiv(false);
    showMileageDiv(false);
    setShowUpdateDiv(false);
    setShowupdateDivReimb(false);
    showReimbursementDiv(true);
  };
  const showMileageDivision = () => {
    setShowAddButtonDiv(true)
    showMileageDiv(true);
    setShowUpdateDiv(false);
    setShowupdateDivReimb(false);
    showReimbursementDiv(false);
    setopenCommentBoxforProvidermailSent(false);
  };

  const [updateDiv, setShowUpdateDiv] = useState(false);
  const [updateDivReimb, setShowupdateDivReimb] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showUpdateDiv = () => {
    setShowUpdateDiv(true);
  };

  const hideUpdateDiv = () => {
    setShowUpdateDiv(false);
    setShowupdateDivReimb(false);
  };

  const [updateData, setUpdateData] = useState([]);
  const OnUpdateReHistorySubmit = async () => {
    // setIsLoading(true)
    let username = sessionStorage.getItem("username");

    const ProviderName = sessionStorage.getItem("ProviderName");
    // const CurrentUserEmail = sessionStorage.getItem("username");

    setShowUpdateDiv(false);
    setShowupdateDivReimb(false);
    setUpdateData([]);
    await post("/api/reimbursementhistory/update-email-reimbursement-history", {
      username,
      updateData,
      ProviderName,
    });
    alert("Request Update  Emailed Successfully !");

    setIsLoading(false);

    // window.location.reload(false)
  };

  const onReimburseForm = () => {
    localStorage.setItem('fromButtonClick', true);
    window.location.href = "/forms/milagereimbursment";

  }

  const OnUpdateMilageReSubmit = async () => {

    let childrenNames = MilageDataForEdit.Children;

    // setIsLoading(true)
    let username = sessionStorage.getItem("username");
    let ProviderName = sessionStorage.getItem("ProviderName");

    setShowUpdateDiv(false);
    setShowupdateDivReimb(false);
    setUpdateData([]);

    await post("/api/reimbursementhistory/update-email-milage-reimbursement", {
      username,
      updateData,
      ProviderName,
      childrenNames
    });
    alert("Request Update  Emailed Successfully !");
    // window.location.reload(false);
  };

  const fetchData = async () => {
    const providerid = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/reimbursementhistory/getlist", { providerid });
    console.log(data);
    if (Array.isArray(data.data.ReHistoryData))
      setReHistoryData(data.data.ReHistoryData);
    if (Array.isArray(data.data.MilageReData))
      setMilageReData(data.data.MilageReData);
  };

  const clickedForDownload = async (row) => {
    // fchwb.spWeb_getLodesReport
    let MileageReimbursementID = MilageReData[row].MileageReimbursementID;
    const providerid = sessionStorage.getItem("providerSessionId");
    let dataIA = await post("/api/reimbursementhistory/getformdata", {
      providerid,
      MileageReimbursementID,
    });

    sessionStorage.removeItem("mileageformdata");
    sessionStorage.removeItem("mileageformdataHeaderData");

    sessionStorage.setItem(
      "mileageformdataHeaderData",
      JSON.stringify(dataIA.data.HeaderData)
    );
    sessionStorage.setItem("mileageformdata", JSON.stringify(dataIA.data.data));
    window.open("/print/mileageformview", "PRINT", "height=1000,width=1000");
    // window.print(<CriticalUnusualIncidentFormPrint/>)
  };

  const clickedForDownloadReHistory = async (row) => {
    // fchwb.spWeb_getLodesReport
    // let MileageReimbursementID = MilageReData[row].MileageReimbursementID;
    let Payment_Date = ReHistoryData[row].Payment_Date;
    const providerid = sessionStorage.getItem("providerSessionId");
    let dataIA = await post("/api/reimbursementhistory/get-rehistory-data", {
      providerid,
      Payment_Date,
    });

    sessionStorage.removeItem("rehistorydata");
    sessionStorage.setItem("rehistorydata", JSON.stringify(dataIA.data.data));
    window.open("/print/rehistory", "PRINT", "height=1000,width=1000");
    // window.print(<CriticalUnusualIncidentFormPrint/>)
  };

  const [PdfData, setPdfData] = useState([]);

  const DownLoadPdfForReHistoryReport = async (row) => {
    let Payment_Date = ReHistoryData[row].Payment_Date;
    const providerid = sessionStorage.getItem("providerSessionId");
    let dataIA = await post("/api/reimbursementhistory/get-rehistory-data", {
      providerid,
      Payment_Date,
    });
    setPdfData(dataIA.data.data);
    // sessionStorage.setItem("cuiformdata", JSON.stringify(dataIA.data.data[0]));

    html2canvas(inputRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF("landscape", "mm");
      var position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      doc.save(`${sessionStorage.getItem("ProviderName")}.pdf`);
    });
  };

  const [
    openCommentBoxforProvidermailSent,
    setopenCommentBoxforProvidermailSent,
  ] = useState(false);
  const [ExistingMailListData, setExistingMailListData] = useState([]);
  const [paymentValue, setpaymentValue] = useState("");
  const sendMailToProviderOpenCommentBox = async (row) => {
    let value = ReHistoryData[row].Payment_Date;
    setpaymentValue(value);
    // let Payment_Date = ReHistoryData[row].Payment_Date;
    setopenCommentBoxforProvidermailSent(true);
    setShowUpdateDiv(false);
    setShowupdateDivReimb(false);
  };

  const sendMailToProviderCloseCommentBox = async (row) => {
    setopenCommentBoxforProvidermailSent(false);
    setShowUpdateDiv(false);
    setShowupdateDivReimb(false);
    //get all the data whome email is going to
  };

  const [Email, AllEmails] = useState([
    JSON.parse(sessionStorage.getItem("ProviderData")).Email,
    sessionStorage.getItem("username"),
  ]);
  const sendMailToProvider = async () => {
    console.log("updateData", Email);
    setIsLoading(true);
    let Payment_Date = paymentValue;
    const providerid = sessionStorage.getItem("providerSessionId");
    let dataIA = await post("/api/reimbursementhistory/get-rehistory-data", {
      providerid,
      Payment_Date,
    });
    setPdfData(dataIA.data.data);
    html2canvas(inputRef.current).then(async (canvas) => {
      const imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF("landscape", "mm");
      var position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      // const emailToSend = JSON.parse(
      //   sessionStorage.getItem("ProviderData")
      // ).Email;
      // const CurrentUserEmail = sessionStorage.getItem("username");

      const out = doc.output("datauristring");
      const description = updateData.description;
      const EmailString = updateData.EmailString;
      const response = await axios.post(
        `/api/reimbursementhistory/sendReHistoryReportToProvider`,
        {
          pdf: out.split("base64,")[1],
          // emailToSend,
          // CurrentUserEmail,
          Email,
          description,
          // EmailString,
        }
      );
      alert("Email Sent");
      setIsLoading(false);
      window.location.reload(false);

      // let temp = doc.save(`${sessionStorage.getItem("ProviderName")}.pdf`);
    });
  };

  const sample_callback = (
    namef // Callback
  ) => {
    var keyword = namef.toString();
    var trackname = keyword.toString().split("\r\n");
    //  var name =    "<p>some text</p><br /><br /><p>another text</p>"
    var name = trackname[0] + "<p>" + trackname[1] + "</p>" + trackname[2];
    return name;
  };

  // const printPartOfPage= (elementId, uniqueIframeId)=>{
  const printData = async (row) => {
    let Payment_Date = ReHistoryData[row].Payment_Date;
    const providerid = sessionStorage.getItem("providerSessionId");
    let dataIA = await post("/api/reimbursementhistory/get-rehistory-data", {
      providerid,
      Payment_Date,
    });
    setPdfData(dataIA.data.data);
    var content = document.getElementById("divToPrint");
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  const PrintDiv = async (row) => {
    //console.log('print');
    // const printData = async (row) => {
    let Payment_Date = ReHistoryData[row].Payment_Date;
    const providerid = sessionStorage.getItem("providerSessionId");
    let dataIA = await post("/api/reimbursementhistory/get-rehistory-data", {
      providerid,
      Payment_Date,
    });
    setPdfData(dataIA.data.data);
    let printContents = document.getElementById("divToPrint").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(false);
    //  window.open();
  };
  // }
  // }

  console.log("Email", Email);
  useEffect(() => {
    fetchData();
  }, []);

  const ReHistoryColumns = [
    {
      name: "Payment Date",
      selector: row => row.Payment_Date,
      sortable: true,
    },
    {
      name: "Total",
      selector: row => row.Total,
      sortable: true,
    },

    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div className="inline-flex three-buttons">
          <Link
            to="#"
            onClick={() => clickedForDownloadReHistory(j)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={search}></img>
          </Link>
          &nbsp;
          <Link to="#" onClick={() => sendMailToProviderOpenCommentBox(j)}>
            {/* <img src={mail}></img> */}
            <img src={edit}></img>

            {/* <img src={search}></img> */}
          </Link>
          &nbsp;
          <Link
            to="#"
            onClick={() => requesReHistorytEdit(j)}
          // type="button"
          // className="normal"
          >
            {/* Send Mail */}
            <img src={mail}></img>
          </Link>{" "}
          &nbsp;
          <Link
            to="#"
            onClick={() => DownLoadPdfForReHistoryReport(j)}
            // type="button"
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={download}></img>
          </Link>
          &nbsp;
          <Link
            to="#"
            onClick={() => PrintDiv(j)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={print}></img>
          </Link>
        </div>
      ),
    },
  ];

  const MilageReColumns = [
    {
      name: "Entered Date",
      selector: row => row.Entered_Date,
      sortable: true,
    },
    {
      name: "Total Requested",
      selector: row => row.Total_Requested,
      sortable: true,
    },

    {
      name: "Status",
      selector: row => row.Status,
      sortable: true,
    },

    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div className="inline-flex three-buttons">
          <Link
            to="#"
            onClick={() => clickedForDownload(j)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={search}></img>
          </Link>{" "}
          &nbsp;
          <Link
            to="#"
            onClick={() => requestMilageReEdit(j)}
            // type="button"
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={mail}></img>
          </Link>
        </div>
      ),
    },
  ];

  const ReHistoryReportColumns = [
    // {
    //   name: "Payment Due Date",
    //   selector: "Payment_Due_Date",
    //   sortable: true,
    //   wrap: true,
    // },
    // {
    //   name: "ProviderLocation",
    //   selector: "Provider_Location",
    //   sortable: true,
    //   wrap: true,
    // },

    {
      name: "Child",
      selector: row => row.Child,
      sortable: true,
      wrap: true,
    },
    {
      name: "Type of Service",
      selector: row => row.Type_of_Service,
      sortable: true,
      wrap: true,
    },
    {
      name: "Notes",
      selector: row => row.Notes,
      sortable: true,
      wrap: true,
    },
    {
      name: "Begin Date",
      selector: row => row.Begin_Date,
      sortable: true,
      wrap: true,
    },

    {
      name: "End Date",
      selector: row => row.End_Date,
      sortable: true,
      wrap: true,
    },
    {
      name: "Units",
      selector: row => row.Units,
      sortable: true,
      wrap: true,
    },
    {
      name: "Rate",
      selector: row => row.Rate,
      sortable: true,
      wrap: true,
    },

    {
      name: "Amount",
      selector: row => row.Amount,
      sortable: true,
      wrap: true,
    },
  ];
  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();
  return (
    <div className="main">
      <Header />

      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <SideBar />
              <div className="tab-detail Reimbursment pl">
                <div className="tab-detail-top">
                  <div className="Reimbursment-top">
                    <img src={reimhistory} alt="" />
                    <div className="pagesubtitle">
                      Reimbursement History
                      <p>
                        Below is a record of the reimbusements you have
                        received.
                      </p>
                    </div>
                  </div>
                  {/* {showTab2 ? <Link to="#" onClick={showUpdateDivMiReimb} className="request-btn">request update</Link> : ''}*/}
                  {showAddButton ? (<Link to="#" onClick={onReimburseForm} className="request-btn"><img src={plus} style={{ "height": "18px" }} alt="#" />Add New Form</Link>) : (
                    ""
                  )}
                </div>
                <div id="divToPrint" ref={inputRef} className="divprint">
                  <iframe
                    id="ifmcontentstoprint"
                    style={{
                      height: "0px",
                      width: "0px",
                      position: "absolute",
                    }}
                  ></iframe>
                  <div className="main">
                    <div className="main-content">
                      <h4
                        className="title"
                        style={{
                          "text-align": "center",
                          "font-size": "1.45em",
                          "font-weight": "bold",
                          "margin-top": "50px",
                        }}
                      >
                        Saint Francis Ministries, Inc{" "}
                      </h4>
                      <h5
                        className="payment_date increasedFont"
                        style={{
                          "text-align": "right",
                          "margin-right": "25px",
                          "font-size": "1em",
                          "font-weight": "500",
                          "margin-top": "30px",
                        }}
                      >
                        Payment Date{" "}
                        {PdfData[0] ? PdfData[0].Payment_Due_Date : ""}
                      </h5>
                      <p
                        className="payment_date increasedFont"
                        style={{
                          "text-align": "left",
                          width: "250px",
                          "margin-left": "30px",
                          "font-size": "1em",
                          "font-weight": "500",
                          "margin-top": "-20px",
                        }}
                      >
                        <br></br>

                        <span
                          dangerouslySetInnerHTML={{
                            __html: sample_callback(
                              PdfData[0] ? PdfData[0].Provider_Location : ""
                            ),
                          }}
                        />
                        {/* {PdfData[0] ? PdfData[0].Provider_Location : ""}
                         */}
                        {/* {sample_callback(
                          PdfData[0] ? PdfData[0].Provider_Location : ""
                        )} */}
                        {/* split(",").join("") */}
                      </p>

                      <div className="container-fluid">
                        <div
                          className="tab-detail Critical"
                          style={{ width: "95%", margin: "0px 30px" }}
                        >
                          <table cellPadding="0" cellSpacing="0">
                            <thead>
                              <tr>
                                <th className="increasedFont"
                                  style={{
                                    border: "1px solid grey",
                                    "border-collapse": "collapse",
                                    background: "#3075B6",
                                    color: "white",
                                    "font-size": "1em",
                                  }}
                                >
                                  Child
                                </th>
                                <th className="increasedFont"
                                  style={{
                                    border: "1px solid grey",
                                    "border-collapse": "collapse",
                                    background: "#3075B6",
                                    color: "white",
                                    "font-size": "1em",
                                  }}
                                >
                                  Type of Service
                                </th>
                                <th className="increasedFont"
                                  style={{
                                    border: "1px solid grey",
                                    "border-collapse": "collapse",
                                    background: "#3075B6",
                                    color: "white",
                                    "font-size": "1em",
                                  }}
                                >
                                  Notes
                                </th>
                                <th className="increasedFont"
                                  style={{
                                    border: "1px solid grey",
                                    "border-collapse": "collapse",
                                    background: "#3075B6",
                                    color: "white",
                                    "font-size": "1em",
                                  }}
                                >
                                  Begin Date
                                </th>
                                <th className="increasedFont"
                                  style={{
                                    border: "1px solid grey",
                                    "border-collapse": "collapse",
                                    background: "#3075B6",
                                    color: "white",
                                    "font-size": "1em",
                                  }}
                                >
                                  End Date
                                </th>
                                <th className="increasedFont"
                                  style={{
                                    border: "1px solid grey",
                                    "border-collapse": "collapse",
                                    background: "#3075B6",
                                    color: "white",
                                    "font-size": "1em",
                                  }}
                                >
                                  Units
                                </th>
                                <th className="increasedFont"
                                  style={{
                                    border: "1px solid grey",
                                    "border-collapse": "collapse",
                                    background: "#3075B6",
                                    color: "white",
                                    "font-size": "1em",
                                  }}
                                >
                                  Rate
                                </th>
                                <th className="increasedFont"
                                  style={{
                                    border: "1px solid grey",
                                    "border-collapse": "collapse",
                                    background: "#3075B6",
                                    color: "white",
                                    "font-size": "1em",
                                    "text-align": "right",
                                  }}
                                >
                                  Amount
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {PdfData.map((dataOutput) => (
                                <tr>
                                  <td className="increasedFont">{dataOutput.Child}</td>
                                  <td className="increasedFont">{dataOutput.Type_of_Service}</td>
                                  <td className="increasedFont">{dataOutput.Notes}</td>
                                  <td className="increasedFont">{dataOutput.Begin_Date}</td>
                                  <td className="increasedFont">{dataOutput.End_Date}</td>
                                  <td className="increasedFont">{dataOutput.Units}</td>
                                  <td className="increasedFont">${dataOutput.Rate}</td>
                                  <td className="increasedFont" style={{ "text-align": "right" }}>
                                    ${Number(dataOutput.Amount).toFixed(2)}
                                  </td>
                                </tr>
                              ))}
                              <tr
                                style={{
                                  "margin-top": "30px",
                                  "text-align": "right",
                                }}
                              >
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="increasedFont">
                                  Total : $
                                  {Number(
                                    PdfData.reduce(
                                      (a, v) => (a = a + v.Amount),
                                      0
                                    )
                                  ).toFixed(2)}
                                </td>{" "}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            "text-align": "left",
                            width: "50%",
                            "margin-left": "30px",
                            bottom: "0px",
                            position: "relative",
                          }}
                          className="row"
                        >
                          <div className="row">
                            Please review your payments for accuracy.
                            <br />
                            If there is a discrepancy, contact your case worker
                            <br />
                            immediately.
                          </div>
                          <div className="row" style={{ margin: "15px 0px" }}>
                            Thank you
                          </div>
                          <div className="row">
                            <span className="text-left">
                              Report Generated on {currDate} {currTime}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-detail-bottom">
                  <form className="Reimbursment-form">
                    <div className="tabui">
                      <ul>
                        <li>
                          <Link
                            onClick={showReimbursementDivision}
                            className={showReimbursement ? "active" : ""}
                            to="#"
                          >
                            Reimbursement History
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={showMileageDivision}
                            className={showMileage ? "active" : ""}
                            to="#"
                          >
                            Mileage Reimbursement
                          </Link>
                        </li>
                      </ul>
                    </div>

                    {openCommentBoxforProvidermailSent ? (
                      <div className="req-update-textarea">
                        <MultipleValueTextInput
                          onItemAdded={(item, allItems) => AllEmails(allItems)}
                          onItemDeleted={(item, allItems) =>
                            console.log("removed")
                          }
                          label="Email List"
                          name="item-input"
                          placeholder=""
                          values={[
                            JSON.parse(sessionStorage.getItem("ProviderData"))
                              .Email,
                            sessionStorage.getItem("username"),
                          ]}
                        />
                        {/* Email List */}
                        {/* <label>
                          {" "}
                          <b>
                            {
                              JSON.parse(sessionStorage.getItem("ProviderData"))
                                .Email
                            }
                            ,{sessionStorage.getItem("username")}
                          </b>
                        </label> */}
                        {/* <br></br>
                        <label><b>Input Additional Email List</b></label>
                        <input
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              EmailString: e.target.value,
                            })
                          }
                        ></input> */}
                        <label>
                          <b> Description:</b>{" "}
                          {/* <span>
                            <b>Request Update for</b>- Reimbursement History |
                            Payment Date:{" "}
                            {ReimbursmentHistoryDataForEdit.Payment_Date
                              ? ReimbursmentHistoryDataForEdit.Payment_Date
                              : ""}
                            | Total:{" "}
                            {ReimbursmentHistoryDataForEdit.Total
                              ? ReimbursmentHistoryDataForEdit.Total
                              : ""}
                            <br></br>
                            <br></br>
                            <b> Description for Update:</b>{" "}
                          </span> */}
                        </label>
                        <textarea
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                        <div className="req-update-action">
                          <Link
                            to="#"
                            onClick={sendMailToProviderCloseCommentBox}
                            className="close"
                          >
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={sendMailToProvider}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {updateDivReimb ? (
                      <div className="req-update-textarea">
                        <label>
                          <span>
                            <b>Request Update for</b>- Reimbursement History |
                            Payment Date:{" "}
                            {ReimbursmentHistoryDataForEdit.Payment_Date
                              ? ReimbursmentHistoryDataForEdit.Payment_Date
                              : ""}
                            | Total:{" "}
                            {ReimbursmentHistoryDataForEdit.Total
                              ? ReimbursmentHistoryDataForEdit.Total
                              : ""}
                            <br></br>
                            <br></br>
                            <b> Description for Update:</b>{" "}
                          </span>
                        </label>
                        <textarea
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                        <div className="req-update-action">
                          <Link
                            to="#"
                            onClick={hideUpdateDiv}
                            className="close"
                          >
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={OnUpdateReHistorySubmit}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {isLoading ? <LoadSpinner /> : ""}

                    {updateDiv ? (
                      <div className="req-update-textarea">
                        <label>
                          <span>
                            <b>Request Update for</b>- Mileage reimbursement
                            Entered Date:{" "}
                            {MilageDataForEdit.Entered_Date
                              ? MilageDataForEdit.Entered_Date
                              : ""}
                            | Total_Requested:{" "}
                            {MilageDataForEdit.Total_Requested
                              ? MilageDataForEdit.Total_Requested
                              : ""}
                            Status :{" "}
                            {MilageDataForEdit.Status
                              ? MilageDataForEdit.Status
                              : ""}{" "}
                            <br></br>
                            <br></br>
                            <b> Description for Update:</b>{" "}
                          </span>
                        </label>
                        <textarea
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                        <div className="req-update-action">
                          <Link
                            to="#"
                            onClick={hideUpdateDiv}
                            className="close"
                          >
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={OnUpdateMilageReSubmit}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="form-tabs">
                      {showReimbursement ? (
                        <div className="form-tab-1 m-bottom">
                          <DataTable
                            columns={ReHistoryColumns}
                            data={Array.isArray(ReHistoryData) ? ReHistoryData : []}
                            pagination={false}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {showMileage ? (
                        <div>
                          <DataTable
                            columns={MilageReColumns}
                            data={Array.isArray(MilageReData) ? MilageReData : []}
                            pagination={false}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ReimbusrsmentHistory;