import Footer from "../../Footer";
import Header from "../../../frontendPages/Header";
import ContentManagementSideBar from "../ContentManagementSideBar/ContentManagementSideBar";
import manageanounce from "../../../images/assets/cm/manageanounce.svg";
import ContentHeaderTopDetail from "../ContentHeaderTopDetail/ContentHeaderTopDetail";
import "../AdoptionContact/AdoptionContact.scss";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import editimage from "../../../images/assets/cm/edit.svg";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import axios from "axios";
import { post, put } from "../../../utils/common";
import LoadSpinner from "../../Loader/LoadSpinner";
import "../../../css/style.scss"

function ManageAnnouncement() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [AnnouncementById, setAnnounceMentById] = useState([]);

  const [AnnouncementId, setAnnouncementId] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [addvsupdate, setaddvsupdate] = useState(null);

  const [AnnouncementTypeData, setAnnouncementTypeData] = useState([]);
  const [allAnnouncementData, setAllannouncementData] = useState([]);
  const [show, setShow] = useState(false);
  const [isNewForm, setIsNewForm] = useState(true);
  const [filterClause, setFilterClause] = useState("All");

  const fetchData = async (showModal, s) => {
    setIsLoading(true);

    let data = await fetch("/api/manageannouncement/index");
    if (!data.ok) {
      return;
    }
    data = await data.json();
    console.log(data);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    switch (s) {
      case "All":
        break;
      case "Active":
        data.data = data.data.filter(d => {
          const endDate = new Date(d.EndDate);
          return !d.EndDate || endDate >= today;
        });
        break;
      case "In-Active":
        data.data = data.data.filter(d => {
          const endDate = new Date(d.EndDate);
          return d.EndDate && endDate < today;
        });
        break;
    }
    setData(data.data);
    setFilterClause(s)
    setIsLoading(false);
    if (showModal) {
      // setShow(true)
    }
  };

  const addNewAnnouncement = (e) => {
    e.preventDefault();
    setaddvsupdate(true);

    console.log("announcement added");
    setAnnounceMentById([]);
    setAllannouncementData([]);
    // AnnouncementById.AnnouncementTypeID = "0";
    setAnnounceMentById((prev) => ({
      ...prev,
      AnnouncementTypeID: "0",
    }))
    setIsNewForm(true);
  };

  const deleteAnnouncement = async () => {
    setIsLoading(true);
    let response = await axios.delete(
      `/api/manageannouncement/delete/${encodeURIComponent(AnnouncementId)}`
    );
    if (response) {
      alert("Data deleted Successfully");
      window.location.reload(false);
    }
    window.location.reload(false);
    setIsLoading(false);
  };

  const CancelAnnouncement = () => {
    // alert("Data deleted Successfully")
    window.location.reload(false);
  };

  const storeData = async (e) => {
    if (AnnouncementById.Title && AnnouncementById.AnnouncementTypeID != "0" && AnnouncementById.BeginDate && AnnouncementById.EndDate && AnnouncementById.Picturepath) {
      setIsLoading(true);
      e.preventDefault();
      const data = new FormData();
      if (!AnnouncementById.Picturepath) {
        alert("Select File");
        setIsLoading(false);
        return;
      }
      data.append("file", AnnouncementById.Picturepath);
      data.append("AnnouncementById", JSON.stringify(AnnouncementById));
      axios
        .post("/api/manageannouncement/store/", data, {
          // receive two    parameter endpoint url ,form data
        })
        .then((res) => {
          // then print response status
          alert("Record created successfully !");
          setIsLoading(false);
          window.location.reload(false);
        });
    } else {
      if (!AnnouncementById.Title) {
        alert('Please Fill Announcement Title')
      } else if (AnnouncementById.AnnouncementTypeID == "0") {
        alert('Please Select Announcement Type')
      } else if (!AnnouncementById.Picturepath) {
        alert('Please Select Picture')
      } else if (!AnnouncementById.BeginDate) {
        alert('Please Fill Begin Date')
      } else if (!AnnouncementById.EndDate) {
        alert('Please Fill End Date')
      }
    }
  }

  const [announcementTypeList, setAnnouncementTypeList] = useState([]);

  const fetchAllAnnouncementTypeData = async () => {
    setIsLoading(true);
    let data = await post("/api/manageannouncement/getallannouncementtype");
    // if (!data.ok) {
    //    return;
    // }
    // data = await data.json();
    console.log("sssss", data);
    setAnnouncementTypeList(data.data.data);
    setIsLoading(false);
  };

  const updateAnnoncement = async () => {
    setIsLoading(true);
    const id = AnnouncementId;
    const formData = new FormData();
    formData.append("file", AnnouncementById.Picturepath);
    formData.append("announcementDocumentData", JSON.stringify(AnnouncementById));
    console.log("announcement updated", id);
    // await put("/api/manageannouncement/update/" + id, { AnnouncementById });
    axios.put("/api/manageannouncement/update/" + id, formData, {

    }).then((res) => {
      alert("Record updated successfully !");
      setIsLoading(false);
      window.location.reload(false);
    })
  }

  const [dataByIdLoadedStatus, setdataByIdLoadedStatus] = useState(false);
  //get all provider by selected  provider tyoe
  const getAnnouncementById = async (e) => {
    try {
      e.preventDefault()
      setIsLoading(true);
      setaddvsupdate(false);
      const id = AnnouncementId.Id;
      setAnnouncementId({ ...AnnouncementId, Id: id });
      console.log(id);
      let allannouncementById = await post(
        "/api/manageannouncement/getannouncementbyid",
        { id }
      );
      setAnnounceMentById(allannouncementById.data.data[0]);
      console.log(AnnouncementById);
      setIsNewForm(false);

      const announcementtypeid = AnnouncementById.AnnouncementTypeID;
      if (announcementtypeid) {
        let announcementtypedata = await post(
          "/api/manageannouncement/getannouncementtypebyid",
          { announcementtypeid }
        );
        setAnnouncementTypeData(announcementtypedata.data.data[0]);
        console.log("type", AnnouncementTypeData);
      }

      setdataByIdLoadedStatus(true);
      setIsLoading(false);
    } catch (err) {
      alert("Unable to fetch Data")
    }
  };

  const getAllDataByState = async (s) => {

    // if (s == "active") {
    //   setIsLoading(true);
    //   let data = await post("/api/manageannouncement/getannouncementbystatus", {
    //     status: "true",
    //   });
    //   setData(data.data.data);
    //   setIsLoading(false);
    //   setFilterClause("Active")
    // }
    // if (s == "inactive") {
    //   setIsLoading(true);
    //   let data = await post("/api/manageannouncement/getannouncementbystatus", {
    //     status: "false",
    //   });
    //   setData(data.data.data);
    //   setIsLoading(false);
    //   setFilterClause("In-Active")
    // }
    if (s) {
      fetchData(true, s);
    }



  };

  useEffect(() => {
    fetchData(false, "All");
    fetchAllAnnouncementTypeData();
  }, []);

  const columns = [
    {
      name: "AnnouncementsID",
      selector: row => row.AnnouncementsID,
      sortable: true,
      wrap: true,
    },
    {
      name: "Title",
      selector: row => row.Title,
      sortable: true,
      wrap: true,
    },
    {
      name: "Description",
      selector: row => row.Description,
      sortable: true,
      wrap: true,
    },
    {
      name: "LinkPath",
      selector: row => row.LinkPath,
      sortable: true,
      wrap: true,
    },

    {
      name: "AnnouncementTypeID",
      selector: row => row.AnnouncementTypeID,
      sortable: true,
      wrap: true,
    },

    {
      name: "Picturepath",
      selector: row => row.Picturepath,
      sortable: true,
      wrap: true,
    },
    {
      name: "IsKansas",
      selector: row => row.IsKansas,
      cell: (row, j) => (row.IsKansas ? "Yes" : "No"),
      sortable: true,
      wrap: true,
    },
    {
      name: "IsNebraska",
      selector: row => row.IsNebraska,
      cell: (row, j) => (row.IsNebraska ? "Yes" : "No"),
      sortable: true,
      wrap: true,
    },
    {
      name: "IsCarousel",
      selector: row => row.IsCarousel,
      cell: (row, j) => (row.IsCarousel ? "Yes" : "No"),
      sortable: true,
      wrap: true,
    },
    {
      name: "CarouselImagesID",
      selector: row => row.CarouselImagesID,

      sortable: true,
      wrap: true,
    },

    // {
    //    name: 'Action',
    //    sortable: true,
    //    cell: (row, j) => (
    //       <div class="inline-flex">
    //          <button >
    //             <Link to="/add``"><img src={editimage} alt="" /></Link>
    //          </button>
    //          &nbsp;&nbsp;

    //       </div>
    //    ),
    // },
  ];

  const clearDocumentTitle = () => {
    setAnnouncementId((prev) => ({
      ...prev,
      Id: "0"
    }))
  }

  return (
    <>
      <div className="main">
        <Header />
        <div className="main-content">
          <ContentHeaderTopDetail />
          <div className="pagewithsidebar">
            <div className="container">
              <div className="pagewithsidebar-inner">
                <ContentManagementSideBar />
                <div className="tab-detail">
                  <div className="tab-detail-top children-tabs">
                    <div className="children-section">
                      <img src={manageanounce} alt="" />
                      <div className="pagesubtitle">Manage Announcements</div>
                    </div>
                  </div>

                  {isLoading ? <LoadSpinner /> : ""}

                  <div className="tab-detail-bottom children-data">
                    <div className="float-right ml-2">
                      <button className="normal" onClick={() => setShow(true)}>
                        Announcement List
                      </button>
                    </div>
                    <div className="manage-anounce mb-30 float-right">
                      <Link
                        to="#"
                        onClick={() => getAllDataByState("All")}
                        className="normal"
                      >
                        All
                      </Link>
                      <Link
                        to="#"
                        onClick={() => getAllDataByState("Active")}
                        className="normal"
                      >
                        Active
                      </Link>
                      <Link
                        to="#"
                        onClick={() => getAllDataByState("In-Active")}
                        className="normal"
                      >
                        In-Active
                      </Link>
                    </div>
                    <form className="children-doc-form manage-announce-form">

                      <div className="one-third d-flex gap-20 mb-4">
                        {/* onClick={handleSelected} */}
                        <Link
                          to="#"
                          onClick={(e) => { setaddvsupdate(false); clearDocumentTitle() }}
                          className="normal"
                        >
                          Edit
                        </Link>
                        <Link
                          to="#"
                          onClick={(e) => { setaddvsupdate(true); addNewAnnouncement(e); clearDocumentTitle() }}
                          className="normal"
                        >
                          New
                        </Link>
                      </div>
                      {(addvsupdate !== null && addvsupdate == false) &&
                        <>
                          <div className="form-row mb-30 gap-20 ai-fe">

                            <div className="one-third">
                              <label>Title Name:</label>
                              {/* onChange={e => setSelectedTitleId({ ...titleId, titleId: e.target.value })} */}
                              <select
                                className="mb-0"
                                onChange={(e) => {
                                  setAnnouncementId({
                                    ...AnnouncementId,
                                    Id: e.target.value,
                                  })
                                  AnnouncementId.Id = e.target.value;
                                  addvsupdate ? addNewAnnouncement(e) : getAnnouncementById(e);
                                }
                                }
                                name="cars"
                                id="cars"
                              >
                                <option value="0">Select</option>
                                {data.map((d) => (
                                  <option value={d.AnnouncementsID}>{d.Title}</option>
                                ))}
                              </select>
                            </div>

                          </div>
                        </>}
                      {(addvsupdate !== null && (addvsupdate ? true : AnnouncementId.Id !== "0")) &&
                        <>
                          {/* <div className="manage-anounce mb-30">
                            <Link
                              to="#"
                              onClick={() => getAllDataByState("all")}
                              className="normal"
                            >
                              All
                            </Link>
                            <Link
                              to="#"
                              onClick={() => getAllDataByState("active")}
                              className="normal"
                            >
                              Active
                            </Link>
                            <Link
                              to="#"
                              onClick={() => getAllDataByState("inactive")}
                              className="normal"
                            >
                              In-Active
                            </Link>
                          </div> */}
                          <div className="form-row">
                            <div className="one-third">
                              <label>Announcement Title:<span style={{ 'color': 'red' }}>*</span></label>
                              <input
                                value={
                                  AnnouncementById.Title ? AnnouncementById.Title : ""
                                }
                                onChange={(e) =>
                                  setAnnounceMentById({
                                    ...AnnouncementById,
                                    Title: e.target.value,
                                  })
                                }
                                type="text"
                                disabled={!isNewForm}
                              />
                            </div>
                            {/* dataByIdLoadedStatus */}

                            <div className="one-third pl-3">
                              <label>Announcement Type:<span style={{ 'color': 'red' }}>*</span></label>
                              <select
                                onChange={(e) =>
                                  setAnnounceMentById({
                                    ...AnnouncementById,
                                    AnnouncementTypeID: e.target.value,
                                  })
                                }
                                value={AnnouncementById?.AnnouncementTypeID}
                                disabled={!isNewForm}
                              >
                                <option value="0">Please Select</option>
                                {dataByIdLoadedStatus &&
                                  announcementTypeList
                                    .filter(
                                      (ann) =>
                                        ann.AnnouncementTypeID ===
                                        AnnouncementById.AnnouncementTypeID
                                    )
                                    .map((ann) => (
                                      <option selected value={ann.AnnouncementTypeID}>
                                        {ann.AnnouncementTypeName}
                                      </option>
                                    ))
                                  // ) 
                                  // : (
                                  // <option value="0">Please Select</option>
                                  // )}
                                }
                                {announcementTypeList.map((ann) => (
                                  <option value={ann.AnnouncementTypeID}>
                                    {ann.AnnouncementTypeName}
                                  </option>
                                ))}
                                {/* <option>Select</option>
                          {announcementTypeList.map(ann=>(
                           <option value={ann.AnnouncementTypeID}>{ann.AnnouncementTypeName}</option>
                          ))} */}
                              </select>

                              {/* <input onChange={e => setAnnouncementTypeData({ ...AnnouncementTypeData, AnnouncementTypeName: e.target.value })} value={AnnouncementTypeData.AnnouncementTypeName ? AnnouncementTypeData.AnnouncementTypeName : ''} type="text" /> */}
                            </div>
                            <div className="one-third pl-3 ">
                              <label>URL Link:</label>
                              <input
                                onChange={(e) =>
                                  setAnnounceMentById({
                                    ...AnnouncementById,
                                    LinkPath: e.target.value,
                                  })
                                }
                                value={
                                  AnnouncementById.LinkPath
                                    ? AnnouncementById.LinkPath
                                    : ""
                                }
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="one-third">
                              <label>
                                Picture:<span style={{ 'color': 'red' }}>*</span>
                              </label>
                              <input
                                type="file"
                                onChange={(e) => {
                                  setAnnounceMentById({
                                    ...AnnouncementById,
                                    Picturepath: e.target.files[0],
                                  });
                                  setdataByIdLoadedStatus(false);
                                }
                                }
                                id="myFile"
                                name="filename"
                              />
                              {/* {dataByIdLoadedStatus
                                ? AnnouncementById.Picturepath
                                : ""} */}
                              <span>
                                {dataByIdLoadedStatus ? AnnouncementById?.Picturepath : AnnouncementById?.Picturepath?.name}
                              </span>
                            </div>
                            <div className="one-third pl-3">
                              <label>Begin Date:<span style={{ 'color': 'red' }}>*</span></label>
                              <input
                                onChange={(e) =>
                                  setAnnounceMentById({
                                    ...AnnouncementById,
                                    BeginDate: e.target.value,
                                  })
                                }
                                value={
                                  AnnouncementById.BeginDate
                                    ? AnnouncementById.BeginDate.split("T")[0]
                                    : ""
                                }
                                type="date"
                              />
                            </div>
                            <div className="one-third pl-3">
                              <label>End Date:<span style={{ 'color': 'red' }}>*</span></label>
                              <input
                                onChange={(e) =>
                                  setAnnounceMentById({
                                    ...AnnouncementById,
                                    EndDate: e.target.value,
                                  })
                                }
                                value={
                                  AnnouncementById.EndDate
                                    ? AnnouncementById.EndDate.split("T")[0]
                                    : ""
                                }
                                type="date"
                              />
                            </div>
                          </div>
                          <br />
                          <div className="checkbox-design">
                            <span>
                              <input
                                onChange={(e) =>
                                  setAnnounceMentById({
                                    ...AnnouncementById,
                                    IsKansas: e.target.checked,
                                  })
                                }
                                type="checkbox"
                                checked={AnnouncementById.IsKansas ? "checked" : ""}
                                name="Kansas Foster-Family"
                                value="Kansas Foster-Family"
                              />
                              <label for="Kansas-Foster Family"> Kansas</label>
                            </span>
                            <span>
                              <input
                                onChange={(e) =>
                                  setAnnounceMentById({
                                    ...AnnouncementById,
                                    IsNebraska: e.target.checked,
                                  })
                                }
                                type="checkbox"
                                checked={AnnouncementById.IsNebraska ? "checked" : ""}
                                name="Kansas Kinship Family"
                                value="Kansas Kinship Family"
                              />
                              <label for="Kansas Kinship Family">Nebraska</label>
                            </span>
                            <span>
                              <input
                                onChange={(e) =>
                                  setAnnounceMentById({
                                    ...AnnouncementById,
                                    IsOklahoma: e.target.checked,
                                  })
                                }
                                type="checkbox"
                                checked={AnnouncementById.IsOklahoma ? "checked" : ""}
                                name="Kansas Therapeutic Family"
                                value="Kansas Therapeutic Family"
                              />
                              <label for="Kansas Therapeutic Family">Oklahoma</label>
                            </span>
                          </div>
                          <br />
                          <br />

                          <div className="form-row gap-20">
                            <div className="full-input">
                              <div className="desc-add-to-caraousel">
                                <label>Description:</label>
                                {/* <span>
                                          <input type="checkbox" name="Kansas Therapeutic Family" value="Kansas Therapeutic Family" />
                                          <label for="Kansas Therapeutic Family">Add to Carousel</label>
                                       </span> */}
                              </div>

                              <textarea
                                onChange={(e) =>
                                  setAnnounceMentById({
                                    ...AnnouncementById,
                                    Description: e.target.value,
                                  })
                                }
                                row="100"
                                column="20"
                                value={
                                  AnnouncementById.Description
                                    ? AnnouncementById.Description
                                    : ""
                                }
                              ></textarea>
                              <div className="checkbox-design">
                                <span>
                                  <input
                                    type="checkbox"
                                    name="Kansas Therapeutic Family"
                                    value="Kansas Therapeutic Family"
                                  />
                                  <label for="Kansas Therapeutic Family">
                                    Add to Carousel
                                  </label>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="form-grey mt-25">
                            <h6 className="small-text">Carousel Addition</h6>
                            <div className="form-row">
                              <div className="one-third pl-3">
                                <label>Image Title:</label>
                                <input type="text" />
                              </div>
                              <div className="one-third pl-3">
                                <label>Button Link URL:</label>
                                <input type="text" />
                              </div>
                              <div className="one-third pl-3">
                                <label>Upload File:</label>
                                <input type="file" />
                              </div>
                            </div>
                          </div>
                          <div className="req-update-action mb-3">
                            {addvsupdate ? (
                              <Link to="#" onClick={storeData} className="normal">
                                Submit
                              </Link>
                            ) : (
                              ""
                            )}
                            {addvsupdate ? (
                              ""
                            ) : (
                              <Link
                                to="#"
                                onClick={updateAnnoncement}
                                className="normal"
                              >
                                Update
                              </Link>
                            )}
                            {/* {AnnouncementId?'': <Link to="#" onClick={deleteAnnouncement} className="close">Delete</Link>} */}
                            <Link
                              to="#"
                              onClick={CancelAnnouncement}
                              className="close"
                            >
                              Cancel
                            </Link>
                          </div>
                        </>}
                    </form>
                  </div>

                  {show && (
                    <div className="modal fade show" style={{ display: "block", padding: "0 40px", marginTop: "30px" }}>
                      <div className="modal-dialog modal-90w">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Announcement List - ({filterClause})</h5>
                            <button
                              type="button"
                              className="close-btn-modal"
                              onClick={() => setShow(false)}
                            >
                              &times;
                            </button>
                          </div>
                          <div className="modal-body">
                            <DataTable columns={columns} data={data} pagination={true} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {show && <div className="modal-backdrop fade show"></div>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div >

    </>
  );
}

export default ManageAnnouncement;
