
import adoptioncm  from '../../../images/assets/cm/adoptioncm.svg';
import calendercm  from '../../../images/assets/cm/calendercm.svg';
import contentcm  from '../../../images/assets/cm/contentcm.svg';
import caregivercm  from '../../../images/assets/cm/caregivercm.svg';
import infocm  from '../../../images/assets/cm/infocm.svg';
import managecm  from '../../../images/assets/cm/managecm.svg';
import annoucementcm  from '../../../images/assets/cm/annoucementcm.svg';
import carouselcm  from '../../../images/assets/cm/carouselcm.svg';
import newsletterscm  from '../../../images/assets/cm/newsletterscm.svg';
import manageparentcm  from '../../../images/assets/cm/manageparentcm.svg';
import trainingscm  from '../../../images/assets/cm/trainingscm.svg';

function ContentManagementSideBar() {

   



    return (



        <div className="sidebar">
            <ul>
                <li><a href="/contentmanage/adoptioncontact"><img src={adoptioncm} alt="" /><span>Adoption Contacts</span></a></li>
                <li><a href="/contentmanage/calenderofevents"><img src={calendercm} alt="" /><span>Calendar of Events</span></a></li>
                <li><a href="/contentmanage/managecontentlinks"><img src={contentcm} alt="" /><span>Manage Content Links</span></a></li>
                <li><a href="/contentmanage/sfmcaregiverrecognition"><img src={caregivercm} alt="" /><span>SFM CareGiver Recognition</span></a></li>
                <li><a href="/contentmanage/informationupdate"><img src={infocm} alt="" /><span>Information Update</span></a></li>
                <li><a href="/contentmanage/caraouseldoc"><img src={managecm} alt="" /><span>Manage Carousel Documents</span></a></li>
                <li><a href="/contentmanage/manageannouncements"><img src={annoucementcm} alt="" /><span>Manage Announcement</span></a></li>
                <li><a href="/contentmanage/managecorousel"><img src={carouselcm} alt="" /><span>Manage Carousel</span></a></li>
               {/* <li><a href="/contentmanage/managenewsletter"><img src={newsletterscm} alt="" /><span> Manage NewsLetter</span></a></li>*/}
                <li><a href="/contentmanage/manageparentdocuments"><img src={manageparentcm} alt="" /><span>Manage Parent Documents</span></a></li>
                <li><a href="/contentmanage/managetranining"><img src={trainingscm} alt="" /><span>Manage Training</span></a></li>
           
            </ul>
        </div>




    );
}
export default ContentManagementSideBar;