import Footer from "../../Footer";
import Header from "../../Header";
import MyInformationinner from "../../../images/assets/myinfo/MyInformationinner.svg";
import download from "../../../images/assets/myinfo/download.svg";
import search from "../../../images/assets/myinfo/search.svg";
// import acmanage from '../../images/assets/images/ManageInfo.svg';
import SideBar from "../../sidebar/sidebar";
import "../ImmidiateAssesment/ImmidiateAssesment.scss";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import { Link } from "react-router-dom";
import CriticalUnusualIncidentFormDownload from "../../downloadableForms/CriticalUnusualIncidentDownload/CriticalUnusualIncidentFormDownload";
import ImmidiateAssesmentFormDownload from "../../downloadableForms/ImmidiateAssesmentDownload/ImmidiateAssesmentFormDownload";
import { post } from "../../../utils/common";
import { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import html2canvas from "html2canvas";
import ReactDOMServer from "react-dom/server";
import jsPDF from "jspdf";
import logoplacement from "../../../images/logo-placement.jpg";
import ImmidiateAssesmentFormPrint from "../../downloadableForms/ImmidiateAssesmentDownload/ImmidiateAssesmentPrint";
import plus from '../../../images/assets/cm/plus.svg';
import edit from "../../../images/assets/myinfo/edit.svg";
const doc = new jsPDF();
// const CIFORMPDF = <CriticalUnusualIncidentFormPrint />;

function ImmidiateAssesment() {
  const inputRef = useRef(null);

  const viewForm = () => {
    window.open("/print/immidiateassesment");
  };

  const [IAHistoryFromString, setIAHistoryFromString] = useState([]);
  const [IACalmingFromString, setIACalmingFromString] = useState([]);
  const [IAIndicatorsFromString, setIAIndicatorsFromString] = useState([]);
  const [IATriggersFromString, setIATriggersFromString] = useState([]);


  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onImmidiateForm = () =>{
    localStorage.setItem('fromButtonClick', true);
    window.location.href = "/form/immidiateassesmentform";
    
  }

  const editIAForm = async (row) => {
    if(data[row].Form_Status == "Signed and completed"){
      alert("Form Is Completed, You Cannot Edit")
      return;
    }
    setIsLoading(true);
    let SFCSFamiliesIAID = data[row].SFCSFamiliesIAID;
    const providerid = sessionStorage.getItem("providerSessionId");
    sessionStorage.removeItem("SFCSFamiliesIAID");
    sessionStorage.setItem("SFCSFamiliesIAID", SFCSFamiliesIAID);

    let dataIA = await post("/api/immidiateassesment/getiaform", {
      providerid,
      SFCSFamiliesIAID,
    }); 

    sessionStorage.removeItem("iaformdata");
    sessionStorage.removeItem("iabodyformdata");
    sessionStorage.setItem("iaformdata", JSON.stringify(dataIA.data.data[0]));
    sessionStorage.setItem(
      "iabodyformdata",
      JSON.stringify(dataIA.data.bodyData[0] ? dataIA.data.bodyData[0] : [])
    );
    localStorage.setItem("FromIA", "true");
  
    window.location.href = "/form/ImmidiateAssesmentEdit";
    setIsLoading(false);
    // data[j]
  };

  const fetchData = async () => {
    const providerid = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/immidiateassesment/getlistdata", {
      providerid,
    });
    setData(data.data.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [SAHistory, setSAHistory] = useState([]);
  const [SAIndicators, setSAIndicators] = useState([]);
  const [SACalming, setSACalming] = useState([]);
  const [SATriggers, setSATriggers] = useState([]);



  const [dataForDownload, setDataForDownload] = useState([]);
  const clickedForDownload = async (row) => {
    let SFCSFamiliesIAID = data[row].SFCSFamiliesIAID;
    const providerid = sessionStorage.getItem("providerSessionId");
    let dataIA = await post("/api/immidiateassesment/getiaform", {
      providerid,
      SFCSFamiliesIAID,
    });

    sessionStorage.removeItem("iaformdata");
    sessionStorage.removeItem("iabodyformdata");

    sessionStorage.setItem("iaformdata", JSON.stringify(dataIA.data.data[0]));
    sessionStorage.setItem(
      "iabodyformdata",
      JSON.stringify(dataIA.data.bodyData[0] ? dataIA.data.bodyData[0] : [])
    );
    window.open(
      "/print/immidiateassesmentform",
      "PRINT",
      "height=1000,width=1000"
    );
  };
  const [PdfData, setPdfData] = useState([]);
  const [PdfBodyData, setPdfBodyData] = useState([]);
  const DownLoadPdf = async (row) => {
    let SFCSFamiliesIAID = data[row].SFCSFamiliesIAID;
    const providerid = sessionStorage.getItem("providerSessionId");
    let dataIA = await post("/api/immidiateassesment/getiaform", {
      providerid,
      SFCSFamiliesIAID,
    });

    sessionStorage.removeItem("iaformdata");
    sessionStorage.removeItem("iabodyformdata");

    sessionStorage.setItem("iaformdata", JSON.stringify(dataIA.data.data[0]));
    sessionStorage.setItem(
      "iabodyformdata",
      JSON.stringify(dataIA.data.bodyData[0] ? dataIA.data.bodyData[0] : [])
    );

       if(dataIA.data.data[0].SAHistory != null){
      let string = dataIA.data.data[0].SAHistory;
      let arrayofIAHistory = string.split("\r\n");
      setIAHistoryFromString(arrayofIAHistory);
    
    }
    if(dataIA.data.data[0].SACalming != null){
        let string1 = dataIA.data.data[0].SACalming;
        let arrayofIACalming = string1.split("\r\n");
        setIACalmingFromString(arrayofIACalming);
    }
    if(dataIA.data.data[0].SAIndicators != null){
        let string2 = dataIA.data.data[0].SAIndicators;
        let arrayofIAIndicators = string2.split("\r\n");
        setIAIndicatorsFromString(arrayofIAIndicators);
    }
    if(dataIA.data.data[0].SATriggers != null){
        let string3 = dataIA.data.data[0].SATriggers;
        let arrayofIATriggers = string3.split("\r\n");
        setIATriggersFromString(arrayofIATriggers);
    }

    setPdfData(dataIA.data.data[0]);
    setPdfBodyData(dataIA.data.bodyData[0]);

   

    html2canvas(inputRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF("p", "mm");
      var position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("download.pdf");
    });
  };

  // console.log("dow", dataForDownload);

  const columns = [
    {
      name: "Client Name",
      selector: row => row.Client_Name,
      // cell: (row, j) => (row.DocumentType[0]),
      sortable: true,
    },
    {
      name: "Created Date",
      selector: row => row.EnteredDate,
      // cell: (row, j) => (row.DocumentType[0]),
      sortable: true,
    },
    {
      name: "Date of Placement",
      selector: row => row.Date_of_Placement,
      // cell: (row, j) => (new Date(row.UploadDate).toLocaleDateString()),
      sortable: true,
    },
    {
      name: "Form Status",
      selector: row => row.Form_Status,
      sortable: true,
    },

    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div className="inline-flex">
          <Link
            onClick={() => clickedForDownload(j)}
            className="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            to="#"
          >
            <img src={search} alt="edit"></img>
          </Link>
          &nbsp;
          <Link
            onClick={() => editIAForm(j)}
            class="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            to="#"
          >
            <img src={edit}></img>
          </Link>
          &nbsp;
          <Link
            onClick={() => DownLoadPdf(j)}
            to="#"
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={download} alt="download"></img>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div className="main">
      <Header />

      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <SideBar />
              <div className="tab-detail pl">
                <div className="tab-detail-top">
                  <div className="m-profile">
                    <img src={MyInformationinner} alt="" />
                    <div className="pagesubtitle">
                      Immediate Assessment
                      <p>
                        Below is a record of Immediate assessment reports you
                        have completed.
                      </p>
                    </div>
                  </div>
                  { <Link to="#" href="#" onClick={onImmidiateForm} className="request-btn"><img src={plus} style={{"height": "18px"}} alt="#"/> Add New Form</Link>}
                </div>

                <div id="divToPrint" ref={inputRef} class="divprint">
                  <div className="main">

                    <div className="main-content">
                      <div className="pagewithsidebar">
                        <div className="container">
                          <div className="pagewithsidebar-inner">
                            <div className="tab-detail Critical pagealignpad">
                              <div className="tab-detail-top">
                                {/* <div className="Critical-unusual">
                                  <img src={lodinner} alt="" />
                                  LoG of Daily Events
                                </div> */}
                                <div className="pdfheader">
                                  <div
                                    style={{
                                      "text-align": "right",
                                      "marging-top": "0px",
                                      position: "relative",
                                      "margin-left": "300px",
                                    }}
                                  >
                                    <img
                                      className="footer-logoo"
                                      src={logoplacement}
                                      alt=""
                                    />
                                  </div>
                                  <h4
                                    className="title"
                                    style={{
                                      "text-align": "center",
                                      "font-size": "1.45em",
                                      "font-weight": "bold",
                                      "margin-top": "0px",
                                      "margin-left": "500px",
                                    }}
                                  >
                                    Saint Francis Ministries, Inc <br></br>
                                    Immediate assessment
                                  </h4>
                                </div>
                              </div>
                              <div className="mt-50">
                                <form className="fontweight">
                                  <div className="form-row dandt">
                                    <div className="one-fourth">
                                      <label for="birthday" className="increasedFont">Child:</label>
                                      <input
                                      className="increasedFont"
                                        type="text"
                                        value={PdfData.ClientName}
                                      />
                                    </div>
                                    <div className="one-fourth">
                                      <label for="appt" className="increasedFont">Date of Birth:</label>
                                      <input
                                      className="increasedFont"
                                        type="text"
                                        value={PdfData.DOB}
                                        id="appt"
                                        name="appt"
                                      />
                                    </div>
                                    <div className="one-fourth">
                                      <label for="birthday" className="increasedFont">
                                        Date of Placement:
                                      </label>
                                      <input
                                      className="increasedFont"
                                        type="text"
                                        value={PdfData.DateOfPlacement}
                                        id="birthday"
                                        name="birthday"
                                      />
                                    </div>
                                    <div className="one-fourth">
                                      <label for="appt" className="increasedFont">Placement Time:</label>
                                      <input
                                      className="increasedFont"
                                        type="text"
                                        value={PdfData.PlacementTime}
                                        id="appt"
                                        name="appt"
                                      />
                                    </div>
                                  </div>
                                  <div className="form-row mt-25">
                                    <div className="full-input">
                                      <label for="appt" className="increasedFont">
                                        1. List any medication allergies or
                                        special conditions/limitations
                                      </label>
                                      <input type="text" className="increasedFont" value={PdfData.SpecialConditions}/>

                                    </div>
                                  </div>
                                  <div className="form-row mt-25">
                                    <div className="full-input">
                                      <label for="appt" className="increasedFont">
                                        2. List any food allergies/special
                                        diets/eating or nutritional
                                        abnormalities
                                      </label>
                                      <input type="text" className="increasedFont" value={PdfData.SpecialDiets}/>
                                    </div>
                                  </div>

                                  <div className="g-background mt-25">
                                    <div className="form-row">
                                      <label for="appt" className="increasedFont">
                                        3. Description and location of tattoo's,
                                        cuts, bruises or skin rashes:
                                      </label>
                                      <small>
                                        <i>
                                          (This is not intended to indicate need
                                          to unclothe child to conduct search.
                                          Ask child/youth if there are any such
                                          marks that are not visible, indicate
                                          where they are and write "reported by
                                          child / youth")
                                        </i>
                                      </small>
                                      <div className="form-row mt-25">
                                        <div className="label20 labelpadtop">
                                          <label for="appt" className="increasedFont">General Body Location:</label>
                                        </div>
                                        <div className="label25">
                                          <input type="text" className="increasedFont" value={PdfBodyData ? PdfBodyData.GeneralLocation : ''} />

                                        </div>
                                        <div className="label3"></div>
                                        <div className="label20 labelpadtop">
                                          <label for="appt" className="increasedFont">Detail Body Location:</label>
                                        </div>
                                        <div className="label25">
                                          <input type="text" className="increasedFont" value={PdfBodyData ? PdfBodyData.DetailLocation : ''} />

                                        </div>
                                      </div>
                                      <div className="one-full">
                                        <label for="appt" className="increasedFont">Notes:</label>
                                        <textarea className="textareaheight increasedFont"
                                          value={PdfBodyData ? PdfBodyData.Notes : ''}
                                        ></textarea>
                                      </div>
                                      <div className="one-full">
                                        <label for="appt" className="increasedFont">Description:</label>
                                        <textarea className="textareaheight increasedFont"
                                         value={PdfBodyData ? PdfBodyData.Description : ''}></textarea>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-row mt-0">
                                    <div className="full-input">
                                      <label for="appt" className="increasedFont">
                                        4. Describe any other current physical
                                        complaint, illness or pain
                                      </label>
                                      <input type="text" className="increasedFont"  value={PdfData.PhysicalComplaints}/>
                                    </div>
                                  </div>
                                  <div className="form-row mt-25">
                                    <div className="full-input">
                                      <label for="appt" className="increasedFont">
                                        5. Describe the mood or attitude of
                                        child/youth at the time of placement.
                                      </label>
                                      <input type="text" className="increasedFont"  value={PdfData.ClientAttitude}/>
                                    </div>
                                  </div>

                                  <div className="g-background mt-10">
                                    <div className="form-row">
                                      <div className="one-full">
                                        <label className="checkbox-label increasedFont">
                                          6. Does child/youth have thoughts of
                                          harming self or others?{" "}
                                          <b className="increasedFont">{PdfData.IsHarmfulThoughts}</b>
                                        </label>
                                        <label for="appt" className="increasedFont">
                                          If Yes, what have they thought about
                                          and is there a plan?{" "}
                                          {PdfData.HarmfulThoughtsDescription}
                                        </label>
                                      </div>
                                      <div className="one-full">
                                        <label className="checkbox-label increasedFont">
                                          7. Does child/youth feel like running
                                          away? <b className="increasedFont">{PdfData.IsRunningAway}</b>
                                        </label>
                                        <label className="check-action increasedFont">
                                          If Yes, Is there a plan?{" "}
                                          <span>
                                            <b className="increasedFont">{PdfData.IsRunningAwayPlan}</b>{" "}
                                          </span>
                                        </label>
                                      </div>
                                      <small >
                                        ***IMPORTANT: If answers to questions 6
                                        or 7 are yes, Resource Family will
                                        contact FCH worker, remain one on one
                                        with child to develop plan, and/or
                                        contact case manager emergency number.
                                        ***
                                      </small>
                                    </div>
                                  </div>

                                  <div className="form-row check-section fd-c">
                                    <h4 className="increasedFont" style={{"color":"#1C4C7A"}} >Safety Assessment Tool</h4>
                                    <label for="appt" className="increasedFont"  style={{"fontSize":"18px"}} >
                                      Does child/youth have a history of any of
                                      the following (obtain information from
                                      placing agency)?
                                    </label>
                                    <div className="label75">
                        <ul>
                          {IAHistoryFromString.length>0?IAHistoryFromString.slice(
                            0,
                            IAHistoryFromString.length - 1
                          ).map((iaData, index) => (
                            <li>
                              {/* <input
                                type="checkbox"
                                id="vehicle2"
                                name="vehicle2"
                              /> */}
                              <label for="vehicle2" className="increasedFont">
                                {" "}
                                {iaData}{" "}
                              </label>
                            </li>
                          )):''}
                        </ul>
                      </div>
                                    <div className="checkbox-grid">
                                      <div className="one-fourth">
                                        <span className="check-option ">
                                          <label for="vehicle2" className="increasedFont" style={{"fontSize":"18px"}}>
                                            Other (please list)
                                            <input
                                      className="increasedFont"
                                        type="text"
                                        value={PdfData.OtherHistoryList}
                                      />
                                          </label>
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-row check-section fd-c">
                                    <h4 className="increasedFont"  style={{"color":"#1C4C7A","fontSize":"18px", "marginTop":"200px"}} >
                                      Ask child / youth following questions
                                    </h4>
                                    <label for="appt" className="increasedFont" style={{"fontSize":"18px"}}>
                                      {" "}
                                      These things are "triggers" for
                                      child/youth and cause him/her to get upset
                                      or mad. (check all that apply)
                                    </label>
                                    <div className="label75">
                        <ul>
                          {IATriggersFromString.length>0?IATriggersFromString.slice(
                            0,
                            IATriggersFromString.length - 1
                          ).map((iaData3, index) => (
                            <li>
                              {/* <input
                                type="checkbox"
                                id="vehicle2"
                                name="vehicle2"
                              /> */}
                              <label for="vehicle2" className="increasedFont">
                                {" "}
                                {iaData3}{" "}
                              </label>
                            </li>
                          )):''}
                        </ul>
                      </div>
                                    <div className="checkbox-grid">
                                      <div className="one-fourth">
                                        <span className="check-option">
                                          <label for="vehicle2" className="increasedFont" style={{"fontSize":"18px"}}>
                                            {" "}
                                            Other (please list) 
                                            <input
                                      className="increasedFont"
                                        type="text"
                                        value={PdfData.OtherTriggerList}
                                      />
                                          </label>
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-row check-section fd-c">
                                    <label for="appt" className="increasedFont" style={{"color":"#1C4C7A", "fontSize":"18px"}} >
                                      When child/youth is mad or upset, they
                                      report they do these things. (check all
                                      that apply)
                                    </label>
                                    <div className="label75">
                        <ul>
                          {IAIndicatorsFromString.length>0?IAIndicatorsFromString.slice(
                            0,
                            IAIndicatorsFromString.length - 1
                          ).map((iaData2, index) => (
                            <li>
                              {/* <input
                                type="checkbox"
                                id="vehicle2"
                                name="vehicle2"
                              /> */}
                              <label for="vehicle2" className="increasedFont">
                                {" "}
                                {iaData2}{" "}
                              </label>
                            </li>
                          )):''}
                        </ul>
                      </div>
                                    <div className="checkbox-grid">
                                      <div className="one-fourth">
                                        <span className="check-option">
                                          <label for="vehicle2" className="increasedFont" style={{"fontSize":"18px"}}>
                                            Other (please list)
                                            <input
                                      className="increasedFont"
                                        type="text"
                                        value={PdfData.OtherUpsettingIndicatorsList}
                                      />
                                          </label>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-row check-section fd-c">
                                    <label for="appt" className="increasedFont" >
                                      Child/youth reports these things help calm
                                      him/her down when they are mad or upset.
                                      (check all that apply)
                                    </label>
                                    <div className="label75">
                        <ul>
                          {IACalmingFromString.length>0?IACalmingFromString.slice(
                            0,
                            IACalmingFromString.length - 1
                          ).map((iaData1, index) => (
                            <li>
                              {/* <input
                                type="checkbox"
                                id="vehicle2"
                                name="vehicle2"
                              /> */}
                              <label for="vehicle2" className="increasedFont">
                                {" "}
                                {iaData1}{" "}
                              </label>
                            </li>
                          )):''}
                        </ul>
                      </div>
                                    <div className="checkbox-grid">
                                      <div className="one-fourth">
                                        <span className="check-option">
                                          <label for="vehicle2" className="increasedFont" style={{"fontSize":"18px"}}>
                                            Other (please list)
                                            <input
                                      className="increasedFont"
                                        type="text"
                                        value={PdfData.OtherCalmingActivitiesList}
                                      />
                                          </label>
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-row mt-25">
                                    <div className="label15 labelpadtop">
                                      <label
                                        for="appt"
                                        className="increasedFont"
                                      >
                                        Person Reporting:
                                      </label>
                                    </div>
                                    <div className="label23">
                                      <input
                                        className="increasedFont"
                                        type="text"
                                        id="birthday"
                                        name="birthday"
                                        onChange={(e) =>
                                          setPdfData({
                                            ...PdfData,
                                            Provider_Member: e.target.value,
                                          })
                                        }
                                        value={PdfData.Provider_Member}
                                      />

                                      {/* <select>
                                                        <option value="0">Select</option>

                                                    </select> */}
                                    </div>
                                    <div className="label7"></div>
                                    <div className="label15 labelpadtop">
                                      <label
                                        for="birthday"
                                        className="increasedFont"
                                      >
                                        Date Completed:
                                      </label>
                                    </div>
                                    <div className="label23">
                                      <input
                                        type="text"
                                       // className="increasedFont"
                                        onChange={(e) =>
                                          setPdfData({
                                            ...PdfData,
                                            CompletedDate: e.target.value,
                                          })
                                        }
                                        value={PdfData.CompletedDate}
                                      />
                                     
                                    </div>
                                  </div>


                                  {/* <div className="g-background mt-10" style={{"marginBottom":"150px"}} >
                                    <h6 className="increasedFont">MEDICATIONS THAT ARRIVED WITH CHILD/YOUTH</h6>
                                    <div className="form-row">
                                      <div className="label18">
                                        <label for="appt" className="increasedFont">Name of Medication:</label>
                                        <input type="text" className="increasedFont" />
                                      </div>
                                      <div className="label18">
                                        <label for="appt" className="increasedFont">Dose:</label>
                                        <input type="text" className="increasedFont" />
                                      </div>
                                      <div className="label18">
                                        <label for="appt" className="increasedFont">When Given:</label>
                                        <input type="text"  className="increasedFont"/>
                                      </div>
                                      <div className="label18">
                                        <label for="appt" className="increasedFont">What for:</label>
                                        <input type="text" className="increasedFont"/>
                                      </div>
                                      <div className="label18">
                                        <label for="appt" className="increasedFont">Initial Count:</label>
                                        <input type="text" className="increasedFont" />
                                      </div>
                                    </div>
                                  </div> */}

                                  {/* <div className="form-row g-background">
                                    <div className="label25 ">
                                      <label for="birthday" className="increasedFont">
                                        Person Electronically Signing the Form:
                                      </label>
                                      <input
                                      className="increasedFont"
                                        type="text"
                                        value={PdfData.ProviderMember}
                                      />
                                    </div>
                                    <div className="label5"></div>
                                    <div className="label40 i-agree">
                                      <span className="check-option">
                                        <input
                                          className="increasedFont"
                                          type="checkbox"
                                          id="vehicle2"
                                          name="vehicle2"
                                          value="Car"
                                        />
                                        <label for="vehicle2" className="increasedFont">
                                          I, name have reviewed the above
                                          document and agree it is correct.
                                        </label>
                                      </span>
                                      <span>
                                        <label for="appt" className="increasedFont">
                                          Login Password *:
                                        </label>
                                        <input type="text" />
                                      </span>
                                      <small className="red-text">
                                        *You must “Sign and Submit” to
                                        complete this electronic form.
                                      </small>
                                    </div>
                                    <div className="label5"></div>
                                    <div className="label25">
                                      <ul className="immediate-assessment">
                                        <li>
                                          Provide original to foster care
                                          worker.
                                        </li>
                                        <li>Copy to Resource Family.</li>
                                        <li>
                                          Copy to oversight agency for the
                                          child.
                                        </li>
                                      </ul>
                                    </div>
                                  </div> */}

                                  <div className="action-btn"></div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <Footer /> */}
                  </div>
                </div>

                <div className="tab-detail-bottom">
                  <form className="attach-doc-form">
                    <DataTable
                      columns={columns}
                      data={data}
                      pagination={false}
                    />
                    {/* <table>
                                            <tr>
                                                <th>Client Name</th>
                                                <th>Date of Placement</th>
                                                <th>Form Status</th>
                                                <th width="80px"></th>
                                            </tr>
                                            <tr>
                                                <td>Alfreds Futterkiste</td>
                                                <td>Maria Anders</td>
                                                <td>Germany</td>
                                                <td className="table-act" align="right"><Link to="#"><img src={search} alt="" onClick={viewForm} /></Link> <ImmidiateAssesmentFormDownload /></td>
                                            </tr>
                                            <tr>
                                                <td>Alfreds Futterkiste</td>
                                                <td>Maria Anders</td>
                                                <td>Germany</td>
                                                <td className="table-act" align="right"><Link to="#"><img src={search} alt="" onClick={viewForm} /></Link><ImmidiateAssesmentFormDownload /></td>
                                            </tr>
                                            <tr>
                                                <td>Alfreds Futterkiste</td>
                                                <td>Maria Anders</td>
                                                <td>Germany</td>
                                                <td className="table-act" align="right"><Link to="#"><img src={search} onClick={viewForm} alt="" /></Link> <ImmidiateAssesmentFormDownload /></td>
                                            </tr>
                                            <tr>
                                                <td>Alfreds Futterkiste</td>
                                                <td>Maria Anders</td>
                                                <td>Germany</td>
                                                <td align="right" className="table-act"><Link to="#"><img src={search} onClick={viewForm} alt="" /></Link> <ImmidiateAssesmentFormDownload /></td>
                                            </tr>

                                        </table> */}

                    {/* <div className="Assessment-detail">
                                            <div className="Assessment-detail-field">
                                                <div className="one-four-field">
                                                    <label>Child Name:</label>
                                                    <input type="text" />
                                                </div>
                                                <div className="one-four-field">
                                                    <label>Date of Birth:</label>
                                                    <input type="text" />
                                                </div>
                                                <div className="one-four-field">
                                                    <label>Date of Placement:</label>
                                                    <input type="text" />
                                                </div>
                                                <div className="one-four-field">
                                                    <label>Placement Time (ex. 10:00am):</label>
                                                    <input type="text" />
                                                </div>
                                            </div>
                                            <div className="form-row-Immidiate">
                                                <label>1. List any medication allergies or special conditions/limitations (contact SFCS nurse for assessment needs).</label>
                                                <input type="text" />
                                            </div>
                                            <div className="form-row-Immidiate">
                                                <label>2. List any food allergies/special diets</label>
                                                <input type="text" />
                                            </div>
                                            <div className="grey-field">
                                                <div className="form-row-Immidiate">
                                                    <label>3. Description and location of tattoo's, cuts, bruises or skin rashes:
                                                        <span>(This is not intended to indicate need to unclothe child to conduct search. Ask child/youth if there are any such marks that are not visible, indicate where they are and write "reported by child / youth")</span>
                                                    </label>
                                                    <div className="inner-form-field">
                                                        <div className="one-third-field">
                                                            <label>General Body Location:</label>
                                                            <input type="text" />
                                                        </div>
                                                        <div className="one-third-field">
                                                            <label>Detail Body Location:</label>
                                                            <input type="text" />
                                                        </div>
                                                        <div className="one-third-field">
                                                            <label>Notes:</label>
                                                            <input type="text" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <label>Description:</label>
                                                <textarea></textarea>
                                            </div>
                                            <div className="form-row-Immidiate">
                                                <label>4. Describe any other current physical complaint or illness.</label>
                                                <input type="text" />
                                            </div>
                                            <div className="form-row-Immidiate">
                                                <label>5. Describe the mood or attitude of child/youth at the time of placement.</label>
                                                <input type="text" />
                                            </div>
                                            <div className="grey-field">
                                                <div className="form-row-Immidiate">
                                                    <div className="label-action">
                                                        <label>6. Description and location of tattoo's, cuts, bruises or skin rashes:</label>
                                                        <div className="label-check">
                                                            <span><input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />Yes</span>
                                                            <span><input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />No</span>
                                                        </div>
                                                    </div>
                                                    <div className="inner-form-field">
                                                        <div className="one-third-field">
                                                            <label>Date of Placement:</label>
                                                            <input type="text" />
                                                        </div>
                                                        <div className="one-third-field">
                                                            <label>Date of Placement:</label>
                                                            <input type="text" />
                                                        </div>
                                                        <div className="one-third-field">
                                                            <label>Date of Placement:</label>
                                                            <input type="text" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <label>Description:</label>
                                                <textarea></textarea>
                                            </div>

                                            <div className="form-row check-section fd-c gap-0">
                                                <h6>Safety Assessment Tools</h6>
                                                <label for="appt"> Does child/youth have a history of any of the following (obtain information from placing agency)?</label>
                                                <div className="checkbox-grid">
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-row check-section fd-c gap-0">
                                                <h6>***Ask child / youth following questions***</h6>
                                                <label for="appt"> These things are "triggers" for child/youth and cause him/her to get upset or mad. (check all that apply)</label>
                                                <div className="checkbox-grid">
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row check-section fd-c gap-0">
                                                <label for="appt"> When child/youth is mad or upset, they report they do these things. (check all that apply)</label>
                                                <div className="checkbox-grid">
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row check-section fd-c gap-0">
                                                <label for="appt"> Child/youth reports these things help calm him/her down when they are mad or upset. (check all that apply)</label>
                                                <div className="checkbox-grid">
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-grey mt-25">
                                                <h6>MEDICATIONS THAT ARRIVED WITH CHILD/YOUTH</h6>
                                                <div className="form-row ai-fe">
                                                    <div className="one-fifth">
                                                        <label>Name of Medication:</label>
                                                        <input type="text" />
                                                    </div>
                                                    <div className="one-fifth">
                                                        <label>Dose:</label>
                                                        <input type="text" />
                                                    </div>
                                                    <div className="one-fifth">
                                                        <label>When Given:</label>
                                                        <input type="text" />
                                                    </div>
                                                    <div className="one-fifth">
                                                        <label>What for:</label>
                                                        <input type="text" />
                                                    </div>
                                                    <div className="one-fifth">
                                                        <label>Initial Count:</label>
                                                        <input type="text" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-row fd-c">
                                                <div className="full-input">
                                                    <label><b>Person electronically signing the form:  Brenda Stovall/Don Stovall</b></label>
                                                    <label><b>Person electronically signing the form: </b></label>
                                                </div>
                                                <div className="one-half">
                                                    <label>Name of Medication:</label>
                                                    <input type="email" />
                                                </div>


                                            </div>

                                            <div className="form-grey bg-blue mt-25">
                                                <div className="form-row ai-fe">
                                                    <div className="full-input">
                                                        <span className="check-option">
                                                            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                                                            <label for="vehicle2"> I have a car</label>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="form-row ai-c">
                                                    <div className="one-half">
                                                        <label>Logon Password *</label>
                                                        <input type="text" />
                                                    </div>
                                                    <div className="one-half">
                                                        <label>* You must "Sign and Submit" to complete this electronic form.</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="one-fourth">
                                                    <label>Provide Original to Foster Care Worker Copy to Resource Family Copy to oversight agency for the child</label>
                                                </div>
                                            </div>
                                        </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ImmidiateAssesment;