import Footer from "../../Footer";
import Header from "../../../frontendPages/Header";
import ContentManagementSideBar from "../ContentManagementSideBar/ContentManagementSideBar";
import sfminner from "../../../images/assets/cm/sfminner.svg";
import ContentHeaderTopDetail from "../ContentHeaderTopDetail/ContentHeaderTopDetail";
import "../AdoptionContact/AdoptionContact.scss";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import editimage from "../../../images/assets/cm/edit.svg";
import contactimage from "../../../images/assets/cm/contact.svg";
import trashimage from "../../../images/assets/cm/trash.svg";
import DataTable from "react-data-table-component";
import { post, put } from "../../../utils/common";
import { Editor } from "@tinymce/tinymce-react";
// import { DropdownDate, DropdownComponent } from "react-dropdown-date";
import axios from "axios";
import LoadSpinner from "../../Loader/LoadSpinner";

function SFMCareGiverRecognition() {
  const editorRef = useRef(null);
  const [titleData, setTitleData] = useState([]);
  const [providerMonthId, setProviderMonthId] = useState([]);
  const [SFMCaregiverData, setSFMCAreGiverData] = useState([]);
  // const [startDate, setStartDate] = useState(new Date());

  const fetchData = async () => {
    setArticleTitleDiv(true);

    setIsLoading(true);
    let data = await fetch("/api/sfmcaregiver/index");
    if (!data.ok) {
      return;
    }
    const AllData = await data.json();
    setTitleData(AllData.data);
    setIsLoading(false);
  };

  const [articleTitleDiv, setArticleTitleDiv] = useState(true);
  const [dataByIdLoadedStatus, setdataByIdLoadedStatus] = useState(false);

  function selectElement(id, valueToSelect) {
    let element = document.getElementById(id);
    element.value = valueToSelect;
  }

  //  const [databyIdLoadedStatus,setDataByIdLoadedStatus] = useState(true);
  const fetchSFMDataByProviderMonthId = async () => {
    setArticleTitleDiv(true);

    setIsLoading(true);
    const id = providerMonthId.Id;
    let allDataById = await post("/api/sfmcaregiver/getdatabyid", { id });
    setSFMCAreGiverData(allDataById.data.data[0]);
    setSFMData(allDataById.data.data[0]);
    console.log("sfm", SFMCaregiverData);
    // setArticleTitleDiv(false);
    setdataByIdLoadedStatus(true);

    let AwardDate = allDataById.data.data[0].AwardDate;

    let MonthValue = AwardDate.slice(5, 7);

    let YearValue = AwardDate.slice(0, 4);

    MonthValue = parseInt(MonthValue);

    YearValue = parseInt(YearValue);

    selectElement("month_list", MonthValue - 1);
    selectElement("year_list", YearValue);

    // document.getElementById("month_list").value=MonthArray[MonthValue];

    console.log("monthValue", MonthValue);

    console.log("Year Value", YearValue);

    setIsLoading(false);
  };

  const AddArticleNew = () => {
    setSFMCAreGiverData([]);
    setSFMData([]);
    setArticleTitleDiv(true);
    window.location.reload(false);
  };

  // const formatDate = (date) => {
  //   setIsLoading(true);
  //   // formats a JS date to 'yyyy-mm-dd'
  //   var d = new Date(date),
  //     month = "" + (d.getMonth() + 1),
  //     day = "" + d.getDate(),
  //     year = d.getFullYear();

  //   if (month.length < 2) month = "0" + month;
  //   if (day.length < 2) day = "0" + day;

  //   return [year, month, day].join("-");
  //   setIsLoading(false);
  // };

  const closeForm = () => {
    window.location.reload(false);
  };

  const getYearList = () => {
    const year = new Date("1990").getFullYear();
    return (
      // <option>Select</option>
      Array.from(new Array(50), (v, i) => (
        <option key={i} value={year + i}>
          {year + i}
        </option>
      ))
    );
  };

  const [isLoading, setIsLoading] = useState(false);

  const [SFMData, setSFMData] = useState([]);
  const onSubmitButtonClickStore = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (!SFMData.FileName) {
      alert("Attach File");
      setIsLoading(false);
      return false;
    }
    if (!SFMData.ProgramMonthID) {
      alert("Select State!!");
      setIsLoading(false);
      return false;
    }
    const data = new FormData();
    data.append("file", SFMData.FileName);
    setSFMData({ ...SFMData, Picturepath: SFMData.FileName.name });
    // setSFMData({ ...SFMData, AwardDate: new Date(SFMData.Year,SFMData.Month+1) })

    data.append("SFMData", JSON.stringify(SFMData));
    axios
      .post("/api/sfmcaregiver/store/", data, {
        // receive two    parameter endpoint url ,form data
      })
      .then((res) => {
        // then print response status
        alert("Record created successfully !");
        setIsLoading(false);
        window.location.reload(false);
      });
  };

  console.log("fv", SFMData);
  const onSubmitButtonClickUpdate = async (e) => {
    setIsLoading(true);
    const id = SFMData.ProviderMonthID;
    const data = new FormData();
    if (SFMData.FileName) {
      data.append("file", SFMData.FileName);
      setSFMData({ ...SFMData, Picturepath: SFMData.FileName.name });
    }

    data.append("SFMData", JSON.stringify(SFMData));
    axios
      .put("/api/sfmcaregiver/update/" + id, data, {
        // receive two    parameter endpoint url ,form data
      })
      .then((res) => {
        // then print response status
        alert("Record Updated successfully !");
        setIsLoading(false);
        window.location.reload(false);
      });
  };

  const getMonthList = () => {
    let theMonths = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return theMonths.map((v, i = 1) => (
      <option key={v} value={i++}>
        {v}
      </option>
    ));
  };

  const handleSubmit = () => {
    setIsLoading(true);
    alert("Record Added ");
    setIsLoading(false);
    window.location.reload(true);
  };

  const [ProgramState, setProgramState] = useState([]);
  const getProgramStateListDropDown = async () => {
    setIsLoading(true);
    let data = await fetch("/api/sfmcaregiver/program-state");
    if (!data.ok) {
      return;
    }
    const AllData = await data.json();
    setProgramState(AllData.data);
    setIsLoading(false);
  };
  console.log(SFMData);
  useEffect(() => {
    getProgramStateListDropDown();

    // getDropList()
    fetchData();
  }, []);

  console.log("ProgramState", ProgramState);

  const history = useHistory();

  const DeleteDataSelected = async (index) => {
    // const id =titleData[j].ProviderMonthID;

    setIsLoading(true);
    const temp = [...titleData];
    const primaryKey = temp[index].ProviderMonthID;
    temp.splice(index, 1);
    try {
      await axios.delete(
        `/api/sfmcaregiver/delete/${encodeURIComponent(primaryKey)}`
      );
      setTitleData(temp);
      alert("Data Deleted Successfully!!");
    } catch (err) {}
    setIsLoading(false);
  };

  const EditDataSelected = async (j) => {
    setSFMData(titleData[j]);
    setArticleTitleDiv(true);
    setdataByIdLoadedStatus(true);
    setSFMCAreGiverData(titleData[j]);

    let AwardDate = titleData[j].AwardDate;

    let MonthValue = AwardDate.slice(5, 7);

    let YearValue = AwardDate.slice(0, 4);

    MonthValue = parseInt(MonthValue);

    YearValue = parseInt(YearValue);

    selectElement("month_list", MonthValue - 1);
    selectElement("year_list", YearValue);

    // document.getElementById("month_list").value=MonthArray[MonthValue];

    console.log("monthValue", MonthValue);

    console.log("Year Value", YearValue);

    setIsLoading(false);
    window.location.href = "#pagesubtitle";
  };
  const columns = [
    // {
    //    name: 'ProviderMonthID',
    //    selector: 'ProviderMonthID',
    //    sortable: true,
    // },
    {
      name: "Title",
      selector: row => row.PictureTitle,
      sortable: true,
      wrap: true,
    },
    {
      name: "Article Title",
      selector: row => row.Title,
      sortable: true,
      wrap: true,
    },
    {
      name: "AwardDate",
      selector: row => row.AwardDate,
      cell: (row, j) => (row.AwardDate ? row.AwardDate.slice(0, 10) : "NULL"),
      sortable: true,
      wrap: true,
    },
    {
      name: "Picturepath",
      selector: row => row.Picturepath,
      sortable: true,
      wrap: true,
    },
    {
      name: "Description",
      selector: row => row.Description,
      cell: (row, j) => (
        <span dangerouslySetInnerHTML={{ __html: row.Description }} />
      ),
      sortable: true,
      wrap: true,
    },

    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div class="inline-flex">
          <button onClick={() => EditDataSelected(j)}>
            <Link to="#">
              <img src={editimage} alt="" />
            </Link>
          </button>
          {/* &nbsp;&nbsp; */}
          {/* <button>
            <Link to="#">
              <img src={contactimage} alt="" />
            </Link>
          </button> */}
          &nbsp;&nbsp;
          <button>
            <Link to="#" onClick={() => DeleteDataSelected(j)}>
              <img src={trashimage} alt="" />
            </Link>
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="main">
      <Header />
      <div className="main-content">
        <ContentHeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <ContentManagementSideBar />
              <div className="tab-detail">
                <div className="tab-detail-top children-tabs">
                  <div className="children-section">
                    <img src={sfminner} alt="" />
                    <div className="pagesubtitle" id="pagesubtitle">
                      SFM Caregiver Recognition
                    </div>
                  </div>
                  {isLoading ? <LoadSpinner /> : ""}
                </div>
                <div className="tab-detail-bottom children-data">
                  <form className="children-doc-form sfm-form">
                    <div className="form-row mb-30 gap-20 ai-fe">
                      <div className="one-half">
                        <select
                          onChange={(e) =>
                            setProviderMonthId({
                              ...providerMonthId,
                              Id: e.target.value,
                            })
                          }
                          className="mb-0"
                          name="cars"
                          id="cars"
                        >
                          <option>Select</option>

                          {titleData.map((titlename) => (
                            <option value={titlename.ProviderMonthID}>
                              {titlename.Title}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="one-fourth d-flex gap-20">
                        <Link
                          to="#"
                          onClick={fetchSFMDataByProviderMonthId}
                          className="normal"
                        >
                          Select
                        </Link>
                        <Link
                          to="#"
                          onClick={AddArticleNew}
                          className="grey-btn"
                        >
                          Add Family
                        </Link>
                      </div>

                      {/* <select
                          onChange={(e) =>
                            setAnnounceMentById({
                              ...AnnouncementById,
                              AnnouncementTypeID: e.target.value,
                            })
                          }
                        >
                          {dataByIdLoadedStatus ? (
                            announcementTypeList
                              .filter(
                                (ann) =>
                                  ann.AnnouncementTypeID ===
                                  AnnouncementById.AnnouncementTypeID
                              )
                              .map((ann) => (
                                <option selected value={ann.AnnouncementTypeID}>
                                  {ann.AnnouncementTypeName}
                                </option>
                              ))
                          ) : (
                            <option value="0">Please Select</option>
                          )}
                          {announcementTypeList.map((ann) => (
                             <option value={ann.AnnouncementTypeID}>{ann.AnnouncementTypeName}</option>
                          ))}
                         
                        </select> */}
                      <div className="one-fourth">
                        <label>Program State</label>
                        <select
                          onChange={(e) =>
                            setSFMData({
                              ...SFMData,
                              ProgramMonthID: e.target.value,
                            })
                          }
                        >
                          {dataByIdLoadedStatus ? (
                            ProgramState.filter(
                              (ann) =>
                                ann.ProgramStateID === SFMData.ProgramMonthID
                            ).map((ann) => (
                              <option selected value={ann.ProgramStateID}>
                                {ann.ProgramState}
                              </option>
                            ))
                          ) : (
                            <option value="0">Please Select</option>
                          )}
                          {
                            ProgramState.filter(
                              (ann) =>
                                ann.ProgramStateID !== SFMData.ProgramMonthID
                            ).map((ann) => (
                              <option value={ann.ProgramStateID}>
                                {ann.ProgramState}
                              </option>
                            ))
                            // ProgramState.map((ann) => (
                            //   <option value={ann.ProgramStateID}>
                            //     {ann.ProgramState}
                            //   </option>
                            // ))
                          }
                        </select>
                        {/* <select  onChange={(e) =>
                            setSFMData({
                              ...SFMData,
                              ProgramMonthID: e.target.value,
                            })
                          } className="mb-0" name="cars" id="cars">
                          <option value="volvo">Select</option>
                          {ProgramState.map((ps) => (
                            <option value={ps.ProgramStateID}>
                              {ps.ProgramState}
                            </option>
                          ))}
                          
                        </select> */}
                      </div>
                    </div>
                    <div className="form-row gap-20">
                      {articleTitleDiv ? (
                        <div className="one-fourth gap-20">
                          <label>Article Title</label>
                          <input
                            value={SFMData.Title}
                            onChange={(e) =>
                              setSFMData({ ...SFMData, Title: e.target.value })
                            }
                            type="text"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="one-fourth gap-20">
                        <label>Month</label>
                        <select
                          className="mb-0"
                          name="cars"
                          id="month_list"
                          onChange={(e) =>
                            setSFMData({ ...SFMData, Month: e.target.value })
                          }
                        >
                          <option value="volvo">Select</option>
                          {getMonthList()}
                        </select>

                        {/* <DropdownDate
                                       startDate={
                                          // optional, if not provided 1900-01-01 is startDate
                                          "1900-01-01" // 'yyyy-mm-dd' format only
                                       }
                                       endDate={
                                          // optional, if not provided current date is endDate
                                          "2013-12-31" // 'yyyy-mm-dd' format only
                                       }
                                       selectedDate={
                                          // optional
                                          startDate // 'yyyy-mm-dd' format only
                                       }
                                       onDateChange={(date) => {
                                          // optional
                                          console.log(date);
                                          setStartDate({ date: startDate, selectedDate: formatDate(date) });
                                        }}

                                    /> */}
                      </div>
                      <div className="one-fourth gap-20">
                        <label>Year</label>
                        <select
                          className="mb-0"
                          name="cars"
                          id="year_list"
                          onChange={(e) =>
                            setSFMData({ ...SFMData, Year: e.target.value })
                          }
                        >
                          <option>Select</option>
                          {getYearList()}
                        </select>
                      </div>
                    </div>

                    <div className="form-grey mt-25">
                      <div className="form-row">
                        <div className="full-input">
                          <label>Picture Title:</label>
                          <input
                            value={SFMData.PictureTitle}
                            onChange={(e) =>
                              setSFMData({
                                ...SFMData,
                                PictureTitle: e.target.value,
                              })
                            }
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="form-row ai-fe">
                        <div className="one-half">
                          <label>Select Picture:</label>
                          <input
                            type="file"
                            onChange={(e) =>
                              setSFMData({
                                ...SFMData,
                                FileName: e.target.files[0],
                              })
                            }
                          />
                        </div>
                        <div className="one-half">
                          <label>Currently selected for display:</label>
                          <span className="d-flex">
                            <input
                              type="checkbox"
                              name="vehicle1"
                              value="Bike"
                            />
                            <label>
                              Is Current{" "}
                              <small>
                                {" "}
                                *This will uncheck the other entries.
                              </small>
                            </label>
                          </span>
                        </div>
                      </div>
                      <div className="req-update-textarea pl-0 pr-0">
                        <label>
                          <span>
                            <b>Short Biography</b>
                          </span>
                        </label>

                        <textarea
                          value={SFMData.Description ? SFMData.Description : ""}
                          onChange={(e) =>
                            setSFMData({
                              ...SFMData,
                              Description: e.target.value,
                            })
                          }
                        ></textarea>

                        {/* <label>Description:</label> */}
                        {/* <textarea></textarea> */}
                        {/* <Editor apiKey="43knvham046kn3i9brlvbog2h3aahv3yb590n0p7tcmhu9kh"

                                    
                                       //onInit={(evt, editor) => editorRef.current = editor}
                                       
                                       value= {SFMData.Description ? SFMData.Description : ''}
                                       

                                       
                                       onChange={e => setSFMData({ ...SFMData, Description: e.level.content })}
                                       init={{
                                         
                                          selector: 'textarea',
                                          // plugins: 'link image table',
                                          contextmenu: 'link image imagetools table spellchecker lists',
                                          height: 500,
                                          menubar: false,
                                          plugins: [
                                             'advlist autolink lists link image',
                                             'charmap print preview anchor help',
                                             'searchreplace visualblocks code',
                                             'insertdatetime media table paste wordcount',
                                             'link image imagetools table spellchecker lists'
                                          ],
                                          toolbar:
                                             'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help'
                                       }}

                                    /> */}
                        {/* <Editor
                          apiKey="43knvham046kn3i9brlvbog2h3aahv3yb590n0p7tcmhu9kh"
                          // content= {SFMData.Description}

                          initialValue={
                            SFMData.Description ? SFMData.Description : ""
                          }
                          onChange={(e) =>
                            setSFMData({
                              ...SFMData,
                              Description: e.level.content,
                            })
                          }
                          init={{
                            selector: "textarea",
                            // plugins: 'link image table',
                            contextmenu:
                              "link image imagetools table spellchecker lists",
                            height: 500,
                            menubar: false,
                            plugins: [
                              "advlist autolink lists link image",
                              "charmap print preview anchor help",
                              "searchreplace visualblocks code",
                              "insertdatetime media table paste wordcount",
                              "link image imagetools table spellchecker lists",
                            ],
                            toolbar:
                              "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help",
                          }}
                        /> */}

                        <div className="req-update-action">
                          {dataByIdLoadedStatus ? (
                            <Link
                              to="#"
                              onClick={onSubmitButtonClickUpdate}
                              className="normal"
                            >
                              Submit
                            </Link>
                          ) : (
                            <Link
                              to="#"
                              onClick={onSubmitButtonClickStore}
                              className="normal"
                            >
                              Submit
                            </Link>
                          )}

                          <Link to="#" onClick={closeForm} className="close">
                            Close
                          </Link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <DataTable
                  columns={columns}
                  data={titleData}
                  pagination={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SFMCareGiverRecognition;
