import Footer from "./Footer";
import Header from "./Header";
import React, { useEffect, useState } from "react";
import banner from "../images/assets/images/banner.jpg";
import clock from "../images/assets/images/clock.svg";
import home from "../images/assets/images/home.svg";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { post } from "../utils/common";
import axios from "axios";
function Index(props) {
  const [data, setData] = useState([]);
  const [caraouselData, setCaraouselData] = useState([]);

  const fetchCaraouselData = async () => {
    let data = await axios.get("/api/managecaraousel/getcaraouselforHomePage");
    setCaraouselData(data.data.data);
  };

  const fetchData = async () => {
    let data = await post("/api/eventcalender/getDataForIndexPage");
    const dayArray = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",

    ];

    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var time = new Date();
    console.log(
      time.toLocaleString("en-US", { hour: "numeric", hour12: true })
    );
    //   let data1 = await data.json();
    let data2 = data.data.data;

    let res = data2.map((item) => {
      const d = new Date(item.CalendarDate);
      const date = new Date(item.CalendarDate);
      console.log("date", d);
      let monthName = month[d.getMonth()];
      //  let monthName = date.getMonth()+1
      item.monthName = monthName;
      //  item.Day= d.getDay()
      item.DayName = dayArray[d.getDay()];
      // item.Day = date.getDate();
      item.Day = item.CalendarDate.slice(8, 10);
      item.startTime = d.toLocaleString("en-US", {
        hour: "numeric",
        hour12: true,
      });
      item.endTime = new Date(item.EndTime).toLocaleString("en-US", {
        hour: "numeric",
        hour12: true,
      });
      return item;
    });
    // const ordered = Object.keys(res).sort().reduce(
    //   (obj, key) => {
    //     obj[key] = res[key];
    //     return obj;
    //   },
    //   {}
    // );
    console.log("res", res);
    // res= res.sort()
    setData(res);
  };

  const getTime = (startDate, endDate) => {
    let start = startDate;
    let end = endDate;
    let startHour = startDate.slice(11, 13);
    let endHour = endDate.slice(11, 13);
    let startAMPM;
    let endAMPM;

    let newStartHour;
    let newEndHour;

    if (parseInt(startHour) < 12) {
      startAMPM = "AM";
      newStartHour = startHour;
    } else {
      startAMPM = "PM";
      newStartHour = startHour - 12;
    }

    if (parseInt(endHour) < 12) {
      endAMPM = "AM";
      newEndHour = endHour;
    } else {
      endAMPM = "PM";
      newEndHour = endHour - 12;
    }

    return (
      newStartHour +
      ":" +
      startDate.slice(14, 16) +
      startAMPM +
      "-" +
      newEndHour +
      ":" +
      endDate.slice(14, 16) +
      endAMPM
    );

    return 5;
  };

  // {caraouselData.map(caraouselImage=>(
  //   <img src={"http://localhost:3000/api/managecaraousel/files/"+caraouselImage.ImageName}></img>
  // ))}


  const onClickItem = (link) => {
    let url = link;
    console.log(url)
    window.open(url)
    console.log("Hello")
  }
  useEffect(() => {
    // if (sessionStorage.getItem('Password') == 'Welcome@123' || sessionStorage.getItem('Password') == 'Setup@123') {
    //   window.location = "/enforce-password-change";

    // }
    fetchCaraouselData();

    fetchData();
  }, []);

  console.log("caraousel", caraouselData);
  return (
    <div className="main">
      <Header />
      <div className="main-content">
        {/* showArrows={true} onChange={onChange} onClickItem={onClickItem} onClickThumb={onClickThumb} */}
        <Carousel key={caraouselData.length} showArrows={true} showThumbs={false} autoFocus={true} autoPlay={true} interval={3000} infiniteLoop={true} transitionTime={200}>

          {/* <Carousel
          infiniteLoop
          autoPlay
          stopOnHover
          interval={5000}
        > */}
          {caraouselData.map((caraouselImage, index) => {
            return (
              <div key={index} onClick={() => onClickItem(caraouselImage.urlButtonLink)}>

                <img
                  src={
                    "https://sfmfamilies.org/api/managecaraousel/files/" +
                    caraouselImage.ImageName
                  }
                />

{/* <img
                src={
                  "http://localhost:3000/api/managecaraousel/files/" +
                  caraouselImage.ImageName
                }
              /> 
                {/* this was class container */}
                <div className="legend legendstyle" style={{ "z-index": "1000" }}>
                  <div className="hero-text">
                    <div className="hero-text-box">
                      <h6 className="hero-textalign">
                        {/* Do You Want to Foster Youth In Therapeutic Foster Care?{" "} */}
                        {caraouselImage.ImageTitle}
                      </h6>
                    </div>
                  </div>
                  <div className="hero-text-box">
                    <Link
                      // to={{ pathname: "https://sfmfamilies.org/index" }}
                      to={{
                        pathname:
                          "/index",
                      }}
                      //target="_blank"
                    >
                      Learn more
                    </Link>
                  </div>

                </div>

                {/* <p className="legend">Legend 1</p> */}
              </div>
            );
          })}
        </Carousel>
        {/* <Carousel>
          {caraouselData.map((caraouselImage) => (
            <div>
              <img
                src={
                  "http://localhost:3000/api/managecaraousel/files/" +
                  caraouselImage.ImageName
                }
              />
              <p className="legend">Legend 1</p>
            </div>
          ))}{" "}
        </Carousel> */}
        {/* <div className="hero" style={{ backgroundImage: `url(${banner})` }}>
          <div className="container">
            <div className="hero-text">
              <div className="hero-text-box">
                <h6>
                  Do You Want to Foster Youth In Therapeutic Foster Care?{" "}
                </h6>
                <Link
                  // to={{ pathname: "http://www.kfapa.org/" }}
                  to={{
                    pathname:
                      "https://sfmfamilies.org/api/downlodableform/files/Therapeutic Foster Care Rev 7-21.pdf",
                  }}
                  target="_blank"
                >
                  Learn more
                </Link>
              </div>
            </div>
          </div>
        </div> */}

        <div className="cta">
          <div className="container">
            <div className="cta-btn">
              {/*  <Link to="/fosterfamilyinquiry">Foster Family Application</Link>*/}
            </div>
          </div>
        </div>

        <div className="about">
          <div className="container">
            <div className="even-cal">
              <h2 className="c-heading">Event Calender</h2>
              <ul>
                {data.map((calendarData) => (
                  <li>
                    <div className="cal-date">
                      <b>{calendarData.Day}</b>
                      {calendarData.monthName}
                      <br></br>
                      <small>{calendarData.DayName}</small>
                    </div>
                    <div className="event-detail">
                      <h3>{calendarData.Title}</h3>
                      {calendarData.IsAllDayEvent == true ? (
                        <span>
                          <img src={clock} alt="" /> All Day Event
                        </span>
                      ) : (
                        <span>
                          <img src={clock} alt="" />
                          {getTime(
                            calendarData.CalendarDate,
                            calendarData.EndTime
                          )}
                          {/* {calendarData.startTime} -{" "}
                        {calendarData.endTime} */}
                        </span>
                      )}

                      {/* <span>{new Date("2013-04-08T10:28:43Z").getHours()}</span> */}
                      <span>
                        <img src={home} alt="" />
                        {calendarData.Location}
                      </span>
                    </div>
                  </li>
                ))}

                {/* <li>
                  <div className="cal-date">
                    <b>12</b>june
                  </div>
                  <div className="event-detail">
                    <h3>CPR / 1st Aide Training</h3>
                    <span>
                      <img src={clock} alt="" /> 9:30am - 3:30pm
                    </span>
                    <span>
                      <img src={home} alt="" />
                      4155 East Harry, Wichita, KS 67218
                    </span>
                  </div>
                </li> */}
                {/* <li>
                  <div className="cal-date">
                    <b>12</b>june
                  </div>
                  <div className="event-detail">
                    <h3>CPR / 1st Aide Training</h3>
                    <span>
                      <img src={clock} alt="" /> 9:30am - 3:30pm
                    </span>
                    <span>
                      <img src={home} alt="" />
                      4155 East Harry, Wichita, KS 67218
                    </span>
                  </div>
                </li> */}
                {/* <li>
                  <div className="cal-date">
                    <b>12</b>june
                  </div>
                  <div className="event-detail">
                    <h3>CPR / 1st Aide Training</h3>
                    <span>
                      <img src={clock} alt="" /> 9:30am - 3:30pm
                    </span>
                    <span>
                      <img src={home} alt="" />
                      4155 East Harry, Wichita, KS 67218
                    </span>
                  </div>
                </li> */}
              </ul>
            </div>
            <div className="about-content">
              <h2 className="c-heading">About Us</h2>
              <p>
                SFM Families is an on-line community for our valued foster and
                kinship families. It's a place to find responsive support,
                up-to-date information, easily accessible tools and training,
                and professional resources. If you are not a foster parent and
                would like to explore this opportunity for service, please
                contact us. Your decision could bring the change a child needs
                to reach his or her full potential.
              </p>
              <div className="disclaimber">
                <div className="disclaimber-box">
                  <h6>
                    <b>DISCLAIMER:</b>
                    The information may not be complete or up to date. Please
                    verify this information with your Resource Worker. Please
                    'request update' for any changes.
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="action-btn"
          style={{
            "background-color": "#ebe5e5",
            "justify-content": "space-around",
          }}
        >
          <h6>Helpful Links</h6>

          <Link
            to={{ pathname: "https://saintfrancisministries.org/" }}
            target="_blank"
          >
            St-francis.org
          </Link>

          <Link
            to={{
              pathname: "https://saintfrancisministries.org/contact/", 
            }}
            target="_blank"
          >
            Customer Care
          </Link>

          <Link to="/secure/myworker">Contact My Worker</Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Index;
