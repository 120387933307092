import React from 'react';
import landingpagelogo from '../../../../images/assets/landingpage/landingpagelogo.png';
import fbtop from '../../../../images/assets/landingpage/fbtop.svg';
import twtop from '../../../../images/assets/landingpage/twtop.svg';
import yttop from '../../../../images/assets/landingpage/yttop.svg';
import fmslogo from '../../../../images/assets/images/sfm_logo.png';
import sfm from '../../../../images/assets/images/sfm.svg';
import { Link } from 'react-router-dom';



function Header() {


  return (
    <div className="header">
      <div className="header-top">
        <div className="container">
          <div className="top-bar">
            <Link to="tel:800 423 1342" className="phone-action">800 423 1342</Link>
            <ul>
              <li> <Link to={{ pathname: "https://www.facebook.com/SaintFrancisMinistries/" }} replace target="_blank" className="phone-action"><img src={fbtop} alt="" /></Link></li>
              <li> <Link to={{ pathname: "https://twitter.com/SaintFrancisMin/" }} replace target="_blank" className="phone-action"><img src={twtop} alt="" /></Link></li>
              <li> <Link to={{ pathname: "https://www.youtube.com/user/SaintFrancisCS" }} replace target="_blank" className="phone-action yt-icon" ><img src={yttop} alt="" /></Link></li>
            </ul>
            <Link to={{ pathname: "https://secure.donationpay.org/saintfrancisministries/" }} replace target="_blank" className="donate-action">donate</Link>
          </div>
        </div>
      </div>
      <div className="header-bottom">
        <div className="container">
          <div className="navbar">
            <Link to="/accountmanage" className="logoauth"><img src={fmslogo} alt=""></img></Link>
            <Link to="/accountmanage" className="logoauth-m"><img src={sfm} alt=""></img></Link>
            <div className="mobile-menu">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <ul className="main-menu">
              <li><Link to="/whoweare">who we are</Link>
                <ul className="sub-menu">
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/our-story/" }} replace target="_blank">our story</Link></li>
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/leadership-team/" }} replace target="_blank">Leadership team</Link></li>
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/foundation/" }} replace target="_blank">the saint francis foundation</Link></li>
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/prayer-request/" }} replace target="_blank">prayer request</Link></li>
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/fostering-in-faith/" }} replace target="_blank">fostering the faith</Link></li>


                </ul>

              </li>
              <li><Link to="/whatwedo">What we do</Link>
                <ul className="sub-menu">
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/services/" }} replace target="_blank">our services</Link></li>
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/adoption/" }} replace target="_blank">Adoption</Link></li>
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/foster-care/" }} replace target="_blank">foster care</Link></li>
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/in-home-prevention-services/" }} replace target="_blank">in-home prevention services</Link></li>
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/independent-living/" }} replace target="_blank">independent living</Link></li>
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/residential-programs/" }} replace target="_blank">residential programs</Link></li>
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/behavioral-health/" }} replace target="_blank">behavioral health</Link></li>
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/fatherhood-fire/" }} replace target="_blank">fatherhood fire</Link></li>
                </ul>


              </li>
              <li><Link to="/whereweserve">where we serve</Link>
                <ul className="sub-menu">
                  <li><Link to={{ pathname: "http://saintfrancisministries.org/locations/?sort=all" }} replace target="_blank">location overview</Link></li>

                  <li><Link to={{ pathname: "https://saintfrancisministries.org/arkansas/" }} replace target="_blank">arkansas</Link></li>
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/kansas/" }} replace target="_blank">kansas</Link></li>
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/mississippi/" }} replace target="_blank">mississippi</Link></li>
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/nebraska/" }} replace target="_blank">nebraska</Link></li>
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/oklahoma/" }} replace target="_blank">oklahoma</Link></li>
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/texas-2/" }} replace target="_blank">texas</Link></li>
                </ul>
              </li>
              <li><Link to="/newsroom">newsroom</Link>
                <ul className="sub-menu">
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/newsroom/ministry-news/" }} replace target="_blank">ministry news</Link></li>

                  <li><Link to={{ pathname: "https://saintfrancisministries.org/newsroom/hi-lites/" }} replace target="_blank">hi-lites</Link></li>
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/category/donor-stories/" }} replace target="_blank">donor stories</Link></li>
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/newsroom/annual-reports/" }} replace target="_blank">annual report</Link></li>
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/category/fostering-in-faith/" }} replace target="_blank">fostering the faith</Link></li>
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/category/foster-family-features/" }} replace target="_blank">foster family features</Link></li>
                </ul>
              </li>
              <li><Link to={{ pathname: "https://saintfrancisministries.org/foundation/strength-through-partnership/" }} replace target="_blank">community partnership</Link></li>
              <li><Link to={{ pathname: "https://saintfrancisministries.org/careers-2/sw/" }} replace target="_blank">Careers</Link>
                <ul className="sub-menu">
                  <li><Link to={{ pathname: "https://saintfrancisministries.org/careers-2/sw/" }} replace target="_blank">careers at salina west</Link></li>
                </ul>
              </li>
              <li><Link to={{ pathname: "https://saintfrancisministries.org/contact/" }} replace target="_blank">contact us</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
