import React, { useEffect, useRef, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import { Link } from 'react-router-dom';
import { post } from '../utils/common';
import Loader from "react-loader-spinner";
import LoadSpinner from './Loader/LoadSpinner';

import SelectSearch, { fuzzySearch } from 'react-select-search';

import "./select-search.css";

function Staff() {

  


   const [value, setValue] = useState("");

   const [providerTypeData, setProviderTypeData] = useState([]);
  
   const [allProviderData, setAllProvider] = useState([]);
   const [providerSession, setProviderSessionData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);


   //fetch all provider type with api's
   const fetchData = async () => {
      let data = await fetch('/api/provider/getprovidertype');
      let providerTypeData = await data.json();
      console.log("all providertype data", providerTypeData);
      setProviderTypeData(providerTypeData.data);

   };

   //fetch all provider data list 
   const fetchAllProviderData = async () => {
      setIsLoading(true);
      let data = await fetch('/api/provider/getallprovider');
      let allProviderData1 = await data.json();
      let allProviderData = allProviderData1.data;

      let res = allProviderData.map(item => {
         item.name = item.PName;
         item.value = item.PNumber;
         delete item.PName;
         delete item.PNumber;
         return item;
      });
      setAllProvider(res);

      setIsLoading(false);

   }


   console.log("all provider", allProviderData);



   //get all provider by selected  provider tyoe
   const getAllProviderByTypeId = async (e) => {
      setIsLoading(true);

      const id = e.target.value;
      if (id == 0) {
         let data = await fetch('/api/provider/getallprovider');
         let allProviderData = await data.json();
         console.log("all provider", allProviderData);
         
         let allproviderData1 = allProviderData.data;
         let res = allproviderData1.map(item => {
            item.name = item.PName;
            item.value = item.PNumber;
            delete item.PName;
            delete item.PNumber;
            return item;
         });
         setAllProvider(res);
         // setAllProvider(allProviderData.data);
      }
      else {
         console.log(id);
         let allProviderDataByTypeId = await post('/api/provider/getallproviderbytypeid', { id });
         console.log("all provider by type id", allProviderDataByTypeId)

         let allproviderData1 = allProviderDataByTypeId.data.data;
         let res = allproviderData1.map(item => {
            item.name = item.PName;
            item.value = item.PNumber;
            delete item.PNumber;
            delete item.PName;
            return item;
         });
         setAllProvider(res);
         // setAllProvider(allProviderDataByTypeId.data.data);
      }
      setIsLoading(false);


   }

   const settingProvider = async (e) => {
      console.log(e);
      const providerId = providerSession.Id;
      // sessionStorage.removeItem("ProviderData");
      // sessionStorage.removeItem("LocationData");
      // sessionStorage.removeItem("ProviderResourceWorkerData")
      sessionStorage.setItem('providerSessionId', providerId)
      
      window.location.href = '/myprofile'


   }

   useEffect(() => {
      fetchData()
      fetchAllProviderData()
   }, []);

   return (
      <div className="main">

         <Header />

         <div className="main-content">


            <div className="top-link">
               <div className="container">
                  <Link to="/managementcenter/provider" className={window.location.pathname === '/staff' ? 'active' : ''} >Management Center</Link> | <Link to="/contentmanage/adoptioncontact">Content Management</Link>
               </div>
            </div>

            {isLoading ? (<LoadSpinner />) : ''}


            <div className="set-provider">
               <div className="container">
                  <h2 className="small-headings">Set Provider</h2>
                  <form className="provider-form">
                     <div className="ot-field">
                        <label className="form-label">Provider Type: </label>
                        <select onChange={getAllProviderByTypeId} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">

                           {providerTypeData.map(providertype => (
                              < option value={providertype.ProviderTypeID} >{providertype.ProviderType}</option>
                           ))}
                        </select>
                     </div>
                     <div className="ot-field">
                        <label className="form-label">Change to Provider:</label>
                        {/* //old one  */}
                        {/* <select onChange={e => setProviderSessionData({ ...providerSession, Id: e.target.value })} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">

                           {allProviderData.map(provider => (
                              <option value={provider.PNumber}>{provider.PName}</option>

                           ))}
                        </select> */}

                        {/* //new one  */}

                        <SelectSearch
                           options={allProviderData}
                           value={providerSession.Id}
                           onChange={e => setProviderSessionData({ ...providerSession, Id: e })}
                           search
                           filterOptions={fuzzySearch}
                           placeholder="Set Provider"
                        />
                     </div>
                     <div className="act-btn">
                        <Link to="#" onClick={settingProvider}>Set Provider</Link>
                     </div>
                  </form>
               </div>
            </div>
         </div >

         <Footer />

      </div >

   );
}

export default Staff;
