import Footer from "../../Footer";
import Header from "../../../frontendPages/Header";
import imminner from "../../../images/assets/currentChildren/imminner.svg";
import edit from "../../../images/assets/currentChildren/edit.svg";
import search from "../../../images/assets/currentChildren/search.svg";
import "../../CurrentChildren/ImmidiateAssesmentCurrentChildren/ImmidiateAssesmentCurrentChildren.scss";
import CurrentChildrenSideBar from "../CurrentChildrenSidebar/CurrentChildrenSidebar";
import HeaderTopDetail from "../HeaderTopDetail/HeaderTopDetail";
import { useState } from "react";
import { Link } from "react-router-dom";
import LoadSpinner from "../../Loader/LoadSpinner";
import { post } from "../../../utils/common";
import DataTable from "react-data-table-component";
import { useEffect } from "react";
import mail from "../../../images/mail.png";
import plus from '../../../images/assets/cm/plus.svg';

function ImmidiateAssesmentCurrentChildren() {
  const [updateDiv, setShowUpdateDiv] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDataForEdit, setSelectedDataForEdit] = useState([]);

  const showUpdateDiv = () => {
    setShowUpdateDiv(true);
  };

  const hideUpdateDiv = () => {
    setShowUpdateDiv(false);
  };

  const [updateData, setUpdateData] = useState([]);
  const [activeId, setActiveId] = useState();

  const onupdateClick = async () => {
    let username = sessionStorage.getItem("username");
    setShowUpdateDiv(false);
    setUpdateData([]);
    await post("/api/immidiateassesment/update-immidiateassesment", {
      username,
      updateData,
    });
    alert("Request Update  Emailed Successfully !");
    // window.location.reload(false)
    // alert("Submitted Successfully");
    // window.location.reload(false)
  };

  const editIAForm = async (row) => {
    if(data[row].Form_Status == "Signed and completed"){
      alert("Form Is Completed, You Cannot Edit")
      return;
    }
    setIsLoading(true);
    let SFCSFamiliesIAID = data[row].SFCSFamiliesIAID;
    const providerid = sessionStorage.getItem("providerSessionId");
    sessionStorage.removeItem("SFCSFamiliesIAID");
    sessionStorage.setItem("SFCSFamiliesIAID", SFCSFamiliesIAID);

    let dataIA = await post("/api/immidiateassesment/getiaform", {
      providerid,
      SFCSFamiliesIAID,
    }); 

    sessionStorage.removeItem("iaformdata");
    sessionStorage.removeItem("iabodyformdata");
    sessionStorage.setItem("iaformdata", JSON.stringify(dataIA.data.data[0]));
    sessionStorage.setItem(
      "iabodyformdata",
      JSON.stringify(dataIA.data.bodyData[0] ? dataIA.data.bodyData[0] : [])
    );
    localStorage.setItem("FromIA", "false");
  
    window.location.href = "/form/ImmidiateAssesmentEdit";
    setIsLoading(false);
    // data[j]
  };

  // const [isLoading, setIsLoading] = useState(false);
  const [childrenData, setChildrenData] = useState([]);
  const fetchChildData = async () => {
    const id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/currentchildren/index", { id });
    setChildrenData(data.data.data);
    let children_id = data.data.data[0].ClientID;
    setActiveId(children_id);
    

    let provider_id = id;
    let detailData = await post("/api/currentchildren/getia", {
      provider_id,
      children_id,
    });
    console.log("detailData", detailData);
    for(let i =0; i < detailData.data.data.length; i++){

      detailData.data.data[i].ClientName = data.data.data[0].ClientName;
    }

    setData(detailData.data.data);

  };

  

  const handleCurrentChildGlobalIA = async (children_id) => {
    setActiveId(children_id);

    setIsLoading(true);
    sessionStorage.setItem("childrenId", children_id);
    children_id = sessionStorage.getItem("childrenId");
    const provider_id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/currentchildren/getia", {
      provider_id,
      children_id,
    });
    // setselectedChildrenData(data.data.data);
    let detaildata = await post("/api/currentchildren/children-detail", {
      provider_id,
      children_id,
    });
    sessionStorage.setItem("current_child", detaildata.data.data[0].ClientName);
    setIsLoading(false);
    setData(data.data.data);
  };

  const [data, setData] = useState([]);

  // const fetchData = async () => {
  //     const child_id = sessionStorage.getItem("childrenId")
  //     const provider_id = sessionStorage.getItem("providerSessionId")
  //     let data = await post('/api/immidiateassesment/getcurrentchildrenia', { child_id, provider_id });
  //     if (!data.ok) {

  //         return;
  //     }
  //     data = await data.json();
  //     console.log(data);
  //     setData(data.data);
  // };

  useEffect(() => {
    fetchChildData();
    // fetchData()
  }, []);

  const clickedForDownload = async (row) => {
    let SFCSFamiliesIAID = data[row].SFCSFamiliesIAID;
    const providerid = sessionStorage.getItem("providerSessionId");
    let dataIA = await post("/api/immidiateassesment/getiaform", {
      providerid,
      SFCSFamiliesIAID,
    });

    sessionStorage.removeItem("iaformdata");
    sessionStorage.removeItem("iabodyformdata");

    sessionStorage.setItem("iaformdata", JSON.stringify(dataIA.data.data[0]));
    sessionStorage.setItem(
      "iabodyformdata",
      JSON.stringify(dataIA.data.bodyData[0] ? dataIA.data.bodyData[0] : [])
    );
    window.open(
      "/print/immidiateassesmentform",
      "PRINT",
      "height=1000,width=1000"
    );
  };

  const setEditData = (rowData) => {
    setShowUpdateDiv(true);
    setUpdateData(data[rowData]);
    setSelectedDataForEdit(data[rowData]);
  };

  const columns = [
    {
      name: "Client Name",
      selector: row => row.ClientName,
      // cell: (row, j) => (row.DocumentType[0]),
      sortable: true,
    },
    {
      name: "Created Date",
      selector: row => row.EnteredDate,
      // cell: (row, j) => (row.DocumentType[0]),
      sortable: true,
    },
    {
      name: "Date of Placement",
      selector: row => row.Date_of_Placement,
      // cell: (row, j) => (new Date(row.Date).toLocaleDateString()),
      sortable: true,
    },
    {
      name: "Form Status",
      selector: row => row.Form_Status,
      sortable: true,
    },
    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div class="inline-flex three-buttons">
           
         
          <Link
            to="#"
            onClick={() => clickedForDownload(j)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={search}></img>
          </Link>
          &nbsp;
          <Link
            onClick={() => editIAForm(j)}
            class="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            to="#"
          >
            <img src={edit}></img>
          </Link>
          &nbsp;
          <Link
            to="#"
            onClick={() => setEditData(j)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={mail}></img>
          </Link>
          &nbsp;
        </div>
      ),
    },
  ];

  return (
    <div className="main">
      <Header />
      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <CurrentChildrenSideBar />
              <div className="tab-detail">
                <div className="tab-detail-top childprofile-tabs">
                  <div className="children-section">
                    <img src={imminner} alt="" />
                    <div className="pagesubtitle">Immediate Assessment</div>
                  </div>
                  <Link to="/form/immidiateassesmentform" className="btn-big"><img src={plus} style={{"height": "18px"}} alt="#"/>
                    Add New Form
                  </Link>
                </div>
                <div className="children-tab">
                  {childrenData.map((child) => {
                    return (
                      <span
                        className={activeId === child.ClientID ? "active" : ""}
                        onClick={() =>
                          handleCurrentChildGlobalIA(child.ClientID)
                        }
                      >
                        {child.ClientName}
                      </span>
                    );
                  })}
                  {/* <span className="active">Easton Elliott</span> | <span>Easton Easton</span> | <span>Anna Litchfield</span> */}
                </div>
                {isLoading ? <LoadSpinner /> : ""}

                <div className="tab-detail-bottom children-data">
                  <form className="children-doc-form">
                    {updateDiv ? (
                      <div className="req-update-textarea">
                        <label>
                          <span>
                            <b>Request Update for</b>- Immediate Assessment |
                            <b>
                              {" "}
                              Child: {sessionStorage.getItem(
                                "current_child"
                              )} |{" "}
                            </b>
                            Date of Placement:{" "}
                            {selectedDataForEdit.Date_of_Placement
                              ? selectedDataForEdit.Date_of_Placement
                              : ""}{" "}
                            | Form Status:{" "}
                            {selectedDataForEdit.Form_Status
                              ? selectedDataForEdit.Form_Status
                              : ""}{" "}
                            | SFCSFamiliesIAID:{" "}
                            {selectedDataForEdit.SFCSFamiliesIAID
                              ? selectedDataForEdit.SFCSFamiliesIAID
                              : ""}
                            <br></br>
                            <br></br>
                            <b> Description for Update:</b>
                          </span>
                        </label>
                        <textarea
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                        
                        <div className="req-update-action">
                          <Link
                            to="#"
                            onClick={hideUpdateDiv}
                            className="close"
                          >
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={onupdateClick}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {isLoading ? <LoadSpinner /> : ""}

                    {/* <table>
                                            <tr>
                                                <th className="dop-type">Date of Placement</th>
                                                <th>Form Status</th>
                                                <th className="download-action"></th>
                                            </tr>
                                            <tr>
                                                <td>02/01/18</td>
                                                <td>Signed and Completes</td>
                                                <td className="download-icon"><Link to=""><img src={search} alt="" /></Link><Link to="#" onClick={showUpdateDiv}><img src={edit} alt="" /></Link></td>
                                            </tr>
                                            <tr>
                                                <td>02/01/18</td>
                                                <td>Signed and Completes</td>
                                                <td className="download-icon"><Link to=""><img src={search} alt="" /></Link><Link to="#" onClick={showUpdateDiv}><img src={edit} alt="" /></Link></td>
                                            </tr>
                                            <tr>
                                                <td>02/01/18</td>
                                                <td>Signed and Completes</td>
                                                <td className="download-icon"><Link to=""><img src={search} alt="" /></Link><Link to="#" onClick={showUpdateDiv}><img src={edit} alt="" /></Link></td>
                                            </tr>


                                        </table> */}
                    <DataTable
                      columns={columns}
                      data={data}
                      pagination={false}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ImmidiateAssesmentCurrentChildren;
