import Footer from '../../Footer';
import Header from '../../../frontendPages/Header';
import ContentManagementSideBar from '../ContentManagementSideBar/ContentManagementSideBar';
import mcinner from '../../../images/assets/cm/mcinner.svg';
import search from '../../../images/assets/cm/search.svg';
import editimage from '../../../images/assets/cm/edit.svg';
import contactimage from '../../../images/assets/cm/contact.svg';
import trashimage from '../../../images/assets/cm/trash.svg';
import ContentHeaderTopDetail from '../ContentHeaderTopDetail/ContentHeaderTopDetail';
import "../AdoptionContact/AdoptionContact.scss"
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { post, put } from '../../../utils/common';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { useAlert } from 'react-alert'
import Alert from 'react-popup-alert';
import LoadSpinner from '../../Loader/LoadSpinner';





function AdoptionContact() {
    const [data, setData] = useState([]);
    const [showAddOption, setShowAddOption] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const showAddDiv = () => {
        setShowAddOption(true)
    }

    const removeRow = async index => {
        setIsLoading(true)
        const temp = [...data];
        const primaryKey = temp[index].AdoptionContactID;
        temp.splice(index, 1);
        try {
            await axios.delete(`/api/adoptioncontact/delete/${encodeURIComponent(primaryKey)}`);
            setData(temp);
        } catch (err) { }
        setIsLoading(false)
    };


    const hideAddDiv = () => {
        setIsLoading(true)
        console.log("hiding")
        setShowAddOption(false)
        setIsLoading(false)

    }

    const fetchData = async () => {
        setIsLoading(true)
        try {
            const response = await fetch('/api/adoptioncontact/index');
            if (!response.ok)
                return
            const result = await response.json();
            if (Array.isArray(result.data)) {
                setData(result.data);
            } else {
                console.error('Unexpected data format:', result.data);
                setData([]);
            }
        } catch (error) {
            console.error('Fetch error:', error.message);
        }
        setIsLoading(false)
    };

    useEffect(() => {
        fetchData();
    }, []);

    const history = useHistory();

    const [contactData, setContactData] = useState({
        Title: '',
        Name: '',
        Phone: '',
        Email: '',
    });
    console.log(contactData)


    const onSubmitButtonClick = async e => {
        setIsLoading(true)


        console.log(contactData)
        e.preventDefault();
        await post('/api/adoptioncontact/store', { contactData });
        alert('Record created successfully !');
        window.location.reload(false);
        history.push('/contentmanage/adoptioncontact');
        setIsLoading(false)
    };




    const columns = [
        {
            name: 'Name',
            selector: row => row.Name,  // Ensure case matches exactly
            sortable: true,
        },
        {
            name: 'Title',
            selector: row => row.Title,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: row => row.Phone || 'N/A',  // Handle empty values
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.Email,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row, index) => (
                <div className="inline-flex">
                    <button onClick={() => history.push(`/contentmanage/adoptioncontact/add?id=${row.AdoptionContactID}`)}>
                        <img src={editimage} alt="Edit" />
                    </button>
                    &nbsp;&nbsp;
                    <button onClick={() => removeRow(index)}>
                        <img src={trashimage} alt="Delete" />
                    </button>
                </div>
            ),
        },
    ];


    return (


        <div className="main">
            <Header />
            <div className="main-content" >
                <ContentHeaderTopDetail />
                <div className="pagewithsidebar">
                    <div className="container">
                        <div className="pagewithsidebar-inner">
                            <ContentManagementSideBar />
                            <div className="tab-detail">
                                <div className="tab-detail-top children-tabs">
                                    <div className="children-section">
                                        <img src={mcinner} alt="" />
                                        <div className="pagesubtitle">
                                            Manage Adoption Contacts
                                        </div>
                                    </div>
                                    <div className="search-tab">
                                        <form onSubmit={showAddDiv}>
                                            {/* <div className="search-button">
                                                <input type="text" placeholder="Search.." name="search" />
                                                <button type="submit"><img src={search} /></button>
                                            </div> */}
                                            <Link to="#" onClick={showAddDiv}>Add</Link>
                                        </form>
                                    </div>
                                    {isLoading ? <LoadSpinner /> : ""}
                                </div>
                                <div className="tab-detail-bottom children-data">
                                    {showAddOption ?
                                        <form className="children-doc-form" onSubmit={onSubmitButtonClick}>
                                            <div className="add-childrendata">
                                                <div className="form-row">
                                                    <div className="one-fourth">
                                                        <label>Name :</label>
                                                        <input type="text" onChange={e => setContactData({ ...contactData, Name: e.target.value })}
                                                        ></input>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <label>Title :</label>
                                                        <input type="text" onChange={e => setContactData({ ...contactData, Title: e.target.value })}></input>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <label>Phone No. :</label>
                                                        <input type="text" onChange={e => setContactData({ ...contactData, Phone: e.target.value })}></input>
                                                    </div>
                                                    <div className="one-fourth">
                                                        <label>Email :</label>
                                                        <input type="email" onChange={e => setContactData({ ...contactData, Email: e.target.value })}></input>
                                                    </div>
                                                </div>
                                                <div className="req-update-action j-left">
                                                    <button className="close" onClick={hideAddDiv} >Close</button>
                                                    {/* <Link to="adoptioncontact/add" onClick={hideAddDiv}>close</Link> */}
                                                    <button type="submit" className="normal"> Submit</button>

                                                </div>
                                            </div>

                                        </form>
                                        : ''}

                                    <DataTable
                                        columns={columns}
                                        data={Array.isArray(data) ? data : []}  // Safe fallback
                                        pagination
                                        progressPending={isLoading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}




export default AdoptionContact;
