import Footer from "../../Footer";
import Header from "../../Header";
import "../CriticalUnusualIncidentForm/CriticalUnusualIncidentForm.scss";
import "../ImmidiateAssesmentForm/ImmidiateAssesmentForm.scss";
import lodinner from "../../../images/assets/formimages/lodinner.svg";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import FormSideBar from "../FormSidebar/FormSidebar";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { post } from "../../../utils/common";
import { useEffect, useState } from "react";
import LoadSpinner from "../../Loader/LoadSpinner";


function LogOfDailyEventsEdit() {
  const history = useHistory();
  const [logofdailyeventseditdata, setlogofdailyeventseditdata] = useState(false);

  const onSubmitButtonClick = async (status) => {
    if (EditLoadData.LodeDate == undefined || EditLoadData.LodeDate == undefined
      //  EditLoadData.ProviderMemberID == undefined || EditLoadData.ProviderMemberID == undefined ||
      //EditLoadData.ClientID == undefined || EditLoadData.ClientID == undefined 
    ) {
      alert("Please enter the Mandatory fields");
      return;
    }
    //e.preventDefault();
    let Status;
    console.log("edit", EditLoadData);
    if (status == "fromDraft")
      EditLoadData.Status = "Save as draft";
    if (status == "fromSubmit")
      EditLoadData.Status = "Completed";
    let EnteredBy = sessionStorage.getItem("username");
    let ProviderID = sessionStorage.getItem("providerSessionId");
    let LodesID = sessionStorage.getItem("setLodesForEditId");

    EditLoadData.Notes = (EditLoadData.Notes).replace(/'/g, "''");

    // console.log(x);
    let ClientID = selectChildData
      .filter((x) => x.ClientName == EditLoadData.ClientName)
      .map((x) => x.ClientID)[0];


    let ProviderMemberID = personSigningTheForm
      .filter((x) => x.ProviderName == EditLoadData.ProviderMemberName)
      .map((x) => x.ProviderMemberID)[0];


    console.log("ClientID", ClientID);
    console.log("MemberID ", ProviderMemberID);


    await post("/api/lodes/updatelodes", {
      EditLoadData,
      ProviderID,
      EnteredBy,
      LodesID,
      ProviderMemberID,
      ClientID,
      Status
    });
    alert("Record Updated successfully !");
    let fromButtonClick = localStorage.getItem("fromButtonClick");
    if (fromButtonClick === "true")
      window.location.href = "/lodes";
    history.push("/form/formindex");
  };

  const closeClicked = () => {
    history.push("/lodes");
  };

  // const [EditData,setEditData] = useState([]);

  const [selectChildData, setSelectChildData] = useState([]);
  const [personSigningTheForm, setSigningTheForm] = useState([]);

  const fetchFormDataLoad = async () => {
    let providerid = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/immidiateassesment/getformdataload", {
      providerid,
    });

    setSelectChildData(data.data.data.recordsets[0]);

    setSigningTheForm(data.data.data.recordsets[1]);
    setlogofdailyeventseditdata(true);
  };

  const [EditLoadData, setEditLoadData] = useState([]);
  const fetchFormLodesDataByLodesId = async () => {
    let providerid = sessionStorage.getItem("providerSessionId");
    let LodesId = sessionStorage.getItem("setLodesForEditId");
    let data = await post("/api/lodes/getformdataloadbyid", {
      providerid,
      LodesId,
    });

    setEditLoadData(data.data.data[0]);

    // setSelectChildData(data.data.data.recordsets[0]);

    // setSigningTheForm(data.data.data.recordsets[1]);
  };

  useEffect(() => {
    fetchFormDataLoad();
    fetchFormLodesDataByLodesId();
  }, []);

  return (
    <div className="main">
      <Header />

      <div className="main-content">
        {logofdailyeventseditdata ? <HeaderTopDetail /> : <LoadSpinner />}
        
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <FormSideBar />
              <div className="tab-detail Critical">
                <div className="tab-detail-top">
                  <div className="Critical-unusual">
                    <img src={lodinner} alt="" />
                    LoG of Daily Event
                  </div>
                </div>
                <div className="tab-detail-bottom">
                  <form>
                    <div className="form-row dandt">
                      <div className="one-third">
                        <label for="birthday">Date:<span style={{ color: "red" }}>*</span></label>
                        <input
                          type="text"
                          value={EditLoadData.LodeDate}
                          onChange={(e) =>
                            setEditLoadData({
                              ...EditLoadData,
                              LodeDate: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="one-third">
                        <label for="birthday">Person Completing Lode:<span style={{ color: "red" }}>*</span></label>
                        <select
                          onChange={(e) =>
                            setEditLoadData({
                              ...EditLoadData,
                              ProviderMemberID: e.target.value,
                            })
                          }
                        >
                          <option value="0">Select:</option>
                          {personSigningTheForm
                            .filter(
                              (person) =>
                                person.ProviderName ===
                                EditLoadData.ProviderMemberName
                            )
                            .map((person) => (
                              <option selected value={person.ProviderMemberID}>
                                {person.ProviderName}
                              </option>
                            ))}

                          {personSigningTheForm.map((person) => (
                            <option value={person.ProviderMemberID}>
                              {person.ProviderName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="one-third">
                        <label for="appt">Youth:<span style={{ color: "red" }}>*</span></label>
                        <select
                          onChange={(e) =>
                            setEditLoadData({
                              ...EditLoadData,
                              ClientID: e.target.value,
                            })
                          }
                        >
                          <option value="0">Select:</option>
                          {/* {FormdatabyIncidentIdLoadedStatus ? ( */}
                          {selectChildData
                            .filter(
                              (childData) =>
                                childData.ClientName === EditLoadData.ClientName
                            )
                            .map((childData) => (
                              <option selected value={childData.ClientID}>
                                {childData.ClientName}
                              </option>
                            ))}
                          {/* <option selected value={EditLoadData.ClientID}>{EditLoadData.ClientName}</option> */}
                          {selectChildData.map((child) => (
                            <option value={child.ClientID}>
                              {child.ClientName}
                            </option>
                          ))}
                          {/* <option value="1">Audi</option>
                                                    <option value="2">BMW</option>
                                                    <option value="3">Citroen</option> */}
                        </select>
                      </div>
                    </div>

                    <div className="imp-note mt-25">
                      <span>TIPS FOR COMPLETING OBSERVATIONS</span>
                      <ol>
                        <li>
                          Please be factual. Please share positives and
                          negatives.
                        </li>
                        <li>
                          Highlight progress of case plan goals/objectives.
                        </li>
                        <li>
                          Your lode is an official record of youth's care
                          provision.
                        </li>
                        <li>
                          Please return to your worker at home visit, email or
                          SFM Families Website.
                        </li>
                      </ol>
                    </div>

                    <div className="form-row fr-w mt-25">
                      <h6 className="small-text">Appointments</h6>
                      <label className="p-0" for="birthday">
                        Please address appointments in your daily observation
                        log below.
                      </label>
                    </div>
                    <div className="form-row dandt fr-w mt-25">
                      <div className="one-half">
                        <label for="birthday">Medical:</label>
                        <input
                          type="text"
                          value={EditLoadData.MedicalAppointment}
                          onChange={(e) =>
                            setEditLoadData({
                              ...EditLoadData,
                              MedicalAppointment: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="one-half">
                        <label for="birthday">School Meetings:</label>
                        <input
                          type="text"
                          value={EditLoadData.SchoolAppointment}
                          onChange={(e) =>
                            setEditLoadData({
                              ...EditLoadData,
                              SchoolAppointment: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="one-half">
                        <label for="birthday">Case Plan:</label>
                        <input
                          type="text"
                          value={EditLoadData.CasePlanAppointment}
                          onChange={(e) =>
                            setEditLoadData({
                              ...EditLoadData,
                              CasePlanAppointment: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="one-half">
                        <label for="birthday">Mental Health:</label>
                        <input
                          type="text"
                          value={EditLoadData.PsychiatricAppointment}
                          onChange={(e) =>
                            setEditLoadData({
                              ...EditLoadData,
                              PsychiatricAppointment: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="one-half">
                        <label for="birthday">Dentist:</label>
                        <input
                          type="text"
                          value={EditLoadData.DentistAppointment}
                          onChange={(e) =>
                            setEditLoadData({
                              ...EditLoadData,
                              DentistAppointment: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="one-half">
                        <label for="birthday">Court:</label>
                        <input
                          type="text"
                          value={EditLoadData.CourtAppointment}
                          onChange={(e) =>
                            setEditLoadData({
                              ...EditLoadData,
                              CourtAppointment: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="one-half">
                        <label for="birthday">Vision:</label>
                        <input
                          type="text"
                          value={EditLoadData.VisionAppointment}
                          onChange={(e) =>
                            setEditLoadData({
                              ...EditLoadData,
                              VisionAppointment: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="one-half">
                        <label for="birthday">Other:</label>
                        <input
                          type="text"
                          value={EditLoadData.OtherAppointment}
                          onChange={(e) =>
                            setEditLoadData({
                              ...EditLoadData,
                              OtherAppointment: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="form-grey mt-25">
                      <h6 className="small-text">BIRTH FAMILY CONTACT</h6>
                      <div className="form-row">
                        <div className="one-third">
                          <label>Date and Time (Home Visit):</label>
                          <input
                            type="text"
                            value={EditLoadData.HomeVisitDate}
                            onChange={(e) =>
                              setEditLoadData({
                                ...EditLoadData,
                                HomeVisitDate: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="one-third">
                          <label>Date and Time (Phone Calls):</label>
                          <input
                            type="text"
                            value={EditLoadData.PhoneCallDate}
                            onChange={(e) =>
                              setEditLoadData({
                                ...EditLoadData,
                                PhoneCallDate: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="one-third">
                          <label>Date and Time (Other):</label>
                          <input
                            type="text"
                            value={EditLoadData.OtherDate}
                            onChange={(e) =>
                              setEditLoadData({
                                ...EditLoadData,
                                OtherDate: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="one-third">
                          <label>Name / Relationship:</label>
                          <input
                            type="text"
                            value={
                              EditLoadData.HomeVisitNameRelationShipOfContact
                                ? EditLoadData.HomeVisitNameRelationShipOfContact
                                : ""
                            }
                            onChange={(e) =>
                              setEditLoadData({
                                ...EditLoadData,
                                HomeVisitNameRelationShipOfContact:
                                  e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="one-third">
                          <label>Name / Relationship:</label>
                          <input
                            type="text"
                            value={
                              EditLoadData.PhoneCallNameRelationShipOfContact
                                ? EditLoadData.PhoneCallNameRelationShipOfContact
                                : ""
                            }
                            onChange={(e) =>
                              setEditLoadData({
                                ...EditLoadData,
                                PhoneCallNameRelationShipOfContact:
                                  e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="one-third">
                          <label>Name / Relationship:</label>
                          <input
                            type="text"
                            value={
                              EditLoadData.OtherNameRelationShipOfContact
                                ? EditLoadData.OtherNameRelationShipOfContact
                                : ""
                            }
                            onChange={(e) =>
                              setEditLoadData({
                                ...EditLoadData,
                                OtherNameRelationShipOfContact: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="req-update-textarea pt-0">
                      <label>
                        <span>
                          <b>Narrative</b>
                        </span>
                      </label>
                      <textarea
                        value={EditLoadData.Notes}
                        onChange={(e) =>
                          setEditLoadData({
                            ...EditLoadData,
                            Notes: e.target.value,
                          })
                        }
                      ></textarea>
                      <div className="req-update-action">
                        <Link to="#" onClick={closeClicked} className="close">
                          Close
                        </Link>
                        <Link
                          to="#"
                          onClick={() => onSubmitButtonClick("fromDraft")}
                          className="normal"
                        >
                          Save as Draft
                        </Link>
                        <Link
                          to="#"
                          onClick={() => onSubmitButtonClick("fromSubmit")}
                          className="normal"
                        >
                          Submit
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LogOfDailyEventsEdit;