import Footer from '../../Footer';
import Header from '../../../frontendPages/Header';
import CurrentChildrenSideBar from '../CurrentChildrenSidebar/CurrentChildrenSidebar';
import HeaderTopDetail from '../HeaderTopDetail/HeaderTopDetail';
import courtdateinner from '../../../images/assets/currentChildren/courtdateinner.svg';
import edittwo from '../../../images/assets/currentChildren/edittwo.svg';
import '../../CurrentChildren/CourtDates/CourtDates.scss'
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import LoadSpinner from '../../Loader/LoadSpinner';
import { post } from '../../../utils/common';
import mail from '../../../images/mail.png';
import plus from '../../../images/assets/cm/plus.svg';




function CourtDates() {
    const [data, setData] = useState([]);
    const [selectedDataForEdit, setSelectedDataForEdit] = useState([])

    const [childrenData, setChildrenData] = useState([]);
    const [courtData, setCourtData] = useState([]);

    const [updateData, setUpdateData] = useState([])
    const [addChildDiv, setAddChildDiv] = useState(false)


    const hideAddChildDiv = () => {
        setAddChildDiv(false)
    }




    const onChildAdditionSubmit = async () => {

        //setIsLoading(true)

        let username = sessionStorage.getItem('username');
        setShowUpdateDiv(false)
        setAddChildDiv(false)
        setUpdateData([])
        await post('/api/courtdate/update-courtdate-addition', { username, updateData });
        alert('Request Addition  Emailed Successfully !');
        setIsLoading(false)
        // window.location.reload(false)
    }

    const fetchData = async () => {
        setIsLoading(true)
        const id = sessionStorage.getItem('providerSessionId');
        let data = await post('/api/currentchildren/index', { id });
        setChildrenData(data.data.data);
        setIsLoading(false)

        let child_id = parseInt(data.data.data[0].ClientID);
        setActiveId(child_id)
        let provider_id = id
        let detailData = await post('/api/courtdate/current-children-court-date', { provider_id, child_id });
        console.log("detailData", detailData)

        setData(detailData.data.data);

    };



    // const handleCurrentChildGlobal = async (children_id) => {
    //     setIsLoading(true);
    //     sessionStorage.setItem("childrenId", children_id)
    //     // setTimeout(10);
    //     const provider_id = sessionStorage.getItem('providerSessionId');

    //     let data = await post('/api/currentchildren/children-detail', { provider_id, children_id });
    //     // setselectedChildrenData(data.data.data);
    //     setIsLoading(false);
    // }

    const [activeId, setActiveId] = useState();

    const handleCurrentChildgetCourtDates = async (children_id) => {
        setShowUpdateDiv(false)
        setAddChildDiv(false)
        setUpdateData([])
        setActiveId(children_id)

        setIsLoading(true);
        sessionStorage.setItem("childrenId", children_id);
        let child_id = sessionStorage.getItem("childrenId")
        child_id = children_id;

        const provider_id = sessionStorage.getItem('providerSessionId');
        // const children_id = sessionStorage.getItem('children_id');
        let data = await post('/api/courtdate/current-children-court-date', { provider_id, child_id });
        setData(data.data.data);


        let detaildata = await post('/api/currentchildren/children-detail', { provider_id, children_id });
        sessionStorage.setItem("current_child", detaildata.data.data.length > 0 ? detaildata.data.data[0].ClientName : "Null");
        console.log("Data", data[0])

        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const [updateDiv, setShowUpdateDiv] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const showUpdateDiv = () => {
        setShowUpdateDiv(false)
        setAddChildDiv(true)
    }

    const hideUpdateDiv = () => {
        setShowUpdateDiv(false)
    }
    // const submitUpdate = () => {
    //     setIsLoading(true);
    //     alert("Submitted ");
    //     window.location.reload(false)
    //     setIsLoading(false)

    // }

    const onupdateClick = async () => {


        // setIsLoading(true)


        let username = sessionStorage.getItem('username');
        setShowUpdateDiv(false)
        setAddChildDiv(false)
        setUpdateData([])
        await post('/api/courtdate/update-courtdate', { username, updateData });
        alert('Request Update  Emailed Successfully !');
        setIsLoading(false)
        //  window.location.reload(false)
        // alert("Submitted Successfully");
        // window.location.reload(false)
    }




    const requestEdit = (rowData) => {
        setShowUpdateDiv(true)
        setAddChildDiv(false)

        setSelectedDataForEdit(data[rowData])
        console.log(selectedDataForEdit)
    }
    const columns = [

        {
            name: 'Date',
            selector: row => row.Date,
            cell: (row, j) => (new Date(row.Date).toLocaleDateString()),
            sortable: true,
        },
        {
            name: 'Location',
            selector: row => row.Location,
            sortable: true,
        },
        {
            name: 'Time',
            selector: row => row.Time,

            sortable: true,
        },
        {
            name: 'Action',
            sortable: true,
            cell: (row, j) => (
                <div class="inline-flex">

                    <Link onClick={() => requestEdit(j)}

                        class="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        to="#"
                    >
                        <img src={mail} alt="edit"></img>
                    </Link>

                </div>
            ),
        },


    ];
    return (

        <div className="main">
            <Header />
            <div className="main-content" >
                <HeaderTopDetail />
                <div className="pagewithsidebar">
                    <div className="container">
                        <div className="pagewithsidebar-inner">
                            <CurrentChildrenSideBar />
                            <div className="tab-detail">
                                <div className="tab-detail-top childprofile-tabs">
                                    <div className="children-section">
                                        <img src={courtdateinner} alt="" />
                                        <div className="pagesubtitle">
                                            Court Dates
                                        </div>
                                    </div>
                                    <Link to="#" onClick={showUpdateDiv}><img src={plus} style={{"height": "18px"}} alt="#"/>Request Addition</Link>
                                </div>

                                {/* <div className="children-tab">
                                        <span className="active">Easton Elliott</span> | <span>Easton Easton</span> | <span>Anna Litchfield</span>
                                    </div> */}
                                <div className="children-tab">

                                    {childrenData.map(child => {
                                        return <span className={activeId === child.ClientID ? "active" : ""} onClick={() => handleCurrentChildgetCourtDates(child.ClientID)}>{child.ClientName}</span>
                                    })}

                                    {/* // <span className="active">Easton Elliott</span> | <span>Easton Easton</span> | <span>Anna Litchfield</span> */}

                                </div>

                                {isLoading ? (<LoadSpinner />) : ''}

                                <div className="tab-detail-bottom children-data">
                                    <form className="children-doc-form">
                                        {addChildDiv ? <div className="req-update-textarea">
                                            <label><span><b>Request Addition for</b>-  Court Dates|
                                                <b> Child: {sessionStorage.getItem("current_child")}  </b>
                                                <br></br>
                                                <br></br>
                                                <b> Description for Additon:</b>
                                            </span></label>
                                            <textarea onChange={e => setUpdateData({ ...updateData, description: e.target.value })}></textarea>
                                            <div className="req-update-action">
                                                <Link to="#" onClick={hideAddChildDiv} className="close">close</Link>
                                                <Link to="#" onClick={() => onChildAdditionSubmit()} className="normal">Submit</Link>
                                            </div>
                                        </div> : ''}


                                        {updateDiv ? <div className="req-update-textarea">
                                            <label><span><b>Request Update For</b>- Court Dates|
                                                <b> Child: {sessionStorage.getItem("current_child")} | </b>
                                                Date: {selectedDataForEdit.Date ? selectedDataForEdit.Date : ''}    |
                                                Time: {selectedDataForEdit.Time ? selectedDataForEdit.Time : ''} |
                                                Location: {selectedDataForEdit.Location ? selectedDataForEdit.Location : ''}
                                                <br></br>
                                                <br></br>
                                                <b> Description for Update:</b>
                                            </span></label>
                                            <textarea onChange={e => setUpdateData({ ...updateData, description: e.target.value })}></textarea>
                                            <div className="req-update-action">
                                                <Link to="#" onClick={hideUpdateDiv} className="close">close</Link>
                                                <Link to="#" onClick={onupdateClick} className="normal">Submit</Link>
                                            </div>
                                        </div> : ''}

                                        {/* <table>
                                        <tr>
                                            <th className="date-th">Date</th>
                                            <th className="time-th">Time</th>
                                            <th>Location</th>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <td>mm/dd/yyyy</td>
                                            <td>00:00</td>
                                            <td>Place</td>
                                            <td className="download-icon"><a href=""><img src={edittwo} alt="" /></a></td>
                                        </tr>
                                    </table> */}

                                        <DataTable columns={columns} data={data} pagination={true} />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    );
}




export default CourtDates;
