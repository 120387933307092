import "../../Forms/CriticalUnusualIncidentForm/CriticalUnusualIncidentForm.scss";
import Myinfoinner from "../../../images/assets/form/Myinfoinner.svg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { post } from "../../../utils/common";
import { useHistory } from "react-router";

function CriticalUnusualIncidentFormPrint() {
  const [data, setData] = useState([]);

  const [IncidentTypeDataFromString, setIncidentTypeDataFromString] = useState(
    []
  );
  const clickedForDownload = async () => {
    const cuiformdata = JSON.parse(sessionStorage.getItem("cuiformdata"));
    console.log("formdata", cuiformdata);
    setData(cuiformdata ? cuiformdata : []);

    let string = cuiformdata.Incident_Type;

    let arrayofIncident = string.split("\r\n");

    console.log("arrayofIncident", arrayofIncident);

    setIncidentTypeDataFromString(arrayofIncident);
    // const providerid = sessionStorage.getItem('providerSessionId');
    // const UnusualIncident_FosterParentID = sessionStorage.getItem('UnusualIncident_FosterParentID');

    // let dataIA = await post('/api/unusualincident/getcuiform', { providerid, UnusualIncident_FosterParentID });
    // // console.log(dataIA.data.data)
    // setData(dataIA.data.data[0])
    // window.open('/print/criticalunusualform', "PRINT", "height=400,width=600");
  };

  useEffect(() => {
    clickedForDownload();
  }, []);

  return (
    <div className="main">
      <div className="main-content">
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <div className="tab-detail Critical pagealignpad">
                <div className="tab-detail-top">
                  <div className="Critical-unusual">
                    <img src={Myinfoinner} alt="" />
                    <span className="headerpad">
                      CRITICAL/UNUSUAL INCIDENTS
                    </span>
                  </div>
                </div>
                <div>
                  <form>
                    <div className="select-incident">
                      <div className="form-row mt-25">
                        <div className="label9 labelpadtop">
                          <label className="increasedFont">Incident :</label>
                        </div>
                        <div className="label23">
                          <input
                            type="text"
                            id="birthday"
                            name="birthday"
                            className="increasedFont"
                            onChange={(e) =>
                              setData({
                                ...data,
                                IncidentDescription: e.target.value,
                              })
                            }
                            value={data.IncidentDescription}
                          />
                          {/* <Link to="#" className="blue-btn">Select</Link>
                                                <Link to="#" className="red-btn">Delete</Link> */}
                        </div>
                      </div>
                    </div>
                    {/* {data.Incident_Date} */}

                    <div className="form-row mt-25">
                      <div className="label9 labelpadtop">
                        <label for="appt" className="increasedFont">
                          Child:
                        </label>
                      </div>
                      <div className="label23">
                        <input
                          type="text"
                          id="birthday"
                          name="birthday"
                          className="increasedFont"
                          onChange={(e) =>
                            setData({ ...data, Client_Name: e.target.value })
                          }
                          value={data.Client_Name}
                        />
                      </div>
                      <div className="label7"></div>
                      <div className="label12 labelpadtop">
                        <label for="birthday" className="increasedFont">
                          Date of Birth:
                        </label>
                      </div>
                      <div className="label23">
                        <input type="text" className="increasedFont"  onChange={(e) =>
                          setData({ ...data, dateofbirth : e.target.value })
                        }
                        value={data.DOB} />
                      </div>
                    </div>
                    <div className="g-background">
                      <div className="form-row mt-25">
                        <div className="label20">
                          <label for="birthday" className="increasedFont">
                            Date of Incident:
                          </label>
                          <input
                            type="text"
                            id="birthday"
                            name="birthday"
                            className="increasedFont"
                            onChange={(e) =>
                              setData({
                                ...data,
                                Incident_Date: e.target.value,
                              })
                            }
                            value={data.Incident_Date}
                          />
                          <small>*Future date will not be allowed.</small>
                        </div>
                        <div className="label5"></div>
                        <div className="label20">
                          <label for="appt" className="increasedFont">
                            Time of Incident:
                          </label>
                          <input
                            type="text"
                            id="appt"
                            name="appt"
                            className="increasedFont"
                            onChange={(e) =>
                              setData({
                                ...data,
                                Incident_Date: e.target.value,
                              })
                            }
                            value={data.Incident_Date}
                          />
                        </div>
                        <div className="label10"></div>
                        <div className="label20">
                          <label for="birthday increasedFont">
                            Date of Discovery:
                          </label>
                          <input
                            type="text"
                            id="birthday"
                            name="birthday"
                            className="increasedFont"
                            onChange={(e) =>
                              setData({
                                ...data,
                                Discovery_Date: e.target.value,
                              })
                            }
                            value={data.Discovery_Date}
                          />
                          <small>*Future date will not be allowed.</small>
                        </div>
                        <div className="label5"></div>
                        <div className="label20">
                          <label for="appt" className="increasedFont">
                            Time of Discovery:
                          </label>
                          <input
                            type="text"
                            id="appt"
                            name="appt"
                            className="increasedFont"
                            onChange={(e) =>
                              setData({
                                ...data,
                                Discovery_Date: e.target.value,
                              })
                            }
                            value={data.Discovery_Date}
                          />
                        </div>
                      </div>

                      <div className="form-row mt-30">
                        <div className="form-container">
                          <div className="formrowalign">
                            <label className="label50 labelpadtop increasedFont">
                              Name(s) of Foster Parent(s):
                            </label>
                            <input
                              className="label50 increasedFont"
                              type="text"
                              value={data.NameOfFosterParent == "undefined"? '': data.NameOfFosterParent}
                            ></input>
                          </div>
                          <div className="formrowalign">
                            <label className="label50 labelpadtop2 increasedFont">
                              Identify Saint Francis Office Location(s):
                            </label>
                            <input
                              className="label50 increasedFont"
                              type="text"
                              value={data.IdentifySaintFrancisOfficeLocation == "undefined"? '': data.IdentifySaintFrancisOfficeLocation}
                            ></input>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-row mt-30">
                      <div className="formrowalign2">
                        <label className="rowalign label25 increasedFont">
                          What agency placed child in the home:
                        </label>
                        <span className="rowalign label12">
                          <input
                            type="radio"
                            className="increasedFont"
                            name="agency_name"
                            id="SaintFrancis"
                            value={"SaintFrancis"}
                            checked={data.SaintFrancis ? true : false}
                          />
                          <label for="SaintFrancis" className="increasedFont">
                            Saint Francis
                          </label>
                        </span>
                        <span className="rowalign label7">
                          <input
                            type="radio"
                            className="increasedFont"
                            name="agency_name"
                            id="JJA"
                            value={"JJA"}
                            checked={data.JJA ? true : false}

                          />
                          <label for="JJA" className="increasedFont">
                            JJA
                          </label>
                        </span>
                        <span className="rowalign label7">
                          <input
                            type="radio"
                            className="increasedFont"
                            name="agency_name"
                            id="KVC"
                            value={"KVC"}
                            checked={data.KVC ? true : false}

                          />
                          <label for="KVC" className="increasedFont">
                            KVC
                          </label>
                        </span>
                        <span className="rowalign label7">
                          <input
                            type="radio"
                            className="increasedFont"
                            name="agency_name"
                            id="TFI"
                            value={"TFI"}
                            checked={data.TFI ? true : false}

                          />
                          <label for="TFI" className="increasedFont">
                            TFI
                          </label>
                        </span>
                        <span className="rowalign label7">
                          <input
                            type="radio"
                            className="increasedFont"
                            name="agency_name"
                            id="COC"
                            value={"COC"}
                            checked={data.COC ? true : false}

                          />
                          <label for="COC" className="increasedFont">
                            COC
                          </label>
                        </span>
                        <span className="rowalign label7">
                          <input
                            type="radio"
                            className="increasedFont"
                            name="agency_name"
                            id="DCF"
                            value={"DCF"}
                            checked={data.DCF ? true : false}

                          />
                          <label for="DCF" className="increasedFont">
                            DCF
                          </label>
                        </span>
                        <div>
                          <span className="rowalign">
                            <input
                              type="radio"
                              name="agency_name"
                              id="Other"
                              value={"Other"}
                              checked={data.Other ? true : false}

                            />
                            <label for="Other" className="increasedFont">
                              Other
                            </label>
                          </span>
                          <span className="rowalign">
                            <p className="identifytext increasedFont">
                              {" "}
                              (Identify):
                            </p>
                            <input
                              className="input_text"
                              id="Identify"
                              type="text"
                              value={data.othertextfield == "undefined"? '': data.othertextfield}
                            />
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* <div className="g-background">
                      <div className="form-row ">
                        <div className="label18 labelpadtop">
                          <label for="appt">Child:</label>
                        </div>
                        <div className="label30">
                          <input
                            type="text"
                            id="birthday"
                            name="birthday"
                            onChange={(e) =>
                              setData({ ...data, Client_Name: e.target.value })
                            }
                            value={data.Client_Name}
                          />

                          {/* <select>
                                                        <option value="0">Select</option>

                                                    </select> *
                        </div>
                        <div className="label4"></div>
                        <div className="label18 labelpadtop">
                          <label for="appt">Person Reporting:</label>
                        </div>
                        <div className="label30">
                          <input
                            type="text"
                            id="birthday"
                            name="birthday"
                            onChange={(e) =>
                              setData({
                                ...data,
                                Provider_Member: e.target.value,
                              })
                            }
                            value={data.Provider_Member}
                          />

                          {/* <select>
                                                        <option value="0">Select</option>

                                                    </select> *
                        </div>
                      </div>
                      <div className="form-row ">
                        <div className="label18 labelpadtop">
                          <label for="appt">Extent of Child Injury:</label>
                        </div>
                        <div className="label30">
                          <input
                            type="text"
                            id="birthday"
                            name="birthday"
                            onChange={(e) =>
                              setData({ ...data, ChildInjury: e.target.value })
                            }
                            value={data.ChildInjury}
                          />

                          {/* <select>
                                                        <option value="0">Select</option>

                                                    </select> *
                        </div>
                        <div className="label4"></div>
                        <div className="label18 labelpadtop">
                          <label for="appt">
                            Extent of Foster Parent Injury:
                          </label>
                        </div>
                        <div className="label30">
                          <input
                            type="text"
                            id="birthday"
                            name="birthday"
                            onChange={(e) =>
                              setData({ ...data, ParentInjury: e.target.value })
                            }
                            value={data.ParentInjury}
                          />

                          {/* <select>
                                                        <option value="0">Select</option>

                                                    </select> *
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="note">
                          <b>Help</b>
                          <ol>
                            <li>
                              None - No observable or predictable injury will
                              arise from the incident.
                            </li>
                            <li>Low - No pain or mild pain.</li>
                            <li>
                              Moderate - Discomforting or distressing pain
                              (treatment may include doctor visit).
                            </li>
                            <li>
                              Severe - Intense, severe, unbearable pain
                              (treatment may include emergency room visit).
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div> */}

                    <div className="incident-type form-row">
                      <div className="label15">
                        <h6 className="increasedFont">Type of Incident</h6>
                      </div>
                      <div className="label75">
                        <ul>
                          {IncidentTypeDataFromString.length>0?IncidentTypeDataFromString.slice(
                            0,
                            IncidentTypeDataFromString.length - 1
                          ).map((incData, index) => (
                            <li>
                              <input
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                              />
                              <label for="vehicle1" className="increasedFont">
                                {" "}
                                {incData}{" "}
                              </label>
                            </li>
                          )):''}
                        </ul>
                      </div>
                    </div>

                    <div className="form-row v-pass mt-25">
                      <p className="headtext increasedFont">
                        Did incident take place while on home visit/pass?:
                      </p>
                      {/* {data.HomeVisitIncident === "Yes" ? ( */}
                      <span>
                        <input
                          className="increasedFont"
                          type="radio"
                          id="Yes"
                          name="fav_language"
                          value="Yes"
                          checked={
                            data.HomeVisitIncident === "Yes" ? true : false
                          }
                        />
                        {/* onChange={e => setAddFormData({ ...AddFormData, IsHomeVisitIncident: e.target.checked })} type="radio" id="Yes" name="fav_language" value="Yes" /> */}
                        <label for="Yes" className="increasedFont">
                          Yes
                        </label>
                      </span>
                      {/* ) : ( */}
                      <span>
                        <input
                          className="increasedFont"
                          type="radio"
                          id="No"
                          name="fav_language"
                          value="No"
                          checked={
                            data.HomeVisitIncident === "No" ? true : false
                          }
                        />
                        {/* onChange={e => setAddFormData({ ...AddFormData, IsHomeVisitIncident: !e.target.checked })} name="fav_language" value="No" /> */}
                        <label className="increasedFont" for="No">
                          No
                        </label>
                      </span>
                      {/* )} */}
                    </div>

                    <div>
                      <div className="add-cmt">
                        <label className="headtext increasedFont">
                          Describe Incident:
                          <span className="italictext">
                            (if more room is needed, pleases attach additional
                            sheets)
                          </span>
                        </label>
                        <textarea
                          className="increasedFont"
                          value={data.IncidentDescription == "undefined"? '': data.IncidentDescription}
                        ></textarea>
                      </div>
                      <div className="actiontextarea">
                        <label className="headtext label17 increasedFont">
                          Immediate Action Taken:
                        </label>
                        <textarea
                          className="increasedFont"
                          value={data.ImmediateActionTaken == "undefined"? '': data.ImmediateActionTaken}
                        >
                          {" "}
                        </textarea>
                      </div>
                    </div>

                    <div className="form-row mt-25">
                      <div className="formrowalign2">
                        <label className="rowalign label15 headtext increasedFont">
                          Extent of Child Injury:
                        </label>
                        <span className="rowalign label10">
                          <input type="radio" name="child_injury" id="None" />
                          <label for="None" className="increasedFont">
                            {data.ChildInjury}
                          </label>
                        </span>
                        {/* <span className="rowalign label10">
                          <input type="radio" name="child_injury" id="Low" />
                          <label for="Low">Low</label>
                        </span>
                        <span className="rowalign label12">
                          <input type="radio" name="child_injury" id="Moderate" />
                          <label for="Moderate">Moderate</label>
                        </span>
                        <span className="rowalign label10">
                          <input type="radio" name="child_injury" id="Severe" />
                          <label for="Severe">Severe</label>
                        </span> */}
                      </div>
                    </div>
                    <div className="form-row mt-10">
                      <div className="formrowalign2">
                        <label className="rowalign label15 headtext increasedFont">
                          Foster Parent Injury:
                        </label>
                        <span className="rowalign label10">
                          <input
                            type="radio"
                            name="parent_injury"
                            id="None"
                            className="increasedFont"
                          />
                          <label for="None" className="increasedFont">
                            {data.ParentInjury}
                          </label>
                        </span>
                        {/* <span className="rowalign label10">
                          <input type="radio" name="parent_injury" id="Low" />
                          <label for="Low">Low</label>
                        </span>
                        <span className="rowalign label12">
                          <input type="radio" name="parent_injury" id="Moderate" />
                          <label for="Moderate">Moderate</label>
                        </span>
                        <span className="rowalign label10">
                          <input type="radio" name="parent_injury" id="Severe" />
                          <label for="Severe">Severe</label>
                        </span> */}
                      </div>
                    </div>

                    <div className="add-cmt2 mt-25">
                      <label className="headtext increasedFont">
                        All adults present or responsible for the client during
                        the incident:
                      </label>
                      <textarea
                        value={data.AdultsInvolved == "undefined"? '': data.AdultsInvolved}
                        className="increasedFont"
                      ></textarea>
                    </div>

                    <div className="form-row mt-25">
                      <div className="label15 labelpadtop">
                        <label for="appt" className="increasedFont">
                          Person Reporting:
                        </label>
                      </div>
                      <div className="label23">
                        <input
                          type="text"
                          id="birthday"
                          name="birthday"
                          className="increasedFont"
                          onChange={(e) =>
                            setData({
                              ...data,
                              Provider_Member: e.target.value,
                            })
                          }
                          value={data.Provider_Member}
                        />

                        {/* <select>
                                                        <option value="0">Select</option>

                                                    </select> */}
                      </div>
                      <div className="label7"></div>
                      <div className="label15 labelpadtop">
                        <label for="birthday" className="increasedFont">
                          Date Completed:
                        </label>
                      </div>
                      <div className="label23">
                        <input type="text" className="increasedFont"   onChange={(e) =>
                          setData({
                            ...data,
                            CompletedDate: e.target.value,
                          })
                        }
                        value={data.CompletedDate}/>
                      </div>
                    </div>

                    <div className="mt-25 notificationalign">
                      <div className="textfont">
                        Send to foster care worker. Original Placed in Child’s
                        file.
                      </div>
                      <div className="headtext">
                        <p>Notification</p>
                      </div>
                      <table>
                        <tr>
                          <th>Contact (If Applicable)</th>
                          <th>Contact Name</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Verbal/Email</th>
                          <th>By whom?</th>
                        </tr>
                        <tr className="tableinputbg">
                          <td>Administration</td>
                          <td>
                            {" "}
                            <input type="text" value={data.AdminContact?data.AdminContact:''} />
                          </td>
                          <td>
                            {" "}
                            <input type="text" value={data.AdminDate?data.AdminDate:''}/>
                          </td>
                          <td>
                            {" "}
                            <input type="text" value={data.AdminTime?data.AdminTime:''} />
                          </td>
                          <td>
                            {" "}
                            <input type="text" value={data.AdminEmail?data.AdminEmail:''}/>
                          </td>
                          <td>
                            {" "}
                            <input type="text" value={data.AdminBywhom?data.AdminBywhom:''} />
                          </td>
                        </tr>
                        <tr>
                          <td>Protection Reporting Center (when applicable)</td>
                          <td>
                            {" "}
                            <input type="text" value={data.ReportingCenterContact?data.ReportingCenterContact:''} />
                          </td>
                          <td>
                            {" "}
                            <input type="text" value={data.ReportingCenterByDate?data.ReportingCenterByDate:''}/>
                          </td>
                          <td>
                            {" "}
                            <input type="text"  value={data.ReportingCenterTime?data.ReportingCenterTime:''}/>
                          </td>
                          <td>
                            {" "}
                            <input type="text" value={data.ReportingCenterEmail?data.ReportingCenterEmail:''}/>
                          </td>
                          <td>
                            {" "}
                            <input type="text" value={data.ReportingCenterByWhom?data.ReportingCenterByWhom:''} />
                          </td>
                        </tr>
                        <tr className="tableinputbg">
                          <td>
                            Saint Francis Agency verbal notification immediately
                          </td>
                          <td>
                            {" "}
                            <input type="text" value={data.SFAVerbalNotificationContact?data.SFAVerbalNotificationContact:''} />
                          </td>
                          <td>
                            {" "}
                            <input type="text" value={data.SFAVerbalNotificationDate?data.SFAVerbalNotificationDate:''}/>
                          </td>
                          <td>
                            {" "}
                            <input type="text" value={data.SFAVerbalNotificationTime?data.SFAVerbalNotificationTime:''}/>
                          </td>
                          <td>
                            {" "}
                            <input type="text" value={data.SFAVerbalNotificationEmail?data.SFAVerbalNotificationEmail:''}/>
                          </td>
                          <td>
                            {" "}
                            <input type="text" value={data.SFAVerbalNotificationByWhom?data.SFAVerbalNotificationByWhom:''}/>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Saint Francis Agency written notification within 24
                            hours
                          </td>
                          <td>
                            {" "}
                            <input type="text" value={data.SFAWrittenNotificationContact?data.SFAWrittenNotificationContact:''} />
                          </td>
                          <td>
                            {" "}
                            <input type="text" value={data.SFAWrittenNotificationDate?data.SFAWrittenNotificationDate:''} />
                          </td>
                          <td>
                            {" "}
                            <input type="text" value={data.SFAWrittenNotificationTime?data.SFAWrittenNotificationTime:''}/>
                          </td>
                          <td>
                            {" "}
                            <input type="text" value={data.SFAWrittenNotificationEmail?data.SFAWrittenNotificationEmail:''}/>
                          </td>
                          <td>
                            {" "}
                            <input type="text" value={data.SFAWrittenNotificationByWhom?data.SFAWrittenNotificationByWhom:''}/>
                          </td>
                        </tr>
                        <tr className="tableinputbg">
                          <td>Police/Sheriff</td>
                          <td>
                            {" "}
                            <input type="text" value={data.Police_SheriffContact?data.Police_SheriffContact:''}/>
                          </td>
                          <td>
                            {" "}
                            <input type="text" value={data.Police_SheriffDate?data.Police_SheriffDate:''} />
                          </td>
                          <td>
                            {" "}
                            <input type="text" value={data.Police_SheriffTime?data.Police_SheriffTime:''}/>
                          </td>
                          <td>
                            {" "}
                            <input type="text" value={data.Police_SheriffEmail?data.Police_SheriffEmail:''}/>
                          </td>
                          <td>
                            {" "}
                            <input type="text" value={data.Police_SheriffByWhom?data.Police_SheriffByWhom:''}/>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div className="action mt-25">
                      <p>STEPS OF ACTION</p>
                      <a>
                        When you observe or are part of a Critical/Unusual
                        Incident, proceed through the following steps:
                      </a>
                      <ol>
                        <li>
                          Take immediate action as needed (i.e. safety,
                          emergency procedures, first aid).
                        </li>
                        <li>
                          Decide if emergent assistance from anyone is needed.
                          <ul>
                            <li>
                              If emergent assistance is needed, contact the
                              appropriate people (i.e. police, fire department,
                              etc.)
                            </li>
                            <li>
                              For verbal notification to Saint Francis, the
                              emergency number is 1-888-732-4673
                            </li>
                          </ul>
                        </li>
                        <li>Complete the Critical/Unusual Incident report.</li>
                        <li>
                          Send Critical/Unusual Incident form to your foster
                          care worker by email or mail within 24 hours.
                        </li>
                        <li>
                          Allegations of abuse and neglect must be reported
                          immediately.
                        </li>
                      </ol>
                    </div>

                    {/* <div className="add-cmt">
                      <label>Describe Incident:</label>
                      <textarea value={data.IncidentDescription}></textarea>
                      {/* onChange={e => setAddFormData({ ...AddFormData, IncidentDescription: e.target.value })}></textarea> 
                      <label>Immediate Action Taken:</label>
                      <textarea value={data.ImmediateActionTaken}> </textarea>
                      {/* onChange={e => setAddFormData({ ...AddFormData, ImmediateActionTaken: e.target.value })}> </textarea> 
                      <label>All Adults Present for Child Incident:</label>
                      <textarea value={data.AdultsInvolved}></textarea>
                      {/* onChange={e => setAddFormData({ ...AddFormData, AdultsInvolved: e.target.value })}></textarea> 
                    </div> */}
                    {/* <div className="action-btn">
                                            <Link to="#" onClick={closeClicked} className="red-btn">Close</Link>
                                            <Link to="#" onClick={onSubmitButtonClick} className="blue-btn">Submit</Link>
                                        </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default CriticalUnusualIncidentFormPrint;
