import React, { useState } from "react";
import { Redirect, useHistory } from "react-router";
import fmsmain from "../images/assets/images/fmsmain.svg";
import fmslogo from "../images/assets/images/sfm_logo.png";
import logoutimage from "../images/assets/logout.svg";
import { Link } from "react-router-dom";
import { isEmpty } from "class-validator";

function Header(props) {
  const history = useHistory();

  const RoleInfo = sessionStorage.getItem("RoleInfo");
  const logout = () => {
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("providerSessionId");
    sessionStorage.clear();
    history.push("/");
    window.location.href = "/";
  };
  let token = sessionStorage.getItem("token");
  let providerId = sessionStorage.getItem("providerSessionId");
  //TODO
  //For multiple user roles we have to create id from api itself
  const [isOpenMenuVisible, setisOpenMenuVisibleDiv] = useState(false);
  const openMenu = () => {
    if (!isOpenMenuVisible) {
      setisOpenMenuVisibleDiv(true);
    } else {
      setisOpenMenuVisibleDiv(false);
    }
  };

  return (
    <div className="header">
      <div className="header-top">
        <div className="container">
          {token ? (
            <div className="user-logout">
              <span>
                <Link to="#" onClick={() => logout()}>
                  <b>Logout :</b> <img src={logoutimage} />
                </Link>
                <span className="narea">
                  {sessionStorage.getItem("username")}(
                  {sessionStorage.getItem("RoleInfo")})
                </span>
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="header-bottom">
        <div className="container">
          <div className="navbar">
            <Link to="/index" className="logoauth">
              <img src={fmslogo} alt=""></img>
            </Link>
            <div className="mobile-menu" onClick={openMenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            {token ? (
              <ul
                className={`main-menu ${isOpenMenuVisible ? "open-menu" : ""}`}
              >
                <li>
                  <Link to="/index">home</Link>
                </li>

                {RoleInfo != "FosterHome" ? (
                  <li>
                    <Link to="/staff">staff</Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/staff">Set Provider</Link>
                      </li>
                      <li>
                        <Link to="/managementcenter/provider">
                          Management Center
                        </Link>
                      </li>

                      {sessionStorage.getItem("RoleInfo") == "Admin" ? (
                        <li aria-disabled="true">
                          <Link to="/contentmanage/adoptioncontact">
                            Content Management
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </li>
                ) : (
                  ""
                )}
                {providerId ? (
                  <li>
                    <Link to="/myprofile">my resources</Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/myprofile">My Information</Link>
                      </li>
                      <li>
                        <Link to="/currentchildren/children">
                          Current children
                        </Link>
                      </li>
                      <li>
                        <Link to="/previouschildren/index">
                          Previous children
                        </Link>
                      </li>
                      <li>
                        <Link to="/form/formindex">Forms</Link>
                      </li>
                      <li>
                        <Link to="/onlinetraining/one">Online trainings</Link>
                      </li>
                      {/* <li><Link to="/secure/placementSearch">Search for placement</Link></li> */}
                      <li><Link to="/secure/HomeHelp">Help Guide</Link></li>
                    </ul>
                  </li>
                ) : (
                  ""
                )}

                {/* <li><Link to="#">services</Link></li> */}
                <li>
                  <Link to="/parentdocument/parenthandbook">
                    parent document
                  </Link>

                  <ul className="sub-menu">
                    <li>
                      <Link to="/parentdocument/parenthandbook">
                        Parent Handbook
                      </Link>
                    </li>
                    <li>
                      <Link to="/parentdocument/paymentschedule">
                        Payment Schedule
                      </Link>
                    </li>
                    <li>
                      <Link to="/parentdocument/fosterhomeregulation">
                        Foster Home Regulation
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/secure/eventscalender">Events</Link>
                </li>
                <li>
                  <Link to="/contentmanage/announcementlist">
                    AnnounceMents
                  </Link>
                </li>
              </ul>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
