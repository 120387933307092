import Footer from '../../Footer';
import Header from '../../Header';
import levelofcareinner from '../../../images/assets/myinfo/levelofcareinner.svg';
import SideBar from '../../sidebar/sidebar';
import '../LevelOfCare/LevelofCare.scss'
import HeaderTopDetail from '../../CurrentChildren/HeaderTopDetail/HeaderTopDetail';
import { useState } from 'react';


function LevelOfCareRequestUpdate() {
    const [data, setData] = useState([]);
    const fetchData = async () => {
        let data = await fetch('/api/levelofcare/index');
        if (!data.ok) {
            return;
        }
        data = await data.json();
        console.log(data);
        setData(data.data);
    };

    useState(() => {
        fetchData();
    }, []);

    return (
        <div className="main">

            <Header />

            <div className="main-content" >
                <HeaderTopDetail />
                <div className="pagewithsidebar">
                    <div className="container">
                        <div className="pagewithsidebar-inner">
                            <SideBar />
                            <div className="tab-detail">
                                <div className="tab-detail-top">
                                    <div className="m-profile">
                                        <img src={levelofcareinner} alt="" />
                                        <div className="pagesubtitle">
                                            Level of Care
                                            <p>Below are level of  care that you are not willing or able to care for.</p>
                                        </div>
                                    </div>
                                    {/* <a href="#" className="request-btn">request update</a> */}
                                </div>
                                <div className="tab-detail-bottom">
                                    <form className="levelofcare">
                                        <div className="req-update-textarea">
                                            <label><span><b>Request update for</b> -  Adults in home    |   Name: Stovall, Don    |    Age: 57    |    Type: Resource Parent - Father
                                            <br></br>
                                            <br></br>
                                           <b> Description for Update:</b></span></label>
                                            <textarea></textarea>
                                            <div className="req-update-action">
                                                <a href="/levelofcare" className="close">close</a>
                                                <a href="/" className="normal">Submit</a>
                                            </div>
                                        </div>


                                        <div className="checkbox-section oe-design">
                                            <div className="form-row">
                                                {data.map((levelofcare, index) =>
                                                    <div className="one-fourth">
                                                        <span className="checkbox-design">
                                                            <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                            <label for="vehicle1">{levelofcare.FCHLevelOfCare}</label>
                                                        </span>
                                                    </div>

                                                )}
                                            </div>
                                            {/* <div className="form-row">
                                                <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Respite" />
                                                        <label for="vehicle1"> Respite</label>
                                                    </span>
                                                </div>
                                                <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Respite (Weekend only)" />
                                                        <label for="vehicle1"> Respite (Weekend only)</label>
                                                    </span>
                                                </div>
                                                <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                        <label for="vehicle1"> Emergency</label>
                                                    </span>
                                                </div>
                                                <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                        <label for="vehicle1"> Basic 1</label>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                        <label for="vehicle1">  Basic 2 (Moderate)</label>
                                                    </span>
                                                </div>
                                                <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                        <label for="vehicle1"> Intensive 1</label>
                                                    </span>
                                                </div>
                                                <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                        <label for="vehicle1"> Intensive 2 or Treatment Transition</label>
                                                    </span>
                                                </div>
                                                <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                        <label for="vehicle1"> Informal Care</label>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                        <label for="vehicle1"> Pre-Adoptive</label>
                                                    </span>
                                                </div>
                                                <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                        <label for="vehicle1"> Specific Children</label>
                                                    </span>
                                                </div>
                                                <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                        <label for="vehicle1"> Intellectual/Developmental Disability </label>
                                                    </span>
                                                </div>
                                                <div className="one-fourth">
                                                    <span className="checkbox-design">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                        <label for="vehicle1"> Therapeutic Foster Care</label>
                                                    </span>
                                                </div>
                                            </div> */}
                                            <a href="#" className="yellow-btn">Level of Care Definition</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    );
}


export default LevelOfCareRequestUpdate;

