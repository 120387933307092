import Footer from "../../Footer";
import Header from "../../Header";
import leastdesired from "../../../images/assets/myinfo/leastdesired.svg";
import SideBar from "../../sidebar/sidebar";
import "../LeastDesiredCondition/LeastDesiredCondition.scss";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { post } from "../../../utils/common";
import LoadSpinner from "../../Loader/LoadSpinner";
import plus from '../../../images/assets/cm/plus.svg';

function LeastDesiredCondition() {
  const [updateDiv, setShowUpdateDiv] = useState(false);

  const showUpdateDiv = () => {
    setShowUpdateDiv(true);
  };

  const hideUpdateDiv = () => {
    setShowUpdateDiv(false);
  };

  const [updateData, setUpdateData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // const submitForm = () => {
  //     alert("submitted")
  //     window.location.reload(false);
  // }

  const [data, setData] = useState([]);

  const [Behaviour, setBehaviour] = useState([]);
  const [SubstanceAbuse, setSubstanceAbuse] = useState([]);
  const [MoodTraumaPsychosis, setMoodTraumaPsychosis] = useState([]);
  const [MedicalConditions, setMedicalConditions] = useState([]);
  const [SexualBehaviour, setSexualBehaviour] = useState([]);
  const [Disabilities, setDisabilities] = useState([]);

  const fetchData = async () => {
    const id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/leastdesired/index", { id });
    console.log(data);
    const newData = data.data.data;

    let res1 = newData.filter(
      (dItem) => dItem.ConditionGroup == "Substance Abuse"
    );

    setSubstanceAbuse(res1);

    let res2 = newData.filter((dItem) => dItem.ConditionGroup == "Behaviors");

    setBehaviour(res2);

    let res3 = newData.filter(
      (dItem) => dItem.ConditionGroup == "Disabilities"
    );

    setDisabilities(res3);

    let res4 = newData.filter(
      (dItem) => dItem.ConditionGroup == "Medical Conditions"
    );

    setMedicalConditions(res4);

    let res5 = newData.filter(
      (dItem) => dItem.ConditionGroup == "Mood/Trauma/Psychosis"
    );

    setMoodTraumaPsychosis(res5);

    let res6 = newData.filter(
      (dItem) => dItem.ConditionGroup == "Sexual Behavior"
    );

    setSexualBehaviour(res6);

    // setShortDataPublic(resPublic);

    // if (!data.ok) {
    //     return;
    // }
    // // data = await data.json();
    // console.log(data);

    setData(data.data.data);
  };

  console.log("ss", SubstanceAbuse);

  const OnUpdateSubmit = async () => {
    setIsLoading(true);
    let username = sessionStorage.getItem("username");
    await post("/api/leastdesired/update-least-desired", {
      username,
      updateData,
    });
    alert("Request Update  Emailed Successfully !");
    setIsLoading(false);
    window.location.reload(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="main">
      <Header />

      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <SideBar />
              <div className="tab-detail">
                <div className="tab-detail-top">
                  <div className="m-profile">
                    <img src={leastdesired} alt="" />
                    <div className="pagesubtitle">
                      Least Desired Conditions
                      <p>
                        Below are conditions or behaviors of children you are
                        not willing or able to care for.
                      </p>
                    </div>
                  </div>
                  <Link to="#" onClick={showUpdateDiv} className="request-btn"><img src={plus} style={{"height": "18px"}} alt="#"/>
                    request update
                  </Link>
                </div>
                {isLoading ? <LoadSpinner /> : ""}
                <div className="tab-detail-bottom">
                  <form className="least-desired-condition">
                    {updateDiv ? (
                      <div className="req-update-textarea">
                        <label>
                          <span>
                            <b>Request update for</b> -Least Desired Condition
                            <br></br>
                            <br></br>
                            <b> Description for Update:</b>
                          </span>
                        </label>
                        <textarea
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                        <div className="req-update-action">
                          <Link
                            to="#"
                            onClick={hideUpdateDiv}
                            className="close"
                          >
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={OnUpdateSubmit}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="checkbox-section">
                      <h6 className="small-heading">Behaviors</h6>
                      <div className="form-row">
                        {Behaviour.map((behaviour) => (
                          <div className="one-fourth">
                            <span className="checkbox-design">
                              <input
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                value="Bike"
                                checked={
                                  behaviour.ConditionChecked == 0
                                    ? ""
                                    : "checked"
                                }
                                readOnly
                              />
                              <label for="vehicle1">
                                {" "}
                                {behaviour.Condition}
                              </label>
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="checkbox-section">
                      <h6 className="small-heading">Disabilities</h6>
                      <div className="form-row">
                        {Disabilities.map((disability) => (
                          <div className="one-fourth">
                            <span className="checkbox-design">
                              <input
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                value="Bike"
                                checked={
                                  disability.ConditionChecked == 0
                                    ? ""
                                    : "checked"
                                }
                                readOnly
                              />
                              <label for="vehicle1">
                                {" "}
                                {disability.Condition}
                              </label>
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="checkbox-section">
                      <h6 className="small-heading">Medical Conditions</h6>
                      <div className="form-row">
                      {MedicalConditions.map((medical) => (
                          <div className="one-fourth">
                            <span className="checkbox-design">
                              <input
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                value="Bike"
                                checked={
                                    medical.ConditionChecked == 0
                                    ? ""
                                    : "checked"
                                }
                                readOnly
                              />
                              <label for="vehicle1">
                                {" "}
                                {medical.Condition}
                              </label>
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="checkbox-section">
                      <h6 className="small-heading">Mood/Trauma/Psychosis</h6>
                      <div className="form-row">
                      {MoodTraumaPsychosis.map((moodtrauma) => (
                          <div className="one-fourth">
                            <span className="checkbox-design">
                              <input
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                value="Bike"
                                checked={
                                    moodtrauma.ConditionChecked == 0
                                    ? ""
                                    : "checked"
                                }
                                readOnly
                              />
                              <label for="vehicle1">
                                {" "}
                                {moodtrauma.Condition}
                              </label>
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="checkbox-section">
                      <h6 className="small-heading">Sexual Behavior</h6>
                      <div className="form-row">
                      {SexualBehaviour.map((sexual) => (
                          <div className="one-fourth">
                            <span className="checkbox-design">
                              <input
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                value="Bike"
                                checked={
                                    sexual.ConditionChecked == 0
                                    ? ""
                                    : "checked"
                                }
                                readOnly
                              />
                              <label for="vehicle1">
                                {" "}
                                {sexual.Condition}
                              </label>
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="checkbox-section">
                      <h6 className="small-heading">Substance Abuse</h6>
                      <div className="form-row">
                        {SubstanceAbuse.map((substance) => (
                          <div className="one-fourth">
                            <span className="checkbox-design">
                              <input
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                value="Bike"
                                checked={
                                  substance.ConditionChecked == 0
                                    ? ""
                                    : "checked"
                                }
                                readOnly
                              />
                              <label for="vehicle1">
                                {" "}
                                {substance.Condition}
                              </label>
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LeastDesiredCondition;
