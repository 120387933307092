// import '../../CriticalUnusualIncidentForm/CriticalUnusualIncidentForm.scss'
import "../../Forms/ImmidiateAssesmentForm/ImmidiateAssesmentForm.scss";
import immediateinner from "../../../images/assets/formimages/immediateinner.svg";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import React, { useState, useEffect } from "react";
import download from "../../../images/assets/myinfo/download.svg";
import ReactToPrint from "react-to-print";
import { useRef } from "react";
import lodinner from "../../../images/assets/formimages/lodinner.svg";


const LogOfDailyEventsPrint = () => {
  const [data, setData] = useState([]);
  const clickedForDownload = async () => {
    const lodesformdata = JSON.parse(sessionStorage.getItem("lodesformdata"));

    console.log(lodesformdata);
    setData(lodesformdata);
  };

  console.log(data);

  useEffect(() => {
    clickedForDownload();
  }, []);

  return (
    <div className="main">
      <div className="main-content">
        {/* <HeaderTopDetail /> */}
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              {/* <FormSideBar /> */}
              <div className="tab-detail Critical  pagealignpad">
                <div className="tab-detail-top">
                  <div className="Critical-unusual">
                    <img src={lodinner} alt="" />
                    <span className="headerpad">LoG of Daily Events</span>
                  </div>
                </div>
                <div >
                  <form>
                    <div className="form-row ">
                      <div className="label8 labelpadtop">
                        <label for="birthday">Date:</label>
                      </div>
                      <div className="label15">
                        <input type="text" value={data.LodeDate} />
                      </div>
                      <div className="label7"></div>
                      <div className="label15 labelpadtop">
                        <label for="birthday">Person Completing Lode:</label>
                      </div>
                      <div className="label20">
                        <input type="text" value={data.ProviderMemberName} />
                        {/* <select> */}
                        {/* <option value="0">Select:</option> */}
                        {/* <option value="1">Audi</option>
                                                    <option value="2">BMW</option>
                                                    <option value="3">Citroen</option> */}
                        {/* </select> */}
                      </div>
                      <div className="label7"></div>
                      <div className="label8 labelpadtop">
                        <label for="appt">Youth:</label>
                      </div>
                      <div className="label20">
                        <input type="text" value={data.ClientName} />
                        {/* <select>
                                                <option value="0">Select:</option> */}
                        {/* <option value="1">Audi</option>
                                                    <option value="2">BMW</option>
                                                    <option value="3">Citroen</option> */}
                        {/* </select> */}
                      </div>
                    </div>

                    <div className="imp-note mt-25">
                      <span>TIPS FOR COMPLETING OBSERVATIONS</span>
                      <ol>
                        <li>
                          Please be factual. Please share positives and
                          negatives.
                        </li>
                        <li>
                          Highlight progress of case plan goals/objectives.
                        </li>
                        <li>
                          Your lode is an official record of youth's care
                          provision.
                        </li>
                        <li>
                          Please return to your worker at home visit, email or
                          SFM Families Website.
                        </li>
                      </ol>
                    </div>

                    <div className="form-row fr-w mt-25">
                      <h6 className="small-text">Appointments</h6>
                      <label className="p-0" for="birthday">
                        Please address appointments in your daily observation
                        log below.
                      </label>
                    </div>
                    <div className="form-row  fr-w mt-25">
                      <div className="label15 labelpadtop">
                        <label for="birthday">Medical:</label>
                      </div>
                      <div className="label15">
                        <input type="text" value={data.MedicalAppointment} />
                      </div>
                      <div className="label5"></div>
                      <div className="label15 labelpadtop">
                        <label for="birthday">School Meetings:</label>
                      </div>
                      <div className="label15">
                        <input type="text" value={data.SchoolAppointment} />
                      </div>
                      <div className="label5"></div>
                      <div className="label15 labelpadtop">
                        <label for="birthday">Case Plan:</label>
                      </div>
                      <div className="label15">
                        <input type="text" value={data.CasePlanAppointment} />
                      </div>
                    </div>
                    <div className="form-row  fr-w mt-5">
                      <div className="label15 labelpadtop">
                        <label for="birthday">Mental Health:</label>
                      </div>
                      <div className="label15">
                        <input
                          type="text"
                          value={data.PsychiatricAppointment}
                        />
                      </div>
                      <div className="label5"></div>
                      <div className="label15 labelpadtop">
                        <label for="birthday">Dentist:</label>
                      </div>
                      <div className="label15">
                        <input type="text" value={data.DentistAppointment} />
                      </div>
                      <div className="label5"></div>
                      <div className="label15 labelpadtop">
                        <label for="birthday">Court:</label>
                      </div>
                      <div className="label15">
                        <input type="text" value={data.CourtAppointment} />
                      </div>
                    </div>
                    <div className="form-row  fr-w mt-5">
                      <div className="label15 labelpadtop">
                        <label for="birthday">Vision:</label>
                      </div>
                      <div className="label15">
                        <input type="text" value={data.VisionAppointment} />
                      </div>
                      <div className="label5"></div>
                      <div className="label15 labelpadtop">
                        <label for="birthday">Other:</label>
                      </div>
                      <div className="label15">
                        <input type="text" value={data.OtherAppointment} />
                      </div>
                    </div>
                    <div className="form-grey ">
                      <h6 className="small-text">BIRTH FAMILY CONTACT</h6>
                      <div className="form-row  fr-w mt-5">
                        <div className="label18 labelpadtop">
                          <label>Date and Time (Home Visit):</label>
                        </div>
                        <div className="label25">
                          <input type="text" value={data.HomeVisitDate} />
                        </div>
                        <div className="label5"></div>
                        <div className="label18 labelpadtop">
                          <label>Name / Relationship:</label>
                        </div>
                        <div className="label25">
                          <input
                            type="text"
                            value={data.HomeVisitNameRelationShipOfContact ? data.HomeVisitNameRelationShipOfContact : ''}
                          />
                        </div>
                      </div>
                      <div className="form-row  fr-w mt-5">
                        <div className="label18 labelpadtop">
                          <label>Date and Time (Home Visit):</label>
                        </div>
                        <div className="label25">
                          <input type="text" value={data.PhoneCallDate} />
                        </div>
                        <div className="centergap"></div>
                        <div className="label18 labelpadtop">
                          <label>Name / Relationship:</label>
                        </div>
                        <div className="label25">
                          <input
                            type="text"
                            value={data.PhoneCallNameRelationShipOfContact ? data.PhoneCallNameRelationShipOfContact : ''}
                          />
                        </div>
                      </div>
                      <div className="form-row  fr-w mt-5">
                        <div className="label18 labelpadtop">
                          <label>Date and Time (Home Visit):</label>
                        </div>
                        <div className="label25">
                          <input type="text" value={data.OtherDate} />
                        </div>
                        <div className="centergap"></div>
                        <div className="label18 labelpadtop">
                          <label>Name / Relationship:</label>
                        </div>
                        <div className="label25">
                          <input
                            type="text"
                            value={data.OtherNameRelationShipOfContact ? data.OtherNameRelationShipOfContact : ''}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="req-update-textarea pt-0">
                      <label>
                        <span>
                          <b>Narrative</b>
                        </span>
                      </label>
                      <textarea value={data.Notes}></textarea>
                      {/* <div className="req-update-action">
                                            <Link to="#" className="close">Close</Link>
                                            <Link to="#" className="normal">Save and Close</Link>
                                            <Link to="#" className="normal">Submit</Link>
                                        </div> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogOfDailyEventsPrint;
