import Footer from "../HeaderFooter/Footer/Footer";
import Header from "../HeaderFooter/Header/Header";

import landingcss from "../LandingMain/LandingMain.scss";
import headerscss from "../../LandingPages/HeaderFooter/Header/Header.scss";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadSpinner from "../../Loader/LoadSpinner";
import designimage from "../../../images/222design.png";

// login function
async function loginUser(credentials) {
  // return fetch('/api/staff/getstaffbyemailandpassword', {
  return fetch("/api/authentication/Login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  });
}

function LandingMain(props) {
  const history = useHistory();
  // if (props.token) {
  //   window.location.href = "/index";
  //   // history.push('/index');
  // }

  const [ShowPassword, setShowpassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const showTogglePassword = (event) => {
    event.target.checked ? setShowpassword(false) : setShowpassword(true);
  };
  const [email, setUserName] = useState();
  const [password, setPassword] = useState();
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      let response = await loginUser({
        email,
        password,
      });

      console.log(response);
      if (!response.ok) {
        alert("Invalid Credentials !");
        setIsLoading(false);
        return false;
      } else {
        // history.push('/index');
        response = await response.json();
        console.log(response);
        props.setToken(response.userDataWithToken.token);
        sessionStorage.setItem("RoleInfo", response.roleData[0].RoleName);
        sessionStorage.setItem("username", response.data[0].UserName);
        sessionStorage.setItem("userId", response.data[0].UserId);
        sessionStorage.setItem("token", response.token);
        localStorage.setItem("username", response.data[0].UserName);
        localStorage.setItem("userId", response.data[0].UserId);
       // sessionStorage.setItem("Password", password);

        if (response.roleData[0].RoleName === "FosterHome") {
          sessionStorage.setItem(
            "providerSessionId",
            response.data[0].providerid
          );

          // password
          if (password == "Setup@123" || password == "Welcome@123") {
            alert(
              "Please Change Your Password First!!We are redirecting you to a Please change password First"
            );
            window.location = "/enforce-password-change";
          } else {
            let url = "/myprofile";
            window.location = "/myprofile";
          }
        } else {
          if (password == "Setup@123" || password == "Welcome@123") {
            alert(
              "Please Change Your Password First!!We are redirecting you to a Page ,Please change password First"
            );
            window.location = "/enforce-password-change";
            // history.push("/enforce-password-change");
          } 
          else {
           window.location = "/index";
          }
        }
        setIsLoading(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onKeyPressHandle = (e) => {
    //  e.preventDefault();
    if (e.which === 13) {
      handleSubmit(e);
    }
  };

  console.log(email, password);
  // useEffect(() => {
  //   const keyDownHandler = async e => {
  //     console.log('User pressed: ', e.key);

  //     if (e.key === 'Enter') {
  //       e.preventDefault();
  //       // let e = event

  //       // 👇️ call submit function here
  //       handleSubmit(e);
  //     }
  //   };

  //   document.addEventListener('keydown', keyDownHandler);

  //   return () => {
  //     document.removeEventListener('keydown', keyDownHandler);
  //   };
  // }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      <Header />
      <div className="hero-section">
        <div className="container">
          <div className="hero-text">
            <h2>SAINT FRANCIS MINISTRIES | SFMFAMILIES.ORG</h2>
            <p>
              SFM Families is an on-line community for our valued foster and
              kinship families. It's a place to find responsive support,
              up-to-date information, easily accessible tools and training, and
              professional resources. If you are not a foster parent and would
              like to explore this opportunity for service, please contact us.
              Your decision could bring the change a child needs to reach his or
              her full potential.
            </p>
            <div className="action-btn">
              <Link
                to={{
                  pathname: "https://saintfrancisministries.org/foster-care/",
                }}
                target="_blank"
                title="More Questions...?"
              >
                Learn more
              </Link>
              <Link
                to={{ pathname: "/form/fosterparentreferalform" }}
                title="Ready to foster"
              >
                I’m Ready to Start
              </Link>
              <Link
                to="/forms/public/powerofyou"
                title="Recommend/Refer A Family..."
              >
                Power of You
              </Link>
            </div>
          </div>

          {isLoading ? <LoadSpinner /> : ""}
          <div className="login-form">
            <form>
              <h3>My Account</h3>
              <div className="form-row fd-c">
                <div class="log-field">
                  <label class="form-label">Username:</label>
                  <input
                    name="email"
                    type="text"
                    onChange={(e) => setUserName(e.target.value)}
                    onKeyPress={onKeyPressHandle}
                    class="form-control"
                  />
                </div>
                <div class="log-field">
                  <label class="form-label">Password:</label>
                  <input
                    type={ShowPassword ? "password" : "text"}
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    name="password"
                    class="form-control"
                    onKeyPress={onKeyPressHandle}
                  />
                  {/* type={ShowPassword.hidden ? 'password' : 'text'} */}
                  {/* onClick={showTogglePassword} */}
                </div>
                <div className="show-pass">
                  <input
                    type="checkbox"
                    onChange={showTogglePassword}
                    id="showpass"
                    name="vehicle3"
                    value="Boat"
                  />
                  <label for="showpass"> Show Password</label>
                </div>
                <div class="log-action">
                  {/* <a href="#" className="yellow-btn">  */}
                  <Link to="#" onClick={handleSubmit} className="yellow-btn">
                    Login{" "}
                  </Link>
                  {/* </a> */}

                  <Link to="/forgot">forgot Password?</Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="about-section">
        {/* <div className="container">
          <div className="about-section-img">
            <img src={aboutimg} alt=""></img>
          </div>
          <div className="about-section-text">
            <p>
              <h4 className="about-section-text-h4 mb-20"> MYTH VS REALITY</h4>
              <p ><b className="about-section-text-h5">MYTH</b> – I can’t foster because I have no parenting experience, or my children are grown and out of the house.
              </p>
              <p ><b className="about-section-text-h5">REALITY</b> =There is no age requirement other than being at least 21 years old. Foster parents are responsible
                people who have made a
                commitment to all children regardless of relation. Many “empty nesters” find fostering a rewarding experience.</p>


            </p>
          </div>
        </div> */}
        <div className="container img__cont">
          {/* <div className="about-section-img"> */}
          <img src={designimage} alt=""></img>

          {/* </div> */}
          <div className="button-class">
            <Link
              to={{
                pathname:
                  "https://www.canva.com/design/DAE3UnTySP8/NKUur8taU7SRa1Lpea0EdQ/watch?utm_content=DAE3UnTySP8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
              }}
              target="_blank"
            >
              <button className="xys">Click to learn more!</button>
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default LandingMain;
