import { useState } from 'react';
import { Link } from 'react-router-dom';
import MyProfile from '../../../images/assets/images/myprofile.svg';



function PreviousChildrenSidebar(props) {
    const data = props.data ? props.data : '';
    console.log("from sidebar", data)


    const handleGlobalPreviousChild = (prevChildId) => {
        sessionStorage.setItem("PreviousChildId",prevChildId);
        window.location.reload(false)
    }

    return (


        <div className="sidebar">
            <h3 className="children-dec">Children</h3>
            <ul>
                {data ? data.map(d => (
                    <li><Link to="#" className="active" onClick={() => handleGlobalPreviousChild(d.ClientID)}><span>{d.ClientName}</span></Link></li>

                )) : ''}


                {/* <li><Link to="#"><span>Ajualip, Ronaldo</span></Link></li>
                <li><Link to="#"><span>Allison, Austin</span></Link></li> */}
            </ul>
        </div>


    );
}


export default PreviousChildrenSidebar;

