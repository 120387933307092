import Footer from "../../Footer";
import Header from "../../../frontendPages/Header";
import ContentManagementSideBar from "../ContentManagementSideBar/ContentManagementSideBar";
import calenderinner from "../../../images/assets/cm/calenderinner.svg";
import ContentHeaderTopDetail from "../ContentHeaderTopDetail/ContentHeaderTopDetail";
import "../AdoptionContact/AdoptionContact.scss";
import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { post } from "../../../utils/common";
import { useHistory } from "react-router";
import axios from "axios";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import moment from "moment";
import ReactDOM from "react-dom";
import timeGridPlugin from "@fullcalendar/timegrid";

function CalenderOfEvents() {
  let calendarComponentRef = React.createRef();
  const history = useHistory();
  const [baseData, setBaseData] = useState([]);
  const [data, setData] = useState([]);

  const [showAddEvent, showAddEventDiv] = useState(false);

  const showAddEventDivision = () => {
    showAddEventDiv(true);
  };

  const hideAddEvenDivision = () => {
    showAddEventDiv(false);
  };

  const [eventStoreData, seteventStoreData] = useState([]);

  const [listOnPrivateCalender, setListOnPrivateCalender] = useState(false);

  const ListOnPrivateCalenderClicked = (e) => {
    if (e.target.checked) {
      setListOnPrivateCalender(true);
      seteventStoreData({ ...eventStoreData, IsSecure: e.target.checked });
    } else {
      setListOnPrivateCalender(false);
      seteventStoreData({ ...eventStoreData, IsSecure: e.target.checked });
    }
  };
  //add calender data
  const onSubmitButtonClick = async (e) => {
    console.log(eventStoreData);
    e.preventDefault();
    eventStoreData.Title = (eventStoreData.Title).replace(/'/g,"''");
    eventStoreData.Description = (eventStoreData.Description).replace(/'/g,"''");
    await axios.post("/api/eventcalender/store", { eventStoreData });
    alert("Record created successfully !");
    window.location.reload(true);
    history.push("/contentmanage/calenderofevents");
  };

  const fetchData = async () => {
    let data = await fetch("/api/eventcalender/index");
    if (!data.ok) {
      return;
    }
    data = await data.json();

    setData(data.data);
    setBaseData(data.data);
  };
  console.log("data", data);

  const [events, setEvents] = useState({
    title: data.Title,
    start: data.CalendarDate,
    date: data.CalendarDate,
    allDay: false,
    displayEventTime: true,
    Location: data.Location,
  });

  let allEvents = [
    data.map((obj) =>
      // console.log(obj.CalendarDate)
      ({
        ...events,
        id: Math.random(),
        title: obj.Title + "/" + obj.Location,
        start: obj.CalendarDate,
        date: obj.CalendarDate,
      })
    ),
  ];

  let allArrayEvents = [];
  allArrayEvents.push(allEvents[0]);

  const [isDataAllDayEvent, setIsDataAllDayEvent] = useState(false);
  const AllDayEventClicked = (e) => {
    if (e.target.checked) {
      setIsDataAllDayEvent(true);
      seteventStoreData({ ...eventStoreData, IsAllDayEvent: e.target.checked });
    } else {
      setIsDataAllDayEvent(false);
      seteventStoreData({ ...eventStoreData, IsAllDayEvent: e.target.checked });
    }
  };

  const handleEventClick = ({ event, el }) => {
    console.log("event ", event.title);
    console.log("baseData", baseData);
    let dataForEditing = baseData.filter(
      (da) => da.Title == event.title.split("/")[0]
    );

    //   let mappedPosts = posts.map(post => {
    //     post.authors = authors.filter(author => post.authors.some(a => a === author.email));

    //     return post;
    // });

    console.log("dataForEditing", dataForEditing);
    sessionStorage.setItem(
      "gridCalDataForEditing",
      JSON.stringify(dataForEditing[0])
    );
    window.location.href = "/edit/eventgrid";
  };

  console.log("eventStoreData", eventStoreData);

  useEffect(() => {
    fetchData();
  }, []);

  //   modal: false,
  //   calendarWeekends: true,
  //   event: []
  return (
    <div className="main">
      <Header />
      <div className="main-content">
        <ContentHeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <ContentManagementSideBar />
              <div className="tab-detail" style={{"margin-bottom": "32px"}}>
                <div className="tab-detail-top children-tabs">
                  <div className="children-section">
                    <img src={calenderinner} alt="" />
                    <div className="pagesubtitle">Calendar of Events</div>
                  </div>
                  <div className="search-tab">
                    <form action="/action_page.php">
                      {showAddEvent ? (
                        ""
                      ) : (
                        <a href="#" onClick={showAddEventDivision}>
                          Add a new item to the calendar
                        </a>
                      )}
                    </form>
                  </div>
                </div>

                {showAddEvent ? (
                  <div className="tab-detail-bottom children-data">
                    <form className="children-doc-form">
                      <div className="form-row jc-sb">
                        <div className="one-half">
                          <label>Title:</label>
                          <input
                            type="text"
                            onChange={(e) =>
                              seteventStoreData({
                                ...eventStoreData,
                                Title: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="one-fourth">
                          <label>SFM Office Location:</label>
                          {/* <input type="text" /> */}
                          <select name="cars" id="cars">
                            <option value="volvo">Kansas</option>
                            <option value="saab">Oklahama</option>
                            <option value="mercedes">Nebraska</option>
                          </select>
                        </div>
                        <div className="one-fourth">
                          <label>Date of Event:</label>
                          <input
                            type="date"
                            onChange={(e) =>
                              seteventStoreData({
                                ...eventStoreData,
                                CalendarDate: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="checkbox-design mb-20">
                        <span>
                          <input
                            type="checkbox"
                            onChange={ListOnPrivateCalenderClicked}
                            name="private-calender"
                            value="private-calender"
                          />
                          <label htmlFor="private-calender">
                            {" "}
                            List on Private Calendar only
                          </label>
                        </span>
                        {listOnPrivateCalender ? (
                          <div>
                            <span>
                              <input
                                type="checkbox"
                                name="Priority Event"
                                value="Priority Event"
                                onChange={(e) =>
                                  seteventStoreData({
                                    ...eventStoreData,
                                    IsFosterFamily: e.target.checked,
                                  })
                                }
                              />
                              <label htmlFor="Priority Event">
                                KS Foster Family
                              </label>
                            </span>
                            <span>
                              <input
                                type="checkbox"
                                name="All Day Event"
                                value="All Day Event"
                                onChange={(e) =>
                                  seteventStoreData({
                                    ...eventStoreData,
                                    IsKinshipFamily: e.target.checked,
                                  })
                                }
                              />
                              <label htmlFor="All Day Event">
                                KS Kinship Family
                              </label>
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                        <span>
                          <input
                            type="checkbox"
                            name="Priority Event"
                            value="Priority Event"
                            onChange={(e) =>
                              seteventStoreData({
                                ...eventStoreData,
                                OnMainPage: e.target.checked,
                              })
                            }
                          />
                          <label htmlFor="Priority Event">Priority Event</label>
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            name="All Day Event"
                            value="All Day Event"
                            onChange={AllDayEventClicked}
                          />
                          <label htmlFor="All Day Event">All Day Event</label>
                        </span>
                        <span>
                          <small>* Note: Uncheck to enter time.</small>
                        </span>
                      </div>
                      <div className="checkbox-design mb-30">
                        <b>Event Mode:</b>
                        <span>
                          <input
                            type="checkbox"
                            name="In-person training"
                            value="In-person training"
                            onChange={(e) =>
                              seteventStoreData({
                                ...eventStoreData,
                                isInPerson: e.target.checked,
                              })
                            }
                          />
                          <label htmlFor="In-person training">
                            {" "}
                            In-person training
                          </label>
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            name="Value training"
                            value="Value training"
                            onChange={(e) =>
                              seteventStoreData({
                                ...eventStoreData,
                                isVirtual: e.target.checked,
                              })
                            }
                          />
                          <label htmlFor="Value training">
                            Virtual training
                          </label>
                        </span>
                      </div>
                      <div className="form-row mb-30 jc-sb">
                        {isDataAllDayEvent ? (
                          ""
                        ) : (
                          <>
                            <div className="one-fourth">
                              <label>Begin Time:</label>
                              <input
                                type="time"
                                onChange={(e) =>
                                  seteventStoreData({
                                    ...eventStoreData,
                                    BeginTime: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="one-fourth">
                              <label>End Time:</label>
                              <input
                                type="time"
                                onChange={(e) =>
                                  seteventStoreData({
                                    ...eventStoreData,
                                    EndTime: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </>
                        )}
                        <div className="one-half">
                          <label>Event Location:</label>
                          <input
                            type="text"
                            onChange={(e) =>
                              seteventStoreData({
                                ...eventStoreData,
                                Location: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="req-update-textarea pt-0 pl-0 pr-0">
                        <label className="pl-0">Description</label>
                        <textarea
                          className="mt-0"
                          onChange={(e) =>
                            seteventStoreData({
                              ...eventStoreData,
                              Description: e.target.value,
                            })
                          }
                        ></textarea>
                        <div className="form-row mb-20 fd-c">
                          <label className="pl-0">Link path:</label>
                          <input
                            type="text"
                            onChange={(e) =>
                              seteventStoreData({
                                ...eventStoreData,
                                LinkPath: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="req-update-action">
                          <Link
                            to="#"
                            onClick={hideAddEvenDivision}
                            className="close"
                          >
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={onSubmitButtonClick}
                            className="normal"
                          >
                            {" "}
                            Submit
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : (
                  ""
                )}

                <FullCalendar
                  timeZone={"UTC"}
                  plugins={[dayGridPlugin]} //interactionPlugin
                  events={allArrayEvents[0]}
                  allDay="false"
                  // displayEventTime="true"
                  // timeFormat="h(:mm)A"
                  editable="true"
                  // defaultView="timeGridDay"
                  // header={{
                  //   left: "prev,next today",
                  //   center: "title",
                  //   right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
                  // }}
                  // plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  ref={calendarComponentRef}
                  // weekends={this.state.calendarWeekends}
                  // events={allArrayEvents[0]}
                  eventClick={handleEventClick}
                  // nowIndicator='true'
                  // height='parent'
                />
                {/* <Modal isOpen={modal} toggle={toggle}>
                  <ModalHeader toggle={toggle}>
                  <Input value={eventSelected.title} 
              type="email"
              name="email"
              id="exampleEmail"
              placeholder="Title"
            /> */}
                {/* TITLE: {eventSelected.title} */}
                {/* </ModalHeader>
                  <ModalBody>
                    <div>
                      <input type="text" value={new Date(eventSelected.start).toString()} ></input> */}
                {/* Timing: {new Date(eventSelected.start).toString()} */}
                {/* </div>
                  </ModalBody>
                  <ModalFooter>
                    <Link to="#" onClick={upDateEvent} className="normal">Update</Link>{" "}
                    <Link to="#" className="close" onClick={toggle}>
                      Cancel
                    </Link>
                  </ModalFooter>
                </Modal> */}
                {/* allArrayEvents[0].map(allev => {
                                 return 2;
                              }) */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CalenderOfEvents;
