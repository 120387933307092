import Footer from "../../Footer";
import Header from "../../../frontendPages/Header";
import CurrentChildrenSideBar from "../CurrentChildrenSidebar/CurrentChildrenSidebar";
import HeaderTopDetail from "../HeaderTopDetail/HeaderTopDetail";
import ccinner from "../../../images/assets/currentChildren/ccinner.svg";
import search from "../../../images/assets/currentChildren/search.svg";
import download from "../../../images/assets/currentChildren/download.svg";
import edit from "../../../images/assets/myinfo/edit.svg";
import "../../CurrentChildren/AttachedDocuments/AttachedDocuments.scss";
import { Link } from "react-router-dom";
import { useState } from "react";
import { post } from "../../../utils/common";
import { useEffect } from "react";
import LoadSpinner from "../../Loader/LoadSpinner";
import DataTable from "react-data-table-component";
import mail from "../../../images/mail.png";
import { DownloadURL } from "../../common/commonURL";
import axios from 'axios';
import plus from '../../../images/assets/cm/plus.svg';
import Modal from "react-modal";

Modal.setAppElement("#root");

function AttachDocuments() {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }
  const [imgName, setImgName] = useState("");
  const [childrenData, setChildrenData] = useState([]);
  const [selectedChildrenData, setselectedChildrenData] = useState([]);
  const [selectedDataForEdit, setSelectedDataForEdit] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const fetchData = async () => {
    const id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/currentchildren/index", { id });
    setChildrenData(data.data.data);
    let children_id = data.data.data[0].ClientID;

    setActiveId(children_id);
    let provider_id = id;
    let detailData = await post("/api/currentchildren/get-attach-document", {
      provider_id,
      children_id,
    });

    sessionStorage.setItem("childid", children_id);

    // console.log("detailData", detailData);

    // setAttachDocument(detailData.data.data);
    // setselectedChildrenData(data.data.data);
    let data1;

    // if (sessionStorage.getItem("RoleInfo" == "FosterHome")) {
    //   data1 = detailData.data.data.filter(
    //     (d) => d.Document_Type !== "Monthly Report"
    //   );
    // } else {
    //   data1 = detailData.data.data;
    // }

    data1 = detailData.data.data.filter(
      (d) => d.Document_Type !== "Monthly Report"
    );
    setAttachDocument(data1);
  };

  const [attachDocument, setAttachDocument] = useState([]);
  const [activeId, setActiveId] = useState();

  const handleCurrentChildGlobal = async (children_id) => {
    setShowAddDiv(false);
    setShowUpdateDiv(false);
    setUpdateData([]);
    sessionStorage.setItem("childid", children_id);

    setActiveId(children_id);

    setIsLoading(true);
    sessionStorage.setItem("childrenId", children_id);
    // setTimeout(10);
    const provider_id = sessionStorage.getItem("providerSessionId");

    let data = await post("/api/currentchildren/get-attach-document", {
      provider_id,
      children_id,
    });
    // setselectedChildrenData(data.data.data);
    let data1;

    if (sessionStorage.getItem("RoleInfo" == "FosterHome")) {
      data1 = data.data.data.filter(
        (d) => d.Document_Type !== "Monthly Report"
      );
    } else {
      data1 = data.data.data;
    }
    setAttachDocument(data1);

    //common code
    // children_id = sessionStorage.getItem("childrenId");
    let detaildata = await post("/api/currentchildren/children-detail", {
      provider_id,
      children_id,
    });
    // setAttachDocument(detaildata.data.data);
    sessionStorage.setItem(
      "current_child",
      detaildata.data.data.length > 0
        ? detaildata.data.data[0].ClientName
        : "Null"
    );
    // console.log("Data", data[0])
    //common code end

    setIsLoading(false);
  };

  console.log(attachDocument);

  const [incidentDropDownList, setincidentDropdownList] = useState([]);
  const getDropdownList = async () => {
    let data = await post("/api/currentchildren/get-incident-dropdown");
    setincidentDropdownList(data.data.data);
  };

  const getAttachDocuments = async () => {
    setIsLoading(true);
    var children_id = sessionStorage.getItem("childrenId");
    // setTimeout(10);
    const provider_id = sessionStorage.getItem("providerSessionId");

    let data = await post("/api/currentchildren/get-attach-document", {
      provider_id,
      children_id,
    });
    // setselectedChildrenData(data.data.data);
    let data1;

    if (sessionStorage.getItem("RoleInfo" == "FosterHome")) {
      data1 = data.data.data.filter(
        (d) => d.Document_Type !== "Monthly Report"
      );
    } else {
      data1 = data.data.data;
    }
    setAttachDocument(data1);
    setIsLoading(false);
  };

  const [updateDiv, setShowUpdateDiv] = useState(false);
  const [AddDiv, setShowAddDiv] = useState(false);

  const showUpdateDiv = () => {
    setShowUpdateDiv(true);
    setShowAddDiv(false);
  };
  const hideAddDiv = () => {
    setShowAddDiv(false);
  };

  const hideUpdateDiv = () => {
    setShowUpdateDiv(false);
    setShowAddDiv(false);
  };
  const showAddDiv = () => {
    setShowUpdateDiv(false);
    setShowAddDiv(true);
  };

  const [editData, setEditDocumentData] = useState([]);
  const setEditData = (j) => {
    setEditDocumentData(attachDocument[j]);
    setShowUpdateDiv(true);
    setShowAddDiv(false);
    setSelectedDataForEdit(attachDocument[j]);
  };
  console.log(editData);

  const onSubmitAdditionClick = async () => {
    setIsLoading(true)

    let username = sessionStorage.getItem("username");
    setShowAddDiv(false);
    setShowUpdateDiv(false);
    // setUpdateData([]);

    // @Clientid int,
    // @Description varchar(max),
    // @EnteredBy varchar(max),
    // @EnteredDate datetime,
    // @FileName varchar(max),
    // @Filesize int,
    // @UploadBy varchar(max),
    // @UploadDate datetime,
    // @ScanDocumentTypeID int,
    // @NewIdentity int OUTPUT



    // const id = selectedCaraouselData.CarouselImagesID;
   let Clientid = sessionStorage.getItem("childid");
   let EnteredBy = sessionStorage.getItem('username');

    const data = new FormData();
    data.append("file", updateData.FileName);
    data.append("attachData", JSON.stringify(updateData));
    data.append("Clientid",Clientid);
    data.append("EnteredBy",EnteredBy);



    // console.log("data", data);
    await axios
    .post("/api/currentchildren/upload-cc-ad/", data, {
      // receive two    parameter endpoint url ,form data
    })
    .then((res) => {
      // then print response status
      alert("Record created successfully !");
      setIsLoading(false);
      window.location.reload(false);
    });

    // await post(
    //   "/api/currentchildren/update-currentchildren-document-addition",
    //   { username, updateData }
    // );

    // alert("Uploaded Successfully !");

    setIsLoading(false);
    // window.location.reload(false)
  };

  const [updateData, setUpdateData] = useState([]);
  const submitUpdate = async () => {
    // setIsLoading(true)
    let username = sessionStorage.getItem("username");
    setShowAddDiv(false);
    setShowUpdateDiv(false);
    setUpdateData([]);
    await post("/api/currentchildren/update-currentchildren-document-update", {
      username,
      updateData,
    });
    alert("Request Update  Emailed Successfully !");
    setIsLoading(false);
    // window.location.reload(false)
  };
  console.log(editData);

  const download_file = async(fileName) => {
    let data = await post("/api/attachdocument/downloadAttachment", {
      fileName,
    });

    // using Java Script method to get PDF file
    fetch(data.data.data).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = fileName;
        alink.click();
        var downloadedPath=data.data.data;
     var downloadedFile=downloadedPath.replace(/^.*(\\|\/|\:)/, '');
     setTimeout(async function() { 
       let data = await post("/api/attachdocument/infoPage", {
        downloadedFile,
    });
   }, 5000);
      });
    });
  };

  const openInNewWindow = async(fileName) => {
    let tokenData = await post("/api/authentication/generateToken", {
      userId: localStorage.getItem("userId"), userName: localStorage.getItem("username")
    });
    let data = await post("/api/attachdocument/downloadAttachment", {
      fileName,
    });
    setImgName(data.data.data+"?token="+tokenData.data.token);
    setTimeout(() => {
      toggleModal();
    //  window.open(data.data.data+"?token="+tokenData.data.token, "popup", "width=800,height=600");
     var downloadedPath=data.data.data;
     var downloadedFile=downloadedPath.replace(/^.*(\\|\/|\:)/, '');
     setTimeout(async function() { 
       let data = await post("/api/attachdocument/infoPage", {
        downloadedFile,
    });
   }, 5000);
      });
    // let url =
    //   DownloadURL.attachDocumentURL +
    //   fileName +
    //   DownloadURL.attachDocumentTokenString;

    // window.open(url, "popup", "width=800,height=600");

  };

  useEffect(() => {
    fetchData();
    getAttachDocuments();
    getDropdownList();
  }, []);

  const columns = [
    {
      name: "Document type",
      selector: row => row.Document_Type,
      sortable: true,
    },
    {
      name: "Document Attached",
      selector: row => row.Download,
      cell: (row, j) => (row.Download != "" ? "Yes" : "No"),
      sortable: true,
    },
    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div class="inline-flex three-buttons">
          <Link
            to="#"
            onClick={() => openInNewWindow(row.Download)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={search}></img>
          </Link>
          &nbsp;
          <Link
            to="#"
            onClick={() => setEditData(j)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={mail}></img>
          </Link>
          &nbsp;
          <Link
            to="#"
            onClick={() => download_file(row.Download)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={download}></img>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div className="main">
      <Header />
      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <CurrentChildrenSideBar />
              <div className="tab-detail pl">
                <div className="tab-detail-top childprofile-tabs">
                  <div className="children-section">
                    <img src={ccinner} alt="" />
                    <div className="pagesubtitle">Attach Document</div>
                  </div>
                  {
                    <Link to="#" onClick={showAddDiv} className="btn-big"><img src={plus} style={{"height": "18px"}} alt="#"/>
                      Request Addition
                    </Link>
                  }
                </div>
                <div>
                  {/* <button onClick={toggleModal}>Open modal</button> */}

<Modal
  isOpen={isOpen}
  onRequestClose={toggleModal}
  contentLabel="My dialog"
  className="mymodal"
  overlayClassName="myoverlay"
  closeTimeoutMS={500}
> 
<div class="btn-bg">
<button onClick={toggleModal} class="close-button topright">&times;</button>
</div>

  <div> <iframe  src={imgName}   class="iframeWidht"/>         
     </div>
 
</Modal>
                  </div>
                <div className="children-tab">
                  {/* <span className="active">Easton Elliott</span> | <span>Easton Easton</span> | <span>Anna Litchfield</span> */}
                  {childrenData.map((child) => {
                    return (
                      <span
                        className={activeId === child.ClientID ? "active" : ""}
                        onClick={() => handleCurrentChildGlobal(child.ClientID)}
                      >
                        {child.ClientName}
                      </span>
                    );
                  })}
                </div>

                {isLoading ? <LoadSpinner /> : ""}

                {updateDiv ? (
                  <div className="req-update-textarea">
                    <label>
                      <span>
                        <b>Request Update for - </b> Attached Documents |
                        <b>
                          {" "}
                          Child: {sessionStorage.getItem(
                            "current_child"
                          )} |{" "}
                        </b>
                        Document Type:{" "}
                        {selectedDataForEdit.Document_Type
                          ? selectedDataForEdit.Document_Type
                          : ""}{" "}
                        | File Size:{" "}
                        {selectedDataForEdit.File_Size
                          ? selectedDataForEdit.File_Size
                          : ""}{" "}
                        | File Name:{" "}
                        {selectedDataForEdit.Download
                          ? selectedDataForEdit.Download
                          : ""}{" "}
                        <br></br>
                        <br></br>
                        <b>Description For Update:</b>
                        {/* Date:{LicenseExceptionDataForEdit.Date ? LicenseExceptionDataForEdit.Date : 'Null'}   |
                                                               Reason: {LicenseExceptionDataForEdit.Reason ? LicenseExceptionDataForEdit.Reason : 'Null'} |
                                                               Child Name: {LicenseExceptionDataForEdit.Child_Name ? LicenseExceptionDataForEdit.Child_Name : 'Null'} */}
                      </span>
                    </label>

                    <textarea
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          description: e.target.value,
                        })
                      }
                    ></textarea>
                    <div className="req-update-action">
                      <Link to="#" onClick={hideUpdateDiv} className="close">
                        close
                      </Link>
                      <Link to="#" onClick={submitUpdate} className="normal">
                        Submit
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {AddDiv ? (
                  <div className="req-update-textarea">
                    <label>
                      <span>
                        <b>Request Addition for</b>- Attached Documents
                        <b>
                          {" "}
                          Child: {sessionStorage.getItem("current_child")}{" "}
                        </b>
                        <br></br>
                        <br></br>
                        <b> Description for Additon:</b>
                      </span>
                    </label>
                    <textarea
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          Description: e.target.value,
                        })
                      }
                    ></textarea>
                    <div className="form-grey">
                      <div className="form-row">
                        <div class="ot-field">
                          <label class="form-label">Select Incident:</label>
                          <select
                            onChange={(e) =>
                              setUpdateData({
                                ...updateData,
                                ScanDocumentTypeID: e.target.value,
                              })
                            }
                          >
                            <option>Select</option>
                            {incidentDropDownList.map((d) => (
                              <option value={d.ScanDocumentTypeID}>
                                {d.DocumentType}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div class="ot-field">
                          <label class="form-label">Select Picture:</label>
                          <input type="file" onChange={(e) =>
                              setUpdateData({
                                ...updateData,
                                FileName: e.target.files[0],
                              })
                            }></input>
                        </div>
                      </div>
                      {/* <div className="req-update-action">
                        <Link to="#" className="close">
                          close
                        </Link>
                        <Link to="#" className="normal">
                          Submit
                        </Link>
                      </div> */}
                    </div>
                    <div className="req-update-action">
                      <Link to="#" onClick={hideAddDiv} className="close">
                        close
                      </Link>
                      <Link to="#" onClick={onSubmitAdditionClick} className="normal">
                        Submit
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="tab-detail-bottom children-attachment">
                  <form className="children-attach-form">
                    {/* <table>
                                            <tr>
                                                <th calssName="doc-type">Document type</th>

                                                <th className="doc-attach">Document Attached</th>
                                                <th className="search-icon">View</th>
                                                <th className="download-icon">Download</th>
                                                <th className="edit-icon">Action</th>
                                            </tr>
                                            <tr>
                                                <td>Medical Card</td>
                                                <td className="doc-attach">YES</td>
                                                <td className="search-icon"><Link to="#"><img src={search} alt="" /></Link></td>
                                                <td className="download-icon"><Link to="#"><img src={download} alt="" /></Link></td>
                                                <td className="edit-icon"><Link to="#"><img src={edit} alt="" /></Link></td>
                                            </tr>
                                            <tr>
                                                <td>Medical Consent</td>
                                                <td className="doc-attach">YES</td>
                                                <td className="search-icon"><Link to="#"><img src={search} alt="" /></Link></td>
                                                <td className="download-icon"><Link to="#"><img src={download} alt="" /></Link></td>
                                                <td className="edit-icon"><Link to="#"><img src={edit} alt="" /></Link></td>
                                            </tr>
                                            <tr>
                                                <td>Release of Confidential Information</td>
                                                <td className="doc-attach">YES</td>
                                                <td className="search-icon"><Link to="#"><img src={search} alt="" /></Link></td>
                                                <td className="download-icon"><Link to="#"><img src={download} alt="" /></Link></td>
                                                <td className="edit-icon"><Link to="#"><img src={edit} alt="" /></Link></td>
                                            </tr>
                                            <tr>
                                                <td>Child Picture</td>
                                                <td className="doc-attach">YES</td>
                                                <td className="search-icon"><Link to="#"><img src={search} alt="" /></Link></td>
                                                <td className="download-icon"><Link to="#"><img src={download} alt="" /></Link></td>
                                                <td className="edit-icon"><Link to="#"><img src={edit} alt="" /></Link></td>
                                            </tr>
                                            <tr>
                                                <td>SSN Card</td>
                                                <td className="doc-attach">YES</td>
                                                <td className="search-icon"><Link to="#"><img src={search} alt="" /></Link></td>
                                                <td className="download-icon"><Link to="#"><img src={download} alt="" /></Link></td>
                                                <td className="edit-icon"><Link to="#"><img src={edit} alt="" /></Link></td>
                                            </tr>

                                            <tr>
                                                <td>Birth Certificate</td>
                                                <td className="doc-attach">YES</td>
                                                <td className="search-icon"><Link to="#"><img src={search} alt="" /></Link></td>
                                                <td className="download-icon"><Link to="#"><img src={download} alt="" /></Link></td>
                                                <td className="edit-icon"><Link to="#"><img src={edit} alt="" /></Link></td>
                                            </tr>

                                            <tr>
                                                <td>Private Insurance</td>
                                                <td className="doc-attach">YES</td>
                                                <td className="search-icon"><Link to="#"><img src={search} alt="" /></Link></td>
                                                <td className="download-icon"><Link to="#"><img src={download} alt="" /></Link></td>
                                                <td className="edit-icon"><Link to="#"><img src={edit} alt="" /></Link></td>
                                            </tr>

                                            <tr>
                                                <td>PPS 5110-Initial Referral to out of home placement</td>
                                                <td className="doc-attach">YES</td>
                                                <td className="search-icon"><Link to="#"><img src={search} alt="" /></Link></td>
                                                <td className="download-icon"><Link to="#"><img src={download} alt="" /></Link></td>
                                                <td className="edit-icon"><Link to="#"><img src={edit} alt="" /></Link></td>
                                            </tr>

                                            <tr>
                                                <td>PPS 5110-Reinstatement</td>
                                                <td className="doc-attach">YES</td>
                                                <td className="search-icon"><Link to="#"><img src={search} alt="" /></Link></td>
                                                <td className="download-icon"><Link to="#"><img src={download} alt="" /></Link></td>
                                                <td className="edit-icon"><Link to="#"><img src={edit} alt="" /></Link></td>
                                            </tr>

                                            <tr>
                                                <td>DCF Referral</td>
                                                <td className="doc-attach">YES</td>
                                                <td className="search-icon"><Link to="#"><img src={search} alt="" /></Link></td>
                                                <td className="download-icon"><Link to="#"><img src={download} alt="" /></Link></td>
                                                <td className="edit-icon"><Link to="#"><img src={edit} alt="" /></Link></td>
                                            </tr>
                                        </table> */}
                    <DataTable
                      columns={columns}
                      data={attachDocument}
                      pagination={true}
                    />

                    {/* <div className="form-grey">
                      <div className="form-row">
                        <div class="ot-field">
                          <label class="form-label">Select Incident:</label>
                          <select></select>
                        </div>
                        <div class="ot-field">
                          <label class="form-label">Select Picture:</label>
                          <input type="file"></input>
                        </div>
                      </div>
                      <div className="req-update-action">
                        <Link to="#" className="close">
                          close
                        </Link>
                        <Link to="#" className="normal">
                          Submit
                        </Link>
                      </div>
                    </div> */}

                    {/* <div className="form-row">
                      <div class="ot-field half-input">
                        <label class="form-label">Select Incident:</label>
                        <select></select>
                      </div>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AttachDocuments;
