import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
const exportPDFByRow = (title,headers,data) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

     title = "Attach Documents";
     headers = [["Document Type", "Date of Upload", "Description"]];

     data = [['PDF', '23/04/2021','Description for the row']]

    let content = {
        startY: 50,
        head: headers,
        body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content)
    doc.save("attach-documents.pdf")
}
export default exportPDFByRow;