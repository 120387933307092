import Footer from "../../Footer";
import Header from "../../Header";
import placementagree from "../../../images/assets/myinfo/placementagree.svg";
import download from "../../../images/assets/myinfo/download.svg";
import edit from "../../../images/assets/myinfo/edit.svg";
import print from "../../../images/assets/myinfo/print.svg";
import search from "../../../images/assets/myinfo/search.svg";

// import acmanage from '../../images/assets/images/ManageInfo.svg';
import SideBar from "../../sidebar/sidebar";
import "../PlacementAgreement/PlacementAgreement.scss";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { post } from "../../../utils/common";
import DataTable from "react-data-table-component";
import mail from "../../../images/mail.png";
import LoadSpinner from "../../Loader/LoadSpinner";
import { DownloadURL } from "../../common/commonURL";

function PlacementAgreementElectronicSign() {
  const [ProviderMember, setProviderMember] = useState([]);
  const fetchProviderMemberData = async () => {
    // let data = await fetch('/api/unusualincident/index');
    const id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/placementagreement/getprovidermember", { id });
    console.log(data);
    setProviderMember(data.data.data);
  };

  const [SFCSAdmission, SetSFCSAdmission] = useState([]);

  const getSFCSAdmission = async () => {

    let clientid = parseInt(sessionStorage.getItem("CLIENT_ID_FOR_PA"));
    let providerid = parseInt(sessionStorage.getItem("providerSessionId"));
    let ElecSignPlacementAgreementIDFORPA = parseInt(sessionStorage.getItem(
      "ElecSignPlacementAgreementIDFORPA"
    ));
    let data = await post("/api/placementagreement/get-xml-data", {
      clientid,
      providerid,
      ElecSignPlacementAgreementIDFORPA,
    });

    SetSFCSAdmission(data.data.data[0]);
   
  };

  const [dataSet, setDataSet] = useState([]);

  const [sfcsWorker, setsfcsWorker] = useState([]);

  const fetchSFCSWorker = async () => {
    // const providerid = sessionStorage.getItem('providerSessionId');
    const provider_id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/sfcsworker/index", { provider_id });
    // if (!data.ok) {
    //     return;
    // }
    // data = await data.json();
    console.log("dcsd", data);
    setsfcsWorker(data.data.data[0]);
  };

 

  const AcceptPlacementAgreement = async () => {
    let staffComDate = new Date();
    let Accept_Reject = (staffComDate.getMonth()+1)+"-"+staffComDate.getDate()+"-"+staffComDate.getFullYear() +" - " + dataSet.Accept_Reject ;
    let sfcsMailId = sfcsWorker.EmailSend;
    let sffcsWorker = sfcsWorker.Resource_Worker;

  let FCH_StaffID = SFCSAdmission.CC_StaffID;
  let FCH_StaffName = SFCSAdmission.CC_StaffName;
  let FCH_DateAcknowledged = new Date();
  let ChangedBy = sessionStorage.getItem("username");
  let ChangedDate = new Date();
  let notes = dataSet.notes;
  let CLIENT_NAME_FOR_PA = sessionStorage.getItem("CLIENT_NAME_FOR_PA");
  let ProviderEmail = JSON.parse(sessionStorage.getItem("ProviderData")).Email;
  
   let ElecSignPlacementAgreementID = sessionStorage.getItem(
      "ElecSignPlacementAgreementIDFORPA"
    );
    
    let data = await post("/api/placementagreement/accept-ack", {
    
     ElecSignPlacementAgreementID,
     sfcsMailId,
     sffcsWorker,
     FCH_StaffID,
     FCH_StaffName,
     FCH_DateAcknowledged,
     ChangedBy,
     ChangedDate,
     notes,
     Accept_Reject,
     CLIENT_NAME_FOR_PA,
     ProviderEmail


   
     
    });
    alert("Acknowledged Successfully");
    window.location.href = "/placementagreement";
  };
  // const RejectPlacementAgreement = async () => {
  //   let sfcsMailId = sfcsWorker.EmailSend;
  //   let providerName = sessionStorage.getItem("ProviderName");
  //   let providerId = sessionStorage.getItem("providerSessionId");
  //   let CLIENT_NAME_FOR_PA = sessionStorage.getItem("CLIENT_NAME_FOR_PA");
  //   let ProviderMemberNameRow = ProviderMember.filter(
  //     (Pmember) => Pmember.ProviderMemberID == dataSet.ProviderMemberID
  //   );
  //   let ProviderMemberName = ProviderMemberNameRow[0].ProviderMemberName;
  //   console.log("ProviderMemberName", ProviderMemberNameRow);
  //   let ProviderMemberID = dataSet.ProviderMemberID;
  //   let Provider_Comment = dataSet.Provider_Comment;
  //   let Provider_IsApproved = 1;
  //   let ElecSignPlacementAgreementID = sessionStorage.getItem(
  //     "ElecSignPlacementAgreementIDFORPA"
  //   );
  //   let data = await post("/api/placementagreement/avoid-pa", {
  //     ProviderMemberName,
  //     ProviderMemberID,
  //     Provider_Comment,
  //     Provider_IsApproved,
  //     ElecSignPlacementAgreementID,
  //     providerName,
  //     providerId,
  //     CLIENT_NAME_FOR_PA,
  //     sfcsMailId
  //   });
  //   alert("Rejected!!");
  //   window.location.href = "/placementagreement";
  // };
  useEffect(() => {
    fetchProviderMemberData();
    fetchSFCSWorker();
    getSFCSAdmission();
  }, []);

  console.log("dataSet", dataSet);

  return (
    <div className="main">
      <Header />

      <div
        style={{
          display: "flex",
          "align-items": "center",
          "justify-content": "center",
          "margin-left": "10px",
          "padding-left": "10px",
        }}
      >
        <iframe
          marginwidth="100"
          marginheight="10"
          hspace="10"
          vspace="5"
          frameborder="1px"
          scrolling="yes"
          src="https://sfmfamilies.org/placement-agreement-pdf"
          //src="http://localhost:3000/placement-agreement-pdf"
          height="600"
          width="800"
        ></iframe>
      </div>
      <div
        style={{
        
          "align-items": "center",
          "justify-content": "center",
        }}
      >
        <form className="child-case-form" style={{"margin-left":"27%"}}>
          <div className="form-col">
          <div className="g-background">
         
        <div class="ot-field">
          <label class="form-label">SFM Staff name:</label>
          {/* <select
            onChange={(e) =>
              setDataSet({
                ...dataSet,
                Resource_Worker: e.target.value,
              })
            }
          >
            <option>Select</option>
            {sfcsWorker.map((pmember) => (
              <option value={pmember.Resource_Worker}>
                {pmember.Resource_Worker}
              </option>
            ))}
          </select> */}
            <input
                        type="text" value={sfcsWorker.Resource_Worker}> 
                           
                        </input>
        </div>
        <div class="ot-field">
          
          <div class="col-md-6">
            <label for="appt">SFM Admissions:</label>
            <input
                        type="text" value={SFCSAdmission.CC_StaffName}> 
                           
                        </input>
            </div>
        </div>
      </div>
      </div>
           
          



          
 </form>
        <Link to="#" className="normal" onClick={AcceptPlacementAgreement} style={{"margin-left":"33.33%"}}>
          Acknowledge
        </Link>
        {"    "}
        {/* <Link to="#" className="close" onClick={RejectPlacementAgreement}>
          Reject
        </Link> */}
        {"    "}
        <Link to="../placementagreement" className="close1" style={{"margin-right":".33%"}}>
          Close
        </Link>
      </div>
      {/* <div >
      <textarea placeholder="Please mention, Accept/Reject Reasons!" cols="30" rows="1" style={{ "width":" 50%","border": "1px solid #888","display":"flex"}} ></textarea>
      </div> */}
        <div className="form-col" style={{"margin-left":"25.3%"}}>
            <div class="ot-field">
              <label class="form-label">Notes:</label>

              <textarea
                onChange={(e) =>
                  setDataSet({
                    ...dataSet,
                    Accept_Reject: e.target.value,
                  })
                }
              ></textarea>
            </div>
          </div>
    

      <Footer />
    </div>
  );
}
//---Person Electronically Signing the Form has to before cancel button
// <div className="form-row dandt">
// <div className="one-third">
//   <label for="birthday">
//     Person Electronically Signing the Form:
//   </label>
//   <select  onChange={(e) =>
//     setAddFormData({
//       ...AddFormData,
//       ProviderMemberID: e.target.checked,
//     })
//   }>
//     <option value="0">Select</option>
//     {personSigningTheForm.map((signperson) => (
//       <option value={signperson.ProviderMemberID}>
//         {signperson.ProviderName}
//       </option>
//     ))}
//   </select>
// </div>

// <div className="one-third">
//   <ul className="immediate-assessment">
//     <li>Provide original to foster care worker.</li>
//     <li>Copy to Resource Family.</li>
//     <li>Copy to oversight agency for the child.</li>
//   </ul>
// </div>
// </div>
// <div className="form-col">
//             <div class="ot-field">
//               <label class="form-label">Comment</label>

//               <textarea
//                 onChange={(e) =>
//                   setDataSet({
//                     ...dataSet,
//                     Provider_Comment: e.target.value,
//                   })
//                 }
//               ></textarea>
//             </div>
//           </div>

export default PlacementAgreementElectronicSign;
