
import logoplacement from "../../../images/logo-placement.jpg";

import dompurify from 'dompurify';

// import acmanage from '../../images/assets/images/ManageInfo.svg';

import "../PlacementAgreement/PlacementAgreement.scss";

import { useState, useEffect, useRef } from "react";

import { post } from "../../../utils/common";




function PlacementAgreementPD() {
  
  const [data, setData] = useState([]);
 



  const fetchDataPrint = async () => {

    let clientid = parseInt(sessionStorage.getItem("CLIENT_ID_FOR_PA"));
    let providerid = parseInt(sessionStorage.getItem("providerSessionId"));
    let pastatus = (sessionStorage.getItem("PAStatus"));
    let ElecSignPlacementAgreementIDFORPA = parseInt(sessionStorage.getItem(
      "ElecSignPlacementAgreementIDFORPA"
    ));
    let data = await post("/api/placementagreement/get-xml-data", {
      clientid,
      providerid,
      ElecSignPlacementAgreementIDFORPA,
      
    });

    data.data.data[0].Comment = "";

    if (data.data.data[0].CC_ElecSignDate != null){data.data.data[0].CC_ElecSignDate = data.data.data[0].CC_ElecSignDate.split("T")[0]}
    if(data.data.data[0].Provider_ElecSignDate != null){data.data.data[0].Provider_ElecSignDate = data.data.data[0].Provider_ElecSignDate.split("T")[0]}
    data.data.data[0].PAStatus = pastatus;
    if(data.data.data[0].Provider_Comment !== "undefined" && data.data.data[0].Provider_Comment !== null){
      data.data.data[0].Comment =  data.data.data[0].ProviderMemberName + " - " +  data.data.data[0].Provider_Comment;

    }

    if(data.data.data[0].Accept_Reject !== "undefined" && data.data.data[0].Accept_Reject !== null){
      data.data.data[0].Comment = data.data.data[0].Comment + "\n" + data.data.data[0].CC_StaffName + " - " +  data.data.data[0].Accept_Reject ;

    }
    setData(data.data.data[0]);
  

        // let printContents =   document.getElementById("divToPrint").innerHTML;
        // //let originalContents =  document.body.innerHTML;
        // document.body.innerHTML =   printContents;
        // window.print();
        // //document.body.innerHTML =   originalContents;
       
       
         const printContents = document.getElementById('divToPrint').innerHTML;
         document.body.innerHTML = printContents;
         window.print();


      window.onafterprint = function() { window.location.href = "/placementagreement";

  };
   
  };

  
  const title = data.AgreementText;
  const sanitizer = dompurify.sanitize;
  

  useEffect(() => {
    fetchDataPrint()
   // getEsignData();
  
  }, []);
  return (
    <div id="divToPrint" >
    <div className="main">

      <div
        style={{
          display: "flex",
          "align-items": "center",
          "justify-content": "center",
          "margin-left": "10px",
          "padding-left": "10px",
        }}
      >
     <div
      class="container"
      style={{ "margin-left": "30px", overflow: "word-break" }}
    >
      <div>
        <div
          style={{
            "text-align": "right",
            "marging-top": "0px",
            position: "relative",
            "margin-left": "300px",
          }}
        >
          <img className="footer-logoo" src={logoplacement} alt="" />
        </div>
        <h4
          className="title"
          style={{
            "text-align": "left",
            "font-size": "1.45em",
            "font-weight": "bold",
            "margin-top": "0px",
            "margin-left": "10px",
            "borderBlock": "1px",
          }}
        >
          Saint Francis Ministries, Inc <br></br>
          Placement Agreement
        </h4>
      </div>

      {/* <h4
        className="title"
        style={{
          "text-align": "left",
          "font-size": "1.2em",
          "font-weight": "bold",
          "margin-top": "50px",
          "margin-left": "10px",
        }}
      >
        Placement Agreement{" "}
      </h4> */}

      

      <span dangerouslySetInnerHTML={{ __html: sanitizer(title)}} />
 
      <br></br>
     
    </div>
      </div>
      
        <form className="child-case-form">
          
          <div class="col-md-12"  style={{"display":"flex","margin-left": "90px"}}>
            <div class="col-md-6">
            <label for="appt">Placement Provider:</label>
            <input
                        type="text" value={data.ProviderMemberName}> 
                           
                        </input>
            </div>
            <div class="col-md-6" style={{"margin-left": "190px"}}>
            
                        <label for="appt">Date:</label>
            <input
                        type="text" value={data.Provider_ElecSignDate}> 
                           
                        </input>
            </div>
          </div>
         
         
        </form>
        <form className="child-case-form">
         
          <div class="col-md-12"  style={{"display":"flex","margin-left": "90px","margin-top": "20px","margin-bottom": "40px"}}>
            <div class="col-md-6">
            <label for="appt">SFM Admissions:</label>
            <input
                        type="text" value={data.CC_StaffName}> 
                           
                        </input>
            </div>
            <div class="col-md-6" style={{"margin-left": "190px"}}>
            <label for="appt">Date:</label>
            <input
                        type="text" value={data.CC_ElecSignDate}> 
                           
                        </input>
            </div>

          </div>
          
          {/* value={data.Accept_Reject} */}
        </form>

        <form className="child-case-form">

        <div class="col-md-6" style={{"margin-left": "90px"}}>
           <label class="form-label">Status:</label>
              <div style={{ "width":" 150%"}}>
      <input type="text" value={data.PAStatus}  style={{ "width":"100%","display":"flex"}} ></input>
      </div> 
           </div>
         
         <div class="col-md-12"  style={{"display":"flex","margin-top": "70px","margin-bottom": "40px", "margin-left": "-190px"}}>

         
           <div class="col-md-6">
           <label class="form-label">Notes:</label> 
              <div style={{ "width":" 300%"}}>
      <textarea value={data.Comment} cols="30" rows="1" style={{ "border": "1px solid #888","display":"flex"}} ></textarea>
      </div> 
           </div>
      
         </div>
         
        
       </form>
     
    </div>
    </div>
  );
  
    }
 
export default PlacementAgreementPD;
