import Footer from '../../Footer';
import Header from '../../../frontendPages/Header';
import '../../PreviousChildren/PreviousChildrenDetail/PreviousChildrenDetail.scss'
import PreviousChildrenSidebar from '../PreviousChildrenSidebar/PreviousChildrenSidebar';
import HeaderTopDetail from '../../CurrentChildren/HeaderTopDetail/HeaderTopDetail';
import childreninner  from '../../../images/assets/currentChildren/childreninner.svg';



function PreviousChildrenDetail() {
    return (

        <div className="main">
        <Header />
        <div className="main-content" >
            <HeaderTopDetail />
            <div className="pagewithsidebar">
                <div className="container">
                    <div className="pagewithsidebar-inner">
                        <PreviousChildrenSidebar />
                        <div className="tab-detail">
                            <div className="tab-detail-top children-tabs">
                                <div className="children-section">
                                    <img src={childreninner} alt="" />
                                    <div className="pagesubtitle">
                                        Children
                                    </div>
                                </div>
                                <div className="children-tab">
                                    <span className="active">Easton Elliott</span> | <span>Easton Easton</span> | <span>Anna Litchfield</span>
                                </div>
                            </div>
                            <div className="tab-detail-bottom children-data">
                                <form className="children-doc-form">
                                    
                                    <div className="req-update-textarea">
                                        <label><span><b>Request update for</b> Chidren (Description for update)</span></label>
                                        <textarea></textarea>
                                        <div className="req-update-action">
                                            <a href="" className="close">close</a>
                                            <a href="" className="normal">Submit</a>
                                        </div>
                                    </div>
                                    <table>
                                        <tr>
                                            <th>Name</th>
                                            <th>Gender</th>
                                            <th>Age</th>
                                            <th>DOB</th>
                                            <th>Race</th>
                                            <th>Case Manager</th>
                                            <th>Office Phone Number</th>
                                            <th>Office Addressv</th>
                                            <th>MCO Provider</th>
                                        </tr>
                                        <tr>
                                            <td>Elliott, Easton</td>
                                            <td>Male</td>
                                            <td>1</td>
                                            <td>02/10/2020</td>
                                            <td>Caucasian</td>
                                            <td>Niemeyer, Megan</td>
                                            <td>(316)284-2477</td>
                                            <td>423 SE 10th Street, Newton, KS. 67114</td>
                                            <td>United HealthCare</td>
                                        </tr>
                                    </table>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
    );
}




export default PreviousChildrenDetail;
