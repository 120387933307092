import Footer from "../../Footer";
import Header from "../../Header";
import "../CriticalUnusualIncidentForm/CriticalUnusualIncidentForm.scss";
import "../ImmidiateAssesmentForm/ImmidiateAssesmentForm.scss";
import immediateinner from "../../../images/assets/formimages/immediateinner.svg";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import FormSideBar from "../FormSidebar/FormSidebar";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { post } from "../../../utils/common";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useRef } from "react";
import axios from 'axios';


function ImmidiateAssesmentForm() {
  const inputRef = useRef(null);
  const history = useHistory();
  const [AddFormData, setAddFormData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sfcsWorker, setsfcsWorker] = useState([]);


  const [selectChildData, setSelectChildData] = useState([]);
  const [GeneraBodylLocation, setGeneralBodyLocation] = useState([]);
 // const [personSigningTheForm, setSigningTheForm] = useState([]);

  const [safetyAssessMentTool, setsafetyAssessMentTool] = useState([]);
  const [questionToBeAskedOne, setQuestionToBeAskedOne] = useState([]);
  const [questionToBeAskedTwo, setQuestionToBeAskedTwo] = useState([]);
  const [questionToBeAskedThree, setQuestionToBeAskedThree] = useState([]);
  const [childSelectedForData, setChildSelectedForData] = useState([]);
  const [FormdatabyIncidentIdLoaded, setFormDatabyIncidentIdLoaded] = useState(
    []
  );
  const [personReportingData, setPersonReportingData] = useState([]);
  

  const [
    FormdatabyIncidentIdLoadedStatus,
    setFormDatabyIncidentIdLoadedStatus,
  ] = useState(false);
 // const [AddFormData, setChildSelectedData] = useState([]);

 const fetchSFCSWorker = async () => {
  // const providerid = sessionStorage.getItem('providerSessionId');
  const provider_id = sessionStorage.getItem("providerSessionId");
  let data = await post("/api/sfcsworker/index", { provider_id });
  // if (!data.ok) {
  //     return;
  // }
  // data = await data.json();
  console.log("dcsd", data);
  setsfcsWorker(data.data.data[0]);
 
};

  const fetchFormDataLoad = async () => {
    let providerid = sessionStorage.getItem("providerSessionId");
    FormdatabyIncidentIdLoaded.NameOfFosterParent = sessionStorage.getItem("ProviderName");
    let data = await post("/api/immidiateassesment/getformdataload", {
      providerid,
    });

    setSelectChildData(data.data.data.recordsets[0]);
    setGeneralBodyLocation(data.data.data.recordsets[6]);
    //setSigningTheForm(data.data.data.recordsets[1]);
    setPersonReportingData(data.data.data.recordsets[1]);
    setsafetyAssessMentTool(data.data.data.recordsets[2]);
    setQuestionToBeAskedOne(data.data.data.recordsets[3]);
    setQuestionToBeAskedTwo(data.data.data.recordsets[4]);
    setQuestionToBeAskedThree(data.data.data.recordsets[5]);
  };

  const FetchFormDataLoadById = () => {};

  function toNumber(value) {
    return Number(value);
  }

  const selectedImmidiateAssesmentList = []

  const onSubmitButtonClick = async (str) => {
    if(AddFormData.ClientID == undefined || AddFormData.DOB == undefined || AddFormData.PlacementTime == undefined ||AddFormData.ProviderMemberID == undefined ||
     AddFormData.date_of_placement == undefined){
        alert("Please enter the mandatory fields");
        return;
      }

      
    if(str === 'submit'){
      AddFormData.IsFinalized = 1;
    }
    let list = userinfoHistory.vehicle2;
    let nums = list.map(toNumber);
     let  HistoryTypeID = nums;

     let list1 = userinfoTrigger.vehicle2;
     let nums1 = list1.map(toNumber);
      let  TriggerTypeID  = nums1;

      let list2 = userinfoIndicator.vehicle2;
      let nums2 = list2.map(toNumber);
       let  IndicatorTypeID  = nums2;

       let list3 = userinfoCalming.vehicle2;
       let nums3 = list3.map(toNumber);
        let  CalmingTypeID  = nums3;

    // console.log("selectedUnusualIncidentList", selectedUnusualIncidentList);

  //  console.log("FormData", FormdatabyIncidentIdLoaded);
    //e.preventDefault();
    let provider_ID = sessionStorage.getItem("providerSessionId");
    let providerid = parseInt(provider_ID);
    AddFormData.SFCSFamiliesBodyPartDetailLocationID = parseInt(AddFormData.SFCSFamiliesBodyPartDetailLocationID);
    AddFormData.ProviderMemberID = parseInt(AddFormData.ProviderMemberID);
    let changedBy = sessionStorage.getItem("username");
    let ResourceWorkermailID = sfcsWorker.EmailSend;

    let ActualProviderName =sessionStorage.getItem("ProviderName");
   // e.preventDefault();
    //here goes the api

    html2canvas(inputRef.current).then(async (canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      var doc = new jsPDF("p", "mm", "a4");
      var pageHeight = 295;
      var imgWidth = (canvas.width * 31) / 210;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var position = 31;

      doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      setIsLoading(true)

      const out = doc.output("datauristring");
      const response = await axios.post("/api/immidiateassesment/ImmediateAssesmentmail",
        {
          pdf: out.split("base64,")[1],
          AddFormData,
          ActualProviderName,
          ResourceWorkermailID
        }
      );
      alert("Email Sent");

      window.location.reload(false);
    });
    let data = await post("/api/immidiateassesment/saveform", {
      AddFormData,HistoryTypeID,TriggerTypeID,IndicatorTypeID,CalmingTypeID,providerid,changedBy,ActualProviderName
    });

    setFormDatabyIncidentIdLoadedStatus(true);

    console.log(AddFormData);
     alert("Record Added successfully !");
     let fromButtonClick= localStorage.getItem("fromButtonClick");
    if(fromButtonClick === "true")
    window.location.href ="/immidiateassesment";
    //window.location.reload(false);
    history.push("/form/formindex");
  };

  const [userinfoHistory, setUserInfoHistory] = useState({
    vehicle2: [],
    response: [],
  });
   //
  const handleChangeHistory = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { vehicle2 } = userinfoHistory;

    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setUserInfoHistory({
        vehicle2: [...vehicle2, value],
        response: [...vehicle2, value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setUserInfoHistory({
        vehicle2: vehicle2.filter((e) => e !== value),
        response: vehicle2.filter((e) => e !== value),
      });
    }

    console.log("userinfo.response", userinfoHistory.response);
  };

  const [userinfoTrigger, setUserInfoTrigger] = useState({
    vehicle2: [],
    response: [],
  });
   //
  const handleChangeTrigger = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { vehicle2 } = userinfoTrigger;

    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setUserInfoTrigger({
        vehicle2: [...vehicle2, value],
        response: [...vehicle2, value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setUserInfoTrigger({
        vehicle2: vehicle2.filter((e) => e !== value),
        response: vehicle2.filter((e) => e !== value),
      });
    }

    console.log("userinfo.response", userinfoTrigger.response);
  };

  const [userinfoIndicator, setUserInfoIndicator] = useState({
    vehicle2: [],
    response: [],
  });
   //
  const handleChangeIndicator = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { vehicle2 } = userinfoIndicator;

    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setUserInfoIndicator({
        vehicle2: [...vehicle2, value],
        response: [...vehicle2, value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setUserInfoIndicator({
        vehicle2: vehicle2.filter((e) => e !== value),
        response: vehicle2.filter((e) => e !== value),
      });
    }

    console.log("userinfo.response", userinfoIndicator.response);
  };

  const [userinfoCalming, setUserInfoCalming] = useState({
    vehicle2: [],
    response: [],
  });
   //
  const handleChangeCalming = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { vehicle2 } = userinfoCalming;

    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setUserInfoCalming({
        vehicle2: [...vehicle2, value],
        response: [...vehicle2, value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setUserInfoCalming({
        vehicle2: vehicle2.filter((e) => e !== value),
        response: vehicle2.filter((e) => e !== value),
      });
    }

    console.log("userinfo.response", userinfoCalming.response);
  };



  const onSubmitButtonClickSub = async (e) => {
    e.preventDefault();
    alert("Record submit successfully !");
    window.location.reload(false);
    let fromButtonClick= localStorage.getItem("fromButtonClick");
    if(fromButtonClick === "true")
    window.location.href ="/immidiateassesment";
    history.push("/form/formindex");

    
  };

  const closeClicked = () => {
    window.location.href ="/currentchildren/currentchildrenimmidiateassesment";
  };

  const [DetailBodyLocation, setDetailedBodyLocation] = useState([]);

  // const [MedicationinputList, setMedicationInputList] = useState([{ MedName: '', MedDose: '', MedFrequency: '', MedPurpose: '', MedCount: ''}]);

  // // // handle input change
  // const handleMedicationChange = (index, evnt) => {
  //   const { name, value } = evnt.target;
  //   const list = [...MedicationinputList];
  //   list[index][name] = value;
  //   setMedicationInputList(list);
  // };

  // // // handle click event of the Remove button
  // const handleRemoveClick = (index) => {
  //   const rows = [...MedicationinputList];
  //   rows.splice(index, 1);
  //   setMedicationInputList(rows);
  // };

  // // handle click event of the Add button
  // const handleAddClick = () => {  
  //   setMedicationInputList([...MedicationinputList, { MedName: "", MedDose: "", MedFrequency:"", MedPurpose:"", MedCount:"" }]);
  //   };
    

    // this.setMedicationInputList([
    //   ...MedicationinputList,
    //   { MedName: "", MedDose: "", MedFrequency:"", MedPurpose:"", MedCount:"" }
    // ]);


  // console.log(MedicationinputList);

  const clearEditForBodyLocations = () => {
    setDetailedBodyLocation([]);
  };

  const [genBodyLocationId, setGeneralBodyLocationId] = useState(false);

  const getAllDetailbodyLocation = async (e) => {
    setGeneralBodyLocationId(true);
    
    const id = e.target.value;
    AddFormData.SFCSFamiliesBodyPartGeneralLocationID = parseInt(id);
    console.log(id);
    let detailBodyLocation = await post("/api/immidiateassesment/detailbody", {
      id,
    });
    console.log("detailbody", detailBodyLocation);
    setDetailedBodyLocation(detailBodyLocation.data.DetailLocation);
  };
  // console.log(personSign/ingTheForm)

  

  const childSelectedData = (event) => {
    const childId = event.target.value;
    selectChildData.map((childData) => {
      if (childData.ClientID == childId) {
        setChildSelectedForData(childData);
        AddFormData.ClientID = parseInt(childId);
     let DOP = childData.date_of_placement.split("T")[0];
        AddFormData.DOB = childData.DOB;
        AddFormData.date_of_placement = DOP;
      }
    });
    // selectChildData.filter(child=>child.ClientID == childId)
  };

const [FormData,setFormData] = useState([])


  useEffect(() => {
    fetchSFCSWorker();
    fetchFormDataLoad();
    
  }, []);

  return (
    <div className="main">
      <Header />

      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <FormSideBar />
              <div className="tab-detail Critical" ref={inputRef}>
                <div className="tab-detail-top">
                  <div className="Critical-unusual">
                    <img src={immediateinner} alt="" />
                    Immediate Assessment
                  </div>
                </div>
                <div className="tab-detail-bottom">
                  <form>
                    <div className="form-row dandt">
                      <div className="one-fourth">
                        <label for="birthday">Child:<span style={{color:"red"}}>*</span></label>
                        <select selected onChange={childSelectedData}>
                          <option value="0">Select</option>

                          {selectChildData.map((child) => (
                            <option value={child.ClientID}>
                              {child.ClientName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="one-fourth">
                        <label for="appt">Date of Birth:<span style={{color:"red"}}>*</span></label>
                        <input   onChange={(e) =>
                          setAddFormData({
                              ...AddFormData,
                              dateofbirth: e.target.value,
                            })
                          } value={childSelectedForData.DOB?childSelectedForData.DOB.slice(0,10):''} type="date" id="appt" name="appt" />
                      </div>
                      <div className="one-fourth">
                        <label for="birthday">Date of Placement:<span style={{color:"red"}}>*</span></label>
                        <input   onChange={(e) =>
                          setAddFormData({
                              ...AddFormData,
                              date_of_placement: e.target.value,
                            })
                          } value={childSelectedForData.date_of_placement?childSelectedForData.date_of_placement.slice(0,10):''}  type="date" id="birthday" name="birthday" />
                      </div>   
                      <div className="one-fourth">
                        <label for="appt">Placement Time:<span style={{color:"red"}}>*</span></label>
                        <input type="time" id="appt" name="appt"  onChange={(e) =>
                          setAddFormData({
                            ...AddFormData,
                            PlacementTime: e.target.value,
                          })
                        }/>
                      </div>
                    </div>
                    <div className="form-row mt-0">
                      <div className="full-input">
                        <label for="appt">
                          1. List any medication allergies or special
                          conditions/limitations
                        </label>
                        <input type="text" onChange={(e) =>
                          setAddFormData({
                            ...AddFormData,
                            SpecialConditions: e.target.value,
                          })
                        } />
                      </div>
                    </div>
                    <div className="form-row mt-0">
                      <div className="full-input">
                        <label for="appt">
                          2. List any food allergies/special diets/eating or
                          nutritional abnormalities
                        </label>
                        <input type="text" onChange={(e) =>
                          setAddFormData({
                            ...AddFormData,
                            SpecialDiets: e.target.value,
                          })
                        } />
                      </div>
                    </div>

                    <div className="g-background">
                      <div className="form-row">
                        <label for="appt">
                          3. Description and location of tattoo's, cuts, bruises
                          or skin rashes:<span style={{color:"red"}}>*</span>
                        </label>
                        <small>
                          <i>
                            (This is not intended to indicate need to unclothe
                            child to conduct search. Ask child/youth if there
                            are any such marks that are not visible, indicate
                            where they are and write "reported by child /
                            youth")
                          </i>
                        </small>
                        <div className="one-half">
                          <label for="appt">General Body Location:<span style={{color:"red"}}>*</span></label>
                          <select onChange={getAllDetailbodyLocation}>
                            <option value="0">Select</option>

                            {GeneraBodylLocation.map((genbody) => (
                              <option value={genbody.ID}>
                                {genbody.BodyName}
                              </option>
                            ))}
                            {/* <option value="1">Audi</option>
                                                        <option value="2">BMW</option>
                                                        <option value="3">Citroen</option> */}
                          </select>
                        </div>
                        <div className="one-half">
                          <label for="appt">Detail Body Location:<span style={{color:"red"}}>*</span></label>
                          <select   onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              SFCSFamiliesBodyPartDetailLocationID: e.target.value,
                            })
                          }>
                            <option value="0">Select</option>
                            {DetailBodyLocation.map((detbody) => (
                              <option
                                value={
                                  detbody.SFCSFamiliesBodyPartDetailLocationID
                                }
                              >
                                {detbody.BodyName}
                              </option>
                            ))}
                            {/* <option value="1">Audi</option>
                                                        <option value="2">BMW</option>
                                                        <option value="3">Citroen</option> */}
                          </select>
                        </div>
                        {/* {genBodyLocationId ? (
                          <Link
                            class="normal"
                            onClick={() => setDetailedBodyLocation([])}
                          >
                            Clear Edit
                          </Link>
                        ) : (
                          ""
                        )} */}
                        <div className="one-full">
                          <label for="appt">Notes:</label>
                          <textarea onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              Notes: e.target.value,
                            })
                          }></textarea>
                        </div>
                        <div className="one-full">
                          <label for="appt">Description:</label>
                          <textarea onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              Description: e.target.value,
                            })
                          }></textarea>
                        </div>
                      </div>
                    </div>

                    <div className="form-row mt-0">
                      <div className="full-input">
                        <label for="appt">
                          4. Describe any other current physical complaint,
                          illness or pain
                        </label>
                        <input type="text" onChange={(e) =>
                          setAddFormData({
                            ...AddFormData,
                            PhysicalComplaints: e.target.value,
                          })
                        } />
                      </div>
                    </div>
                    <div className="form-row mt-0">
                      <div className="full-input">
                        <label for="appt">
                          5. Describe the mood or attitude of child/youth at the
                          time of placement.
                        </label>
                        <input type="text" onChange={(e) =>
                          setAddFormData({
                            ...AddFormData,
                            ClientAttitude: e.target.value,
                          })
                        }/>
                      </div>
                    </div>

                    <div className="g-background">
                      <div className="form-row">
                        <div className="one-full">
                          <label className="checkbox-label">
                            6. Does child/youth have thoughts of harming self or
                            others?
                            <div className="check-action">
                              {" "}
                              <span for="yes">
                                {" "}
                                Yes{" "}
                                <input
                                  type="checkbox"
                                  id="yes"
                                  name="vehicle3"
                                  value="Boat"
                                  onChange={(e) =>
                                    setAddFormData({
                                      ...AddFormData,
                                      IsHarmfulThoughts: e.target.checked,
                                    })
                                  }
                                />
                              </span>
                              {/* <span for="no">
                                {" "}
                                No{" "}
                                <input
                                  type="checkbox"
                                  id="no"
                                  name="vehicle3"
                                  value="Boat"  onChange={(e) =>
                                    setAddFormData({
                                      ...AddFormData,
                                      IsHarmfulThoughts: e.target.checked,
                                    })
                                  }
                                />
                              </span> */}
                            </div>
                          </label>
                          <label for="appt">
                            If Yes, what have they thought about and is there a
                            plan?
                          </label>
                          <input type="text" onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              HarmfulThoughtsDescription: e.target.value,
                            })
                          } />
                        </div>
                        <div className="one-full">
                          <label className="checkbox-label">
                            7. Does child/youth feel like running away?{" "}
                            <div className="check-action">
                              <span for="yes">
                                {" "}
                                Yes{" "}
                                <input
                                  type="checkbox"
                                  id=""
                                  name="vehicle1"
                                  value=""
                                  onChange={(e) =>
                                    setAddFormData({
                                      ...AddFormData,
                                      IsRunningAway: e.target.checked,
                                    })
                                  } />
                              </span>
                              {/* <span for="no">
                                {" "}
                                No{" "}
                                <input
                                  type="checkbox"
                                  id=""
                                  name="vehicle1"
                                  value=""
                                  onChange={(e) =>
                                    setAddFormData({
                                      ...AddFormData,
                                      IsRunningAway: !e.target.checked,
                                    })
                                  }
                                />
                              </span> */}
                            </div>
                          </label>
                          <label className="checkbox-label">
                            If Yes, Is there a plan?{" "}
                            <div className="check-action">
                              <span for="yes">
                                {" "}
                                Yes{" "}
                                <input
                                  type="checkbox"
                                  id=""
                                  name="vehicle4"
                                  value=""
                                  onChange={(e) =>
                                    setAddFormData({
                                      ...AddFormData,
                                      IsRunningAwayPlan: e.target.checked,
                                    })
                                  }/>
                              </span>
                              {/* <span for="no">
                                {" "}
                                No{" "}
                                <input
                                  type="checkbox"
                                  id=""
                                  name="vehicle4"
                                  value=""
                                  onChange={(e) =>
                                    setAddFormData({
                                      ...AddFormData,
                                      IsRunningAwayPlan: !e.target.checked,
                                    })
                                  }
                                />
                              </span>{" "} */}
                              <lable>Explain</lable>
                            </div>
                          </label>
                          <input type="text" 
                          onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              RunningAwayPlanDescription: e.target.value,
                            })
                          }
                          />
                        </div>
                        <small>
                          ***IMPORTANT: If answers to questions 6 or 7 are yes,
                          Resource Family will contact FCH worker, remain one on
                          one with child to develop plan, and/or contact case
                          manager emergency number. ***
                        </small>
                      </div>
                    </div>

                    <div className="form-row check-section fd-c">
                      <h6>Safety Assessment Tool</h6>
                      <label for="appt">
                        Does child/youth have a history of any of the following
                        (obtain information from placing agency)?
                      </label>
                      <div className="checkbox-grid">
                        {safetyAssessMentTool.map((safety,index) => (
                          <div className="one-fourth">
                            <span className="check-option">
                              <input
                                onChange={handleChangeHistory}
                                type="checkbox"
                                id={"chkAssessment"+index}
                                name={"chkAssessment"+index}
                                value={safety.SFCSFamiliesSafetyAssessmentID}
                              />
                              <label for={"chkAssessment"+index}>
                                {safety.SafetyAssessment}
                              </label>
                            </span>
                          </div>
                        ))}

                        <div className="one-fourth">
                          <span className="check-option">
                            <input
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                IsOtherHistory: e.target.checked,
                              })
                            }
                              type="checkbox"
                              id="chkAssessmentOthers"
                              name="chkAssessmentOthers"
                              value="Car"
                            />
                            <label for="chkAssessmentOthers">
                              Other (please list)
                              <input type="text" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  OtherHistoryList: e.target.value,
                                })
                              } 
                              class="form-controler"></input>
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="form-row check-section fd-c">
                      <h6>Ask child / youth following questions</h6>
                      <label for="appt">
                        {" "}
                        These things are "triggers" for child/youth and cause
                        him/her to get upset or mad. (check all that apply)
                      </label>
                      <div className="checkbox-grid">
                        {questionToBeAskedOne.map((question,index) => (
                          <div className="one-fourth">
                            <span className="check-option">
                              <input
                              onChange={handleChangeTrigger}
                                type="checkbox"
                                id={"chkChildYoung"+index}
                                name={"chkChildYoung"+index}
                                value={question.SFCSFamiliesSafetyAssessmentID}
                              />
                              <label for={"chkChildYoung"+index}>
                                {question.SafetyAssessment}
                              </label>
                            </span>
                          </div>
                        ))}

                        <div className="one-fourth">
                          <span className="check-option">
                            <input
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                IsOtherTrigger: e.target.checked,
                              })
                            }
                              type="checkbox"
                              id="chkChildYoungOthers"
                              name="chkChildYoungOthers"
                              value="Car"
                            />
                            <label for="chkChildYoungOthers">
                              {" "}
                              Other (please list)
                              <input type="text" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  OtherTriggerList: e.target.value,
                                })
                              }  class="form-controler"></input>
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="form-row check-section fd-c">
                      <label for="appt">
                        When child/youth is mad or upset, they report they do
                        these things. (check all that apply)
                      </label>
                      <div className="checkbox-grid">
                        {questionToBeAskedTwo.map((question,index) => (
                          <div className="one-fourth">
                            <span className="check-option">
                              <input
                              onChange={handleChangeIndicator}
                                type="checkbox"
                                id={"chkMadUpset"+index}
                                name={"chkMadUpset"+index}
                                value={question.SFCSFamiliesSafetyAssessmentID}
                              />
                              <label for={"chkMadUpset"+index}>
                                {question.SafetyAssessment}
                              </label>
                            </span>
                          </div>
                        ))}

                        <div className="one-fourth">
                          <span className="check-option">
                            <input
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                IsOtherUpsettingIndicators: e.target.checked,
                              })
                            }
                              type="checkbox"
                              id="chkMadUpsetOthers"
                              name="chkMadUpsetOthers"
                              value="Car"
                            />
                            <label for="chkMadUpsetOthers">
                              Other (please list)
                              <input type="text" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  OtherUpsettingIndicatorsList: e.target.value,
                                })
                              } class="form-controler"></input>
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="form-row check-section fd-c">
                      <label for="appt">
                        {" "}
                        Child/youth reports these things help calm him/her down
                        when they are mad or upset. (check all that apply)
                      </label>
                      <div className="checkbox-grid">
                        {questionToBeAskedThree.map((question,index) => (
                          <div className="one-fourth">
                            <span className="check-option">
                              <input
                              onChange={handleChangeCalming}
                                type="checkbox"
                                id={"chkChildReport"+index}
                                name={"chkChildReport"+index}
                                value={question.SFCSFamiliesSafetyAssessmentID}
                              />
                              <label for={"chkChildReport"+index}>
                                {question.SafetyAssessment}
                              </label>
                            </span>
                          </div>
                        ))}

                        <div className="one-fourth">
                          <span className="check-option">
                            <input
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                IsOtherCalmingActivities: e.target.checked,
                              })
                            }
                              type="checkbox"
                              id="chkChildReportOthers"
                              name="chkChildReportOthers"
                              value="Car"
                            />
                            <label for="chkChildReportOthers">
                              Other (please list)
                              <input type="text" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  OtherCalmingActivitiesList: e.target.value,
                                })
                              } class="form-controler"></input>
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* <div className="g-background">
                      <h6>MEDICATIONS THAT ARRIVED WITH CHILD/YOUTH</h6>

                      {
                      
                      MedicationinputList.map((data, index) => {
                        const {MedName, MedDose,MedFrequency,MedPurpose,MedCount}=data
                        return (
                          
                          <div className="form-row rg"  >
                            <div className="one-fifth">
                              <label for="appt">Name of Medication:</label>
                              <input type="text" name="MedName" value={MedName} onChange={(evnt) => handleMedicationChange(index, evnt)}/>
                            </div>
                            <div className="one-fifth">
                              <label for="appt">Dose:</label>
                              <input type="text" name="MedDose" value={MedDose} onChange={(evnt) => handleMedicationChange(index, evnt)}/>
                            </div>
                            <div className="one-fifth">
                              <label for="appt">When Given:</label>
                              <input type="text" name="MedFrequency" value={MedFrequency} onChange={(evnt) => handleMedicationChange(index, evnt)}/>
                            </div>
                            <div className="one-fifth">
                              <label for="appt">What for:</label>
                              <input type="text" name="MedPurpose" value={MedPurpose} onChange={(evnt) => handleMedicationChange(index, evnt)}/>
                            </div>
                            <div className="one-fifth">
                              <label for="appt">Initial Count:</label>
                              <input type="text" name="MedCount" value={MedCount} onChange={(evnt) => handleMedicationChange(index, evnt)}/>
                            </div>
                            <div className="action-btn">
                              {(MedicationinputList.length !== 1)? 
                                <Link 
                                  className="red-btn"
                                  onClick={ handleRemoveClick}
                                >
                                  Clear Edit
                                </Link>:''
                              }
                               
                                <Link
                                  className="blue-btn"
                                  onClick={handleAddClick}>
                                  Add
                                </Link>
                              
                            </div>
                          </div>
                        );
                      })}
                    </div> */}

<div className="form-row mt-25">
                      <div className="label15 labelpadtop">
                        <label for="appt">Person Reporting:<span style={{color:"red"}}>*</span></label>
                      </div>
                      <div className="label23">
                        <select
                          onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              ProviderMemberID: e.target.value,
                            })
                          }
                        >
                          {/* <option>Please Select</option> */}
                          {FormdatabyIncidentIdLoadedStatus ? (
                            personReportingData
                              .filter(
                                (personReporting) =>
                                  personReporting.ProviderMemberID ===
                                  FormdatabyIncidentIdLoaded.ProviderMemberID
                              )
                              .map((personReporting) => (
                                <option
                                  selected
                                  value={personReporting.ProviderMemberID}
                                >
                                  {personReporting.ProviderName}
                                </option>
                              ))
                          ) : (
                            <option value="0">Please Select</option>
                          )}
                          {personReportingData.map((personReporting) => (
                            <option value={personReporting.ProviderMemberID}>
                              {personReporting.ProviderName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="label7"></div>
                      <div className="label15 labelpadtop">
                        <label for="birthday">Date Completed:<span style={{color:"red"}}>*</span></label>
                      </div>
                      <div className="label23">
                        <input type="date" />
                      </div>
                    </div>


                    <div className="action-btn">
                      <Link to="#" onClick={closeClicked} className="red-btn">
                        Cancel
                      </Link>
                      <Link
                        to="#"
                        onClick={() => onSubmitButtonClick('draft')}
                        className="blue-btn"
                      >
                        Save As Draft
                      </Link>

                      <Link
                        to="#"
                        onClick={() => onSubmitButtonClick('submit')}
                        className="blue-btn"
                      >
                        Submit
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ImmidiateAssesmentForm;
