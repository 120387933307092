import Footer from "../Footer";
import Header from "../Header";

import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";

function EventDetailView() {
  const dayArray = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const history = useHistory();

  const [selectedDataToDisplay, setSelectedDataToDisplay] = useState([]);
  const [dateAndTime, setDateAndTime] = useState("");
  const showDetail = async () => {
    let data = await JSON.parse(sessionStorage.getItem("EventDetailView"));
    console.log("data", data);
    setSelectedDataToDisplay(data);
    let startDate = data.CalendarDate;
    let endDate = data.EndTime;

    const DayName = dayArray[new Date(startDate).getDay()];
    const ActualDate = startDate.slice(0, 10);
    let start = startDate;
    let end = endDate;
    let startHour = startDate.slice(11, 13);
    let endHour = endDate.slice(11, 13);
    let startAMPM;
    let endAMPM;

    let newStartHour;
    let newEndHour;

    if (parseInt(startHour) < 12) {
      startAMPM = "AM";
      newStartHour = startHour;
    } else {
      startAMPM = "PM";
      newStartHour = startHour - 12;
    }

    if (parseInt(endHour) < 12) {
      endAMPM = "AM";
      newEndHour = endHour;
    } else {
      endAMPM = "PM";
      newEndHour = endHour - 12;
    }

    let DateAndTime =
      DayName +
      ", " +
      ActualDate +
      ", " +
      newStartHour +
      ":" +
      startDate.slice(14, 16) +
      startAMPM +
      "-" +
      newEndHour +
      ":" +
      endDate.slice(14, 16) +
      endAMPM;
    setDateAndTime(DateAndTime);
  };

  useEffect(() => {
    showDetail();
  }, []);

  return (
    <div className="main">
      <Header />
      <div className="parent-main">
        <div className="container">
          <h2>Event Detail</h2>

          <div className="container">
            {/* {LocationData.map((d) => ( */}
            <div className="event-detail-list">
              <h3>Title:{"  "}{selectedDataToDisplay.Title}</h3>
              <small>Date:{"  "}{dateAndTime}</small>
              <p className="parag">Description: {selectedDataToDisplay.Description}</p>
              {/* <p>{selectedDataToDisplay.EndTime}</p> */}
              <b>Location:{"  "}{selectedDataToDisplay.Location}</b>
              <b>
                Link:{"  "}
                <Link
                  to={{ pathname: selectedDataToDisplay.LinkPath }}
                  target="_blank"
                >
                  {selectedDataToDisplay.LinkPath}
                </Link>
              </b>
              {/* <Link to="#" className="normal">
                  More
                </Link> */}
              <br></br>
              <br></br>
            </div>
            {/* ))} */}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
export default EventDetailView;
