import Footer from '../../Footer';
import Header from '../../../frontendPages/Header';
import daycare from '../../../images/assets/currentChildren/daycare.svg';
import '../../CurrentChildren/DayCare/DayCare.scss'
import CurrentChildrenSideBar from '../CurrentChildrenSidebar/CurrentChildrenSidebar';
import HeaderTopDetail from '../HeaderTopDetail/HeaderTopDetail';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import LoadSpinner from '../../Loader/LoadSpinner';
import { post } from '../../../utils/common';
import mail from '../../../images/mail.png'




function DayCare() {
    const [updateDiv, setShowUpdateDiv] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [addChildDiv, setAddChildDiv] = useState(false)
    const [selectedDataForEdit, setSelectedDataForEdit] = useState([])


    const showUpdateDiv = () => {
        setShowUpdateDiv(false)
        setAddChildDiv(true)
    }

    const hideUpdateDiv = () => {
        setShowUpdateDiv(false)
    }

    const hideAddChildDiv = () => {
       // setShowUpdateDiv(false)
        setAddChildDiv(false)
    }
    const [updateData, setUpdateData] = useState([])
    const onupdateClick = async () => {
        // setIsLoading(true)



        let username = sessionStorage.getItem('username');
        setShowUpdateDiv(false)
        setAddChildDiv(false)
        setUpdateData([])
        await post('/api/daycare/update-daycare', { username, updateData });
        alert('Request update  Emailed Successfully !');
        setIsLoading(false)

        // alert("Submitted Successfully");
        // window.location.reload(false)
    }
    const onChildAdditionSubmit = async () => {
        // setIsLoading(true)



        let username = sessionStorage.getItem('username');
        setShowUpdateDiv(false)
        setAddChildDiv(false)
        setUpdateData([])
        await post('/api/daycare/update-daycare-addition', { username, updateData });
        alert('Request Addition  Emailed Successfully !');
        setIsLoading(false)

        // alert("Submitted Successfully");
        // window.location.reload(false)
    }


    const [childrenData, setChildrenData] = useState([]);
    const fetchChildData = async () => {
        const id = sessionStorage.getItem('providerSessionId');
        let data = await post('/api/currentchildren/index', { id });
        setChildrenData(data.data.data);

        let children_id = data.data.data[0].ClientID;
        setActiveId(children_id)
        let provider_id = id
        let detailData = await post('/api/daycare/getclient-daycare', { provider_id, children_id });
        console.log("detailData", detailData)

        setData(detailData.data.data);
    };

    const [activeId, setActiveId] = useState();

    const handleCurrentChildGlobalDayCare = async (children_id) => {
        setActiveId(children_id)
        setIsLoading(true);
        sessionStorage.setItem("childrenId", children_id);
        children_id = sessionStorage.getItem("childrenId");
        const provider_id = sessionStorage.getItem('providerSessionId');
        let data = await post('/api/daycare/getclient-daycare', { provider_id, children_id });
        // setselectedChildrenData(data.data.data);
        let detaildata = await post('/api/currentchildren/children-detail', { provider_id, children_id });
        sessionStorage.setItem("current_child", detaildata.data.data[0].ClientName);
        setIsLoading(false);
        setData(data.data.data)
    }

    useEffect(() => {
        // fetchData();
        fetchChildData();
    }, []);


    const [data, setData] = useState([]);


    const requestEdit = (rowData) => {
        setShowUpdateDiv(true)
        setAddChildDiv(false)

        setSelectedDataForEdit(data[rowData])
        console.log(selectedDataForEdit)
    }

        // const fetchData = async () => {
        //    //fchweb.spWeb_gridDaycare_1
        //      const child_id = sessionStorage.getItem("childrenId")
        //     let data = await post('/api/daycare/getclient-daycare',{child_id});
        //     if (!data.ok) {

        //         return;
        //     }
        //     data = await data.json();
        //     console.log(data);
        //     setData(data.data);
        // };
       


        const columns = [
            {
                name: 'Name ',
                selector: row => row.Name,
                sortable: true,
            },
            {
                name: 'Date Ranges',
                selector: row => row.Date_Ranges,
                sortable: true,
            },
            {
                name: 'Frequency',
                selector: row => row.Frequency,
                sortable: true,
            },
            {
                name: 'Rate',
                selector: row => row.Rate,
                // cell: (row, j) => (new Date(row.DOB).toLocaleDateString()),
                sortable: true,
            },
            {
                name: 'Additional Info',
                selector: row => row.Additional_Info,
                // cell: (row, j) => (new Date(row.Race).toLocaleDateString()),
                sortable: true,
            },
            {
                name: 'Action',
                sortable: true,
                cell: (row, j) => (
                    <div class="inline-flex">

                        <Link onClick={() => requestEdit(j)}

                            class="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            to="#"
                        >
                            <img src={mail} alt="edit"></img>
                        </Link>

                    </div>
                ),
            },

        ];




        return (

            <div className="main">
                <Header />
                <div className="main-content" >
                    <HeaderTopDetail />
                    <div className="pagewithsidebar">
                        <div className="container">
                            <div className="pagewithsidebar-inner">
                                <CurrentChildrenSideBar />
                                <div className="tab-detail">
                                    <div className="tab-detail-top childprofile-tabs">
                                        <div className="children-section">
                                            <img src={daycare} alt="" />
                                            <div className="pagesubtitle">
                                                DayCare
                                            </div>
                                        </div>
                                        <Link to="#" onClick={showUpdateDiv}>Request Addition</Link>
                                    </div>
                                    <div className="children-tab">
                                        {childrenData.map(child => {
                                            return <span className={activeId === child.ClientID ? "active" : ""} onClick={() => handleCurrentChildGlobalDayCare(child.ClientID)}>{child.ClientName}</span>
                                        })}
                                        {/* <span className="active">Easton Elliott</span> | <span>Easton Easton</span> | <span>Anna Litchfield</span> */}
                                    </div>
                                    {isLoading ? (<LoadSpinner />) : ''}

                                    <div className="tab-detail-bottom children-data">
                                        <form className="children-doc-form">
                                            {addChildDiv ? <div className="req-update-textarea">
                                                <label><span><b>Request addition for</b>- Daycare
                                                    <b> Child: {sessionStorage.getItem("current_child")}  </b>
                                                    <br></br>
                                                    <br></br>
                                                    <b> Description for Additon:</b> </span></label>
                                                <textarea onChange={e => setUpdateData({ ...updateData, description: e.target.value })}></textarea>
                                                <div className="req-update-action">
                                                    <Link to="#" onClick={hideAddChildDiv} className="close">close</Link>
                                                    <Link to="#" onClick={() => onChildAdditionSubmit()} className="normal">Submit</Link>
                                                </div>
                                            </div>
                                                : ''}


                                            {updateDiv ? <div className="req-update-textarea">
                                                <label><span><b>Request Update For</b>- Court Dates|
                                                    <b> Child: {sessionStorage.getItem("current_child")} | </b>
                                                    Name: {selectedDataForEdit.Name ? selectedDataForEdit.Name : ''}    |
                                                    Date Ranges: {selectedDataForEdit.Date_Ranges ? selectedDataForEdit.Date_Ranges : ''} |
                                                    Frequency: {selectedDataForEdit.Frequency ? selectedDataForEdit.Frequency : ''}
                                                    <br></br>
                                                    <br></br>
                                                    <b> Description for Update:</b>
                                                </span></label>
                                                <textarea onChange={e => setUpdateData({ ...updateData, description: e.target.value })}></textarea>
                                                <div className="req-update-action">
                                                    <Link to="#" onClick={hideUpdateDiv} className="close">close</Link>
                                                    <Link to="#" onClick={onupdateClick} className="normal">Submit</Link>
                                                </div>
                                            </div> : ''}

                                            <DataTable columns={columns} data={data} pagination={true} />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }

export default DayCare;
