  import Footer from "../../Footer";
  import Header from "../../Header";
  import lg from "../../../images/assets/images/fmsmain.svg";
  import { Link, useHistory } from "react-router-dom";
  import FormSideBar from "../FormSidebar/FormSidebar";
  import { useEffect, useState } from "react";
  import "./FosterParentReferalForm.scss";
  // import "../CriticalUnusualIncidentForm/CriticalUnusualIncidentForm.scss";
  // import "../ImmidiateAssesmentForm/ImmidiateAssesmentForm.scss";
  import { post } from "../../../utils/common";
  import { useRef } from "react";
  import html2canvas from "html2canvas";
  //import html2pdf from 'html2pdf.js/dist/html2pdf.min'
  import jsPDF from "jspdf";
  import axios from "axios";
  import LoadSpinner from "../../Loader/LoadSpinner";


  function FosterParentReferalForm() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const [isLoading, setIsLoading] = useState(false);
    const inputRef = useRef(null);
    const history = useHistory();
    let token = sessionStorage.getItem("token");

    const [AddFormData, setAddFormData] = useState([]);

    const onSubmitButtonClick = async (e) => {

      if(AddFormData.ParentNameOne === undefined || AddFormData.ParentNameTwo === undefined ||
        AddFormData.ParentOnePhone === undefined || AddFormData.Mailing === undefined )
       { 
       alert("Please enter the Mandatory fields");
        return;
       }     
      e.preventDefault();

      console.log(AddFormData);

      let options ={
        scale: 0.88,
        scrollY: window.scrollY,
        scrollX: 0,
        allowTaint: true,
        useCORS: true,
        width:1024
      };

      
      html2canvas(inputRef.current, options).then(async (canvas) => {
      // JSON.stringify(AddFormData.imgData);
      
        
      var pageHeight = 295;  
      var imgWidth = (canvas.width * 28) / 210 ; 
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm", "a4", true);
      const imgData = canvas.toDataURL("image/png",'PNG', 0, 0, doc.internal.pageSize.getWidth(),doc.internal.pageSize.getHeight())

      var position = 0;

      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
          heightLeft -= pageHeight; 
      }
      setIsLoading(true)
     


        const out = doc.output("datauristring");  
        const response = await axios.post("/api/general/fosterparentreferralmail",
          {
            pdf: out.split("base64,")[1],
            AddFormData
          }
          
         
        );
       
        alert("Email Sent");

        window.location.href = "https://sfmfamilies.org/";
        
        //window.location.reload(true);
      });
      console.log(AddFormData);
      let AddFormData1 = JSON.stringify(AddFormData);

      let data = await post("/api/general/fosterparentreferralsave", {
        AddFormData1 
      });

    };

    const closeClicked = () => {
      history.push("/form/formindex");
    };
    const [stateData, setStateData] = useState([]);

    const fetchStateData = async () => {
      let data = await fetch("/api/general/state");
      if (!data.ok) {
        return;
      }
      data = await data.json();
      console.log(data);
      setStateData(data.data);
    };

    const adjustViewportHeight = () => {
      const viewportHeight = window.innerHeight;
      document.documentElement.style.setProperty('--viewport-height', `${viewportHeight}px`);
      };

      useEffect(() => {
        if (isSafari) {
        window.addEventListener('resize', adjustViewportHeight);
        }
        
        return () => {
        if (isSafari) {
        window.removeEventListener('resize', adjustViewportHeight);
        }
        };
        },);

    const EnableDisable = (e,value) =>{
      if(value === 'CommunicationPhone' && e.target.checked === true){
        AddFormData.CommunicationText = false;
        AddFormData.CommunicationEmail = false;        
      }
      else if(value === "CommunicationText" && e.target.checked === true){
        AddFormData.CommunicationPhone = false;
        AddFormData.CommunicationEmail = false;       
      }
      else if(value === "CommunicationEmail" && e.target.checked === true){
        AddFormData.CommunicationText = false;
        AddFormData.CommunicationPhone = false;
      }
    }


    useEffect(() => {
      fetchStateData();
      console.log("Phone",AddFormData.CommunicationPhone);
      console.log('Email',AddFormData.CommunicationEmail);
      console.log('Text',AddFormData.CommunicationText);
      console.log(AddFormData)
    
      
    } , [AddFormData]);
    

    console.log("state", stateData);

     return (
      
      
              
                <div >
                  <div className="main">
         <Header />
                       <form className="form-border ">
                    <div className="foster-container" ref={inputRef}>
                            <div className="img-main">
                                  <img src={lg} alt="#" />
                              </div>
                              <div className="" >
                        
                            <div>
                            <h3 className="checkbox-heading" style={{"fontSize":30,"fontFamily":"sans-serif"}} >
                          
                            Foster Parent Referral Form
                          </h3>

                          <div className="form-section-1" >
                                  <div className="checkbox-heading section-1" style={{"marginTop":"30px"}}>
                                    <h2>Please Select the type of the program you are interested in.</h2>
                                    <div className="yn-checkbox" >
                                      <span>
                                        <input
                                          type="checkbox"
                                          id="GeneralFoster"
                                          name="f"
                                          value=""
                                          onChange={(e) =>
                                            setAddFormData({
                                              ...AddFormData,
                                              GeneralFosterCare: e.target.checked,
                                            })
                                          }
                                        />
                                        <label for="GeneralFoster"> General Foster Care</label>
                                      </span>
                                      <span>
                                        <input
                                          type="checkbox"
                                          id="bloodRelated"
                                          name="f"
                                          value=""
                                          onChange={(e) =>
                                            setAddFormData({
                                              ...AddFormData,
                                              BloodRelated: e.target.checked,
                                            })
                                          }
                                        />
                                        <label for="bloodRelated"> Blood Related</label>
                                      </span>
                                      <span>
                                        <input
                                          type="checkbox"
                                          id="NonBloodRelated"
                                          name="f"
                                          value=""
                                          onChange={(e) =>
                                            setAddFormData({
                                              ...AddFormData,
                                              NonBloodRelated: e.target.checked,
                                            })
                                          }
                                        />
                                        <label for="NonBloodRelated">Non-Blood Related</label>
                                      </span>
                                      <span>
                                        <input
                                          type="checkbox"
                                          id="Adoption"
                                          name="f"
                                          value=""
                                          onChange={(e) =>
                                            setAddFormData({
                                              ...AddFormData,
                                              Adoption: e.target.checked,
                                            })
                                          }
                                        />
                                        <label for="Adoption"> Adoption</label>
                                      </span>
                                    </div>
                                    
                                  </div>
                                <h4 style={{'marginBottom':'1%'}}>Please fill out the form below. (Asterisks mark required fields).</h4>
                                <div className="foster-form-section" style={{'marginBottom':'1%'}}><h2>Basic Information</h2></div>
                                <div className="label-input-field">
                                <label htmlFor="first-name">First Name*:</label>
                                <input className="input-field" type="text" id="first-name" required onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  ParentNameOne: e.target.value,
                                })}/>
                                </div>
                                <div className="label-input-field">
                                <label htmlFor="last-name">Last Name*:</label>
                                <input className="input-field" type="text" id="last-name" required onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  ParentNameTwo: e.target.value,
                                })
                              } />
                                </div>
                                <div className="label-input-field">
                                <label htmlFor="dob">Date of Birth:</label>
                                <input className="input-field" type="Date" id="dob" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  DobOne: e.target.value,
                                })
                              } />
                                </div>
                                <div className="label-input-field">
                                <label htmlFor="gender">Gender:</label>
                                <input className="input-field" type="text" id="gender" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  genderOne: e.target.value,
                                })
                              }/>
                                </div>

                                <div className="foster-form-section" style={{'marginBottom':'1%'}}><h2>Contact Information</h2></div>

                                <div className="label-input-field">
                                <label htmlFor="phone-cell">Phone No. (Cell)*:</label>
                                <input className="input-field" type="text" id="phone-cell" required onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  ParentOnePhone: e.target.value,
                                })
                              } />
                                </div>

                                <div className="label-input-field">
                                <label htmlFor="phone-home">Phone No. (Home):</label>
                                <input className="input-field" type="text" id="phone-home" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  parentOneEmail: e.target.value,
                                })
                              }/>
                                </div>

                                <div className="label-input-field">
                                <label htmlFor="phone-office">Phone No. (Office):</label>
                                <input className="input-field" type="text" id="phone-office" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  parentTwoEmail: e.target.value,
                                })
                              }/>
                                </div>

                                <div className="label-input-field">
                                <label htmlFor="email">Email Address*:</label>
                                <input className="input-field" type="email" id="email" required onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  Mailing: e.target.value,
                                })
                              } />
                                  </div>
                                  
                                <div className="label-input-field">
                                <label htmlFor="communication" style={{'width':'44%','margin':'1%','marginLeft':'0'}}>What method of communication do you prefer?*</label>
                                  <div className="yn-checkbox input-field" >
                                    
                                    <span>
                                        <input
                                          type="radio"
                                          id="CommunicationPhone"
                                          name="f"
                                          value={"CommunicationPhone"}
                                          onChange={(e) =>
                                            setAddFormData({
                                              ...AddFormData,
                                              CommunicationPhone: e.target.checked,
                                            })
                                          }
                                          onClick={(e)=>{EnableDisable(e,'CommunicationPhone')}}
                                        />
                                        <label for="CommunicationPhone"> Phone</label>
                                      </span>

                                      <span>
                                        <input
                                          type="radio"
                                          id="CommunicationText"
                                          name="f"
                                          value={'CommunicationText'}
                                          onChange={(e) =>
                                            setAddFormData({
                                              ...AddFormData,
                                              CommunicationText: e.target.checked,
                                            })
                                          }
                                          onClick={(e)=>{EnableDisable(e,'CommunicationText')}}
                                        />
                                        <label for="CommunicationText"> Text</label>
                                      </span>

                                      <span>
                                        <input
                                          type="radio"
                                          id="CommunicationEmail"
                                          name="f"
                                          value={"CommunicationEmail"}
                                          onChange={(e) =>
                                            setAddFormData({
                                              ...AddFormData,
                                              CommunicationEmail: e.target.checked,
                                            })
                                          }
                                          onClick={(e)=>{EnableDisable(e,'CommunicationEmail')}}
                                        />
                                        <label for="CommunicationEmail"> Email</label>
                                      </span>
                                  </div>
                                  </div>
                                 

                                <div className="foster-form-section" style={{'marginBottom':'1%','marginTop':'1%'}}><h2>Address Information</h2></div>
                                <div className="label-input-field">
                                <label htmlFor="address">Street Address:</label>
                                <input className="input-field" type="text" id="address"onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  Address: e.target.value,
                                })
                              }  />
                                </div>
                                <div className="label-input-field">
                                <label htmlFor="city">City:</label>
                                <input className="input-field" type="text" id="city"onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  Country: e.target.value,
                                })
                              } />
                                </div>
                                <div className="label-input-field">
                                <label htmlFor="state">State:</label>
                                {/* <select className="select-option"
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                State: e.target.name,
                              })
                            }
                          >
                                                        <option  value="0">Select:</option>
                                                        {stateData.map(states=>(
                                                             <option value={states.StateID}>{states.State}</option>
                                                        ))}
                                                    </select> */}
                                <input className="input-field" type="text" id="state"  onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                State: e.target.value,
                              })
                            }/>
                                </div>
                                <div className="label-input-field">
                                <label htmlFor="zipcode">Zip Code:</label>
                                <input className="input-field" type="text" id="zipcode" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  raceTwo : e.target.value,
                                })
                              }/>
                                </div>


                                <div className="foster-form-section" style={{'marginBottom':'1%'}}><h2>How did you hear about us?</h2></div>
                                <div className="label-input-field">
                                <div className="yn-checkbox input-field" style={{'marginRight':'0'}}>
                                <span style={{'marginBottom':'6px'}}>
                                        <input
                                          type="checkbox"
                                          id="OtherFoster"
                                          name="f"
                                          value=""
                                          onChange={(e) =>
                                            setAddFormData({
                                              ...AddFormData,
                                              OtherFosterCheck: e.target.checked,
                                            })
                                          }
                                        />
                                      </span>
                                      </div>
                                <label htmlFor="OtherFoster">Other Foster Parent:</label>
                                <input className="input-field" type="text" id="OtherFoster"onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  OtherFoster: e.target.value,
                                })
                              }  />
                                </div>
                                <div className="label-input-field">
                                <div className="yn-checkbox input-field" style={{'marginRight':'0'}}>
                                <span style={{'marginBottom':'6px'}}>
                                        <input
                                          type="checkbox"
                                          id="Kinship"
                                          name="f"
                                          value=""
                                          onChange={(e) =>
                                            setAddFormData({
                                              ...AddFormData,
                                              KinshipRelationCheck: e.target.checked,
                                            })
                                          }
                                        />
                                      </span>
                                      </div>
                                <label htmlFor="Kinship">Kinship/Relative Parent:</label>
                                <input className="input-field" type="text" id="Kinship"onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  KinshipRelation: e.target.value,
                                })
                              } />
                                </div>
                                <div className="label-input-field">
                                <div className="yn-checkbox input-field" style={{'marginRight':'0'}}>
                                <span style={{'marginBottom':'6px'}}>
                                        <input
                                          type="checkbox"
                                          id="SocialMedia"
                                          name="f"
                                          value=""
                                          onChange={(e) =>
                                            setAddFormData({
                                              ...AddFormData,
                                              SocialMediaCheck: e.target.checked,
                                            })
                                          }
                                        />
                                      </span>
                                      </div>
                                <label htmlFor="SocialMedia">Social Media:</label>
                                <input className="input-field" type="text" id="SocialMedia"  onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                SocialMedia: e.target.value,
                              })
                            }/>
                                </div>
                                <div className="label-input-field">
                                <div className="yn-checkbox input-field" style={{'marginRight':'0'}}>
                                <span style={{'marginBottom':'6px'}}>
                                        <input
                                          type="checkbox"
                                          id="Special"
                                          name="f"
                                          value=""
                                          onChange={(e) =>
                                            setAddFormData({
                                              ...AddFormData,
                                              EventOutreachCheck: e.target.checked,
                                            })
                                          }
                                        />
                                      </span>
                                      </div>
                                <label htmlFor="Special">Special Event/Outreach:</label>
                                <input className="input-field" type="text" id="Special" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  EventOutreach : e.target.value,
                                })
                              }/>
                                </div>
                                <div className="label-input-field">
                                <div className="yn-checkbox input-field" style={{'marginRight':'0'}}>
                                <span style={{'marginBottom':'6px'}}>
                                        <input
                                          type="checkbox"
                                          id="Flyer"
                                          name="f"
                                          value=""
                                          onChange={(e) =>
                                            setAddFormData({
                                              ...AddFormData,
                                              FlyerCheck: e.target.checked,
                                            })
                                          }
                                        />
                                      </span>
                                      </div>
                                <label htmlFor="Flyer">Flyer:</label>
                                <input className="input-field" type="text" id="SpecFlyerial" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  Flyer : e.target.value,
                                })
                              }/>
                                </div>
                                <div className="label-input-field">
                                <div className="yn-checkbox input-field" style={{'marginRight':'0'}}>
                                <span style={{'marginBottom':'6px'}}>
                                        <input
                                          type="checkbox"
                                          id="Radio"
                                          name="f"
                                          value=""
                                          onChange={(e) =>
                                            setAddFormData({
                                              ...AddFormData,
                                              RadioCheck: e.target.checked,
                                            })
                                          }
                                        />
                                      </span>
                                      </div>
                                <label htmlFor="Radio">Radio:</label>
                                <input className="input-field" type="text" id="Radio" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  Radio : e.target.value,
                                })
                              }/>
                                </div>
                                <div className="label-input-field">
                                <div className="yn-checkbox input-field" style={{'marginRight':'0'}}>
                                <span style={{'marginBottom':'6px'}}>
                                        <input
                                          type="checkbox"
                                          id="Digitalad"
                                          name="f"
                                          value=""
                                          onChange={(e) =>
                                            setAddFormData({
                                              ...AddFormData,
                                              DigitaladCheck: e.target.checked,
                                            })
                                          }
                                        />
                                      </span>
                                      </div>
                                <label htmlFor="Digitalad">Digital ad:</label>
                                <input className="input-field" type="text" id="Digitalad" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  Digitalad : e.target.value,
                                })
                              }/>
                                </div>
                                <div className="label-input-field">
                                <div className="yn-checkbox input-field" style={{'marginRight':'0'}}>
                                <span style={{'marginBottom':'6px'}}>
                                        <input
                                          type="checkbox"
                                          id="BillBoard"
                                          name="f"
                                          value=""
                                          onChange={(e) =>
                                            setAddFormData({
                                              ...AddFormData,
                                              BillBoardCheck: e.target.checked,
                                            })
                                          }
                                        />
                                      </span>
                                      </div>
                                <label htmlFor="BillBoard">Billboard:</label>
                                <input className="input-field" type="text" id="BillBoard" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  BillBoard : e.target.value,
                                })
                              }/>
                                </div>
                                <div className="label-input-field">
                                <div className="yn-checkbox input-field" style={{'marginRight':'0'}}>
                                <span style={{'marginBottom':'6px'}}>
                                        <input
                                          type="checkbox"
                                          id="wordOfMouth"
                                          name="f"
                                          value=""
                                          onChange={(e) =>
                                            setAddFormData({
                                              ...AddFormData,
                                              WordOfMouthcheck: e.target.checked,
                                            })
                                          }
                                        />
                                      </span>
                                      </div>
                                <label htmlFor="wordOfMouth">Word of Mouth:</label>
                                <input className="input-field" type="text" id="workOfMouth" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  WordOfMouth : e.target.value,
                                })
                              }/>
                                </div>
                                <div className="label-input-field">
                                <div className="yn-checkbox input-field" style={{'marginRight':'0'}}>
                                <span style={{'marginBottom':'6px'}}>
                                        <input
                                          type="checkbox"
                                          id="wordOfMouth"
                                          name="f"
                                          value=""
                                          onChange={(e) =>
                                            setAddFormData({
                                              ...AddFormData,
                                              WalkinStateCheck: e.target.checked,
                                            })
                                          }
                                        />
                                      </span>
                                      </div>
                                <label htmlFor="wordOfMouth">Walk in State Child Welfare:</label>
                                <input className="input-field" type="text" id="walkinState" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  WalkinState : e.target.value,
                                })
                              }/>
                                </div>
                                <div className="label-input-field">
                                <div className="yn-checkbox input-field" style={{'marginRight':'0'}}>
                                <span style={{'marginBottom':'6px'}}>
                                        <input
                                          type="checkbox"
                                          id="Agency"
                                          name="f"
                                          value=""
                                          onChange={(e) =>
                                            setAddFormData({
                                              ...AddFormData,
                                              AgencyCheck: e.target.checked,
                                            })
                                          }
                                        />
                                      </span>
                                      </div>
                                <label htmlFor="Agency">Agency:</label>
                                <input className="input-field" type="text" id="Agency" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  Agency : e.target.value,
                                })
                              }/>
                                </div>
                                <div className="label-input-field">
                                <div className="yn-checkbox input-field" style={{'marginRight':'0'}}>
                                <span style={{'marginBottom':'6px'}}>
                                        <input
                                          type="checkbox"
                                          id="emplayee"
                                          name="f"
                                          value=""
                                          onChange={(e) =>
                                            setAddFormData({
                                              ...AddFormData,
                                              EmployeeCheck: e.target.checked,
                                            })
                                          }
                                        />
                                      </span>
                                      </div>
                                <label htmlFor="emplayee">Employee:</label>
                                <input className="input-field" type="text" id="employee" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  Employee : e.target.value,
                                })
                              }/>
                                </div>
                                <div className="label-input-field">
                                <div className="yn-checkbox input-field" style={{'marginRight':'0'}}>
                                <span style={{'marginBottom':'6px'}}>
                                        <input
                                          type="checkbox"
                                          id="generalmedia"
                                          name="f"
                                          value=""
                                          onChange={(e) =>
                                            setAddFormData({
                                              ...AddFormData,
                                              GeneralMediaCheck: e.target.checked,
                                            })
                                          }
                                        />
                                      </span>
                                      </div>
                                <label htmlFor="generalmedia">General Media:</label>
                                <input className="input-field" type="text" id="generalmedia" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  GeneralMedia : e.target.value,
                                })
                              }/>
                                </div>
                                <div className="label-input-field">
                                <div className="yn-checkbox input-field" style={{'marginRight':'0'}}>
                                <span style={{'marginBottom':'6px'}}>
                                        <input
                                          type="checkbox"
                                          id="internet"
                                          name="f"
                                          value=""
                                          onChange={(e) =>
                                            setAddFormData({
                                              ...AddFormData,
                                              InternetCheck: e.target.checked,
                                            })
                                          }
                                        />
                                      </span>
                                      </div>
                                <label htmlFor="internet">Internet:</label>
                                <input className="input-field" type="text" id="internet" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  Internet : e.target.value,
                                })
                              }/>
                                </div>
                                
                                </div>
                    </div>  
                    </div>  
                                                  
</div>         
                                <div className="req-update-textarea pt-0 mt-25 b-0 .container1">
                          <div className="req-update-action ">
                            <Link
                              to="#"
                              onClick={closeClicked}
                              className="close"
                            >
                              Close
                            </Link>
                            <Link
                              to="#"
                              onClick={onSubmitButtonClick}
                              className="normal"
                            >
                              Submit
                            </Link>
                          </div>
                          {isLoading ? (<LoadSpinner />) : ''}
                        </div>
                    




</form>
  <Footer />
</div>
</div>
    );
  }
         
  export default FosterParentReferalForm;  

  // <div className="main">
      //   <Header />

      //   <div className="main-content ">
      //     <div className="pagewithsidebar">
      //       {token ? <FormSideBar /> : ""}

      //       {token ? (
      //         ""
      //       ) : (
      //         <div className="homelink">
      //           <Link to="/">Home</Link>
      //         </div>
      //       )}

      //       <div
      //         className={`tab-detail Critical ${
      //           token ? "" : "formwithout-login"
      //         }`}
      //       >
      //         {/* <div className="container1">
            
      //         </div> */}
      //            <div className="heading-foster-parent">
      //                 <img src={lg} alt="#" />
      //               </div>
      //         <div className="pagewithsidebar-inner" ref={inputRef} >
             
      //           <div   >
      //           <h3 className="heading-foster-parent" >
      //           {" "}
      //           Foster Parent Referral Form
      //         </h3>

                  {/* <form>
                  
                    <div className="form-section-1" ref={inputRef}>
                      <div className="checkbox-heading section-1">
                        <h2>Section 1 : General Information</h2>
                        <div className="yn-checkbox">
                          <span>
                            <input
                              type="radio"
                              id=""
                              name="f"
                              value=""
                              onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  FosterCare: e.target.value,
                                })
                              }
                            />
                            <label for=""> Foster Care</label>
                          </span>
                          <span>
                            <input
                              type="radio"
                              id=""
                              name="f"
                              value=""
                              onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  FosterAdopt: e.target.value,
                                })
                              }
                            />
                            <label for=""> Foster/Adopt</label>
                          </span>
                          <span>
                            <input
                              type="radio"
                              id=""
                              name="f"
                              value=""
                              onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  Adoption: e.target.checked,
                                })
                              }
                            />
                            <label for="">Adoption</label>
                          </span>
                        </div>
                      </div>
                      <div className="gernal-info-field">
                        <div className="parent-name">
                          <span>
                            <h6>First Name: <span style={{color:"red"}}>*</span></h6>
                            <input type="text" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  ParentNameOne: e.target.value,
                                })
                              } />
                          </span>
                        </div>

                     
                      </div>

                      <div className="gernal-info-field">
                      <div className="parent-name">
                        <span>
                            <h6>Last Name :<span style={{color:"red"}}>*</span></h6>
                            <input type="text" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  ParentNameTwo: e.target.value,
                                })
                              }/>
                          </span>

                          </div>
                      </div>

                          <div className="gernal-info-field">
                        <div className="gender">
                          <span>
                            <h6>Gender:</h6>
                            <input type="text"  onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  genderOne: e.target.value,
                                })
                              }/>
                          </span>

                        </div>
                     </div>

                     <div className="gernal-info-field">
                        <div className="parent-name">
                          <span>
                            <h6>DOB:</h6>
                            <input type="Date"  onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  DobOne: e.target.value,
                                })
                              }/>
                          </span>
                          </div>

                        </div>

                      <div className="gernal-info-field">
                        <div className="parent-name">
                        
                        </div>
                       
                      </div>
                      <div className="gernal-info-field">
                        <div className="parent-name">
                        
                        </div>

                        </div>
                      
                      <div className="gernal-info-field">
                          <div className="parent-name">
                          <span>
                            <h6>Email:<span style={{color:"red"}}>*</span></h6>
                            <input type="text"    onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  Mailing: e.target.value,
                                })
                              }/>
                          </span>
                          </div>
                      
                          </div>

                          <div className="gernal-info-field">
                        <div className="parent-name">

                        <span>
                            <h6> Phone(CELL):<span style={{color:"red"}}>*</span></h6>
                            <input type="text" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  ParentOnePhone: e.target.value,
                                })
                              } />
                          </span>
                      
                       
                                                
                        </div>

                        </div>
                      
                      <div className="gernal-info-field">
                          <div className="parent-name">
                         
                          <span>
                            <h6>Phone(Home):</h6>
                            <input type="text" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  parentOneEmail: e.target.value,
                                })
                              } />
                          </span>
                          </div>
                      
                          </div>

                      <div className="gernal-info-field">
                      <div className="parent-name" >

                      <span>
                            <h6>Phone(WORK):</h6>
                            <input type="text" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  parentTwoEmail: e.target.value,
                                })
                              } />
                          </span>
                          
                        </div>
                      
                        </div>


                        <div className="gernal-info-field" >
                        <div className="parent-name" >
                        <h6>State:</h6>
                          <select
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                State: e.target.name,
                              })
                            }
                          >
                                                        <option value="0">Select:</option>
                                                        {stateData.map(states=>(
                                                             <option value={states.StateID}>{states.State}</option>
                                                        ))}
                                                    </select>
                          
                        </div>
                      
                        </div>
                        <div className="gernal-info-field">
                        <div className="parent-name">
                        <span>
                            <h6>City:</h6>
                            <input type="text"  onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  Country: e.target.value,
                                })
                              } />
                          </span>
                         
                        </div>
                        </div>

                   

                        <div className="gernal-info-field" style={{"margin-top": "2%"}}>
                        <div className="parent-name">
                        <span>
                            <h6>Zip:</h6>
                            <input type="text" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  raceTwo : e.target.value,
                                })
                              }/>
                          </span>
                          
                        </div>
                        </div>

                     

                      

                      <div className="gernal-info-field">
                        
                       
                        </div>
                        

                      <div className="gernal-info-field">
                        
                        
                        </div>
                        

                      

                      <div className="check-input" >
                        
                        <div className="yn-checkbox mt-15 fd-c">
                          
                            <span>
                            <h6>Address:</h6>
                            <input type="text" onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  Address: e.target.value,
                                })
                              } />
                          </span>
                         
                        </div>
                      </div>

                      <div className="check-input">
                        
                        <div className="yn-checkbox fd-c">
                          
                        </div>
                      </div>
                    </div>


                    <div className="req-update-textarea pt-0 mt-25 b-0 .container1">
                          <div className="req-update-action ">
                            <Link
                              to="#"
                              onClick={closeClicked}
                              className="close"
                            >
                              Close
                            </Link>
                            <Link
                              to="#"
                              onClick={onSubmitButtonClick}
                              className="normal"
                            >
                              Submit
                            </Link>
                          </div>
                          {isLoading ? (<LoadSpinner />) : ''}
                        </div>
                    
                    <div className="form-section-3 section-3" style={{"margin-top": "550px"}}>
                      
                       <div className="yn">

                       
                      </div>
                    </div>
                  </form>*/}
              
     
    
  

