import Footer from "../../Footer";
import Header from "../../Header";
import "../CriticalUnusualIncidentForm/CriticalUnusualIncidentForm.scss";
import "../ImmidiateAssesmentForm/ImmidiateAssesmentForm.scss";
import mrinner from "../../../images/assets/formimages/mrinner.svg";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import FormSideBar from "../FormSidebar/FormSidebar";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { post } from "../../../utils/common";
import { useEffect, useState, useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import LoadSpinner from "../../Loader/LoadSpinner";

function MilageReimbursment() {
  const history = useHistory();
  const inputRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [AddFormData, setAddFormData] = useState([]);
  

  const onSubmitButtonClick = async (e) => {
    //setIsLoading(true);
    if(AddFormData.ClientID == undefined || AddFormData.From_CityID == undefined ||
      AddFormData.To_CityID == undefined || AddFormData.From_StateID == undefined || 
      AddFormData.To_StateID == undefined || AddFormData.ActivityDate== undefined ||
      AddFormData.ProviderMemberID== undefined || AddFormData.Description == undefined ) 
     { 
     alert("Please enter the Mandatory fields");
      return;
     }
     let options ={
      scale: 1,
      scrollY: window.scrollY,
      scrollX: window.scrollX,
      allowTaint: true,
      useCORS: true,
      width:1024
    };

    
    html2canvas(inputRef.current).then(async (canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      const doc = new jsPDF("p", "mm", "a4");
      const pageHeight = 295;
      const imgWidth = (canvas.width * 31) / 210;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;
      let position = 31;
  
      while (heightLeft >= 0) {
          doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
          if (heightLeft >= 0) {
              doc.addPage();
              position -= 295;
          }
      }
  
      setIsLoading(true);
  
      const out = doc.output("datauristring");
      const pdfData = out.split("base64,")[1];
  
      let EnteredBy = sessionStorage.getItem("username");
      let ProviderID = parseInt(sessionStorage.getItem("providerSessionId"));
      let ProviderName = sessionStorage.getItem("ProviderName");
  
      await post("/api/reimbursementhistory/insertmileage", {
          AddFormData,
          EnteredBy,
          ProviderID,
          ProviderName,
          pdf: pdfData
      });
  
      alert("Record Added successfully !");
  
      let fromButtonClick = localStorage.getItem("fromButtonClick");
      if (fromButtonClick === "true")
          window.location.href = "/reimbursmenthistory";
      else
          history.push("/form/formindex");
  
      alert("Email Sent");
  }).catch(error => {
      console.error("Error generating PDF:", error);
      setIsLoading(false);
  });
  
     

   
  };
  const closeClicked = () => {

    let fromButtonClick= localStorage.getItem("fromButtonClick");
    if(fromButtonClick === "true")
    window.location.href ="/reimbursmenthistory";
    history.push("/form/formindex");
  };

  const [personCompletingForm, setPersonCompletingForm] = useState([]);
  const [fromState, setFromState] = useState([]);
  const [fromCity, setFromCity] = useState([]);
  const [toState, setToState] = useState([]);
  const [toCity, setToCity] = useState([]);
  const [Rate, setRate] = useState([]);
  const [childrenData,setChildrenData] = useState([])
  //  const fetchDataX = async () => {
  //   const id = sessionStorage.getItem("providerSessionId");
  //   let data = await post("/api/currentchildren/index", { id });
  //   setChildrenData(data.data.data);
  //   let children_id = data.data.data[0].ClientID;
  //   setActiveId(children_id);
  //   let provider_id = id;
  //   let detailData = await post("/api/currentchildren/get-attach-document", {
  //     provider_id,
  //     children_id,
  //   });
  //   console.log("detailData", detailData);

  //   setAttachDocument(detailData.data.data);
  // };


  const fetchFormDataLoad = async () => {
    let providerid = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/reimbursementhistory/loadmileageform", {
      providerid,
      
    });

    const id = sessionStorage.getItem("providerSessionId");
    let dataX = await post("/api/currentchildren/index", { id });
    setChildrenData(dataX.data.data);

    setPersonCompletingForm(data.data.data.recordsets[2]);
    setFromState(data.data.data.recordsets[3]);
    setToState(data.data.data.recordsets[3]);
    setFromCity(data.data.data.recordsets[4]);
    setToCity(data.data.data.recordsets[4]);
    setRate(data.data.data.recordsets[5][0].Rate);
    setAddFormData({
      ...AddFormData,
      Rate: data.data.data.recordsets[5][0].Rate,
    });
  };

  const [amountRequested, setAmountRequested] = useState();
  const handleMileRateChange = (e) => {
    setAddFormData({
      ...AddFormData,
      TotalMiles: parseInt(e.target.value),
    });
    setAddFormData({
      ...AddFormData,
      Total: parseInt(e.target.value * Rate),
    });
    setAddFormData({
      ...AddFormData,
      AmountRequested: parseInt(e.target.value * Rate),
    });
    setAmountRequested(parseInt(e.target.value * Rate));
  };

  console.log(AddFormData);

  useEffect(() => {
    fetchFormDataLoad();
  }, []);

  return (
    <div className="main">
      <Header />

      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <FormSideBar />
              <div className="tab-detail Critical" ref={inputRef}>
                <div className="tab-detail-top">
                  <div className="Critical-unusual">
                    <img src={mrinner} alt="" />
                    Mileage Reimbursement
                  </div>
                </div>
                <div className="tab-detail-bottom">
                  <div className="form-with-sidebar">
                    <form className="mileage" ref={inputRef}>
                      <div className="form-row dandt fdc">
                        <div className="one-third">
                          <label for="birthday">Person Completing Form:<span style={{color:"red"}}>*</span></label>
                          <select
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                ProviderMemberID: parseInt(e.target.value),
                              })
                            }
                          >
                            <option value="0">Select:</option>
                            {personCompletingForm.map((personform) => (
                              <option value={personform.ProviderMemberID}>
                                {personform.ProviderName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="one-third">
                          <label for="birthday">Date:<span style={{color:"red"}}>*</span></label>
                          <input
                            type="date"
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                ActivityDate: new Date(
                                  e.target.value
                                ).toISOString(),
                              })
                            }
                          />
                        </div>
                        <div className="one-third">
                          <label for="appt">Trip Begin Time:<span style={{color:"red"}}>*</span></label>
                          <input type="time" />
                        </div>
                      </div>

                      <div className="form-row dandt fdc mt-0 ai-c">
                      <div className="one-third">
                          {/* <p>CHILDREN</p> <br /> */}
                          <label for="birthday">Children:<span style={{color:"red"}}>*</span></label>
                          <select
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                ClientID: parseInt(e.target.value),
                              })
                            }
                          >
                            <option value="0">Select</option>
                            {childrenData.map((child) => (
                              <option value={child.ClientID}>{child.ClientName}</option>
                            ))}
                          </select>
                        </div>

                        <div className="one-third">
                          {/* <p>CHILDREN</p> <br /> */}
                          <label for="birthday">State:<span style={{color:"red"}}>*</span></label>
                          <select
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                From_StateID: parseInt(e.target.value),
                              })
                            }
                          >
                            <option value="0">From...</option>
                            {fromState.map((from) => (
                              <option value={from.StateID}>{from.State}</option>
                            ))}
                          </select>
                        </div>
                        <div className="one-third">
                          {/* <p>SFM AUTHORIZATION</p> <br /> */}
                          <label for="birthday">City:<span style={{color:"red"}}>*</span></label>
                          <select
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                From_CityID: parseInt(e.target.value),
                              })
                            }
                          >
                            <option value="0">From...</option>
                            {fromCity.map((from) => (
                              <option value={from.CityID}>{from.City}</option>
                            ))}
                          </select>
                        </div>
                        <div className="one-third">
                          <span className="check-option">
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                              onChange={(e) =>
                                setAddFormData({
                                  ...AddFormData,
                                  RoundTrip: e.target.checked,
                                })
                              }
                            />
                            <label for="vehicle2"> Round Trip</label>
                          </span>
                        </div>
                      </div>
                      <div className="form-row dandt mt-0">
                        <div className="one-third">
                          <label for="birthday">State:<span style={{color:"red"}}>*</span></label>
                          <select
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                To_StateID: parseInt(e.target.value),
                              })
                            }
                          >
                            <option value="0">To....</option>
                            {toState.map((from) => (
                              <option value={from.StateID}>{from.State}</option>
                            ))}
                          </select>
                        </div>
                        <div className="one-third">
                          <label for="birthday">City:<span style={{color:"red"}}>*</span></label>
                          <select
                            onChange={(e) =>
                              setAddFormData({
                                ...AddFormData,
                                To_CityID: parseInt(e.target.value),
                              })
                            }
                          >
                            <option value="0">To....</option>
                            {toCity.map((from) => (
                              <option value={from.CityID}>{from.City}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="req-update-textarea pt-0 b-0">
                        <label>
                          <span>
                            <b>Description:<span style={{color:"red"}}>*</span></b>
                          </span>
                        </label>
                        <textarea
                          className="m-0"
                          onChange={(e) =>
                            setAddFormData({
                              ...AddFormData,
                              Description: e.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                      <div className="form-row dandt mt-0">
                        <div className="one-third">
                          <label for="birthday">Total Miles:<span style={{color:"red"}}>*</span></label>
                          <input
                            type="text"
                            onChange={(e) => handleMileRateChange(e)}
                          />
                        </div>
                        <div className="one-third">
                          <label for="birthday">Rate/Miles:</label>
                          <input type="text" value={Rate} />
                        </div>
                        <div className="one-third">
                          <label for="birthday">Amount Requested:</label>
                          <input type="text" value={amountRequested} />
                        </div>
                      </div>
                      {isLoading ? <LoadSpinner /> : ""}

                      <div className="imp-note mt-25">
                        <p className="note-p">
                          Mileage should be submitted within 180 days from the
                          date of service. Mileage outside SFM policy will be
                          denied unless an authorization number is noted. Please
                          provide a specific description of the purpose of the
                          trip. Missing information can cause delays in payment.
                          The reimbursement amount will be adjusted by staff as
                          mileage is amended or denied.
                        </p>
                      </div>

                      <div className="req-update-textarea pt-0 mt-25 b-0">
                        <div className="req-update-action">
                          <Link to="#" onClick={closeClicked} className="close">
                            Close
                          </Link>
                          {/* <Link
                            to="#"
                            onClick={onSubmitButtonClick}
                            className="normal"
                          >
                            Save as Draft
                          </Link> */}
                          <Link
                            to="#"
                            onClick={ ()=>onSubmitButtonClick("fromSubmit")}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                    </form>
                    <div className="form-note">
                      <div className="form-grey">
                        <ul>
                          <li>
                            *Note: If you do not know the SFM Authorization,
                            then you may leave the default set to None.
                          </li>
                          <li>* Future dates will not be allowed.</li>
                          <li>
                            * You will only be allowed to select a child if we
                            have them listed as being in your home on the
                            selected date.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MilageReimbursment;