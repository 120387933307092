import Footer from "../../Footer";
import Header from "../../../frontendPages/Header";
import ContentManagementSideBar from "../ContentManagementSideBar/ContentManagementSideBar";
import calenderinner from "../../../images/assets/cm/calenderinner.svg";
import ContentHeaderTopDetail from "../ContentHeaderTopDetail/ContentHeaderTopDetail";
import "../AdoptionContact/AdoptionContact.scss";
import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { post } from "../../../utils/common";
import { useHistory } from "react-router";
import axios from "axios";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import moment from "moment";
import ReactDOM from "react-dom";
import timeGridPlugin from "@fullcalendar/timegrid";

function CalenderOfEventEdit() {
  let calendarComponentRef = React.createRef();
  const history = useHistory();

  const [detailView, setShowDetailView] = useState(true);
  const [showEditDiv, setShowEditDiv] = useState(false);

  const ShowEditDiv = () => {
    setShowEditDiv(true);
    setShowDetailView(false);
  };
  const CloseEditDiv = () => {
    setShowEditDiv(false);
    setShowDetailView(true);
  };
  const hideAddEvenDivision = () => {
    window.location.href = "/contentmanage/calenderofevents";
  };

  const [eventStoreData, seteventStoreData] = useState([]);

  const [listOnPrivateCalender, setListOnPrivateCalender] = useState(false);

  const ListOnPrivateCalenderClicked = (e) => {
    if (e.target.checked) {
      setListOnPrivateCalender(true);
      seteventStoreData({ ...eventStoreData, IsSecure: e.target.checked });
    } else {
      setListOnPrivateCalender(false);
      seteventStoreData({ ...eventStoreData, IsSecure: e.target.checked });
    }
  };

  const [isDataAllDayEvent, setIsDataAllDayEvent] = useState(false);
  const AllDayEventClicked = (e) => {
    if (e.target.checked) {
      setIsDataAllDayEvent(true);
      seteventStoreData({ ...eventStoreData, IsAllDayEvent: e.target.checked });
    } else {
      setIsDataAllDayEvent(false);
      seteventStoreData({ ...eventStoreData, IsAllDayEvent: e.target.checked });
    }
  };

  //add calender data
  const onSubmitButtonClick = async (e) => {
    console.log(eventStoreData);
    e.preventDefault();
    eventStoreData.Description = (eventStoreData.Description).replace(/'/g,"''");
    const id = eventStoreData.CalendarEventID;
    await axios.put("/api/eventcalender/update/" + id, { eventStoreData });
    alert("Record Updated successfully !");
    // window.location.reload(true);
    history.push("/contentmanage/calenderofevents");
  };

  const deleteData = async () => {
    // console.log(id);
    const id = eventStoreData.CalendarEventID;
    try {
      await axios.delete(`/api/eventcalender/delete/${encodeURIComponent(id)}`);
      alert("Data Deleted SuccessFully");
      window.location.href = "/contentmanage/calenderofevents";
    } catch (err) {
      alert(err);
    }
  };

  const getDataToEdit = () => {
    let data = JSON.parse(sessionStorage.getItem("gridCalDataForEditing"));
    if (data.IsSecure) {
      setListOnPrivateCalender(true);
    }

    if (data.IsAllDayEvent) {
      setIsDataAllDayEvent(true);
    }

    seteventStoreData(data);
  };

  console.log("data", eventStoreData);
  useEffect(() => {
    getDataToEdit();
  }, []);

  return (
    <div className="main">
      <Header />
      <div className="main-content">
        <ContentHeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <ContentManagementSideBar />
              <div className="tab-detail" style={{"margin-bottom": "32px"}}>
                <div className="tab-detail-top children-tabs">
                  <div className="children-section">
                    <img src={calenderinner} alt="" />
                    <div className="pagesubtitle">Calendar of Events</div>
                  </div>
                  <div className="search-tab">
                    {/* <form action="/action_page.php">
                      <a href="#">Editing Item</a>
                    </form> */}
                  </div>
                </div>

                {detailView ? (
                  <div className="container">
                    <h2>Event Detail</h2>

                    <div className="container">
                      {/* {LocationData.map((d) => ( */}
                      <div className="event-detail-list">
                        <h3>Title:{eventStoreData.Title}</h3>
                        <small>
                          Start Date:{" "}
                          {new Date(eventStoreData.CalendarDate).toDateString()}
                        </small>
                        <p><b>Description:</b>{eventStoreData.Description}</p>
                        <p>
                          {" "}
                          <b>End Date:</b>
                          {new Date(eventStoreData.EndTime).toDateString()}
                        </p>
                        <b>Location :{eventStoreData.Location}</b>
                        <b>
                          {" "}
                          <Link
                            to={{ pathname: eventStoreData.LinkPath }}
                            target="_blank"
                          >
                            {eventStoreData.LinkPath}
                          </Link>
                        </b>
                        <div>
                        <Link
                          to="#"
                          onClick={() => ShowEditDiv()}
                          className="normal"
                        >
                          Edit
                        </Link>{" "}
                        <Link
                          to="#"
                          onClick={() => deleteData()}
                          className="close"
                        >
                          Delete
                        </Link>
                        </div>
                        <br></br>
                        <br></br>
                      </div>
                      {/* ))} */}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {showEditDiv ? (
                  <div className="tab-detail-bottom children-data">
                    <form className="children-doc-form">
                      <div className="form-row jc-sb">
                        <div className="one-half">
                          <label>Title:</label>
                          <input
                            type="text"
                            onChange={(e) =>
                              seteventStoreData({
                                ...eventStoreData,
                                Title: e.target.value,
                              })
                            }
                            value={eventStoreData.Title}
                          />
                        </div>
                        <div className="one-fourth">
                          <label>SFM Office Location:</label>
                          {/* <input type="text" /> */}
                          <select name="cars" id="cars">
                            <option value="volvo">Kansas</option>
                            <option value="saab">OKLAHAMA</option>
                            <option value="mercedes">Nebraska</option>
                          </select>
                        </div>
                        <div className="one-fourth">
                          <label>Date of Event:</label>
                          <input
                            type="date"
                            onChange={(e) =>
                              seteventStoreData({
                                ...eventStoreData,
                                CalendarDate: e.target.value,
                              })
                            }
                            value={eventStoreData.CalendarDate.slice(0, 10)}
                          />
                        </div>
                      </div>
                      <div className="checkbox-design mb-20">
                        <span>
                          <input
                            type="checkbox"
                            onChange={ListOnPrivateCalenderClicked}
                            name="private-calender"
                            value="private-calender"
                            checked={
                              eventStoreData.IsSecure == true ? "checked" : ""
                            }
                          />
                          <label htmlFor="private-calender">
                            {" "}
                            List on Private Calendar only
                          </label>
                        </span>
                        {listOnPrivateCalender ? (
                          <div>
                            <span>
                              <input
                                type="checkbox"
                                name="Priority Event"
                                value="Priority Event"
                                checked={
                                  eventStoreData.IsFosterFamily == true
                                    ? "checked"
                                    : ""
                                }

                                onChange={(e) =>
                                  seteventStoreData({
                                    ...eventStoreData,
                                    IsFosterFamily: e.target.checked,
                                  })
                                }
                              />
                              <label htmlFor="Priority Event">
                                KS Foster Family
                              </label>
                            </span>
                            <span>
                              <input
                                type="checkbox"
                                name="All Day Event"
                                value="All Day Event"
                                onChange={(e) =>
                                  seteventStoreData({
                                    ...eventStoreData,
                                    IsKinshipFamily: e.target.checked,
                                  })
                                }
                                checked={
                                  eventStoreData.IsKinshipFamily == true
                                    ? "checked"
                                    : ""
                                }
                              />
                              <label htmlFor="All Day Event">
                                KS Kinship Family
                              </label>
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                        <span>
                          <input
                            type="checkbox"
                            name="Priority Event"
                            value="Priority Event"
                            checked={
                              eventStoreData.OnMainPage == true ? "checked" : ""
                            }

                            onChange={(e) =>
                              seteventStoreData({
                                ...eventStoreData,
                                OnMainPage: e.target.checked,
                              })
                            }
                          />
                          <label htmlFor="Priority Event">Priority Event</label>
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            name="All Day Event"
                            value="All Day Event"
                            onChange={AllDayEventClicked}
                            checked={
                              eventStoreData.IsAllDayEvent == true
                                ? "checked"
                                : ""
                            }
                          />
                          <label htmlFor="All Day Event">All Day Event</label>
                        </span>
                        <span>
                          <small>* Note: Uncheck to enter time.</small>
                        </span>
                      </div>
                      <div className="checkbox-design mb-30">
                        <b>Event Mode:</b>
                        <span>
                          <input
                            type="checkbox"
                            name="In-person training"
                            value="In-person training"
                            onChange={(e) =>
                              seteventStoreData({
                                ...eventStoreData,
                                isInPerson: e.target.checked,
                              })
                            }
                            checked={
                              eventStoreData.isInPerson == true
                                ? "checked"
                                : ""
                            }
                          />
                          <label htmlFor="In-person training">
                            {" "}
                            In-person training
                          </label>
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            name="Value training"
                            value="Value training"

                            onChange={(e) =>
                              seteventStoreData({
                                ...eventStoreData,
                                isVirtual: e.target.checked,
                              })
                            }
                            checked={
                              eventStoreData.isVirtual == true
                                ? "checked"
                                : ""
                            }
                          />
                          <label htmlFor="Value training">
                            Virtual training
                          </label>
                        </span>
                      </div>
                      <div className="form-row mb-30 jc-sb">
                        {isDataAllDayEvent ? (
                          ""
                        ) : (
                          <>
                            <div className="one-fourth">
                              <label>
                                Begin Time:{" "}
                                {eventStoreData.CalendarDate.slice(11, 16)}
                              </label>
                              <input
                                type="time"
                                onChange={(e) =>
                                  seteventStoreData({
                                    ...eventStoreData,
                                    BeginTime: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="one-fourth">
                              <label>
                                End Time: {eventStoreData.EndTime.slice(11, 16)}
                              </label>
                              <input
                                type="time"
                                onChange={(e) =>
                                  seteventStoreData({
                                    ...eventStoreData,
                                    EndTime: e.target.value,
                                  })
                                }
                                // value={eventStoreData.EndTime.slice(12, 16)}
                              />
                            </div>
                          </>
                        )}
                        <div className="one-half">
                          <label>Event Location:</label>
                          <input
                            type="text"
                            onChange={(e) =>
                              seteventStoreData({
                                ...eventStoreData,
                                Location: e.target.value,
                              })
                            }
                            value={eventStoreData.Location}
                          />
                        </div>
                      </div>
                      <div className="req-update-textarea pt-0 pl-0 pr-0">
                        <label className="pl-0">Description</label>
                        <textarea
                          className="mt-0"
                          onChange={(e) =>
                            seteventStoreData({
                              ...eventStoreData,
                              Description: e.target.value,
                            })
                          }
                          value={eventStoreData.Description}
                        ></textarea>
                        <div className="form-row mb-20 fd-c">
                          <label className="pl-0">Link path:</label>
                          <input
                            type="text"
                            onChange={(e) =>
                              seteventStoreData({
                                ...eventStoreData,
                                LinkPath: e.target.value,
                              })
                            }
                            value={eventStoreData.LinkPath}
                          />
                        </div>
                        <div className="req-update-action">
                          <Link to="#" onClick={CloseEditDiv} className="close">
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={onSubmitButtonClick}
                            className="normal"
                          >
                            {" "}
                            Submit
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CalenderOfEventEdit;
