// import '../../CriticalUnusualIncidentForm/CriticalUnusualIncidentForm.scss'
import '../../Forms/ImmidiateAssesmentForm/ImmidiateAssesmentForm.scss'
import immediateinner from '../../../images/assets/formimages/immediateinner.svg';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import React, { useState } from 'react';
import download from '../../../images/assets/myinfo/download.svg';
import ReactToPrint from 'react-to-print';
import { useRef } from 'react';
import lodinner from '../../../images/assets/formimages/lodinner.svg';




const ComponentToPrint = React.forwardRef((props, ref) => (



    <div className="main" ref={ref}>

        <div className="main-content" >
            {/* <HeaderTopDetail /> */}
            <div className="pagewithsidebar">
                <div className="container">
                    <div className="pagewithsidebar-inner">
                        {/* <FormSideBar /> */}
                        <div className="tab-detail Critical">
                            <div className="tab-detail-top">
                                <div className="Critical-unusual">
                                    <img src={lodinner} alt="" />
                                    LoG of Daily Events
                                </div>
                            </div>
                            <div className="tab-detail-bottom">
                                <form>
                                    <div className="form-row dandt">
                                        <div className="one-third">
                                            <label for="birthday">Date:</label>
                                            <input type="date" />
                                        </div>
                                        <div className="one-third">
                                            <label for="birthday">Person Completing Lode:</label>
                                            <select>
                                                <option value="0">Select:</option>
                                                {/* <option value="1">Audi</option>
                                                    <option value="2">BMW</option>
                                                    <option value="3">Citroen</option> */}
                                            </select>
                                        </div>
                                        <div className="one-third">
                                            <label for="appt">Youth:</label>
                                            <select>
                                                <option value="0">Select:</option>
                                                {/* <option value="1">Audi</option>
                                                    <option value="2">BMW</option>
                                                    <option value="3">Citroen</option> */}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="imp-note mt-25">
                                        <span>TIPS FOR COMPLETING OBSERVATIONS</span>
                                        <ol>
                                            <li>Please be factual. Please share positives and negatives.</li>
                                            <li>Highlight progress of case plan goals/objectives.</li>
                                            <li>Your lode is an official record of youth's care provision.</li>
                                            <li>Please return to your worker at home visit, email or SFM Families Website.</li>
                                        </ol>
                                    </div>

                                    <div className="form-row fr-w mt-25">
                                        <h6 className="small-text">Appointments</h6>
                                        <label className="p-0" for="birthday">Please address appointments in your daily observation log below.</label>
                                    </div>
                                    <div className="form-row dandt fr-w mt-25">
                                        <div className="one-half">
                                            <label for="birthday">Medical:</label>
                                            <input type="text" />
                                        </div>
                                        <div className="one-half">
                                            <label for="birthday">School Meetings:</label>
                                            <input type="text" />
                                        </div>
                                        <div className="one-half">
                                            <label for="birthday">Case Plan:</label>
                                            <input type="text" />
                                        </div>
                                        <div className="one-half">
                                            <label for="birthday">Mental Health:</label>
                                            <input type="text" />
                                        </div>
                                        <div className="one-half">
                                            <label for="birthday">Dentist:</label>
                                            <input type="text" />
                                        </div>
                                        <div className="one-half">
                                            <label for="birthday">Court:</label>
                                            <input type="text" />
                                        </div>
                                        <div className="one-half">
                                            <label for="birthday">Vision:</label>
                                            <input type="text" />
                                        </div>
                                        <div className="one-half">
                                            <label for="birthday">Other:</label>
                                            <input type="text" />
                                        </div>
                                    </div>

                                    <div className="form-grey mt-25">
                                        <h6 className="small-text">BIRTH FAMILY CONTACT</h6>
                                        <div className="form-row">
                                            <div className="one-third">
                                                <label>Date and Time (Home Visit):</label>
                                                <input type="text" />
                                            </div>
                                            <div className="one-third">
                                                <label>Date and Time (Home Visit):</label>
                                                <input type="text" />
                                            </div>
                                            <div className="one-third">
                                                <label>Date and Time (Home Visit):</label>
                                                <input type="text" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="one-third">
                                                <label>Name / Relationship:</label>
                                                <input type="text" />
                                            </div>
                                            <div className="one-third">
                                                <label>Name / Relationship:</label>
                                                <input type="text" />
                                            </div>
                                            <div className="one-third">
                                                <label>Name / Relationship:</label>
                                                <input type="text" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="req-update-textarea pt-0">
                                        <label><span><b>Narrative</b></span></label>
                                        <textarea></textarea>
                                        <div className="req-update-action">
                                            <Link to="#" className="close">Close</Link>
                                            <Link to="#" className="normal">Save and Close</Link>
                                            <Link to="#" className="normal">Submit</Link>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div >

));
// }


// export default ImmidiateAssesmentFormDownload;



const LogOfDailyEventsDownload = () => {
    const componentRef = useRef();
    return (
        <div >
            <ReactToPrint
                trigger={() =>
                    <button

                        class="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                    >
                        <img src={download}></img>
                    </button>
                }
                content={() => componentRef.current}
            />
            <div className="hiddenPrintable">
                <ComponentToPrint ref={componentRef} />
            </div>
        </div>
    );
}

export default LogOfDailyEventsDownload;