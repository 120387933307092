import React, { useState, useEffect } from 'react';
import { red } from 'tailwindcss/colors';
import { backgroundImage } from 'tailwindcss/defaultTheme';
import Footer from '../Footer';
import Header from '../Header';
import test from '../fosterFamilyInquiry/fosterFamilyInquiry.scss'


function FosterFamilyInquiry() {


   return (


      <div className="main">

         <Header />

         <div className="main-content" >
            <div className="small-container">
               <div className="fosterfamilyinquiry">
                  <h2>Foster Family Inquiry</h2>
                  <p>To learn more or become part of a professional team caring for children,
                     please fill out and submit the form below.</p>

                  <div className="foster-form">
                     <form>
                        <div class="ot-field">
                           <label class="form-label">First Name :</label>
                           <input type="text" class="form-control" />
                        </div>

                        <div class="ot-field">
                           <label class="form-label">Last Name :</label>
                           <input type="text" class="form-control" />
                        </div>
                        <div class="ot-field">
                           <label class="form-label">Spouse’s Name :</label>
                           <input type="text" class="form-control" />
                        </div>
                        <div class="ot-field">
                           <label class="form-label">Street Address :</label>
                           <input type="text" class="form-control" />
                        </div>
                        <div class="ot-field">
                           <label class="form-label">City :</label>
                           <input type="text" class="form-control" />
                        </div>
                        <div class="ot-field">
                           <label class="form-label">State :</label>
                           <select class="form-select" aria-label="Default select example">
                              <option selected>Select</option>
                              {/* <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option> */}
                           </select>
                        </div>
                        <div class="ot-field">
                           <label class="form-label">Zip :</label>
                           <input type="text" class="form-control" />
                        </div>
                        <div class="ot-field">
                           <label class="form-label">Home Phone :</label>
                           <input type="text" class="form-control" />
                        </div>
                        <div class="ot-field">
                           <label class="form-label">Work Phone :</label>
                           <input type="text" class="form-control" />
                        </div>
                        <div class="ot-field">
                           <label class="form-label">E-Mail :</label>
                           <input type="email" class="form-control" />
                        </div>
                        <div class="ot-field">
                           <label class="form-label">Referral Source :</label>
                           <select class="form-select">
                              <option selected>Select</option>
                              {/* <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option> */}
                           </select>
                        </div>


                        <div class="captcha-btn">

                        </div>
                        <div class="act-btn">
                           <a href="#" className="bg-outline">Reset Form</a>
                           <a href="#" className="bg-green">Send request</a>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
         <Footer />

      </div>

   );
}




export default FosterFamilyInquiry;
